import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import SearchField from "../../constants/SearchField";
import axios from "axios";
import { useSelector } from "react-redux";

function LogMaintain() {
    const loadData = useSelector((state) => state.loadReducer);

    const localData = JSON.parse(localStorage.getItem("userData")).data[0];
    let userID = localData.userid;
    let companyid = localData.companyid;

  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);

  useEffect(()=>{


    axios.post(`https://mis.saanviavs.com/api/v1/user/loginhistory`, {keyword:"₹ΘθϖΠ", userid:userID, companyid})

    .then((res)=>{
        setData(res.data.data);
    })
    .catch((err)=>console.log(err))
  },[])

   //search logic
   const filteredData =
   data.length > 0 &&
   data.filter((item) =>
     searchKeyword
       ? Object.values(item).some((value) =>
           value
             ?.toString()
             ?.toLowerCase()
             ?.includes(searchKeyword.toLowerCase().trim())
         )
       : true
   );

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={10}>
              <p className="section-title form-section ptag">
                List of All <span className="form-title">Log History</span>
              </p>
            </Col>
            <Col lg={2}>
              <SearchField
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
            {/* <Col lg={2}>

           {crud && crud.includes("c") && <Button className="commonBtn mt-2" onClick={handleAddTermNavigate}>

              Add Term
            </Button>}
          </Col> */}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="table-main-container">
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th>User Id</th>
                <th>Time</th>
                <th>Date</th>
                <th>IP Address</th>
                <th>City</th>
                <th>Region</th>
                <th>Country</th>
                <th>ISP</th>
              </tr>
            </thead>
            <tbody>
            {loadData ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.userid}</td>
                  <td>{item.time}</td>
                  <td>{item.date}</td>
                  <td>{item.ip}</td>
                  <td>{item.city}</td>
                  <td>{item.state}</td>
                  <td>{item.country}</td>
                  <td>{item.isp}</td>

                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default LogMaintain;
