import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import ReactToPrint from "react-to-print";
import sanviLogo from "../../../assets/sanvi_logo.png";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getPoByid } from "../../../constants/api";
import { useNavigate, useParams } from "react-router-dom";
import { notifyError } from "../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { updatePurchaseOrder } from "../../../redux/actions/purchaseOrder.actions";
import PurchaseOrderPrint from "./PurchaseOrderPrint";

const ViewPurchaseOrder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const URL = extractAndFormatURLPart("purchase");
  let contentRef = useRef(null);
  const { companyid, userid, companyname, branchid, name, branch } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { hoaddress, gstno, pan } =
    useSelector((state) => state.authManager.userData.companyaddress) || {};

  const [data, setData] = useState({
    vendorname: "",
    purchaseorderno: "",
    purchasedate: "",
    deliverytype: "",
    specifytype: "",
    paymentterms: "",
    paymentduedate: "",
    vendorgst: "",
    locationgst: "",
    contactperson: "",
    assignedtype: "",
    deliveryinstruction: "",
    deliverylocation: "",
    specifylocation: "",
    expecteddeliverydate: "",
    assignedby: "",
    address: "",
    deliveryaddress: "",
    igst: "",
    cgst: "",
    sgst: "",
    itemarray: {
      arr: [
        {
          itemname: "",
          itemid: "",
          gst: 0,
          qty: 0,
          rate: 0,
          amount: 0,
          gstamount: "",
          unit: "",
          category: "",
          discount: 0,
          discountamount: 0,
          brand: "",
        },
      ],
    },
    subtotal: 0,
    discount: 0,
    discountamount: 0,
    adjustment: 0,
    tcs: "",
    tcsamount: "",
    finalamount: 0,
    tandc: "",
    company: companyname,
    companyid: companyid,
    branch: branch,
    branchid: branchid,
    userid: userid,
    status: "",
    billedstatus: "",
    month: "",
    financialyear: "",
    id: id,
    org: "",
  });
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(getPoByid, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    vendorname,
    purchaseorderno,
    purchasedate,
    deliverytype,
    deliverylocation,
    specifylocation,
    address,
    deliveryaddress,
    paymentterms,
    paymentduedate,
    contactperson,
    assignedby,
    deliveryinstruction,
    expecteddeliverydate,
    itemarray,
    subtotal,
    discount,
    discountamount,
    adjustment,
    sgst,
    cgst,
    igst,
    finalamount,
    tandc,

    vendorgst,
    locationgst,
    status,
  } = data || {};

  const iscgst =
    vendorgst[0] == locationgst[0] && vendorgst[1] == locationgst[1];

  const printStyles = `
  @page {
    size: auto;
    margin: 0;
  }
  body {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .print-content {
    width: 100% !important;
  }
  @media print {
    .page-break {
      page-break-before: always;
    }
  }
`;

  // const handlePrint = () => {
  //   const printWindow = window.open("", "", "width=1000,height=1000");
  //   const modalContent = contentRef.current;
  //   const tablepartToPrint =
  //     modalContent.querySelector(".print-table").innerHTML;
  //   printWindow.document.write(tablepartToPrint);
  // };

  const handleMarkApprove = () => {
    dispatch(updatePurchaseOrder({ ...data, status: "Approved" }, navigate));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <Row>
              <Col>
                <h1>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={() => navigate("/purchase/purchaseorder")}
                  />
                  Purchase <span className="form-title">Order</span>
                </h1>
              </Col>
              <Col>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex justify-content-between align-items-center">
                    <h2>Status: </h2>
                    &nbsp;
                    <h2
                      style={{
                        color: status == "Draft" ? "red" : "green",
                      }}
                    >
                      {status}
                    </h2>
                  </div>
                  <div className="d-flex gap-2">
                    {status == "Draft" ? (
                      <Button
                        className="commonBtn p-3"
                        onClick={handleMarkApprove}
                      >
                        Mark Approve
                      </Button>
                    ) : null}
                    {status == "Draft" ? null : (
                      <ReactToPrint
                        trigger={() => (
                          <Button className="commonBtn smallBtn cancelBtn px-5">
                            Print
                          </Button>
                        )}
                        content={() => contentRef}
                        pageStyle={printStyles}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <Col ref={contentRef}>
              <div className="print-content">
                <div
                  className="dr-logo-heading dr-logo-headingp"
                  style={{ border: "none" }}
                >
                  <div>
                    <img src={sanviLogo} alt="logo" width={100} height={100} />
                  </div>
                  <div className="company-information">
                    <h2>Saanvi Enterprises</h2>
                    <h4>M-8,Basanti Colony , Rourkela</h4>
                    <h4 className="font-bold">GSTIN/UIN: 21AEYPC5887R1ZH</h4>
                    <h4>State Name : Odisha, Code : 21</h4>
                    <h4> E-Mail : saanvienterprises.rkl@gmail.com</h4>
                  </div>
                  <div>
                    <Button
                      className="commonBtn p-2 mb-1"
                      style={{ border: "none" }}
                    >
                      Purchase Order
                    </Button>
                    <h4>Phone No:- +915847585487</h4>
                    <h4>Website:- www.sanviavs.com</h4>
                    <h4 className="font-bold">
                      Purchase Order:- {purchaseorderno}
                    </h4>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center bill-ship-info">
                  <div>
                    <h4>From:- {vendorname ? vendorname : "--"}</h4>
                    <h4>Address:- {address}</h4>
                    <h4>GST:- {vendorgst}</h4>
                  </div>
                  <div>
                    <h4>Deliver To:- Saanvi Enterprises</h4>
                    <h4>Address:- {deliveryaddress}</h4>
                    <h4>GST:- {locationgst}</h4>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center py-1"
                  style={{ borderBottom: "1px solid black" }}
                >
                  <div>
                    <h4>Payment Terms:- {paymentterms}</h4>
                    <h4>Payment Due Date:- {paymentduedate}</h4>
                    <h4>Payment Due Date:- {contactperson}</h4>
                  </div>
                  <div>
                    <h4>Delivery Type:- {deliverytype}</h4>
                    <h4>Expected Delivery Date:- {expecteddeliverydate}</h4>
                    <h4>Delivery Instructions:- {deliveryinstruction}</h4>
                  </div>
                  <div>
                    <h4>Purchase Date:- {purchasedate}</h4>
                    <h4>Assigned By:- {assignedby}</h4>
                  </div>
                </div>
                <Table responsive className="table-color fs-4">
                  <thead>
                    <tr>
                      <th className="th-color">Item Name</th>
                      <th className="th-color">Unit</th>
                      <th className="th-color">QTY</th>
                      <th className="th-color">Rate</th>
                      <th className="th-color">GST%</th>
                      <th className="th-color">Discount%</th>
                      <th className="th-color">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemarray.arr.length > 0 ? (
                      itemarray.arr.map((item, ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              {item.itemid}-{item.brand}-{item.itemname}
                            </td>
                            <td>{item.unit}</td>
                            <td>{item.qty}</td>
                            <td>{item.rate}</td>
                            <td>{item.gst}</td>
                            <td>{item.discount}</td>
                            <td>{item.discountamount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7">
                          <div className="datanotfound-center-text">
                            No Item Found 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                    {new Array(15).fill("")?.map((emtr, i) => (
                      <tr key={i}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td className="tddata-right">Sub Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold td-top-border">{subtotal}</td>
                    </tr>
                    {new Array(15).fill("")?.map((emtr, i) => (
                      <tr key={i}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                    <tr className="table-row-bg">
                      <td></td>
                      <td className="tddata-right">Total</td>
                      <td className="font-bold">
                        {itemarray.arr.reduce((acc, curr) => {
                          return Number(acc) + Number(curr.qty);
                        }, 0)}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="font-bold">{subtotal}</td>
                    </tr>
                  </tbody>
                </Table>
                <br />
                <Table
                  responsive
                  className="table-bordered fs-4 text-center"
                  style={{ border: "1px solid black" }}
                >
                  <thead>
                    <tr>
                      <th rowSpan={2}>Item Id</th>
                      <th rowSpan={2}>Amount</th>
                      {iscgst ? <th colSpan={2}>CGST</th> : null}
                      {iscgst ? <th colSpan={2}>SGST</th> : null}
                      {!iscgst ? <th colSpan={2}>IGST</th> : null}
                      <th rowSpan={2}>Total</th>
                    </tr>
                    <tr>
                      {iscgst ? <th>%</th> : null}
                      {iscgst ? <th>Amount</th> : null}
                      {iscgst ? <th>%</th> : null}
                      {iscgst ? <th>Amount</th> : null}
                      {!iscgst ? <th>%</th> : null}
                      {!iscgst ? <th>Amount</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {itemarray.arr?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.itemid}</td>
                        <td>{item.discountamount}</td>
                        {iscgst ? <td>{Number(item.gst) / 2}</td> : null}
                        {iscgst ? (
                          <td>
                            {(
                              (((Number(item.discountamount) *
                                Number(item.gst)) /
                                2) *
                                1) /
                              100
                            ).toFixed(2)}
                          </td>
                        ) : null}
                        {iscgst ? <td>{Number(item.gst) / 2}</td> : null}
                        {iscgst ? (
                          <td>
                            {(
                              (((Number(item.discountamount) *
                                Number(item.gst)) /
                                2) *
                                1) /
                              100
                            ).toFixed(2)}
                          </td>
                        ) : null}
                        {!iscgst ? <td>{Number(item.gst)}</td> : null}
                        {!iscgst ? (
                          <td>
                            {(
                              (Number(item.discountamount) * Number(item.gst)) /
                              100
                            ).toFixed(2)}
                          </td>
                        ) : null}
                        <td></td>
                      </tr>
                    ))}
                    <tr>
                      <td className="font-bold">Adjustment:- ({adjustment})</td>
                      <td className="font-bold">
                        {itemarray.arr
                          .reduce((acc, curr) => {
                            return Number(acc) + Number(curr.discountamount);
                          }, 0)
                          .toFixed(2)}
                      </td>
                      {iscgst ? <td></td> : null}
                      {iscgst ? <td className="font-bold">{cgst}</td> : null}
                      {iscgst ? <td></td> : null}
                      {iscgst ? <td className="font-bold">{sgst}</td> : null}
                      {!iscgst ? <td></td> : null}
                      {!iscgst ? <td className="font-bold">{igst}</td> : null}
                      <td className="font-bold">{finalamount}</td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <div className="d-flex" style={{ border: "1px solid black" }}>
                    <div
                      style={{ width: "65%", borderRight: "1px solid black" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <h4 className="text-center font-bold">
                        Terms & Conditions
                      </h4>
                    </div>
                    <div style={{ width: "35%" }}>
                      <h4 className="text-center font-bold">
                        Certified that particulars given above are true &
                        correct
                      </h4>
                      <h2 className="text-center font-bold">
                        For SAANVI ENTERPRISES
                      </h2>
                    </div>
                  </div>
                  <div
                    className="d-flex"
                    style={{ border: "1px solid black", borderTop: "none" }}
                  >
                    <div
                      style={{ width: "65%", borderRight: "1px solid black" }}
                    >
                      <h5 className="text-center font-bold">{data.tandc}</h5>
                    </div>
                    <div style={{ width: "35%" }}></div>
                  </div>
                  <div
                    className="d-flex"
                    style={{ border: "1px solid black", borderTop: "none" }}
                  >
                    <div
                      style={{ width: "65%", borderRight: "1px solid black" }}
                    ></div>
                    <div style={{ width: "35%" }}>
                      <h3 className="text-center">Authorised Signature</h3>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        <div className="d-none">
          <PurchaseOrderPrint ref={(el) => (contentRef = el)} data={data} />
        </div>
      </Container>
    </>
  );
};

export default ViewPurchaseOrder;
