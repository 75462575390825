import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";

const NewSeries = () => {
  const { companyid, userid, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [seriesIDData, setSeriesIDdata] = useState([]);
  const [brands, setBrands] = useState([]);
  const [data, setData] = useState({
    seriesid: "",
    brand: "",
    series: "",
    rate: "",
    companyid: companyid,
    userid: userid,
    org: org,
  });
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setData({
      seriesid: "",
      brand: "",
      series: "",
      rate: "",
    });
    dispatch(reloadPage(true));
  };

  //Series ID ----------------------------
  useEffect(() => {
    //Series Tbale api
    axios

      .post("https://mis.saanviavs.com/api/v1/item/getseries", {

        keyword: "" || org,
      })
      .then((res) => {
        const initialData = res.data && res.data.length > 0 ? res.data : [];
        setSeriesIDdata(initialData);
      })
      .catch((err) => console.log(err));

    //Brand Tbale api
    axios

      .post("https://mis.saanviavs.com/api/v1/item/getbrand", {

        userid,
        companyid,
        branchid,
        keyword: "" || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setBrands(initialData);
      })
      .catch((err) => console.log(err));
  }, [show]);

  const generateSeriesID = () => {
    if (seriesIDData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = seriesIDData[0].seriesid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };
  useEffect(() => {
    let count = generateSeriesID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevData) => ({
      ...prevData,
      seriesid: `SS/SAANVI/${paddedCount}`,
    }));
  }, [seriesIDData]);
  //----------------------------Series ID

  //Input Field Onchange ----------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // ---------------------------------------- Input Field Onchange

  //Submit Function ----------------------------------------
  const handleSave = async () => {
    if (data.brand == "" || data.series == "" || data.rate == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/addseries", {

        ...data,
        companyid: companyid,
        userid: userid,
        org: org,
      });
      if (res.data.message == "Series added successfully") {
        notifySuccess(res.data.message);
        handleClose();
      } else {
        notifyWarning(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // ----------------------------------------Submit Function

  return (
    <>
      <Button className="commonBtn mt-2" onClick={handleShow}>
        New Series
      </Button>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            New <span className="form-title">Series</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Series ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Series ID"
                    name="seriesid"
                    value={data.seriesid}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Brand <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="brand"
                    value={data.brand}
                    onChange={handleChange}
                    isInvalid={data.brand === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {brands.length > 0 &&
                      brands.map((ele, ind) => (
                        <option value={ele.brand} key={ind}>
                          {ele.brand}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Brand
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Series <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Series Name"
                    name="series"
                    value={data.series}
                    onChange={handleChange}
                    isInvalid={data.series === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Series Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Rate <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Rate"
                    name="rate"
                    value={data.rate}
                    onChange={handleChange}
                    isInvalid={data.rate === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Series Price
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NewSeries;
