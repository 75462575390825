import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import axios from "axios";
import { branchAllDataPost, warehouseIdCount } from "../../../../constants/api";
import { addWarehouse, warehouseData } from "../../../../redux/actions/warehouse.actions";
import { branchPagiData } from "../../../../redux/actions/branch.action";

const states = State.getStatesOfCountry("IN");

const AddForm = () => {
  const URL = extractAndFormatURLPart("admin");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid,branchid, companyname } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const branchMaster = useSelector(
    (state) => state.branchData.branchPagiData.data
  );
  const WHdata = useSelector((state) => state.warehouseData.warehouseData.data) || [];
  const [showEmptyError, setShowEmptyError] = useState(false);
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [data, setData] = useState({
    warehouseid: "",
    companyid: companyid,
    warehousetype: "",
    warehousename: "",
    address: "",
    status: "active",
    userid: userid,
    state: "",
    city: "",
    pincode: "",
    branch: "",
    branchid: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "state") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          city: "",
        }));
      } else {
        setSelectedState("");
        setData((prev) => ({
          ...prev,
          state: "",
          city: "",
        }));
      }
    }

    if (name == "branch") {
      if (value) {
        const selectedBranch = branchMaster.find(
          (branch) => branch.branchname == value
        );
        setData((prev) => ({
          ...prev,
          [name]: selectedBranch.branchname,
          branchid: selectedBranch.branchid,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          branch: "",
          branchid: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (
      !data.warehousetype ||
      !data.warehouseid ||
      !data.warehousename ||
      !data.branch
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(addWarehouse(data, navigate));
  };

  useEffect(() => {
    if (selectedState) {
      const citiesOfSelectedState = City.getCitiesOfState("IN", selectedState);
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setCities(cityNames);
    } else {
      setCities([]);
    }
  }, [selectedState]);

  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: "" || "₹ΘθϖΠ",
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(warehouseData(backendData, setLoading));
  }, [dispatch]);

  const generateCategoryID = () => {
    if (WHdata.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = WHdata[0].warehouseid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      warehouseid: `WH/SAANVI/${paddedCount}`,
    }));
  }, []);

  
  useEffect(() => {
    setLoading(!loading);
    dispatch(
      branchPagiData(
        { userid, company: companyname, companyid, keyword: "" },
        setLoading
      )
    );
    //fetchData();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/admin/godownOrShowroom")}
              />
              Add <span className="form-title main"> Godown/Showroom</span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Type <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="warehousetype"
                          value={data.warehousetype}
                          onChange={handleChange}
                          isInvalid={!data.warehousetype && showEmptyError}
                        >
                          <option value="">Select</option>
                          <option value="Godown">Godown</option>
                          <option value="Showroom">Showroom</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          ID<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          name="warehouseid"
                          value={data.warehouseid}
                          onChange={handleChange}
                          isInvalid={!data.warehouseid && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Name<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Warehouse Name"
                          name="warehousename"
                          value={data.warehousename}
                          onChange={handleChange}
                          isInvalid={!data.warehousename && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Branch <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="branch"
                          value={data.branch}
                          onChange={handleChange}
                          isInvalid={!data.branch && showEmptyError}
                        >
                          <option value="">Select</option>
                          {branchMaster
                            ?.filter((branch) => branch.status == "active")
                            .map((branch) => {
                              const { branchname, id } = branch;
                              return (
                                <option value={branchname} key={id}>
                                  {branchname}
                                </option>
                              );
                            })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Adress</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Address"
                          name="address"
                          value={data.address}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>State</Form.Label>
                            <Form.Select
                              name="state"
                              value={data.state}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {states.map((state) => {
                                return (
                                  <option
                                    value={state.name}
                                    key={state.isoCode}
                                  >
                                    {state.name}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Select
                              name="city"
                              value={data.city}
                              onChange={handleChange}
                            >
                              <option value="">Select</option>
                              {cities.map((city, ind) => {
                                return (
                                  <option value={city} key={ind}>
                                    {city}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                              type="number"
                              name="pincode"
                              value={data.pincode}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/admin/godownOrShowroom")}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default AddForm;
