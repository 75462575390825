import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { IoDocumentAttach } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { notifyError } from "../../../constants/toastAlert";

const ViewProject = ({ item, showCustomer, setShowCustomer }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    // setShowCustomer(false);
    setShow(false);
  };

  const {
    type,
    projectid,
    customerfullname,
    projectname,
    projectstartdate,
    projectenddate,
    projectlocation,
    architect,
    contractor,
    status,
    attachments,
    remarks,
    company,
    companyid,
    userid,
    branchid,
    branch,
    org,
    architectarray,
    contractorarray,
  } = item || {};

  const [data, setData] = useState({
    saleorderData: [],
    saleinvoiceData: [],
    quoteData: [],
  });

  useEffect(() => {
    axios

      .post("https://mis.saanviavs.com/api/v1/projects/projectdetails", { projectid })
      .then((res) =>
        res.data
          ? setData((prev) => ({ ...prev, ...res.data }))
          : setData((prev) => prev)
      )
      .catch((err) => {
        console.log(err);
        notifyError(err);
      });
  }, []);

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={showCustomer || show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="form-title colStyle">Project Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Project ID:</Col>
                  <Col className="viewpage-value">
                    {projectid ? projectid : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Project Type:</Col>
                  <Col className="viewpage-value">{type ? type : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  {company ? (
                    <>
                      <Col className="viewpage-title">Company Name:</Col>
                      <Col className="viewpage-value">{company}</Col>
                    </>
                  ) : (
                    <>
                      <Col className="viewpage-title">Customer Name:</Col>
                      <Col className="viewpage-value">{item.customerfullname}</Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Project Name:</Col>
                  <Col className="viewpage-value">
                    {projectname ? projectname : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Project Location:</Col>
                  <Col className="viewpage-value">
                    {projectlocation ? projectlocation : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Status:</Col>
                  <Col className="viewpage-value">{status ? status : "--"}</Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Start Date:</Col>
                  <Col className="viewpage-value">
                    {projectstartdate ? projectstartdate : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">End Date:</Col>
                  <Col className="viewpage-value">
                    {projectenddate ? projectenddate : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Architect:</Col>
                  <Col className="viewpage-value">
                    {architect ? architect : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Contractor:</Col>
                  <Col className="viewpage-value">
                    {contractor ? contractor : "--"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Remarks:</Col>
                  <Col className="viewpage-value">
                    {remarks ? remarks : "--"}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row className="mt-1 mb-1">
                  <Col className="viewpage-title">Attachments:</Col>
                  <Col className="viewpage-value">
                    <div className="uploaded-data">
                      {attachments.length > 0 ? (
                        attachments.map((item, index) => {
                          return (
                            <span key={index}>
                              <a href={item} target="blank">
                                <IoDocumentAttach />
                              </a>
                            </span>
                          );
                        })
                      ) : (
                        <div className="uploaded-data">
                          <span>No Document Found</span>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <br />
            {data.quoteData.length > 0 ? (
              <Row>
                <Col md={12} lg={12} sm={12}>
                  <h2>Available Quotations</h2>
                  <br />
                </Col>
                <Col md={12} lg={12} sm={12} className="table-main-container">
                  <Table responsive className="tableContainer">
                    <thead className="bg-secondary">
                      <th>Quotation No.</th>
                      <th>Quotation Date</th>
                      <th>Customer ID</th>
                      <th>Customer Name</th>
                      <th>Project</th>
                      <th>Sale Person</th>
                      <th>Expiry Date</th>
                    </thead>
                    <tbody>
                      {data.quoteData.map((qt, ind) => (
                        <tr key={ind}>
                          <td>{qt.quoteno}</td>
                          <td>{qt.quotedate}</td>
                          <td>{qt.customerid}</td>
                          <td>{qt.customername}</td>
                          <td>{qt.project}</td>
                          <td>{qt.saleperson}</td>
                          <td>{qt.expiredby}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}
            {data.saleorderData.length > 0 ? (
              <Row>
                <Col md={12} lg={12} sm={12}>
                  <h2>Available Sale Orders</h2>
                  <br />
                </Col>
                <Col md={12} lg={12} sm={12} className="table-main-container">
                  <Table responsive className="tableContainer">
                    <thead className="bg-secondary">
                      <th>Date</th>
                      <th>Sale Order No</th>
                      <th>Quotation No</th>
                      <th>Customer Name</th>
                      <th>Sale Person</th>
                      <th>Order Status</th>
                      <th>Invoiced Status</th>
                      <th>SO Amount</th>
                    </thead>
                    <tbody>
                      {data.saleorderData.map((so, ind) => (
                        <tr key={ind}>
                          <td>{so.saleorderdate}</td>
                          <td>{so.saleorderno}</td>
                          <td>{so.quoteno}</td>
                          <td>{so.customername}</td>
                          <td>{so.salesperson}</td>
                          <td>{so.orderstatus}</td>
                          <td>{so.invoicestatus}</td>
                          <td>{so.finalamount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}

            {data.saleinvoiceData.length > 0 ? (
              <Row>
                <Col md={12} lg={12} sm={12}>
                  <h2>Available Sale Invoices</h2>
                  <br />
                </Col>
                <Col md={12} lg={12} sm={12} className="table-main-container">
                  <Table responsive className="tableContainer">
                    <thead className="bg-secondary">
                      <th>Date</th>
                      <th>Invoice No</th>
                      <th>Sale Order No</th>
                      <th>GI No</th>
                      <th>Invoice Type</th>
                      <th>Name</th>
                      <th>Due Date</th>
                      <th>Status</th>
                      <th>Amount</th>
                      <th>Balance Due</th>
                      <th>Signed Status</th>
                    </thead>
                    <tbody>
                      {data.saleinvoiceData.map((si, ind) => (
                        <tr key={ind}>
                          <td>{si.invoicedate}</td>
                          <td>{si.invoiceno}</td>
                          <td>{si.saleorderno}</td>
                          <td>{si.gino}</td>
                          <td>{si.invoicetype}</td>
                          <td>{si.commonname}</td>
                          <td>{si.paymentduedate}</td>
                          <td>{si.status}</td>
                          <td>{si.grandtotal}</td>
                          <td>{si.balancedue}</td>
                          {si.signature ? (
                            <td className="text-success">Signed</td>
                          ) : (
                            <td className="text-danger">Not Signed</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewProject;
