import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { reloadPage } from "../../../redux/actions/load.action";
import axios from "axios";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";

const EditSeries = ({ id }) => {
  const { companyid, userid, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [brands, setBrands] = useState([]);
  const [data, setData] = useState({});

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    dispatch(reloadPage(true));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    axios

      .post("https://mis.saanviavs.com/api/v1/item/getseries", {

        keyword: "" || org,
      })
      .then((res) => {
        const filteredData =
          res.data && res.data.length > 0
            ? res.data.filter((elem) => {
                return elem.id == id;
              })
            : [];
        setData({ ...filteredData[0] });
      })
      .catch((err) => console.log(err));

    axios

      .post("https://mis.saanviavs.com/api/v1/item/getbrand", {

        userid,
        companyid,
        branchid,
        keyword: "" || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setBrands(initialData);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleUpdate = async () => {
    if (data.brand == "" || data.series == "" || data.rate == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    try {
      const res = await axios.post(

        "https://mis.saanviavs.com/api/v1/item/updateseries",

        data
      );
      if (res.data.message == "Series updated successfully") {
        notifySuccess(res.data.message);
        handleClose();
      } else {
        notifyWarning(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <MdModeEditOutline className="editIcon" onClick={handleShow} />
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Update <span className="form-title">Series</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Series ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Series ID"
                    name="seriesid"
                    value={data.seriesid}
                    disabled
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Brand <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="brand"
                    value={data.brand}
                    onChange={handleChange}
                    isInvalid={data.brand === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {brands.length > 0 &&
                      brands.map((ele, ind) => (
                        <option value={ele.brand} key={ind}>
                          {ele.brand}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Brand
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Series <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Series Name"
                    name="series"
                    value={data.series}
                    onChange={handleChange}
                    isInvalid={data.series === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Series Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Rate <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Rate"
                    name="rate"
                    value={data.rate}
                    onChange={handleChange}
                    isInvalid={data.rate === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Series Price
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleUpdate}
          >
            Update
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSeries;
