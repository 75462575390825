import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import sanviLogo from "../../../assets/sanvi_logo.png";

const DeliveryReceiptForSignature = () => {
  const { id } = useParams();
  const [message, setMessage] = useState("");

  const [data, setData] = useState({
    id: id,
    invoicetype: "",
    commonname: "",
    commonid: "",
    gino: "",
    saleorderno: "",
    invoiceno: "",
    invoicedate: "",
    paymentterms: "",
    paymentduedate: "",
    transportertype: "",
    vehicleno: "",
    salesperson: "",
    ewaybillno: "",
    einvoiceno: "",
    createdby: "",
    remarks: "",
    itemarray: [],
    subtotal: "",
    discount: "",
    discountamount: "",
    discountaccount: "",
    taxableamount: "",
    cgst: "",
    sgst: "",
    igst: "",
    tcs: "",
    tds: "",
    tsamount: "",
    adjustmentamount: "",
    grandtotal: "",
    balancedue: "",
    tandc: "",
    istandc: "",
    status: "",
    userid: "",
    companyid: "",
    branchid: "",
    org: "",
    month: "",
    financialyear: "",
    signature: null,
    showgst: "",
    tcsvalue: "",
    tdsvalue: "",
    purchaseorderno: "",
    purchaseinvoiceno: "",
    contractor: "",
    frightcharges: "",
    architect: "",
    isfrightcharges: "",
    uploads: [],
    transpotername: "",
    staddress: "",
    stpin: "",
    stcity: "",
    btstate: "",
    btcity: "",
    btpin: "",
    btaddress: "",
    ststate: "",
  });

  const signatureCanvasRef = useRef({});
  const contentRef = useRef(null);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    setIsCanvasEmpty(true); // Set canvas state to empty
  };

  const saveSignature = async () => {
    if (signatureCanvasRef.current.isEmpty()) {
      notifyError("Sinature is required");
      return;
    }

    const signatureData = signatureCanvasRef.current.toDataURL();

    try {
      // Make a POST request to your backend API endpoint
      const response = await axios.post(

        "https://mis.saanviavs.com/api/v1/salebill/update",

        {
          ...data,
          signature: signatureData,
        }
      );

      // Handle response from the server
      if (response.data.message == "Sales invoice updated successfully") {
        notifySuccess("Signature Added Successfully");
        setMessage(response.data.message);
      } else {
        notifyError("Something Went Wrong..");
        setMessage("");
  
      }

    } catch (error) {
      // Handle error

      console.error("Error uploading signature:", error);
      setMessage("");
    }
  
  };

  const fetchData = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/salebill/byid", {

        id,
      });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [message]);



  return (
    <div className="p-1">
      <div className="dr-logo-heading">
        <div className="img-logo">
          <img src={sanviLogo} alt="logo" />
        </div>
        <div className="company-information">
          <h2>Saanvi Enterprises</h2>
          <h4>GST No:- GBEPP0300K </h4>
          <h4>PAN No:- GBEPP0300K </h4>
          <h4>
            M, 8, near Sudha Nursing Home, Basanti Nagar, Rourkela, Odisha
            769012
          </h4>
        </div>
        <div className="inv-info">
          <h1 style={{ fontSize: "60px" }}>E</h1>
          <h4>Invoice No:- {data.invoiceno}</h4>
          <h4>Invoice Date:- {data.invoicedate}</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center bill-ship-info">
        <div>
          <h4>Bill To</h4>
          <h2>{data.commonname ? data.commonname : "--"}</h2>
          <h4>{data.btstate}</h4>
          <h4>{data.btcity}</h4>
          <h4>{data.btpin}</h4>
          <h4>{data.btaddress}</h4>
        </div>
        <div>
          <h4>Ship To</h4>
          <h4>{data.ststate}</h4>
          <h4>{data.stcity}</h4>
          <h4>{data.stpin}</h4>
          <h4>{data.staddress}</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center bill-ship-info">
        <div style={{ borderRight: "none" }}>
          <h4>Company Id:- {data.commonid}</h4>
          <h4>GI No:- {data.gino}</h4>
          <h4>Payment Terms:- {data.paymentterms}</h4>
          <h4>Payment Due Date:- {data.paymentduedate}</h4>
          <h4>Issued By:- {data.createdby}</h4>
        </div>
        <div>
          <h4>Transporter:- {data.transpotername}</h4>
          <h4>Vehicle No:- {data.vehicleno}</h4>
          <h4>E-Way bill:- {data.ewaybillno}</h4>
          <h4>Remarks:- {data.remarks}</h4>
        </div>
      </div>
      <br />
      <Table responsive className="table-color">
        <thead>
          <tr>
            <th className="th-color">Item Name</th>
            <th className="th-color">GST%</th>
            <th className="th-color">Rate</th>
            <th className="th-color">QTY</th>
            <th className="th-color">Unit</th>
            <th className="th-color">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.itemarray.length > 0 ? (
            data.itemarray.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>
                    {item.itemid}-{item.itemname}
                  </td>
                  <td>{item.gst}</td>
                  <td>{item.rate}</td>
                  <td>{item.qty}</td>
                  <td>{item.unit}</td>
                  <td>{item.amount}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">
                <div className="datanotfound-center-text">No Item Found 🤔</div>
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td className="tddata-right">Sub Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.subtotal}</td>
          </tr>
          <tr>
            <td className="tddata-right">Discount</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.discount} %</td>
            <td style={{ fontWeight: "bolder" }}>{data.discountamount}</td>
          </tr>
          <tr>
            <td className="tddata-right">Taxable Amount</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.taxableamount}</td>
          </tr>
          <tr>
            <td className="tddata-right">CGST</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.cgst}</td>
          </tr>
          <tr>
            <td className="tddata-right">SGST</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.sgst}</td>
          </tr>
          <tr>
            <td className="tddata-right">IGST</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.igst}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr style={{ border: "1px solid black", backgroundColor: "gray" }}>
            <td className="tddata-right">Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bolder" }}>{data.grandtotal}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-between tandcdiv">
        <div>
          <h2 className="text-center py-1">Terms & Conditions</h2>
          <hr style={{ border: "1px solid black" }} />
          <h4 className="px-1">
            By accepting this invoice, the customer agrees to pay the specified
            amount within the stated timeframe. Any disputes must be raised
            within 30 days. Late payments may incur interest charges. Goods
            remain property of the seller until fully paid. All sales are final.
          </h4>
        </div>
        <div >
        <h5 className="text-center py-1">Certified that particulars given above are true and correct</h5>
        <h2 className="text-center">For Saanvi Enterprises</h2>
        <hr style={{ border: "1px solid black" }}/>
        </div>
      </div>
      <br />
         <Row
        md={1}
         lg={2}
         className="d-flex justify-content-between align-items-center "
       >
         {!data.signature ? (
           <Col
             d-flex
             flex-column
             align-items-start
             justify-content-between
             gap-2
           >
             <h2>E-Sign is Required</h2>
             <div className="border border-dark rounded-3 mb-3">
               <SignatureCanvas
                 ref={signatureCanvasRef}
                 penColor="black"
                 canvasProps={{
                   width: 500,
                   height: 200,
                   className: "sigCanvas",
                 }}
                 onBegin={() => setIsCanvasEmpty(false)} // Set canvas state to not empty when drawing starts
               />
        </div>
           <div className="d-flex gap-3">
              <Button className="commonBtn cancelBtn " onClick={clearSignature}>
               Clear Signature
               </Button>
               <button className="commonBtn " onClick={saveSignature}>
             Accept Signature
           </button>
           </div>
           </Col>
         ) : (
           <Col
             className="d-flex
           flex-column
             align-items-start
          justify-content-between
             gap-2"
           >
             <img
               src={data.signature}
               alt="signature"
               width={200}
              height={100}
            />
             <h3>Receiver's Signature</h3>
           </Col>
         )}
        
       </Row>
    </div>
  );
};

export default DeliveryReceiptForSignature;
