import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { notifyError } from "../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { stockIdCount } from "../../../constants/api";
import { warehouseData } from "../../../redux/actions/warehouse.actions";
import { addStock, stockData } from "../../../redux/actions/stocklocation.actions";

const AddStockLocation = () => {
  const URL = extractAndFormatURLPart("admin");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const warehouseMaster = useSelector(
    (state) => state.warehouseData.warehouseData.data
  );
  const stockIDdata = useSelector((state) => state.stockData.stockData.data) || [];
  const [showEmptyError, setShowEmptyError] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    locationid: "",
    locationname: "",
    locationtype: "room",
    warehouseid: "",
    warehouse: "",
    remarks: "",
    roomno: "",
    rakeno: "",
    companyid: companyid,
    company: companyname,
    status: "active",
    userid: userid,
    branch: branch,
    branchid: branchid,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "warehouse") {
      if (value) {
        const selectedWarehouse = warehouseMaster.find(
          (warehouse) => warehouse.warehousename == value
        );

        setData((prev) => ({
          ...prev,
          warehouse: selectedWarehouse.warehousename,
          warehouseid: selectedWarehouse.warehouseid,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          warehouse: "",
          warehouseid: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRackChange = (e) => {
    if (e.target.checked) {
      if (!data.locationtype.includes("rack")) {
        setData((prev) => ({
          ...prev,
          locationtype: "room,rack",
        }));
      } else {
        setData((prev) => ({
          ...prev,
        }));
      }
    } else {
      setData((prev) => ({
        ...prev,
        rakeno: "",
        locationtype: "room",
      }));
    }
  };

  const handleSave = () => {
    if (!data.locationid || !data.warehouse || !data.roomno) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    if (data.locationtype.includes("rack") && !data.rakeno) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(addStock(data, navigate));
  };

  //for dynamically getting stocklocationid & warehouse Master Data
  const backendData = {
    keyword: "" || "₹ΘθϖΠ",
    companyid: companyid,
    userid: userid,
    branchid:branchid,
  }

  useEffect(() => {
    setLoading(!loading);
    dispatch(stockData(backendData, setLoading));
  }, [dispatch]);

  const generateCategoryID = () => {
    if (stockIDdata.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = stockIDdata[0].locationid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      locationid: `LOC/SAANVI/${paddedCount}`,
    }));
  }, []);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      warehouseData(
        {
          keyword: "₹ΘθϖΠ",
          companyid: companyid,
          userid: userid,
          branchid: branchid,
        },
        setLoading
      )
    );
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <div className="form-container">
          <Form className="form-lable-name">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/admin/stockLocation/")}
              />
              Add <span className="form-title main"> Stock Location </span>
            </h1>
            <hr />
            <div className="d-flex gap-4">
              <div className="w-100">
                <Container fluid>
                  <Row lg={2} md={1} sm={1}>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Location ID<span className="star"> *</span>
                        </Form.Label>
                        <Form.Control
                          disabled
                          type="text"
                          name="locationid"
                          value={data.locationid}
                          onChange={handleChange}
                          isInvalid={!data.locationid && showEmptyError}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>
                          Warehouse <span className="star">*</span>
                        </Form.Label>
                        <Form.Select
                          name="warehouse"
                          value={data.warehouse}
                          onChange={handleChange}
                          isInvalid={!data.warehouse && showEmptyError}
                        >
                          <option value="">Select</option>
                          {warehouseMaster?.filter((item)=>item.status=="active")?.map((warehouse) => {
                            const { warehousename, id } = warehouse;
                            return (
                              <option value={warehousename} key={id}>
                                {warehousename}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Room No
                              <span className="star"> *</span>
                            </Form.Label>
                            <Form.Control
                              // disabled
                              type="text"
                              name="roomno"
                              value={data.roomno}
                              onChange={handleChange}
                              isInvalid={!data.roomno && showEmptyError}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Rack No
                              {data.locationtype.includes("rack") ? (
                                <span className="star"> *</span>
                              ) : null}
                            </Form.Label>
                            <Form.Control
                              disabled={!data.locationtype.includes("rack")}
                              type="text"
                              name="rakeno"
                              value={data.rakeno}
                              onChange={handleChange}
                              isInvalid={
                                !data.rakeno &&
                                data.locationtype.includes("rack") &&
                                showEmptyError
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3 d-flex gap-2 justify-content-between align-items-center"
                            controlId="formBasicCheck"
                          >
                            <Form.Label className="mt-5">
                              Location type
                            </Form.Label>
                            <div className="mt-5">
                              <Form.Check
                                inline
                                className="form-label"
                                type="checkbox"
                                label="Room"
                                value="Room"
                                checked={data.locationtype.includes("room")}
                                // onChange={handleLocationChange}
                                // id="warehouse"
                              />
                              <Form.Check
                                className="form-label"
                                inline
                                type="checkbox"
                                label="Rack"
                                value="Rack"
                                checked={data.locationtype.includes("rack")}
                                onChange={handleRackChange}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Remarks</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Remarks"
                          name="remarks"
                          value={data.remarks}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col></Col>
                  </Row>
                </Container>
              </div>
            </div>
          </Form>
          <hr />
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/admin/stockLocation/")}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Container>
    </>
  );
};

export default AddStockLocation;
