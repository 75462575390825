import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { itemGetItemPost } from "../../../constants/api";
import { useNavigate } from "react-router-dom";
import SearchField from "../../../constants/SearchField";
import axios from "axios";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DataSpinner from "../../../constants/DataSpinner";
import { notifyError, notifyWarning } from "../../../constants/toastAlert";
import * as XLSX from "xlsx";
import { reloadPage } from "../../../redux/actions/load.action";
import ModalPriceUpdate from "./ModalPriceUpdate";

const ListTablePriceUpdate = () => {
  const navigate = useNavigate();
  const inputRef = useRef(null); // Create a ref for the input element
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [closeLoading,setCloseLoading]=useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid,org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const loadData = useSelector((state) => state.loadReducer);
  //   const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  const downloadSample = () => {
    if (data.length == 0) {
      return notifyError(
        "Add New Item,Before Downloading a sample / uploading a sample"
      );
    } else {
      const items = data.map((item, ind) => ({
        "SI NO": ind + 1,
        "Category":item.category,
        "Brand":item.brand,
        "Series":item.series,
        "Item ID": item.itemid,
        "Item Name": item.itemname,
        "Rate": item.rateperunit,
        "Discount": item.discount,
        "MOP":item.mop
      }));

      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Items");
      XLSX.writeFile(workbook, "items.xlsx");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if file is not null and size is less than 50 MB
    if (file && file.size < 50 * 1024 * 1024) {
      // Check if file type is XLS or XLSX
      if (
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setSelectedFile(file);
        handleUpload(file);
      } else {
        notifyWarning("Please select a valid Excel file (XLS or XLSX)");
      }
    } else {
      notifyError("File size should be less than 50 MB");
    }
  };

  const handleUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
        range: 1,
      }); // Skip the first row (header)
      // Send jsonData to your API endpoint
      let jsonArr = [];
      jsonData.forEach((json) => {
        let itemObj = {
          category: json[1],
          brand: json[2],
          series: json[3],
          itemid: json[4],
          itemname: json[5],
          rateperunit: json[6],
          discount: json[7],
          mop:json[8],
        };
        jsonArr.push(itemObj);
      });

      try {
        dispatch(reloadPage(true));


        let res = await axios.post("https://mis.saanviavs.com/api/v1/item/updateprice", {
          companyid: companyid,
          jsonData: jsonArr,
        });
        dispatch(reloadPage(false));
        setCloseLoading(!closeLoading)
      } catch (error) {
        dispatch(reloadPage(false));
        
      }
    };

    reader.readAsArrayBuffer(file);
    setSelectedFile(null);
    setCloseLoading((prev)=>!closeLoading)
  };

  const filteredData = !data
    ? []
    : data.filter((item) =>
        searchKeyword
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchKeyword.toLowerCase().trim())
            )
          : true
      );

  const fetchData = async () => {
    //   setLoading(true);
    try {
      const res = await axios.post(itemGetItemPost, {
        userid: userid,
        branchid: branchid,
        companyid: companyid,
        keyword: searchKeyword || org,
      });
      const initialData =
        res.data && res.data.data && res.data.data.length > 0
          ? res.data.data
          : [];

      setData(initialData);
      // dispatch(reloadPage(false));
    } catch (error) {
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [loadData,closeLoading]);

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={4}>
            <p className="section-title form-section ptag">
              List of All Items<span className="form-title"> Price Update</span>
            </p>
          </Col>
          <Col lg={3}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={5}>
            <div className="d-flex justify-content-between gap-2">
              <ModalPriceUpdate setCloseLoading={setCloseLoading} closeLoading={closeLoading}/>
              <Button className="commonBtn mt-2" onClick={downloadSample}>
                Download Sample
              </Button>
              <div className="w-100">
                <input
                  type="file"
                  accept=".xls, .xlsx"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={inputRef}
                />
                <Button
                  onClick={() => inputRef.current.click()}
                  className="cancelBtn commonBtn mt-2"
                >
                  Upload
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>SI No.</th>
              <th>Category</th>
              <th>Brand</th>
              <th>Series</th>
              <th>Item ID</th>
              <th>Item Name</th>
              <th>Discount</th>
              <th>Price</th>
              <th>MOP</th>
              {/* <th>Updated Price</th> */}
            </tr>
          </thead>
          <tbody>
            {loadData  ? (
              <>
                <DataSpinner count="9" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData?.map((item, index) => {
                const { id, itemid, itemname, rateperunit, discount,category,brand,series,mop } = item;
                return (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{category?category:"--"}</td>
                    <td>{brand?brand:"--"}</td>
                    <td>{series ? series : 0}</td>
                    <td>{itemid}</td>
                    <td>{itemname}</td>
                    <td>{discount ? discount : 0}</td>
                    <td>{rateperunit}</td>
                    <td>{mop}</td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="9" name="Item" />
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListTablePriceUpdate;
