import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Tab, Tabs } from "react-bootstrap";
import PrimaryContactForm from "./PrimaryContactForm";
import StatutoryForm from "./StatutoryForm";
import AddressForm from "./AddressForm";
import BankInformationForm from "./BankInformationForm";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../../../constants/toastAlert";
import { validateBankAccountNumber, validateEmail, validateGSTNumber, validateIFSCCode, validatePanNumber, validatePhoneNumber } from "../../../../constants/validations";
import { addVendor, vendorData } from "../../../../redux/actions/vendor.actions";
const states = State.getStatesOfCountry("IN");

const AddVendorTab = () => {
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid,companyname,branch,branchid } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const [selectedBillingState, setSelectedBillingState] = useState("");
  const [billingCities, setBillingCities] = useState([]);
  const [selectedShippingState, setSelectedShippingState] = useState("");
  const [shippingCities, setShippingCities] = useState([]);
  const navigate = useNavigate();
  const [key, setKey] = useState("primarycontact");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVendorWorkPhoneErr, setIsVendorWorkPhoneErr] = useState(false);
  const [isVendorMobileNoErr, setIsVendorMobileNoErr] = useState(false);
  const [isVendorEmailErr, setIsVendorEmailErr] = useState(false);
  const [isCpWorkPhoneErr, setIsCpWorkPhoneErr] = useState(false);
  const [isCpMobileNoErr, setIsCpMobileNoErr] = useState(false);
  const [isCpEmailErr, setIsCpEmailErr] = useState(false);
  const vendorMaster = useSelector((state) => state.vendorData.vendorData.data) || [];
  const[data,setData]=useState({
    fullname: "",
    samevendor:false,
    vendorid:"",
    companyname: "",
    vendorcategory: "",
    commission:"",
    workphoneno: "",
    mobileno: "",
    email: "",
    cpfullname: "",
    cpworkphoneno: "",
    cpmobileno: "",
    cpemail: "",
    gstno: "",
    pan: "",
    billingattention: "",
    billingcountry: "India",
    billingstate: "",
    billingcity: "",
    billingaddress: "",
    billingpincode: "",
    shippingattention: "",
    shippingcountry: "India",
    shippingstate: "",
    shippingcity:"",
    shippingaddress: "",
    shippingpincode: "",
    bankname: "",
    bankbranch: "",
    accountnumber: "",
    ifsccode: "",
    remarks: "",
    company: companyname,
    companyid: companyid,
    branch: branch,
    branchid: branchid,
    userid: userid
  }
  )


  const switchToNextTab = () => {
    // Determine the next tab's event key based on the current active tab
    let nextTabKey = "";
    switch (key) {
      case "primarycontact":
        nextTabKey = "statutory";
        break;
      case "statutory":
        nextTabKey = "address";
        break;
      case "address":
        nextTabKey = "bankinformation";
        break;
      default:
        nextTabKey = "";
    }

    if (nextTabKey) {
      setKey(nextTabKey);
    }
  };

  const handleSelectKey = (key) => {
    setKey(key);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "billingstate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedBillingState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          billingcity: "",
        }));
      } else {
        setSelectedBillingState("");
        setData((prev) => ({
          ...prev,
          billingstate: "",
          billingcity: "",
        }));
      }
    }

    if (name == "shippingstate") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);
        setSelectedShippingState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          shippingcity: "",
        }));
      } else {
        setSelectedShippingState("");
        setData((prev) => ({
          ...prev,
          shippingstate: "",
          shippingcity: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSubmit=()=>{
    let count = 0;
    if (
      !data.fullname ||
      !data.vendorcategory ||
      !data.workphoneno ||
      !data.cpfullname  ||
      !data.cpworkphoneno 
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    
    const validateAndNotify = (field, validator) => {

      if (data[field]) {
        const validationMsg = validator(data[field]);
        if (validationMsg !== "Done") {
          if (field == "workphoneno") {
            setIsVendorWorkPhoneErr(true)
          }
          if (field == "mobileno") {
            setIsVendorMobileNoErr(true)
          }
          if (field == "email") {
            setIsVendorEmailErr(true)
          }
          if (field == "cpworkphoneno") {
            setIsCpWorkPhoneErr(true)
          }
          if (field == "cpmobileno") {
            setIsCpMobileNoErr(true)
          }
          if (field == "cpemail") {
            setIsCpEmailErr(true)
          }
          count++;
          return notifyError(validationMsg);
        } else {
          if (field == "workphoneno") {
            setIsVendorWorkPhoneErr(false)
          }
          if (field == "mobileno") {
            setIsVendorMobileNoErr(false)
          }
          if (field == "email") {
            setIsVendorEmailErr(false)
          }
          if (field == "cpworkphoneno") {
            setIsCpWorkPhoneErr(false)
          }
          if (field == "cpmobileno") {
            setIsCpMobileNoErr(false)
          }
          if (field == "cpemail") {
            setIsCpEmailErr(false)
          }
        }
      }
    };

    validateAndNotify("workphoneno", validatePhoneNumber);
    validateAndNotify("mobileno", validatePhoneNumber);
    validateAndNotify("email", validateEmail);
    validateAndNotify("cpworkphoneno", validatePhoneNumber);
    validateAndNotify("cpmobileno", validatePhoneNumber);
    validateAndNotify("cpemail", validateEmail);

    if (count > 0) return


    if (key == "statutory") {

      const validateAndNotify = (field, validator) => {

        if (data[field]) {
          const validationMsg = validator(data[field]);
          if (validationMsg !== "Done") {
            count++;
            return notifyError(validationMsg);
          }
        }
      };

      validateAndNotify("gstno", validateGSTNumber);
      validateAndNotify("pan", validatePanNumber);

      if (count > 0) {
        return
      }
    }

    if (key == "address") {
      if (
 !data.billingcountry || !data.shippingcountry || !data.billingstate || !data.shippingstate || !data.billingcity || !data.shippingcity 
      ) {
        setShowEmptyError(true);
        return notifyError("You have empty field");
      }
    }

    if (key == "bankinformation") {
      const validateAndNotify = (field, validator) => {

        if (data[field]) {
          const validationMsg = validator(data[field]);
          if (validationMsg !== "Done") {
            count++;
            return notifyError(validationMsg);
          }
        }
      };

      validateAndNotify("accountnumber", validateBankAccountNumber);
      validateAndNotify("ifsccode", validateIFSCCode);


      if (count > 0) {
        return
      }

    }

    if (submitButtonText == "Save" && count==0) {

      
      dispatch(addVendor(data,navigate));
    }
    


    if (!isLastTab) {
        switchToNextTab();
      }

  }

  useEffect(() => {
    setLoading(!loading);
    dispatch(vendorData({companyid,userid,keyword:""}, setLoading));
  }, [dispatch, loadData]);

  const generateCategoryID = () => {
    if (vendorMaster.length == 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = vendorMaster[0].vendorid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      vendorid: `VENDOR/SAANVI/${paddedCount}`,
    }));
  }, []);

  useEffect(() => {
    if (selectedBillingState) {
      const citiesOfSelectedState = City.getCitiesOfState("IN", selectedBillingState);
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setBillingCities(cityNames);
    } else {
      setBillingCities([]);
    }

    if (selectedShippingState) {
      const citiesOfSelectedState = City.getCitiesOfState("IN", selectedShippingState);
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setShippingCities(cityNames);
    } else {
      setShippingCities([]);
    }

  }, [selectedBillingState,selectedShippingState]);

  const isLastTab = key === "bankinformation";
  const submitButtonText = isLastTab ? "Save" : "Next";

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/purchase/vendor")}
            />
            Vendor <span className="form-title"> Registration</span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs
            defaultActiveKey="primarycontact"
            id="justify-tab-example"
            className="justify-content-end tab-margin"
            //variant='pills'
            //fill
            activeKey={key}
            onSelect={handleSelectKey}
          >
            <Tab
              eventKey="primarycontact"
              title="Primary Contact"
              className="tabview"
            >
              <PrimaryContactForm data={data} setData={setData} handleChange={handleChange} showEmptyError={showEmptyError} isVendorWorkPhoneErr={isVendorWorkPhoneErr} isCpEmailErr={isCpEmailErr} isCpMobileNoErr={isCpMobileNoErr} isVendorMobileNoErr={isVendorMobileNoErr} isVendorEmailErr={isVendorEmailErr} isCpWorkPhoneErr={isCpWorkPhoneErr} />
            </Tab>
            <Tab eventKey="statutory" title="Statutory" className="tabview">
              <StatutoryForm data={data} handleChange={handleChange} showEmptyError={showEmptyError}/>
            </Tab>
            <Tab eventKey="address" title="Address" className="tabview">
              <AddressForm data={data} handleChange={handleChange} showEmptyError={showEmptyError} states={states} billingCities={billingCities} shippingCities={shippingCities} setData={setData} selectedBillingState={selectedBillingState} setSelectedShippingState={setSelectedShippingState}/>
            </Tab>
            <Tab
              eventKey="bankinformation"
              title="Bank Information"
              className="tabview"
            >
              <BankInformationForm data={data} handleChange={handleChange} showEmptyError={showEmptyError}/>
            </Tab>
          </Tabs>
        </Col>
          <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
            <Button className="commonBtn smallBtn" onClick={handleSubmit}>{submitButtonText}</Button>
            {/* <Button className="commonBtn smallBtn" onClick={handleSave}>
              {loadData ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button> */}
            <Button
              className="commonBtn smallBtn cancelBtn"
              onClick={() => navigate("/purchase/vendor/")}
            >
              Cancel
            </Button>
          </Modal.Footer>
      </Row>
      
    </Container>
  );
};

export default AddVendorTab;
