import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import FilterField from "../../../constants/FilterField";
import SearchField from "../../../constants/SearchField";
import { useNavigate } from "react-router-dom";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import GlobalPagination from "../../../constants/GlobalPagination";
import { issueGoodgetPagePost } from "../../../constants/api";
import { reloadPage } from "../../../redux/actions/load.action";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { initialData } from "../../../constants/resData";
import DataSpinner from "../../../constants/DataSpinner";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import ViewIssueGoods from "./ViewIssueGoods";
import DispatchDate from "./DispatchDate";

const ListIssue = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesFromAPI, setTotalPagesFromAPI] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const handleIssueGoods = () => {
    navigate("/inventory/manageStock/issueGoods");
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(issueGoodgetPagePost, {
          page: currentPage,
          pageSize: pageSize,
          userid: userID,
          branchid: branchID,
          companyid: companyID,
          keyword: searchKeyword || selectedMonth || org,
        });

        setData(initialData(res));
        setTotalPagesFromAPI({
          totalPages: res.data.totalPages ? res.data.totalPages : 0,
          totalRecords: res.data.totalItems ? res.data.totalItems : 0,
        });
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loadData, pageSize, currentPage]);

  const filteredData = !data
    ? []
    : data
        .filter((item) =>
          searchKeyword
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchKeyword.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (selectedMonth == "") {
            return true;
          } else {
            const dateParts = item.gidate.split("-");
            const itemMonth = dateParts[1];
            return itemMonth === selectedMonth;
          }
        })
        .filter((item) => {
          if (selectedIssue == "") {
            return true;
          } else {
            const itemIssueto = item.issueto;
            return itemIssueto === selectedIssue;
          }
        });

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {
        userid: userID,

        submodule: "Manage Stock",
        companyid: companyID,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={4}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Issue Goods</span>
            </p>
          </Col>
          <Col lg={2}>
            <FilterField
              label="Issue"
              islable={true}
              emptyValue="All"
              selectedFilterValue={selectedIssue}
              handleSelectedFilter={(e) => setSelectedIssue(e.target.value)}
              filterWords={[
                { value: "Customer", text: "Customer" },
                { value: "Other Company", text: "Other Company" },
                { value: "Branch", text: "Branch" },
                { value: "Sub-Dealer", text: "Sub-Dealer" },
                { value: "Franchise", text: "Franchise" },
                { value: "Return To Vendor", text: "Return To Vendor" },
              ]}
            />
          </Col>
          <Col lg={2}>
            <FilterField
              label="Month"
              islable={true}
              emptyValue="All"
              selectedFilterValue={selectedMonth}
              handleSelectedFilter={(e) => setSelectedMonth(e.target.value)}
              filterWords={[
                { value: "01", text: "January" },
                { value: "02", text: "February" },
                { value: "03", text: "March" },
                { value: "04", text: "April" },
                { value: "05", text: "May" },
                { value: "06", text: "June" },
                { value: "07", text: "July" },
                { value: "08", text: "August" },
                { value: "09", text: "September" },
                { value: "10", text: "October" },
                { value: "11", text: "November" },
                { value: "12", text: "December" },
              ]}
            />
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button className="commonBtn mt-2" onClick={handleIssueGoods}>
                Issue Goods
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading
            data={[
              "Date",
              "GI No.",
              "Issue To",
              "Name",
              `Sale Invoice/Purchase Bill`,
              "Amount",
              "Action",
            ]}
          />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="6" />
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.gidate ? indianFormatDate(item.gidate) : "--"}</td>
                  <td>{item.gino}</td>
                  <td>{item.issueto}</td>
                  <td>{item.issuetoname}</td>
                  <td>{item.psorder ? item.psorder : "N.A."}</td>
                  <td>{item.subtotal}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <ViewIssueGoods data={item} />
                      <DispatchDate
                        id={item.id}
                        gino={item.gino}
                        invoiceno={item.psorder}
                        vehicleno= {item.vehicleno}
                        vehicletype= {item.vehicletype}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="6" name="Issued Items" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListIssue;
