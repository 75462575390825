import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'

function ViewProfile({ show, handleViewProfile, handleViewProfileClose }) {
    return (
        <>
            <Button className="commonBtn smallBtn" onClick={handleViewProfile}>View Profile</Button>
            <Modal show={show} size="lg" onHide={handleViewProfileClose} backdrop="static" keyboard={false} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        View <span className="form-title">Profile</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                            <h1>Under Construction !!</h1>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ViewProfile