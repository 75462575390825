import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import TableHeading from "../../../constants/TableHeading";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";

const ListBrand = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org;

  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);

  const [showInputForm, setShowInputForm] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editStates, setEditStates] = useState({});
  const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);
  const [show, setShow] = useState(false);
  const [itemId, setItemid] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  const [row, setRow] = useState({
    brandid: "",
    brand: "",
    userid: userID,
    companyid: companyID,
    branchid: branchID,
    org: "₹ΘθϖΠ",
  });

  const handleShow = (item) => {
    const { id, brandid } = item;
    setShow(true);
    setItemid(id);
    setSelectedItemNo(brandid);
  };
  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  const [editData, setEditData] = useState({
    brandid: "",
    brand: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post("https://mis.saanviavs.com/api/v1/item/getbrand", {

          userid: userID,
          companyid: companyID,
          branchid: branchID,
          keyword: searchKeyword || org,
        });
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setData(initialData);
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [showInputForm, loadData]);

  // Function to generate category ID
  const generateCategoryID = () => {
    if (data.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = data[0].brandid.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.padStart(4, "0");
    setRow((prevRow) => ({
      ...prevRow,
      brandid: `BRAND/SAANVI/${paddedCount}`,
    }));
  }, [data]);

  const handleAddNewRow = () => {
    setShowInputForm(true);
  };

  const handleEditRow = (rowId) => {
    const recordToEdit = data.find((elem) => elem.id === rowId);

    setEditData({
      id: recordToEdit.id,
      brandid: recordToEdit.brandid,
      brand: recordToEdit.brand,
    });
    if (currentlyEditedRow === rowId) {
      // If the clicked row is already being edited, save the changes
      handleSaveEdit(rowId);
    } else {
      // Set the currently edited row ID
      setCurrentlyEditedRow(rowId);
      // Toggle the edit state only for the clicked row
      toggleEditState(rowId);
    }
  };
  const toggleEditState = (rowId) => {
    // Close the edit form for the previously edited row
    if (currentlyEditedRow !== null && currentlyEditedRow !== rowId) {
      setEditStates((prevStates) => ({
        ...prevStates,
        [currentlyEditedRow]: false,
      }));
    }

    // Open or close the edit form for the clicked row
    setEditStates((prevStates) => ({
      ...prevStates,
      [rowId]: !prevStates[rowId] || false,
    }));
  };

  const filteredData = !data
    ? []
    : data.filter((item) =>
        searchKeyword
          ? Object.values(item).some((value) =>
              value
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchKeyword.toLowerCase().trim())
            )
          : true
      );

  const handleAddData = async () => {
    if (row.brandid == "" || data.brand == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    } else {
      try {

        const res = await axios.post("https://mis.saanviavs.com/api/v1/item/addbrand", {

          ...row,
          org: org,
        });
        if (res.data.message == "Brand added successfully") {
          notifySuccess(res.data.message);
        } else {
          notifyWarning(res.data.message);
        }
        setShowInputForm(false);
        setRow({
          brand: "",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRow((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveEdit = async (rowId) => {
    if (editData.brandid == "" || editData.brand == "") {
      notifyError("You have empty field");
      setShowEmptyError(true);
    } else {
      try {
        const res = await axios.post(
          "https://mis.saanviavs.com/api/v1/item/updatebrand",
          editData
        );

        if (res.data.message == "Brand updated successfully") {
          notifySuccess(res.data.message);
          toggleEditState(rowId);
          setEditData({
            designationname: "",
          });
          setShowEmptyError(false);
          dispatch(reloadPage(true));
          setCurrentlyEditedRow(null);
        } else {
          notifyWarning(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleDelete = () => {
    const deleteData = async () => {
      try {
        const res = await axios.post("https://mis.saanviavs.com/api/v1/item/deletebrand", {

          id: itemId,
          brandid: selectedItemNo,
          companyid: companyID,
          userid: userID,
          org: org,
        });

        if (res.statusText == "OK") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyError("Error Deleting category");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    deleteData();
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Brand</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            <Button className="commonBtn mt-2" onClick={handleAddNewRow}>
              Add Brand
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading data={["Brand ID", "Brand Name", "Action"]} />
          <tbody>
            {showInputForm && (
              <tr>
                <td>
                  <Form.Control
                    className="text-center"
                    placeholder="Brand ID"
                    name="brandid"
                    value={row.brandid}
                    disabled
                  />
                </td>
                <td>
                  {" "}
                  <Form.Control
                    className="text-center"
                    placeholder="Brand Name"
                    name="brand"
                    value={row.brand}
                    onChange={handleChange}
                    isInvalid={row.brand === "" && showEmptyError}
                  />
                </td>
                <td>
                  <div className="d-flex gap-4">
                    <Button className="commonBtn my-1" onClick={handleAddData}>
                      Save
                    </Button>
                    <Button
                      className="commonBtn cancelBtn"
                      onClick={() => setShowInputForm(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </td>
              </tr>
            )}
            {loadData || loading ? (
              <DataSpinner count="3" />
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>
                    {editStates[item.id] ? (
                      <Form.Control
                        placeholder="Brand ID"
                        name="brandid"
                        value={editData.brandid}
                        disabled
                      />
                    ) : (
                      item.brandid
                    )}
                  </td>
                  <td>
                    {editStates[item.id] ? (
                      <Form.Control
                        placeholder="Brand Name"
                        name="brand"
                        value={editData.brand}
                        onChange={handleChange}
                        isInvalid={editData.brand === "" && showEmptyError}
                      />
                    ) : (
                      item.brand
                    )}
                  </td>
                  <td>
                    {editStates[item.id] ? (
                      <Button
                        className="commonBtn"
                        onClick={() => handleSaveEdit(item.id)}
                      >
                        Save
                      </Button>
                    ) : (
                      <>
                        <MdEdit
                          className="editIcon"
                          onClick={() => handleEditRow(item.id)}
                        />
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={() => setShow(false)}
                          handleDelete={handleDelete}
                          name="Brand"
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="3" name="Brands" />
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ListBrand;
