import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import AddPageButton from "../../../constants/AddPageButton";
import { BiSolidFileExport } from "react-icons/bi";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DataSpinner from "../../../constants/DataSpinner";
import { useDispatch, useSelector } from "react-redux";
import { salePaymentData } from "../../../redux/actions/salepayment.actions";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import GlobalPagination from "../../../constants/GlobalPagination";
import ViewSalePayment from "./ViewSalePayment";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import axios from "axios";

const ListSalePayment = () => {
  const pdfRef = useRef(null);
  // const [showDropdown, setShowDropdown] = useState(false);

  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data)[0];
  const data = useSelector(
    (state) => state.salePaymentData.salePaymentPagiData
  );
  const loadData = useSelector((state) => state.loadReducer);

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData.companyid;
  const userid = userData.userid;
  const branchid = userData.branchid;
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
    keyword: searchKeyword || "₹ΘθϖΠ",
  };

  // const handleExportIconClick = () => {
  //   setShowDropdown(!showDropdown);
  // };

  function getCurrentDate() {
    const currentDate = new Date();

    // Get day, month, and year
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = currentDate.getFullYear();

    // Format the date as dd-mm-yyyy
    const formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;

    return formattedDate;
  }

  // Helper function to pad single-digit numbers with a leading zero
  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  useEffect(() => {
    setLoading(!loading);
    dispatch(salePaymentData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleExport = () => {
    // Create a new array to hold the data for export
    const exportData = data.data.map((item, ind) => ({
      "Sl No": ind + 1,
      Date: item.paymentdate,
      "Payment No": item.paymentno,
      Name: item.commonname,
      "Invoice No": item.billarray.map((bill) => bill.invoiceno).join(", "),
      Mode: item.paymentmode,
      "Reference No": item.referenceno,
      Amount: item.total,
      "Unused Amount": item.excessamount,
    }));

    const csvData = Papa.unparse(exportData, {
      header: true, // Include headers based on object keys
    });

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });

    saveAs(blob, `Sale Payment-${getCurrentDate()}.csv`); // FileSaver function to trigger download
  };
  const handlePDF = () => {
    if (pdfRef.current) {
      const element = pdfRef.current;
      const options = {
        margin: 10,
        filename: `Sale Payment-${getCurrentDate()}.pdf`,
        image: { type: "jpeg", quality: 2.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const content = `
      <div style="font-family: Arial, sans-serif;">
      <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
        List of All <span style="color: #ffa000;">Sale Payments</span>
      </p>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr style="background-color: #f0f0f0;">
            <th style="border: 1px solid #ddd; padding: 8px;">Sl No</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Date</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Payment No</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Name</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Invoice No</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Mode</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Reference No</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Amount</th>
            <th style="border: 1px solid #ddd; padding: 8px;">Unused Amount</th>
          </tr>
        </thead>
        <tbody>
          ${data.data
            .map(
              (item, index) => `
            <tr key=${index}>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                index + 1
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.paymentdate ? item.paymentdate : ""
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.paymentno
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.commonname
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.billarray.length > 0
                  ? item.billarray.map((bill) => bill.invoiceno).join(", ")
                  : "--"
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.paymentmode
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.referenceno
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.total
              }</td>
              <td style="border: 1px solid #ddd; padding: 8px;">${
                item.excessamount
              }</td>
            </tr>
          `
            )
            .join("")}
        </tbody>
      </table>
    </div>
    `;
      //html2pdf(element, options);
      html2pdf().from(content).set(options).save();
    }
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

        userid,
        submodule: "Sale Payment",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={7}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Payment Receipt</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <AddPageButton
                text="Payment Receipt"
                link={`/sales/salePayment/addSalePayment`}
              />
            )}
          </Col>
          <Col lg={1} className="pl-0">
            <Dropdown
              as={ButtonGroup}
              // show={showDropdown} // Control the visibility of the Dropdown menu
              // onToggle={(isOpen, event, metadata) => {
              //   if (metadata.source === "select") {
              //     setShowDropdown(isOpen);
              //   }
              // }}
            >
              <Button
                className="commonBtn cancelBtn smallBtn mt-2"
                variant="light"
                //onClick={handleExportIconClick}
              >
                <BiSolidFileExport />
                <span>Export</span>
              </Button>

              <Dropdown.Toggle
                variant="light"
                id="dropdown-split-basic"
                className="commonBtn cancelBtn mt-2"
                style={{ paddingRight: "10px", paddingLeft: "10px" }}
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={handlePDF}>PDF</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleExport}>EXCEL</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive ref={pdfRef}>
          <TableHeading
            data={[
              "Sl No",
              "Date",
              "Payment No",
              "Name",
              "Invoice No",
              "Sale Order No",
              "Mode",
              "Reference No",
              "Amount",
              "Unused Amount",
              "Action",
            ]}
          />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="10" />
            ) : data.data && data.data.length > 0 ? (
              data.data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.paymentdate ? item.paymentdate : ""}</td>
                  <td>{item.paymentno}</td>
                  <td>{item.commonname}</td>
                  {item.billarray.length > 0 ? (
                    <td>
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                        }}
                      >
                        {item.billarray.map((bill, ind) => (
                          <li
                            style={{
                              fontSize: "16px",
                            }}
                            key={ind}
                          >
                            {bill.invoiceno}
                          </li>
                        ))}
                      </ul>
                    </td>
                  ) : (
                    <td>"--"</td>
                  )}
                  {item.billarray.length > 0 ? (
                    <td>
                      <ul
                        style={{
                          listStyleType: "none",
                          padding: 0,
                        }}
                      >
                        {item.billarray.map((bill, ind) => (
                          <li
                            style={{
                              fontSize: "16px",
                            }}
                            key={ind}
                          >
                            {bill.saleorderno}
                          </li>
                        ))}
                      </ul>
                    </td>
                  ) : (
                    <td>"--"</td>
                  )}
                  <td>{item.paymentmode}</td>
                  <td>{item.referenceno}</td>
                  <td>{item.total}</td>
                  <td>{item.excessamount}</td>
                  <td>
                    <ViewSalePayment item={item} />
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="11" name="Sale Payments" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListSalePayment;
