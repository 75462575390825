import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import sanviLogo from "../../../assets/sanvi_logo.png";

const PurchaseBillPrint = React.forwardRef(({ data }, ref) => {
  const iscgst =
    data.vendorgst[0] == data.locationgst[0] &&
    data.vendorgst[1] == data.locationgst[1];

  return (
    <div ref={ref}>
      <div className="d-flex justify-content-between align-items-center bb">
        <div style={{ width: "20%" }}>
          <img
            src={sanviLogo}
            alt="logo"
            style={{ width: "70%", height: "50%" }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <h1>SAANVI ENTERPRISES</h1>
          <h4>
            M-8, Near Sudha Nursing Home ,Basanti Colony, Rourkela,
            Sundargarh,Odisha - 769012
          </h4>
        </div>
        <div style={{ width: "30%" }}>
          <Button className="commonBtn " style={{ borderRadius: "2px" }}>
            Purchase Bill
          </Button>
          <h4>Phone No:- {"9853456525"}</h4>
          <h4>Website:- www.saanviavs.com</h4>
          <h4>Email:- saanviavs@gmail.com</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center p-2 bb">
        <h3>GSTIN:- {data.locationgst}</h3>
        <h3>PAN:- {"AADCS0472N"}</h3>
        <h3>Purchase Bill:- {data.purchasebillno}</h3>
      </div>
      <div className="d-flex justify-content-beteen gap-2 align-items-center p-2 bb w-100">
        <div style={{ width: "auto" }}>
          <h4>Bill To :- &nbsp; {data.company ? data.company : "--"}</h4>
          <h4>
            Address :- {data.deliveryaddress ? data.deliveryaddress : "--"}
          </h4>
          <h4>Phone :- {"9853456525"}</h4>
          <h4>GSTIN :- {data.locationgst}</h4>
        </div>
        <div style={{ width: "auto" }}>
          <h4>Bill From :- &nbsp; {data.vendor}</h4>
          <h4>Address :- {data.address}</h4>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center gap-2 p-2 bb">
        <div>
          <h4>Purchase Bill No:- {data.purchasebillno}</h4>
          <h4>
            {" "}
            Purchase Order:- {data.purchaseorder ? data.purchaseorder : "--"}
          </h4>
          <h4>GRN No:- {data.grn ? data.grn : "--"}</h4>
          <h4> Invoice No :- {data.invoiceno ? data.invoiceno : "--"}</h4>
        </div>
        <div>
          <h4>Account:- {data.account}</h4>
          <h4>Bill Date:- {data.billdate ? data.billdate : "--"}</h4>
          <h4>
            Purchase Date:- {data.purchasedate ? data.purchasedate : "--"}
          </h4>
          <h4>Due Date:- {data.paymentduedate ? data.paymentduedate : "--"}</h4>
        </div>
        <div>
          <h4>
            Payment Terms:- {data.paymentterms ? data.paymentterms : "--"}
          </h4>
          <h4>Vehicle Type:- {data.vehicletype ? data.vehicletype : "--"}</h4>
          <h4>Vehicle No:- {data.vehicleno ? data.vehicleno : "--"}</h4>
          <h4>E-Way Bill:- {data.ewaybill ? data.ewaybill : "--"}</h4>
        </div>
      </div>
      <Table responsive className="table-color fs-4 text-center">
        <thead>
          <tr>
            <th className="th-color">Item</th>
            <th className="th-color">Unit</th>
            <th className="th-color">QTY</th>
            <th className="th-color">Rate</th>
            <th className="th-color">GST%</th>
            <th className="th-color">Discount%</th>
            <th className="th-color">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.itemarray.length > 0 ? (
            data.itemarray.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>
                    {item.itemid}-{item.itemname}
                  </td>
                  <td>{item.unit}</td>
                  <td>{item.qty}</td>
                  <td>{item.rate}</td>
                  <td>{item.gst}</td>
                  <td>{item.discount}</td>
                  <td>{item.discountamount}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">
                <div className="datanotfound-center-text">No Item Found 🤔</div>
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            <td className="tddata-right">Sub Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.subtotal}</td>
          </tr>
          {iscgst ? (
            <tr>
              <td className="tddata-right">CGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.cgst}</td>
            </tr>
          ) : null}
          {iscgst ? (
            <tr>
              <td className="tddata-right">SGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.sgst}</td>
            </tr>
          ) : null}
          {!iscgst ? (
            <tr>
              <td className="tddata-right">IGST</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="font-bold td-top-border">{data.igst}</td>
            </tr>
          ) : null}

          <tr>
            <td className="tddata-right">{data.taxtype}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.tsamount}</td>
          </tr>
          <tr>
            <td className="tddata-right">Freight Advance</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.fadvance}</td>
          </tr>
          <tr>
            <td className="tddata-right">Adjustment </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.adjustmentamont}</td>
          </tr>
          <tr>
            <td className="tddata-right">Grand Total </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.grandtotal}</td>
          </tr>
          <tr>
            <td className="tddata-right">Vendor Advance </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.vendoradvance}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td className="tddata-right ">Total</td>
            <td></td>
            <td className="font-bold">
              {data.itemarray.reduce((acc, curr) => {
                return Number(acc) + Number(curr.qty);
              }, 0)}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border ">{data.finalamount}</td>
          </tr>
        </tbody>
      </Table>
      <div>
        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div
            style={{ width: "65%", borderRight: "1px solid black" }}
            className="d-flex align-items-center justify-content-center"
          >
            <h4 className="text-center font-bold">Terms & Conditions</h4>
          </div>
          <div style={{ width: "35%" }}>
            <h4 className="text-center font-bold">
              Certified that particulars given above are true & correct
            </h4>
            <h2 className="text-center font-bold">For SAANVI ENTERPRISES</h2>
          </div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}>
            <h5 className="text-center font-bold">
              {
                "By accepting this invoice, the customer agrees to pay the specified amount within the stated timeframe. Any disputes must be raised within 30 days. Late payments may incur interest charges. Goods remain property of the seller until fully paid. All sales are final."
              }
            </h5>
          </div>
          <div style={{ width: "35%" }}></div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}></div>
          <div style={{ width: "35%" }}>
            <h3 className="text-center">Authorised Signature</h3>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PurchaseBillPrint;
