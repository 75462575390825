import React, { useEffect, useState } from "react";
import { Button, Col, FormControl, Row, Table } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { Form, useNavigate } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useRef } from "react";

function TableStockReport() {

  const pdfRef = useRef(null);

  const navigate = useNavigate();

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userid = localData.userid;
  let branchid = localData.branchid;
  let companyid = localData.companyid;
  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);
  const loadData = useSelector((state) => state.loadReducer);

  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);

  // for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesFromAPI, setTotalPagesFromAPI] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(8);

  const [issueArray, setIssueArray] = useState([]);
  const [receiveArray, setReceiveArray] = useState([]);
  // const [data, setData] = useState([])

  // get all Data

  const getAllData = () => {
    setLoading(true);
    axios

      .post(`https://mis.saanviavs.com/api/v1/item/getstock`, {

        companyid,
        org:"₹ΘθϖΠ",
        fy: financialyear,
        financialyear:financialyear
      })
      .then((res) => {
        // Flatten the data structure and filter based on multiple fields
        const flattenedData = res.data.open
          .map((item) => ({ ...item, type: "open" }))
          .concat(res.data.issue.map((item) => ({ ...item, type: "issue" })))
          .concat(
            res.data.receive.map((item) => ({ ...item, type: "receive" }))
          );

        const filteredData = flattenedData.filter((item) =>
          Object.values(item)
            .map((value) =>
              String(value).toLowerCase().includes(searchValue.toLowerCase())
            )
            .some(Boolean)
        );

        // Group the filtered data back into the original structure
        const groupedData = {
          open: filteredData.filter((item) => item.type === "open"),
          issue: filteredData.filter((item) => item.type === "issue"),
          receive: filteredData.filter((item) => item.type === "receive"),
        };
        setData(groupedData);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // fetchApiIssueAndReceive();
    getAllData();
  }, [searchValue]);

  // search functionality

  const handleSummaryView = (id) => {
    //const formData = data.find((item) => item.paymentid == id);
    // navigate(`/purchase/paymentsMade/addPaymentMade/vAView`, {
    //   state: { addedData: formData },
    // });
    navigate(`/stock-report/stockReportsMonthlySummary/${id}`);
  };

  // date for today date
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  var formattedTodayDate = dd + "-" + mm + "-" + yyyy;

  return (
    <>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col sm={4} md={4} lg={5} xl={4}>
              <p className="section-title form-section ptag">
                List of All <span className="form-title">Items</span>
              </p>
            </Col>
            <Col></Col>
            <Col sm={2} md={3} lg={2} xl={3}>
              <div className="inputFieldIcon">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="basic-addon2"
                  className="mt-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="iconPosition">
                  <BiSearch />
                </span>
              </div>
            </Col>
            {/* <Col sm={3} md={2} lg={2} xl={2}>
              <Button className="commonBtn1 mt-2">Export</Button>
            </Col> */}

            {/* ... */}
            <Col sm={3} md={2} lg={2} xl={2}>
              {/* Export Button */}
              {/* <ReactHTMLTableToExcel
                id="exportButton"
                className="commonBtn mt-2 cancelBtn"
                table="table-to-export"
                filename={`Stock Report Year, ${formattedTodayDate}`}
                sheet="stock_report"
                buttonText="Export"
              /> */}

<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

            </Col>
          </Row>
        </Col>
        <Col md={12}  className="table-main-container reportTable"  >
          <Table id="table-to-export"  responsive ref={pdfRef} 
          
>
            <thead className="tableContainer">
              <tr>
                <th rowSpan={2}>Item ID</th>
                <th rowSpan={2}>Item Name</th>
                <th rowSpan={2}>Inhand QTY</th>
                <th rowSpan={2}>Reorder Point</th>
                <th colSpan={3}>Opening Balance</th>
                <th colSpan={3}>Receive</th>
                <th colSpan={3}>Issue</th>
                <th colSpan={3}>Closing Balance</th>
                <th rowSpan={2}>Action</th>
              </tr>
              <tr>
                <th>Qty</th>
                <th>Rate</th>
                <th>Value</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Value</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Value</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Value</th>
              </tr>
            </thead>

            <tbody>
              { data.open !== undefined && data.open.length === 0 && (
                <tr>
                  <td colSpan="15">
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
              { loadData || loading ? (
                <tr>
                  <td colSpan="15" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) :  data.open !== undefined &&
                data.open.map((oitem, index) => {
                  const rData =
                    data.receive !== undefined &&
                    data.receive.find((elem) => elem.itemid === oitem.itemid);
                  const iData =
                    data.issue !== undefined &&
                    data.issue.find((elem) => elem.itemid === oitem.itemid);

                  const closingRate = (
                    (oitem.value -
                      (iData ? parseInt(iData.total_amount) : 0) +
                      (rData ? parseInt(rData.total_amount) : 0)) /
                    (oitem.openingqty -
                      (iData ? parseInt(iData.total_qty) : 0) +
                      (rData ? parseInt(rData.total_qty) : 0))
                  ).toFixed(2);
                  return (
                    <tr key={index}>
                      <td>{oitem.itemid}</td>
                      <td>{oitem ? oitem.itemname : ""}</td>
                      <td>{oitem ? oitem.inhandquantity : ""}</td>
                      <td>{oitem ? oitem.reorderpoint : "-"}</td>
                      {/* Opening Balance */}
                      <td>{oitem.openingqty}</td>
                      <td>{oitem.orate}</td>
                      <td>{oitem.value}</td>
                      {/* Receive */}
                      <td>{rData ? rData.total_qty : ""}</td>
                      <td>
                        {rData
                          ? parseInt(
                              rData.total_amount / rData.total_qty
                            ).toFixed(2)
                          : 0}
                      </td>
                      <td>{rData ? rData.total_amount : ""}</td>
                      {/* Issue */}
                      <td>{iData ? iData.total_qty : ""}</td>
                      <td>
                        {iData
                          ? parseInt(
                              iData.total_amount / iData.total_qty
                            ).toFixed(2)
                          : 0}
                      </td>
                      <td>{iData ? iData.total_amount : ""}</td>
                      {/* Closing Balance */}
                      <td>
                        {oitem.openingqty -
                          (iData ? parseInt(iData.total_qty) : 0) +
                          (rData ? parseInt(rData.total_qty) : 0)}
                      </td>
                      <td>{closingRate}</td>
                      <td>
                        {oitem.value -
                          (iData ? parseInt(iData.total_amount) : 0) +
                          (rData ? parseInt(rData.total_amount) : 0)}
                      </td>
                      <td
                        onClick={() => handleSummaryView(oitem.id)}
                      >
                        <AiFillEye className="editIcon" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Col>
        {/* <Col
          style={{ position: "fixed", bottom: 0 }}
          md={12}
          className="pr-0 pl-0 text-center"
        >
        <Table responsive>
        <tbody>
          <tr>
            <td colSpan={2}>Grand Total</td>
          Opening Balance 
            <td colSpan={3}>
              <div>
                <div>200</div>
                <div>26,500.00</div>
              </div>
            </td>
            Receive/Produce 
            <td colSpan={3}>
              <div>
               Adjust the values accordingly 
                <div>90</div>
                <div>1,51,800.00</div>
              </div>
            </td>
            Issue/Sale 
            <td colSpan={3}>
              <div>
              Adjust the values accordingly 
                <div>70</div>
                <div>10,595.00</div>
              </div>
            </td>
           Closing Balance 
            <td colSpan={3}>
              <div>
                 Adjust the values accordingly
                <div>210</div>
                <div>36,871.20</div>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
        </Col> */}
      </Row>
    </>
  );
}

export default TableStockReport;
