import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { notifyError } from "../../../constants/toastAlert";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import {
  addLedger,
  ledgerAutoCount,
  ledgerPagiData,
} from "../../../redux/actions/ledger.actions";

const AddAccountLedger = () => {
  //state part
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    ledgerid: "",
    ledgername: "",
    ledgertype: "Other",
    istds: "No",
    isinventory: "No",
    accountid: "",
    accountname: "",
    accounttype: "",
    openingbalance: "",
    debitcredit: "",
    asondate: "",
    companyid: "",
    userid: "",
    branchid: "",
  });
  const [loading, setLoading] = useState(false);

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { ledgerCount } = useSelector((state) => state.ledgerData) || 0;
  const { data: coaAllData } =
    useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const isLedgerYesCoaData = coaAllData
    ? coaAllData.filter((item) => item.isledger === "Yes")
    : [];
  const { data: ledgerData } = useSelector((state) => state.ledgerData.ledgerPagiData) || [];
  const ledgerAllData = !ledgerData ? [] : ledgerData;


  //Default Data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  };

  useEffect(() => {
    setLoading(!loading);
    // dispatch(ledgerAutoCount(backendData, setLoading));
    dispatch(ledgerPagiData(backendData, setLoading));
    dispatch(coaPagiData(backendData, setLoading));

    let count = autoGenerateID();
    let paddedCount = count.toString().padStart(4, "0");
    let result = `LEDGER/SAANVI/${paddedCount}`;
    setData({
      ...data,
      ledgerid: result,
      userid: userid,
      branchid: branchid,
      companyid: companyid,
    });
  }, [dispatch, loadData, show, ledgerCount]);

  //Auto generate Id start
  function autoGenerateID() {
    if (ledgerAllData.length === 0) {
      return "1";
    } else {
      const currentId = ledgerAllData[0].ledgerid.split("/")[2];
      return String(parseInt(currentId) + 1);
    }
  };

  //Auto generate Id end

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "accountname") {
      const selectedAccount = isLedgerYesCoaData.find(
        (itm) => itm.accountname === value
      );
      if (selectedAccount) {
        setData((prevData) => ({
          ...prevData,
          accountid: selectedAccount.accountid,
          accounttype: selectedAccount.accounttype,
          [name]: value,
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          accountid: "",
          accounttype: "",
          [name]: value,
        }));
      }
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setShowEmptyError(false);
  };

  //filter part
  const groupedData = {}; // This object will store gledgers grouped by accounttype
  isLedgerYesCoaData &&
    isLedgerYesCoaData.forEach((item) => {
      const { accounttype, accountname } = item;
      // Check if the accounttype already exists in groupedData
      if (!groupedData[accounttype]) {
        groupedData[accounttype] = [];
      }
      // Add the accountname to the corresponding accounttype
      groupedData[accounttype].push(accountname);
    });

  //modal open
  const handleShow = () => setShow(true);
  //modal close
  const handleClose = () => {
    setShow(false);
    setData({
      ledgerid: "",
      ledgername: "",
      ledgertype: "Other",
      istds: "No",
      isinventory: "No",
      accountid: "",
      accountname: "",
      accounttype: "",
      openingbalance: "",
      debitcredit: "",
      asondate: "",
      companyid: "",
      userid: "",
      branchid: "",
    });
  };

  const handleSave = async () => {
    if (
      data.ledgername == "" ||
      data.accountname == "" ||
      data.openingbalance == "" ||
      data.debitcredit == "" ||
      data.asondate == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    dispatch(addLedger(data, handleClose));
  };

  return (
    <>
      <Button className="commonBtn mt-2" onClick={handleShow}>
        Add Ledger
      </Button>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Add <span className="form-title">Ledger</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={2} md={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Ledger ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ledger ID"
                        name="ledgerid"
                        value={data.ledgerid}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Other</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Other"
                        name="ledgertype"
                        value={data.ledgertype}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Ledger Name <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Ledger Name"
                    name="ledgername"
                    value={data.ledgername}
                    onChange={handleChange}
                    //required
                    isInvalid={data.ledgername === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Ledger Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row lg={2} md={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="accountname"
                    value={data.accountname}
                    onChange={handleChange}
                    isInvalid={data.accountname === "" && showEmptyError}
                  >
                    <option value="">--Select--</option>
                    {Object.entries(groupedData).map(
                      ([accounttype, accountname], index) => (
                        <optgroup label={accounttype} key={index}>
                          {accountname.map((accountname, accountnameIndex) => (
                            <option value={accountname} key={accountnameIndex}>
                              {accountname}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        TDS Applicable?<span className="star"> *</span>
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="istds"
                          label="Yes"
                          value="Yes"
                          checked={data.istds === "Yes"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                          name="istds"
                          label="No"
                          value="No"
                          checked={data.istds === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Inventory values are affected?
                        <span className="star"> *</span>
                      </Form.Label>
                      <div>
                        <Form.Check
                          inline
                          type="checkbox"
                          name="isinventory"
                          label="Yes"
                          value="Yes"
                          checked={data.isinventory === "Yes"}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="checkbox"
                          name="isinventory"
                          label="No"
                          value="No"
                          checked={data.isinventory === "No"}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row lg={2} md={1}>
              <Col>
                <Row md={2} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="openign Balance">
                      <Form.Label>
                        Opening Balance <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        style={{ textAlign: "right" }}
                        type="number"
                        placeholder="0.00"
                        name="openingbalance"
                        value={data.openingbalance}
                        onChange={handleChange}
                        required
                        isInvalid={data.openingbalance === "" && showEmptyError}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="debitcredit">
                      <Form.Label>
                        Debit/Credit <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="debitcredit"
                        value={data.debitcredit}
                        onChange={handleChange}
                        isInvalid={data.debitcredit === "" && showEmptyError}
                      >
                        <option value="">--Select--</option>
                        <option value="Debit">Debit</option>
                        <option value="Credit">Credit</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Debit or Credit
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row md={1} sm={1}>
                  <Col md={5}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        As on Date <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="date"
                        name="asondate"
                        value={
                          (data.asondate = `${new Date().toISOString().split("T")[0]
                            }`)
                        }
                        min={`${new Date().toISOString().split("T")[0]}`}
                        max={`${new Date().toISOString().split("T")[0]}`}
                        onChange={handleChange}
                        onKeyDown={(e) => e.preventDefault()}
                        isInvalid={data.asondate === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Choose Date
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddAccountLedger;
