import { notifyError, notifyWarning } from "../../constants/toastAlert";
import axios from "axios";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { itemPagiDataPost } from "../../constants/api";
import { FETCH_ITEM_DATA, FETCH_ITEM_IMAGE } from "../actionTypes/item.actiontypes";

//Get all item data using pagination wise
export const itemPagiData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(itemPagiDataPost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_ITEM_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching item data ${error}`);
    notifyError(error.message);
  }
};

//Action dispatcher for document upload
export const itemFileUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
   
    const res = await axios.post(

      "https://mis.saanviavs.com/api/v1/item/fileupload",

      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_ITEM_IMAGE, payload: fileurl });
    } else {
      notifyWarning("Image not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading item image:", error.message);
    throw error;
    notifyError(error.message);
  }
};
