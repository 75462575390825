import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";
import { notifySuccess, notifyWarning } from "../../../constants/toastAlert";
import { userUserpaginationPost } from "../../../constants/api";
import TableHeading from "../../../constants/TableHeading";
import "./AccessControl.css";

const EditAccess = ({ id }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [subModuleData, setSubModuleData] = useState([]);
  const [crudArrayData, setCrudArrayData] = useState(["c", "u", "d"]);
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedSubModule, setSelectedSubModule] = useState([]);
  const [selectedCrud, setSelectedCrud] = useState([]);
  const [moduleOfSubModule, setModuleOfSubModule] = useState("");
  const [userData, setUserData] = useState([]);
  const [modules, setModules] = useState([]);
  const [submodules, setSubmodules] = useState([]);
  const [hide, setHide] = useState(false);
  const handleShow = () => setShow(true);

  let moduleName;
  useEffect(() => {
    //get selected user
    axios

      .post(`https://mis.saanviavs.com/api/v1/user/getuserbyid`, {

        id,
      })
      .then((res) => {
        let initialData = res.data;
        setUserData(initialData.length > 0 ? initialData : []);
        setSelectedModule(
          initialData.length > 0 &&
          initialData[0].module &&
          initialData[0].module
        );
        setSelectedSubModule(
          initialData.length > 0 &&
          initialData[0].submodule &&
          initialData[0].submodule
        );
      })
      .catch((err) => console.log(err));

    // get All Modules
    axios

      .post("https://mis.saanviavs.com/api/v1/module/get", {

        userid: userID,
      })
      .then((res) => {
        setModuleData(res.data.length > 0 ? res.data : []);
        setSubModuleData(
          res.data.length > 0
            ? res.data.filter((ele) => ele.module == moduleName)
            : []
        );
      })
      .catch((err) => console.log(err));
  }, []);
  //   for module

  // const handleRowSelectionModule = (row, e) => {
  //   moduleName = row.module;
  //   const isSelected = selectedModule.includes(row);
  //   if (isSelected) {
  //     // If row is already selected, remove it
  //     setSelectedModule(selectedModule.filter((selected) => selected !== row));
  //   } else {
  //     // If row is not selected, add it
  //     setSelectedModule([...selectedModule, row]);
  //     const selectedModuleName = row.module;
  //     const filteredSubModules = moduleData.filter(
  //       (module) => module.module === selectedModuleName
  //     );
  //     setSubModuleData(filteredSubModules);
  //     setModuleOfSubModule(selectedModuleName);
  //   }
  // };

  const handleRowSelectionModule = (row, e) => {
    const isSelected = selectedModule && selectedModule.find((module) => module === row.module);

    if (isSelected) {
      setHide(true);
      // If row is already selected, remove it
      setSelectedModule(
        selectedModule.filter((module) => module !== row.module)
      );
    } else {
      // If row is not selected, add it
      selectedModule && setSelectedModule([...selectedModule, row.module]);
      const selectedModuleName = row.module;
      const filteredSubModules = moduleData.filter(
        (module) => module.module === selectedModuleName
      );
      setSubModuleData(filteredSubModules);
      setModuleOfSubModule(selectedModuleName);
    }
  };

  //   for sub module

  const handleRowSelectionSubModule = (row) => {
    const isSelected = selectedSubModule.includes(row);
    if (isSelected) {
      // If row is already selected, remove it
      setSelectedSubModule(
        selectedSubModule.filter((selected) => selected !== row)
      );
    } else {
      // If row is not selected, add it
      setSelectedSubModule([...selectedSubModule, row]);
    }
  };

  const result = {};
  // selectedSubModule.forEach((key, index) => {
  //   result[key] = selectedCrud.map((value) => value);
  // });

  const handleSubmit = async () => {
    try {
      dispatch(reloadPage(true));
      const selectedModuleArray = selectedModule.map((row) => row.module);
      const moduleString = selectedModuleArray.join(", ");
      const selectedSubModuleArray = selectedSubModule.map((row) => row);
      const subModuleString = selectedSubModuleArray.join(", ");
      const res = await axios.post(

        "https://mis.saanviavs.com/api/v1/user/updateaccessmodule",

        {
          module: selectedModule,
          submodule: selectedSubModuleArray,
          id,
          crud: result,
        }
      );

      if (res.data.message == "Modules and sub-modules updated") {
        notifySuccess(res.data.message);
        setShow(false);
        dispatch(reloadPage(false));
      } else {
        notifyWarning(res.data.message);
      }
      dispatch(reloadPage(false));
    } catch (error) {
      console.error("Error:", error);
      dispatch(reloadPage(false));
    }
  };
  
  return (
    <>
      <MdEdit className="editIcon" onClick={handleShow} />
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <span className="form-title">Access</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="userid"
                    value={userData.length > 0 ? userData[0].userid : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="designationname"
                    value={
                      userData.length > 0 ? userData[0].designationname : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="name"
                    value={userData.length > 0 ? userData[0].name : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="companyname"
                    value={userData.length > 0 ? userData[0].companyname : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div className="viewpage-table-container">
                  <span className="viewpage-module">Module</span>
                  <Table responsive className="mb-0">
                    <tbody className="table-main-container">
                      {moduleData.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td
                            onClick={(e) => handleRowSelectionModule(row, e)}
                            style={{
                              backgroundColor:
                                selectedModule &&
                                  selectedModule.includes(row.module)
                                  ? "lightblue"
                                  : "inherit",
                            }}
                          >
                            {row.module}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col>
                <div className="viewpage-table-container">
                  <span className="viewpage-module">Sub Module</span>
                  <Table responsive className="mb-0">
                    <tbody className="table-main-container">

                      {subModuleData && subModuleData.length > 0
                        ? subModuleData[0].submodule && subModuleData[0].submodule.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  selectedSubModule && selectedSubModule.length > 0 &&
                                  selectedSubModule.includes(row)
                                }
                                onChange={() =>
                                  handleRowSelectionSubModule(row)
                                }
                              />
                            </td>
                          </tr>
                        ))
                        : []}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <br />
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          >
            Update
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAccess;
