import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import { salesInvoicePagiData } from "../../../../redux/actions/salesInvoice.actions";
import { notifyError, notifySuccess } from "../../../../constants/toastAlert";

function PartbVehicle() {
    const location = useLocation();
    const { invoiceno, ewaybillno } = location.state || {};

  const URL = extractAndFormatURLPart("ewaybill");
  const navigate = useNavigate();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];

  let financialyear = localStorage.financialyear;
  // const financialyear = financialyearString;
  const [loading, setLoading] = useState(false);
  const loadData = useSelector((state) => state.loadReducer);

  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const {
    companyname: lscompany,
    state: lsstate,
    city: lscity,
    pincode: lspincode,
    gstno: lsgstno,
    pan: lspan,
    hoaddress: lshoaddress,
  } = useSelector((state) => state.authManager.userData.companyaddress) || {};
  const salesInvoiceMaster =
    useSelector((state) => state.salesInvoiceData.salesInvoicePagiData.data) ||
    [];
  // const invoiceno = location.state.invoiceno;
  //   States

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const [data, setData] = useState({
    supplyType: "O",
    subSupplyType: "",
    docNo: "",
    docDate: getTodayDate(), //
    fromGstin: lsgstno || "",
    fromTrdName: name || "",
    fromAddr1:  "",
    fromAddr2: "",
    fromPlace: lscity || "",
    fromPincode: lspincode || "",
    fromStateCode: "",
    toGstin: "",
    toTrdName: "",
    toAddr1: "",
    toAddr2: "",
    toPlace: "",
    toPincode: 0,
    toStateCode: "",
    transactionType: 0,
    dispatchFromGSTIN: lsgstno || "",
    dispatchFromTradeName: companyname || "",
    shipToGSTIN: "",
    shipToTradeName: "",
    totalValue: 0,
    cgstValue: 0,
    sgstValue: 0,
    igstValue: 0,
    cessValue: 0,
    cessNonAdvolValue: 0,
    totInvValue: 0,
    transMode: "",
    transDistance: "",
    transporterName: "",
    transporterId: "",
    transDocNo: "",
    vehicleNo: "",
    vehicleType: "",
    itemList: [],
    companyid,
    financialyear,
    ewaybillno: ewaybillno
  });
  const [showEmptyError, setShowEmptyError] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name == "docNo") {
      if (value) {
        const findSaleInvoice = salesInvoiceMaster.find(
          (si) => si.invoiceno == value
        );
        const { bdgstno, bdowner, bdcompany } =
          findSaleInvoice.commonnamealldata[0];

        const itemList =
          findSaleInvoice.itemarray.length > 0
            ? findSaleInvoice.itemarray.map((item) => ({
                productName: item.itemname,
                productDesc: item.itemdescription,
                hsnCode: item.hsncode,
                quantity: +item.qty,
                qtyUnit: item.unit,
                taxableAmount: +item.amount,
                sgstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                cgstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? (Number(item.amount) * Number(item.gst)) / 100 / 2
                    : 0
                ).toFixed(2),
                igstRate: +(
                  !findSaleInvoice.igst || findSaleInvoice.igst == 0
                    ? 0
                    : (Number(item.amount) * Number(item.gst)) / 100
                ).toFixed(2),
                cessRate: 0,
              }))
            : [];

        setData((prev) => ({
          ...prev,
          toGstin: bdgstno,
          toTrdName: findSaleInvoice.commonname,
          shipToTradeName: bdcompany,
          shipToGSTIN: bdgstno,
          toAddr1: findSaleInvoice.staddress,
          toPlace: findSaleInvoice.stcity,
          toPincode: findSaleInvoice.stpin,
          toStateCode: bdgstno ? `${bdgstno[0]}${bdgstno[1]}` : "",
          itemList: itemList,
          cgstValue: Number(findSaleInvoice.cgst),
          sgstValue: Number(findSaleInvoice.sgst),
          igstValue: Number(findSaleInvoice.igst),
          totalValue: Number(findSaleInvoice.subtotal),
          totInvValue: Number(findSaleInvoice.grandtotal),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          toGstin: "",
          toTrdName: "",
          shipToGSTIN: "",
          shipToTradeName: "",
          toAddr1: "",
          toPlace: "",
          toPincode: "",
          toStateCode: "",
          itemList: [],
          cgstValue: 0,
          sgstValue: 0,
          igstValue: 0,
          totalValue: 0,
          totInvValue: 0,
        }));
      }
    }
  };

  // const addItem = () => {
  //   // Create a new item object with default values
  //   const newItem = {
  //     productName: "",
  //     productDesc: "",
  //     hsnCode: 0,
  //     quantity: 0,
  //     qtyUnit: "",
  //     taxableAmount: 0,
  //     sgstRate: 0,
  //     cgstRate: 0,
  //     igstRate: 0,
  //     cessRate: 0,
  //   };

  //   // Append the new item to the itemList array
  //   setData((prevData) => ({
  //     ...prevData,
  //     itemList: [...prevData.itemList, newItem],
  //   }));
  // };

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;

    // Determine if the input is a numeric field
    const isNumericField = [
      "hsnCode",
      "quantity",
      "taxableAmount",
      "sgstRate",
      "cgstRate",
      "igstRate",
      "cessRate",
    ].includes(name);

    // Convert the value to a number if it is a numeric field
    const newValue = isNumericField ? parseFloat(value) : value;

    // Update the state with a new array
    setData((prevState) => {
      // Copy the previous state
      const updatedItemList = [...prevState.itemList];

      // Update the specific field of the item at the given index
      updatedItemList[index] = {
        ...updatedItemList[index],
        [name]: newValue,
      };

      // Return the new state with updated itemList
      return {
        ...prevState,
        itemList: updatedItemList,
      };
    });
  };

  const handleSubmit = () => {
    if (

      !data.vehicleNo 
      // !data.transporterId ||
      // !data.transDocNo
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    } else {
      axios
        .post(`https://mis.saanviavs.com/api/v1/ewaybill/partb`, data)
        .then((res) => {
        
          if(res.data.status_cd == "1"){
            notifySuccess("EWB Part b updated successfully")
            navigate("/ewaybill")
          }else{
            notifyError(res.data.error.message)
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleLeftArrow = () => {
    navigate("/ewaybill");
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      salesInvoicePagiData(
        { userid, branchid, companyid, keyword: org },
        setLoading
      )
    );
  }, [dispatch]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Update Part B <span className="form-title main"> E-waybill</span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
             
                  <Col>
                    <Row>
                    <Col md={6}>
                        <Form.Group controlId="fromStateCode">
                          <Form.Label>
                          State Code <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            name="fromStateCode"
                            value={data.fromStateCode}
                            onChange={handleChange}
                            isInvalid={!data.fromStateCode && showEmptyError}
                          />
                           
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="fromAddr1">
                          <Form.Label>
                           From Place <span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            name="fromAddr1"
                            value={data.fromAddr1}
                            onChange={handleChange}
                            isInvalid={!data.fromAddr1 && showEmptyError}
                          />
                         
                        </Form.Group>
                      </Col>

                 
                    </Row>
             
                  </Col>

                </Row>
               
              

                <hr></hr>
                <Row>
                  <Col>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="vehicleNo">
                          <Form.Label>
                            Vehicle No<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="vehicleNo"
                            value={data.vehicleNo}
                            onChange={handleChange}
                            placeholder="Enter Vehicle Number"
                            isInvalid={!data.vehicleNo && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
         
                    </Row>
                  </Col>
                  <Col>
                  <Row>
                      <Col md={6}>
                        <Form.Group controlId="transMode">
                          <Form.Label>
                            {" "}
                            Mode Of Transportation{" "}
                            <span className="star"> *</span>
                          </Form.Label>
                          <Form.Select
                            // as="select"
                            name="transMode"
                            value={data.transMode}
                            onChange={handleChange}
                            isInvalid={!data.transMode && showEmptyError}
                          >
                            <option value="">Select Transport Mode</option>
                            <option value="1">Road</option>
                            <option value="2">Rail</option>
                            <option value="3">Air</option>
                            <option value="4">Ship</option>
                            <option value="5">inTransit</option>

                            {/* Add more options as needed */}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      </Row>
                </Col>

             
                </Row>
            
              </Container>
              <hr></hr>
              <Container fluid>
             
              </Container>
            </div>
          </div>
        </Form>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSubmit}>
            Create
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleLeftArrow}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
}

export default PartbVehicle;
