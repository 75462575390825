import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { itemGetItemPost } from "../../../constants/api";
import { initialData } from "../../../constants/resData";
import axios from "axios";
import SearchField from "../../../constants/SearchField";
import FilterField from "../../../constants/FilterField";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { notifyError } from "../../../constants/toastAlert";
import { useSelector } from "react-redux";

const AvailableStock = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org
  const [show, setShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [brand, setBrand] = useState([{ value: "", text: "" }]);
  const [categories, setCategories] = useState([{ value: "", text: "" }]);
  const [data, setData] = useState([]);
  const loadData = useSelector((state) => state.loadReducer);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    axios
      .post(itemGetItemPost, {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: searchKeyword || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setData(initialData);
        const uniqueBrands = [...new Set(initialData.map((ele) => ele.brand))];
        setBrand(
          uniqueBrands.map((brand) => {
            return {
              className: "",
              value: brand,
              text: brand,
            };
          })
        );
      })
      .catch((err) => console.log(err));

    //dynamic category generate
    axios

      .post("https://mis.saanviavs.com/api/v1/item/category", {

        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || org,
      })

      .then((res) => {
        setCategories(
          res.data &&
            res.data.data.map((cat) => {
              return {
                className: "",
                value: cat.category,
                text: cat.category,
              };
            })
        );
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  const filteredData = !data
    ? []
    : data
        .filter((item) =>
          searchKeyword
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchKeyword.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (selectedBrand == "") {
            return true;
          } else {
            return item.brand === selectedBrand;
          }
        })
        .filter((item) => {
          if (selectedCategory == "") {
            return true;
          } else {
            return item.category === selectedCategory;
          }
        });

  const handleSelectAll = () => {
    const allSelected = !selectAllChecked;
    setSelectAllChecked(allSelected);
    setSelectedRows(allSelected ? [...filteredData] : []);
  };
  const handleRowSelect = (item) => {
    if (
      selectedRows.some((selectedRow) => selectedRow.itemid === item.itemid)
    ) {
      // If the employee is already selected, remove them from selectedRows
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.itemid !== item.itemid)
      );
    } else {
      // If the employee is not selected, add them to selectedRows
      setSelectedRows([...selectedRows, item]);
    }
  };

  const handleRaise = () => {
    let IDs = selectedRows.map((elem) => {
      return elem.id;
    });
    if (IDs.length < 1) {
      return notifyError("Atleast one row should be selected");
    }
    alert(`PO has been raised`);
    setSelectAllChecked(false);
    setSelectedRows([]);
    handleClose();
  };

  return (
    <>
      <Button
        className="commonBtn smallBtn cancelBtn mt-2 w-25"
        onClick={handleShow}
      >
        Available Stock
      </Button>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Available <span className="form-title">Stock</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={4} md={2}>
              <Col>
                <FilterField
                  label="Category"
                  islable={false}
                  emptyValue="Category Name"
                  selectedFilterValue={selectedCategory}
                  handleSelectedFilter={(e) =>
                    setSelectedCategory(e.target.value)
                  }
                  filterWords={categories}
                />
              </Col>
              <Col>
                <FilterField
                  label="Brand"
                  islable={false}
                  emptyValue="Brand Name"
                  selectedFilterValue={selectedBrand}
                  handleSelectedFilter={(e) => setSelectedBrand(e.target.value)}
                  filterWords={brand}
                />
              </Col>
              <Col>
                <SearchField
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </Col>
              <Col></Col>
            </Row>
            <Table responsive className="table-main-container">
              <TableHeading
                data={[
                  "#",
                  "Item No",
                  "Item Name",
                  "Category",
                  "Brand",
                  "Series",
                  "UOM",
                  "In Hand Stock",
                  <input
                    type="checkbox"
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                  />,
                ]}
              />
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.itemid}</td>
                      <td>{item.itemname}</td>
                      <td>{item.category}</td>
                      <td>{item.brand}</td>
                      <td>{item.series}</td>
                      <td>{item.unit}</td>
                      <td>{item.inhandquantity}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedRows.some(
                            (selectedRow) => selectedRow.itemid === item.itemid
                          )}
                          onChange={() => handleRowSelect(item)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoRecordFoud count="9" name="Items" />
                )}
              </tbody>
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn w-25"
            onClick={handleRaise}
          >
            Raise Purchase Order
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AvailableStock;
