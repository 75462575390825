import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
import { MdDelete, MdEdit } from "react-icons/md";

const DesignationMaster = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const [allData, setAllData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editStates, setEditStates] = useState({});
  const [currentlyEditedRow, setCurrentlyEditedRow] = useState(null);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [showInputForm, setShowInputForm] = useState(false);

  const handleButtonToOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleAddNewRow = () => {
    setShowInputForm(true);
  };

  const [data, setData] = useState({
    designationid: "",
    designationname: "",
  });

  const [editData, setEditData] = useState({
    designationid: "",
    designationname: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditState = (rowId) => {
    // Close the edit form for the previously edited row
    if (currentlyEditedRow !== null && currentlyEditedRow !== rowId) {
      setEditStates((prevStates) => ({
        ...prevStates,
        [currentlyEditedRow]: false,
      }));
    }

    // Open or close the edit form for the clicked row
    setEditStates((prevStates) => ({
      ...prevStates,
      [rowId]: !prevStates[rowId] || false,
    }));
  };

  const handleAddData = async () => {
    if (data.designationid == "" || data.designationname == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    } else {
      try {
        const res = await axios.post(

          "https://mis.saanviavs.com/api/v1/master/adddesignation",

          data
        );
        if (res.data.message == "Designation added successfully") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyWarning(res.data.message);
        }
        setShowInputForm(false);
        // dispatch(reloadPage(true))
        setData({
          designationname: "",
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEditRow = (rowId) => {
    const recordToEdit = allData.find((elem) => elem.id === rowId);

    setEditData({
      id: recordToEdit.id,
      designationid: recordToEdit.designationid,
      designationname: recordToEdit.designationname,
    });
    if (currentlyEditedRow === rowId) {
      // If the clicked row is already being edited, save the changes
      handleSaveEdit(rowId);
    } else {
      // Set the currently edited row ID
      setCurrentlyEditedRow(rowId);
      // Toggle the edit state only for the clicked row
      toggleEditState(rowId);
    }
  };

  const handleSaveEdit = async (rowId) => {
    if (editData.designationid == "" || editData.designationname == "") {
      notifyError("You have empty field");
      setShowEmptyError(true);
    } else {
      try {
        const res = await axios.post(

          "https://mis.saanviavs.com/api/v1/master/updatedesignation",

          editData
        );

        if (res.data.message == "Designation updated successfully") {
          notifySuccess(res.data.message);
          toggleEditState(rowId);
          setEditData({
            designationname: "",
          });
          setShowEmptyError(false);
          dispatch(reloadPage(true));
          setCurrentlyEditedRow(null);
        } else {
          notifyWarning(res.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    dispatch(reloadPage(false));
    // get all Designation
    axios
      .post("https://mis.saanviavs.com/api/v1/master/getdesignation", {

        userid: userID,
      })
      .then((res) => {
        setAllData(res.data.length > 0 ? res.data : []);
      })
      .catch((err) => console.log(err));

    // get designation id dynamically
    axios
      .post("https://mis.saanviavs.com/api/v1/idgenerate/designationid", {

        userid: userID,
      })
      .then((res) => {
        setData((prevData) => ({
          ...prevData,
          designationid: `DS-${+res.data.count + 1}`,
        }));
      })
      .catch((err) => console.log(err));
  }, [showInputForm, loadData]);

  //  for delete
  const handleDelete = (id) => {
    const deleteData = async () => {
      try {
        const res = await axios.post(
          "https://mis.saanviavs.com/api/v1/master/deletedesignation",

          {id}
        );
        
        if (res.data.message == "Designation delete successfully") {
          notifySuccess(res.data.message);
         dispatch(reloadPage(true));
        } else {
          notifyError("Designation not deleted ");
        }
      } catch (error) {
        console.log(error);
      }
    };
    deleteData();
  };
  return (
    <div>
      <span className="form-title" onClick={handleButtonToOpenModal}>
        <BsPlusCircle /> Add Designation
      </span>
      <Modal
        show={openModal == true}
        onHide={() => {
          setOpenModal(false);
        }}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Manage <span className="form-title">Designation</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr>
                      {/*<th>Designation ID</th>*/}
                      <th>Designation Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadData ? (
                      <tr>
                        <td colSpan="3" className="text-center">
                          <div
                            className="spinner-border spinner-border-md"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        </td>
                      </tr>
                    ) : allData.length > 0 ? (
                      allData.map((elem, index) => (
                        <tr key={index}>
                          {/*<td>
                            {editStates[elem.id] ? (
                              <Form.Control
                                placeholder="Designation ID"
                                name="designationid"
                                value={editData.designationid}
                                disabled
                              />
                            ) : (
                              elem.designationid
                            )}
                          </td>*/}
                          <td>
                            {editStates[elem.id] ? (
                              <Form.Control
                                placeholder="Designation Name"
                                name="designationname"
                                value={editData.designationname}
                                onChange={handleChange}
                                isInvalid={
                                  editData.designationname === "" &&
                                  showEmptyError
                                }
                              />
                            ) : (
                              elem.designationname
                            )}
                          </td>
                          <td>
                            {" "}
                            {editStates[elem.id] ? (
                              <Button
                                className="commonBtn"
                                onClick={() => handleSaveEdit(elem.id)}
                              >
                                Save
                              </Button>
                            ) : (
                              <>
                                <MdEdit
                                  className="editIcon"
                                  onClick={() => handleEditRow(elem.id)}
                                />
                                <MdDelete
                                  className="deleteIcon"
                                  onClick={() => handleDelete(elem.id)}
                                />
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <div className="datanotfound-center-text">
                            No Record Found!! 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                    {showInputForm && (
                      <tr>
                        <td>
                          <Form.Control
                            placeholder="Designation ID"
                            name="designationid"
                            value={data.designationid}
                            disabled
                          />
                        </td>
                        <td>
                          {" "}
                          <Form.Control
                            placeholder="Designation Name"
                            name="designationname"
                            value={data.designationname}
                            onChange={handleChange}
                            isInvalid={
                              data.designationname === "" && showEmptyError
                            }
                          />
                        </td>
                        <td>
                          <Button
                            className="commonBtn my-1"
                            onClick={handleAddData}
                          >
                            Save
                          </Button>
                          <Button
                            className="commonBtn cancelBtn"
                            onClick={() => setShowInputForm(false)}
                          >
                            Cancel
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          <h4 className="form-title" onClick={handleAddNewRow}>
            <BsPlusCircle /> Add New
          </h4>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DesignationMaster;
