import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react";
import html2pdf from "html2pdf.js";

import "./Ewaybill.css";
import { notifyError } from "../../../../constants/toastAlert";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  supplyTypeObj,
  transactionTypeObj,
  transportModeObj,
} from "../../../../constants/sampleData";
import ReactToPrint from "react-to-print";
import PrintEwayBill from "./PrintEwayBill";
import "./Ewaybill.css"
function ViewEwayBill() {
  const URL = extractAndFormatURLPart("ewaybill");
  let componentRef = null;
  const navigate = useNavigate();
  const { ewaybillno } = useParams();
  const [data, setData] = useState({
    VehiclListDetails: [],
    actFromStateCode: "",
    actToStateCode: "",
    actualDist: "",
    cessNonAdvolValue: "",
    cessValue: "",
    cgstValue: "",
    docDate: "",
    docNo: "",
    docType: "",
    ewayBillDate: "",
    ewbNo: "",
    extendedTimes: "",
    fromAddr1: "",
    fromAddr2: "",
    fromGstin: "",
    fromPincode: "",
    fromPlace: "",
    fromStateCode: "",
    fromTrdName: "",
    genMode: "",
    igstValue: "",
    itemList: [],
    noValidDays: "",
    otherValue: "",
    rejectStatus: "",
    sgstValue: "",
    status: "",
    subSupplyType: "",
    supplyType: "",
    toAddr1: "",
    toAddr2: "",
    toGstin: "",
    toPincode: "",
    toPlace: "",
    toStateCode: "",
    toTrdName: "",
    totInvValue: "",
    totalValue: "",
    transactionType: "",
    transporterId: "",
    transporterName: "",
    userGstin: "",
    validUpto: "",
    vehicleType: "",
  });

  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/ewaybill/getbyno`, { ewaybillno })
      .then((res) => {
        // setData(res.data.data? setData((prev)=>({...prev,...res.data.data})):setData((prev)=>prev));
        setData((prev) => ({ ...prev, ...res.data.data }));
      })
      .catch((err) => {
        notifyError("Something Went Wrong");
        console.log(err);
      });
  }, []);

  // const handleDownload = () => {
  //   if (pdfRef.current) {
  //     const element = pdfRef.current;
  //     const options = {
  //       margin: 10,
  //       filename: "e waybill.pdf",
  //       image: { type: "jpeg", quality: 2.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };

  //     html2pdf(element, options);
  //   }
  // };

  const printStyles = `
  @page {
    size: auto;
    margin: 0;
  }
  body {
    font-size: 12px;
    margin: 0;
    padding: 0;
  }
  .print-content {
    width: 100% !important;
  }
  @media print {
    .page-break {
      page-break-before: always;
    }
  }
`;

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <Row>
              <Col >
                <Row className="d-flex justify-content-between align-items-center">
                  <Col md={7}>
                    <h1>
                      <FaArrowLeftLong
                        className="left-arrow text-warning"
                        onClick={() => navigate("/ewaybill")}
                      />
                      <span className="form-title">E-way Bill</span>
                    </h1>
                  </Col>
                  <Col md={3} className="d-flex justify-content-between align-items-center gap-2">
                    {/* <Button className="commonBtn ">Cancel E-way Bill</Button> */}\
                    <Button style={{display:"none"}}></Button>
                    <ReactToPrint
                        trigger={() => (
                          <Button className="commonBtn smallBtn cancelBtn px-5">
                            Print
                          </Button>
                        )}
                        content={() => componentRef}
                        pageStyle={printStyles}
                      />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row style={{position:"relative"}} className="d-flex align-items-center justify-content-between">
            {data.status == "CNL" && <div className="overlay-canceled">CANCELLED</div>}
              <Col>
                <Row className="my-2">
                  <Col>
                    <h5 className="viewpage-title">Document No :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data && data.docNo}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Document Date :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data && data.docDate}</h4>
                  </Col>
                </Row>
              </Col>
              <Col className="text-right">
                <QRCode
                  value={`EWB No - ${data.ewbNo},  Invoice Date - ${data.docDate}, From GSTIN - ${data.fromGstin}`}
                  size={120} // Size of the QR code
                  bgColor={"#FFFFFF"} // Background color
                  fgColor={"#000000"}
                  // width={90}
                  // height={10}
                />
              </Col>
            </Row>
            <hr />
            <h4 className="form-title my-3">E-way Bill Details</h4>
            <Row className="my-2">
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">E-way Bill No :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data.ewbNo}</h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Transportation Mode:</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">
                      {data.VehiclListDetails.length > 0
                        ? transportModeObj[data.VehiclListDetails[0].transMode]
                        : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Valid from :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">
                      {data.VehiclListDetails.length > 0
                        ? data.VehiclListDetails[0].enteredDate
                        : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">E-way Bill Date :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data.ewayBillDate}</h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Distance(Km) :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data.actualDist}</h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Valid Upto :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">{data.validUpto}</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Supply Type :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">
                      {supplyTypeObj[data.supplyType]}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Transaction Type :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">
                      {transactionTypeObj[data.transactionType]}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <h5 className="viewpage-title">Validity(in Days) :</h5>
                  </Col>
                  <Col>
                    <h4 className="font-bold">
                      {(() => {
                        const parseDate = (dateStr) => {
                          const parts = dateStr.split("/");
                          if (parts.length === 3) {
                            const day = parseInt(parts[0], 10);
                            const month = parseInt(parts[1], 10) - 1; // Month is zero-based in Date object
                            const year = parseInt(parts[2], 10);
                            return new Date(year, month, day); // Create Date object (year, month, day)
                          }
                          return null;
                        };

                        const validUptoDate = parseDate(data.validUpto);
                        const enteredDate =
                          data.VehiclListDetails.length > 0
                            ? parseDate(data.VehiclListDetails[0].enteredDate)
                            : null;

                        if (
                          validUptoDate &&
                          enteredDate &&
                          !isNaN(validUptoDate) &&
                          !isNaN(enteredDate)
                        ) {
                          const differenceMs = validUptoDate - enteredDate;
                          const differenceDays =
                            differenceMs / (1000 * 60 * 60 * 24);
                          return Math.floor(differenceDays);
                        } else {
                          return "Invalid date(s)";
                        }
                      })()}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Row className="my-2">
              <Col md={6}>
                <h5 className="viewpage-title">Supplier Details</h5>
              </Col>
              <Col md={6}>
                <h5 className="viewpage-title">Customer Details</h5>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <h4 className="font-bold">From</h4>
              </Col>
              <Col md={6}>
                <h4 className="font-bold">To</h4>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Name</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.fromTrdName ? data.fromTrdName : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Name</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.toTrdName ? data.toTrdName : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">GSTIN</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.fromGstin ? data.fromGstin : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">GSTIN</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.toGstin ? data.toGstin : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <h4 className="font-bold">Dispatch From</h4>
              </Col>
              <Col md={6}>
                <h4 className="font-bold">Ship To</h4>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Address</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">{data.fromAddr1}</h4>
                    <h4 className="font-bold">
                      {data.fromPlace}
                      {data.fromPlace ? "," : ""}
                      {data.fromPincode}
                      {data.fromPincode ? "," : ""}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Address</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">{data.toAddr1}</h4>
                    <h4 className="font-bold">
                      {data.toPlace}
                      {data.toPlace ? "," : ""}
                      {data.toPincode}
                      {data.toPincode ? "," : ""}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Location</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.fromPlace ? data.fromPlace : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Location</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.toPlace ? data.toPlace : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Pin Code</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.fromPincode ? data.fromPincode : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">Pin Code</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">
                      {data.toPincode ? data.toPincode : "-"}
                    </h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-2">
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">State Code</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">{data.fromStateCode}</h4>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={3}>
                    <h5 className="viewpage-title">State Code</h5>
                  </Col>
                  <Col md={9}>
                    <h4 className="font-bold">{data.toStateCode}</h4>
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Table
              responsive
              className=" fs-4 text-center"
              style={{ border: "1px solid black" }}
            >
              <thead>
                <tr>
                  <th className="th-color">#</th>

                  <th className="th-color">Transportation Mode</th>
                  <th className="th-color">Vehicle No</th>
                  <th className="th-color">From Place</th>
                  <th className="th-color">Document Number</th>
                  <th className="th-color">Updated Time</th>
                </tr>
              </thead>
              <tbody>
                {data.VehiclListDetails.length > 0 ? (
                  data.VehiclListDetails.map((item, ind) => {
                    return (
                      <tr key={ind}>
                        <td>{ind + 1}</td>
                        <td>{transportModeObj[item.transMode]}</td>
                        <td>{item.vehicleNo}</td>
                        <td>{item.fromPlace}</td>
                        <td>{item.transDocNo}</td>
                        <td>{item.enteredDate}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6">
                      <div className="datanotfound-center-text">
                        No Item Found 🤔
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <div className="d-none">
          <PrintEwayBill ref={(el) => (componentRef = el)} data={data} />
        </div>
      </Container>
    </>
  );
}

export default ViewEwayBill;
