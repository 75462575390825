import React from "react";
import { ROUTES } from "./routerConfig";
import { Route, Routes } from "react-router-dom";
import Signup from "../components/Signup/Signup";
import Login from "../components/Login/Login";
import Sidebar from "../components/Sidebar/Sidebar";
import Dashboard from "../components/Dashboard/Dashboard";
import ProtectRoute from "../components/ProtectRoute/ProtectRoute";
import User from "../components/Admin/User/User";
import Item from "../components/Inventory/Item/Item";
import Category from "../components/Inventory/Category/Category";
import SuggestedItem from "../components/Inventory/SuggestedItem/SuggestedItem";
import AddUser from "../components/Admin/User/AddUser";
import AddFirm from "../components/Admin/FirmManagement/AddFirm";
import EditFirm from "../components/Admin/FirmManagement/EditFirm";
import BranchTab from "../components/Admin/Branch/BranchTab";
import GodownOrShowroomTab from "../components/Admin/godownOrShowroom/GodownOrShowroomTab";
import EditUser from "../components/Admin/User/EditUser";
import AddForm from "../components/Admin/godownOrShowroom/godownOrShowrooms/AddForm";
import AddBranch from "../components/Admin/Branch/Branches/AddBranch";
import EditBranch from "../components/Admin/Branch/Branches/EditBranch";
import EditForm from "../components/Admin/godownOrShowroom/godownOrShowrooms/EditForm";
import FirmManagementTab from "../components/Admin/FirmManagement/FirmManagementTab";
import StockLocationTab from "../components/Admin/StockLocation/StockLocationTab";
import EditAccess from "../components/Admin/AccessControl/EditAccess";
import AccessControl from "../components/Admin/AccessControl/AccessControl";
import AddStockLocation from "../components/Admin/StockLocation/AddStockLocation";
import EmployeeManagement from "../components/EmployeeManagement/EmployeeManagement";
import Employee from "../components/EmployeeManagement/Employee/Employee";
import EditStockLocation from "../components/Admin/StockLocation/EditStockLocation";
import Vendor from "../components/Purchase/Vendor/Vendor";
import AddItem from "../components/Inventory/Item/AddItem";
import EditItem from "../components/Inventory/Item/EditItem";
import AddVendorTab from "../components/Purchase/Vendor/AddVendor/AddVendorTab";
import EditVendorTab from "../components/Purchase/Vendor/EditVendor/EditVendorTab";
import PurchaseOrder from "../components/Purchase/PurchaseOrder/PurchaseOrder";
import AddPurchaseOrder from "../components/Purchase/PurchaseOrder/AddPurchaseOrder";
import EditPurchaseOrder from "../components/Purchase/PurchaseOrder/EditPurchaseOrder";
import ChartOfAccount from "../components/Accounting/ChartOfAccount/ChartOfAccount";
import Customer from "../components/Sales/Customer/Customer";
import AddCustomer from "../components/Sales/Customer/AddCustomer";
import EditCustomer from "../components/Sales/Customer/EditCustomer";
import ManualJournal from "../components/Accounting/ManualJournal/ManualJournal";
import NewJournal from "../components/Accounting/ManualJournal/NewJournal";
import EditJournal from "../components/Accounting/ManualJournal/EditJournal";
import ProjectManagement from "../components/Projects/ProjectManagement/ProjectManagement";
import AddProjectManagement from "../components/Projects/ProjectManagement/AddProjectManagement";
import EditProjectManagement from "../components/Projects/ProjectManagement/EditProjectManagement";
import ManageStock from "../components/ManageStock/ManageStock";
import Receive from "../components/ManageStock/ReceiveGoods/Receive";
import Issue from "../components/ManageStock/IssueGoods/Issue";
import Ledger from "../components/Accounting/Ledger/Ledger";
import PriceUpdate from "../components/Admin/PriceUpdate/PriceUpdate";
import PurchaseBills from "../components/Purchase/PurchaseBills/PurchaseBills";
import AddPurchaseBills from "../components/Purchase/PurchaseBills/AddPurchaseBills";
import EditPurchaseBills from "../components/Purchase/PurchaseBills/EditPurchaseBills";
import TaxesTab from "../components/Taxes/TaxesTab";
import AddTcs from "../components/Taxes/TCS/AddTcs";
import EditTcs from "../components/Taxes/TCS/EditTcs";
import AddTds from "../components/Taxes/TDS/AddTds";
import EditTds from "../components/Taxes/TDS/EditTds";
import Bank from "../components/Banking/Bank/Bank";
import AddBank from "../components/Banking/Bank/AddBank";
import EditBank from "../components/Banking/Bank/EditBank";
import Transaction from "../components/Banking/Transaction/Transaction";
// import BankStatement from "../components/Banking/BankStatement/BankStatement";
// import Transaction from "../components/Banking/Transaction/Transaction";
// import CashInHand from "../components/Banking/CashInHand/CashInHand";
import Quotation from "../components/Sales/Quotation/Quotation";
import AddQuote from "../components/Sales/Quotation/AddQuote";
import EditQuote from "../components/Sales/Quotation/EditQuote";
import PurchaseTarget from "../components/Purchase/PurchaseTarget/PurchaseTarget";
import AddPurchaseTarget from "../components/Purchase/PurchaseTarget/AddPurchaseTarget";
import EditPurchaseTarget from "../components/Purchase/PurchaseTarget/EditPurchaseTarget";
import SalesTarget from "../components/Sales/SalesTarget/SalesTarget";
import AddSalesTarget from "../components/Sales/SalesTarget/AddSalesTarget";
import EditSalesTarget from "../components/Sales/SalesTarget/EditSalesTarget";
import SalesOrder from "../components/Sales/SalesOrder/SalesOrder";
import AddSalesOrder from "../components/Sales/SalesOrder/AddSalesOrder";
import EditSalesOrder from "../components/Sales/SalesOrder/EditSalesOrder";
import ViewSalesOrder from "../components/Sales/SalesOrder/ViewSalesOrder";
import TargetTab from "../components/Target/TargetTab";
import AddSuggestedItem from "../components/Inventory/SuggestedItem/AddSuggestedItem";
import EditSuggestedItem from "../components/Inventory/SuggestedItem/EditSuggestedItem";

import SignaturePad from "../components/SignaturePad/SignaturePad";

// Reports import start
// Account reports
import Reports from "../components/Reports/AccountReports/Reports";
import LedgerReport from "../components/Reports/AccountReports/LedgerReport/LedgerReport";
import ViewTable from "../components/Reports/AccountReports/LedgerReport/ViewTable";
import BalanceSheet from "../components/Reports/AccountReports/Balancesheet/BalanceSheet";
import ProfitAndLoss from "../components/Reports/AccountReports/ProfitAndLoss/ProfitAndLoss";
import PLAccountTransactions from "../components/Reports/AccountReports/ProfitAndLoss/PLAccountTransactions";
import TrialBalance from "../components/Reports/AccountReports/TrialBalance/TrialBalance";
import AccountTransactions from "../components/Reports/AccountReports/TrialBalance/AccountTransactions";
import DetailedAccountTransactions from "../components/Reports/AccountReports/TrialBalance/DetailedAccountTransactions";
import CashBook from "../components/Reports/AccountReports/CashBook/CashBook";
import AddEmployeeTab from "../components/EmployeeManagement/Employee/AddEmployee/AddEmployeeTab";
// Purchase reports
import PurchaseReports from "../components/Reports/PurchaseReport/PurchaseReports";
import SellReport from "../components/Reports/SellReports/SellReport";
import InventoryReports from "../components/Reports/InventoryReports/InventoryReports";
import PurchasePayment from "../components/Purchase/PurchasePayment/PurchasePayment";
import AddPurchasePaymentTab from "../components/Purchase/PurchasePayment/AddPurchasePayment/AddPurchasePaymentTab";
import DeliveryReceipt from "../components/Sales/DeliveryReceipt/DeliveryReceipt";
import SalesInvoice from "../components/Sales/SalesInvoice/SalesInvoice";
import AddSalesInvoice from "../components/Sales/SalesInvoice/AddSalesInvoice";
import EditSalesInvoice from "../components/Sales/SalesInvoice/EditSalesInvoice";
import ViewSalesInvoice from "../components/Sales/SalesInvoice/ViewSalesInvoice";
import InventoryMovement from "../components/Reports/InventoryReports/InventoryMovement/InventoryMovement";
import DeliveryReceiptForSignature from "../components/Sales/DeliveryReceipt/DeliveryReceiptForSignature";
import EditEmployeetab from "../components/EmployeeManagement/Employee/EditEmployee/EditEmployeetab";
import TermCondition from "../components/Admin/Tem&Condition/TermCondition";
import AddTerm from "../components/Admin/Tem&Condition/AddTerm";
import EditTerm from "../components/Admin/Tem&Condition/EditTerm";
import SalePayment from "../components/Sales/SalePayment/SalePayment";
import AddSalePayment from "../components/Sales/SalePayment/AddSalePayment";
import ViewSalePayment from "../components/Sales/SalePayment/ViewSalePayment";
import DayClosure from "../components/Admin/DayClosure/DayClosure";
import Crud from "../components/Admin/Crud/Crud";
import OtherPayment from "../components/Accounting/OtherPayment/OtherPayment";
import Brand from "../components/Inventory/Brand/Brand";
import AddDayClosure from "../components/Admin/DayClosure/AddDayClosure";
import ViewPurchaseOrder from "../components/Purchase/PurchaseOrder/ViewPurchaseOrder";
import LogMaintain from "../components/LogMaintain/LogMaintain";
import ViewQuote from "../components/Sales/Quotation/ViewQuote";
import ViewPurchaseBills from "../components/Purchase/PurchaseBills/ViewPurchaseBills";
import SellReport1 from "../components/Reports/SellReports1/SellReport1";
import StockReports from "../components/Reports/InventoryReports/StockReports/StockReports";
import MonthlySummaryView from "../components/Reports/InventoryReports/StockReports/MonthlySummaryView";
import StockVoucherView from "../components/Reports/InventoryReports/StockReports/StockVoucherView";
import Einvoice from "../components/Sales/SalesInvoice/Einvoice/Einvoice";
import AddCustomerform from "../components/Sales/SalesInvoice/AddCustomerform";
import Series from "../components/Inventory/Series/Series";
import AddCustomerInSO from "../components/Sales/SalesOrder/AddCustomerInSO";
import SellReportsCommission from "../components/Reports/SellReportsCommission/SellReportsCommission";
import ListTable from "../components/Sales/SalesInvoice/Ewaybill/ListTable";
import Ewaybill from "../components/Sales/SalesInvoice/Ewaybill/Ewaybill";
import ViewEwayBill from "../components/Sales/SalesInvoice/Ewaybill/ViewEwayBill";
import ItemAgeReport from "../components/Reports/ItemAgeReport/ItemAgeReport";
import CreditNote from "../components/Sales/CreditNote/CreditNote";
import AddCreditNote from "../components/Sales/CreditNote/AddCreditNote";
import EditCreditNote from "../components/Sales/CreditNote/EditCreditNote";
import ViewCreditNote from "../components/Sales/CreditNote/ViewCreditNote";
import AddEinvoice from "../components/Sales/SalesInvoice/Einvoice/AddEinvoice";
import AuditLog from "../components/LogMaintain/AuditLog";
import ViewEinvoice from "../components/Sales/SalesInvoice/Einvoice/ViewEinvoice";
import CreateEWB from "../components/Sales/SalesInvoice/Einvoice/CreatedEWB";
import PartbVehicle from "../components/Sales/SalesInvoice/Ewaybill/PartbVehicle";
import AddCreditNoteInv from "../components/Sales/SalesInvoice/Einvoice/AddCreditNote";


// Reports import end
const Router = () => {
  return (
    <>
      <Routes>
        <Route exact path={ROUTES.Signup} element={<Signup />} />
        <Route exact path={ROUTES.Login} element={<Login />} />
        <Route
          exact
          path={ROUTES.DeliveryReceiptAddSignature}
          element={<DeliveryReceiptForSignature />}
        />
        <Route
          path={ROUTES.Sidebar}
          element={
            <Sidebar>
              <ProtectRoute />
            </Sidebar>
          }
        >
          <Route exact path={ROUTES.Dashboard} element={<Dashboard />} />

          <Route
            exact
            path={ROUTES.CompanyManagement}
            element={<FirmManagementTab />}
          />
          <Route exact path={ROUTES.AddCompany} element={<AddFirm />} />
          <Route exact path={ROUTES.EditCompany} element={<EditFirm />} />

          <Route exact path={ROUTES.Branch} element={<BranchTab />} />
          <Route exact path={ROUTES.AddBranch} element={<AddBranch />} />
          <Route exact path={ROUTES.EditBranch} element={<EditBranch />} />

          <Route
            exact
            path={ROUTES.GodownShowroom}
            element={<GodownOrShowroomTab />}
          />
          <Route exact path={ROUTES.AddGodownShowroom} element={<AddForm />} />
          <Route
            exact
            path={ROUTES.EditGodownShowroom}
            element={<EditForm />}
          />

          <Route exact path={ROUTES.User} element={<User />} />
          <Route exact path={ROUTES.AddUser} element={<AddUser />} />
          <Route exact path={ROUTES.EditUser} element={<EditUser />} />

          <Route
            exact
            path={ROUTES.AccessControl}
            element={<AccessControl />}
          />
          <Route exact path={ROUTES.EditAccess} element={<EditAccess />} />

          <Route
            exact
            path={ROUTES.StockLocation}
            element={<StockLocationTab />}
          />
          <Route
            exact
            path={ROUTES.AddStockLocation}
            element={<AddStockLocation />}
          />
          <Route
            exact
            path={ROUTES.EditStockLocation}
            element={<EditStockLocation />}
          />
          <Route exact path={ROUTES.PriceUpdate} element={<PriceUpdate />} />

          <Route
            exact
            path={ROUTES.Employeemanagement}
            element={<EmployeeManagement />}
          />
          <Route exact path={ROUTES.Employee} element={<Employee />} />
          <Route exact path={ROUTES.AddEmployee} element={<AddEmployeeTab />} />
          <Route
            exact
            path={ROUTES.EditEmployee}
            element={<EditEmployeetab />}
          />

          <Route exact path={ROUTES.Item} element={<Item />} />
          <Route exact path={ROUTES.AddItem} element={<AddItem />} />
          <Route exact path={ROUTES.EditItem} element={<EditItem />} />

          <Route exact path={ROUTES.ManageStock} element={<ManageStock />} />
          <Route exact path={ROUTES.ReceiveGoods} element={<Receive />} />
          <Route exact path={ROUTES.IssueGoods} element={<Issue />} />

          <Route exact path={ROUTES.Category} element={<Category />} />
          <Route exact path={ROUTES.Brand} element={<Brand />} />
          <Route exact path={ROUTES.Series} element={<Series />} />

          <Route
            exact
            path={ROUTES.SuggestedItem}
            element={<SuggestedItem />}
          />
          <Route
            exact
            path={ROUTES.AddSuggestedItem}
            element={<AddSuggestedItem />}
          />
          <Route
            exact
            path={ROUTES.EditSuggestedItem}
            element={<EditSuggestedItem />}
          />

          <Route exact path={ROUTES.Vendor} element={<Vendor />} />
          <Route exact path={ROUTES.AddVendor} element={<AddVendorTab />} />
          <Route exact path={ROUTES.EditVendor} element={<EditVendorTab />} />

          <Route
            exact
            path={ROUTES.PurchaseOrder}
            element={<PurchaseOrder />}
          />
          <Route
            exact
            path={ROUTES.AddPurchaseOrder}
            element={<AddPurchaseOrder />}
          />
          <Route
            exact
            path={ROUTES.EditPurchaseOrder}
            element={<EditPurchaseOrder />}
          />
          <Route
            exact
            path={ROUTES.ViewPurchaseOrder}
            element={<ViewPurchaseOrder />}
          />
          <Route
            exact
            path={ROUTES.PurchaseBills}
            element={<PurchaseBills />}
          />
          <Route
            exact
            path={ROUTES.AddPurchaseBills}
            element={<AddPurchaseBills />}
          />
          <Route
            exact
            path={ROUTES.EditPurchaseBills}
            element={<EditPurchaseBills />}
          />
          <Route
            exact
            path={ROUTES.ViewPurchaseBills}
            element={<ViewPurchaseBills />}
          />
          <Route
            exact
            path={ROUTES.PurchaseTarget}
            element={<PurchaseTarget />}
          />
          <Route
            exact
            path={ROUTES.AddPurchaseTarget}
            element={<AddPurchaseTarget />}
          />
          <Route
            exact
            path={ROUTES.EditPurchaseTarget}
            element={<EditPurchaseTarget />}
          />
          <Route path={ROUTES.PurchasePayment} element={<PurchasePayment />} />
          <Route
            path={ROUTES.AddPurchasePayment}
            element={<AddPurchasePaymentTab />}
          />

          <Route path={ROUTES.ManualJournal} element={<ManualJournal />} />
          <Route path={ROUTES.AddManualJournal} element={<NewJournal />} />
          <Route path={ROUTES.EditManualJournal} element={<EditJournal />} />
          <Route path={ROUTES.ChartOfAccount} element={<ChartOfAccount />} />
          <Route path={ROUTES.Ledger} element={<Ledger />} />
          <Route path={ROUTES.OtherPayment} element={<OtherPayment />} />

          <Route path={ROUTES.Bank} element={<Bank />} />
          <Route path={ROUTES.AddBank} element={<AddBank />} />
          <Route path={ROUTES.EditBank} element={<EditBank />} />
          <Route path={ROUTES.Transaction} element={<Transaction />} />
          {/* <Route path={ROUTES.BankStatement} element={<BankStatement />} /> */}
          {/* <Route path={ROUTES.CashInHand} element={<CashInHand />} /> */}

          {/* <Route exact path={ROUTES.Accounts} element={<Accounts />} />
          <Route exact path={ROUTES.Sales} element={<Sales />} />
          <Route exact path={ROUTES.Procurement} element={<Procurement />} />
          <Route exact path={ROUTES.Payments} element={<Payments />} />
          <Route exact path={ROUTES.Tax} element={<Tax />} />
          <Route exact path={ROUTES.Item} element={<Item />} /> */}

          <Route exact path={ROUTES.Customers} element={<Customer />} />
          <Route exact path={ROUTES.AddCustomers} element={<AddCustomer />} />
          <Route exact path={ROUTES.EditCustomers} element={<EditCustomer />} />

          <Route exact path={ROUTES.ProjectManagement} element={<ProjectManagement />}/>
          <Route exact path={ROUTES.AddProjectManagement} element={<AddProjectManagement />}/>
          <Route exact path={ROUTES.EditProjectManagement} element={<EditProjectManagement />}/>

          <Route exact path={ROUTES.Quotation} element={<Quotation />} />
          <Route exact path={ROUTES.AddQuotation} element={<AddQuote />} />
          <Route exact path={ROUTES.EditQuotation} element={<EditQuote />} />
          <Route exact path={ROUTES.ViewQuotation} element={<ViewQuote />} />

          <Route exact path={ROUTES.Targets} element={<TargetTab />} />
          <Route exact path={ROUTES.SalesTarget} element={<SalesTarget />} />
          <Route exact path={ROUTES.AddSalesTarget} element={<AddSalesTarget />}/>
          <Route exact path={ROUTES.EditSalesTarget} element={<EditSalesTarget />}/>
          <Route exact path={ROUTES.DeliveryReceipt} element={<DeliveryReceipt />}/>

          <Route exact path={ROUTES.SalesOrder} element={<SalesOrder />} />
          <Route exact path={ROUTES.AddSalesOrder} element={<AddSalesOrder />}/>
          <Route exact path={ROUTES.AddCustomersInSalesOrder} element={<AddCustomerInSO />}/>
          <Route exact path={ROUTES.EditSalesOrder} element={<EditSalesOrder />}/>
          <Route exact path={ROUTES.ViewSalesOrder} element={<ViewSalesOrder />}/>

          <Route exact path={ROUTES.SalesInvoice} element={<SalesInvoice />} />
          <Route exact path={ROUTES.AddSalesInvoice} element={<AddSalesInvoice />}/>
          <Route exact path={ROUTES.AddCustomersInSalesInvoice} element={<AddCustomerform />}/>
          <Route exact path={ROUTES.EditSalesInvoice} element={<EditSalesInvoice />} />
          <Route exact path={ROUTES.ViewSalesInvoice} element={<ViewSalesInvoice />} />

          <Route exact path={ROUTES.SalePayment} element={<SalePayment />} />
          <Route exact path={ROUTES.AddSalePayment} element={<AddSalePayment />}/>
          <Route exact path={ROUTES.ViewSalePayment} element={<ViewSalePayment />}/>

          <Route exact path={ROUTES.CreditNote} element={<CreditNote />} />
          <Route exact path={ROUTES.AddCreditNote} element={<AddCreditNote />}/>
          <Route exact path={ROUTES.EditCreditNote} element={<EditCreditNote />}/>
          <Route exact path={ROUTES.ViewCreditNote} element={<ViewCreditNote />} />

          {/* <Route exact path={ROUTES.ProjectTeams} element={<ProjectTeams />} /> */}

          <Route exact path={ROUTES.Tax} element={<TaxesTab />} />
          <Route exact path={ROUTES.AddTcs} element={<AddTcs />} />
          <Route exact path={ROUTES.EditTcs} element={<EditTcs />} />
          <Route exact path={ROUTES.AddTds} element={<AddTds />} />
          <Route exact path={ROUTES.EditTds} element={<EditTds />} />

          {/* Signature pad route */}

          <Route exact path={ROUTES.signaturepad} element={<SignaturePad />} />

          {/* Report start */}
          {/* Account reports */}
          <Route path="reports" element={<Reports />} />
          <Route path="reports/ledgerReport" element={<LedgerReport />} />
          <Route
            path="reports/ledgerReport/detailedAccountTransactions"
            element={<ViewTable />}
          />
          <Route path="reports/balanceSheet" element={<BalanceSheet />} />
          <Route path="reports/profitAndLoss" element={<ProfitAndLoss />} />
          <Route
            path="reports/profitAndLoss/accountTransactions"
            element={<PLAccountTransactions />}
          />
          <Route path="reports/trialBalance" element={<TrialBalance />} />
          <Route
            path="reports/trialBalance/accountTransactions"
            element={<AccountTransactions />}
          />
          <Route
            path="reports/trialBalance/accountTransactions/detailedAccountTransactions"
            element={<DetailedAccountTransactions />}
          />
          <Route path="reports/cashBook" element={<CashBook />} />
          {/* Purchase report */}
          <Route path="purchase-report" element={<PurchaseReports />} />

          {/* Sell report */}
          <Route path="sell-report" element={<SellReport />} />
          <Route path="sellreport" element={<SellReport1 />} />
          {/* Inventory report */}
          <Route path="inventory-report" element={<InventoryReports />} />
          <Route path="inventory-movement" element={<InventoryMovement />} />
          <Route path="stock-report" element={<StockReports />} />
          <Route
            path="stock-report/stockReportsMonthlySummary/:id"
            element={<MonthlySummaryView />}
          />
          <Route
            path="stock-report/stockReportsMonthlySummary/stockVoucher"
            element={<StockVoucherView />}
          />

          <Route
          exact path={ROUTES.SellReportsCommission} element={<SellReportsCommission />}
          />
          <Route
          exact path={ROUTES.ItemAgeReport} element={<ItemAgeReport />}
          />
          {/* Report end */}

          {/* Term and condition start */}
          <Route path="admin/termcondition" element={<TermCondition />} />
          <Route path="admin/termcondition/addterm" element={<AddTerm />} />
          <Route
            path="admin/termcondition/editterm/:id"
            element={<EditTerm />}
          />
          {/* Term and condition end */}
          {/* Day closure start */}
          <Route path="admin/dayclosure" element={<DayClosure />} />
          <Route path="admin/adddayclosure" element={<AddDayClosure />} />
          {/* Day closure end */}

          {/* Crud start */}
          <Route path="admin/crud" element={<Crud />} />
          {/* Crud end */}

          {/* Log  start */}
          <Route path="/log" element={<LogMaintain />} />
          {/* Log end */}

          {/* e invoice start */}
          <Route path="einvoice" element={<Einvoice />} />
          <Route path="einvoice/add" element={<AddEinvoice/>} />
          <Route path="einvoice/view/:irn" element={<ViewEinvoice/>} />
          <Route path="einvoice/ewb/:irn" element={<CreateEWB/>} />
          <Route path="einvoice/creditnote" element={<AddCreditNoteInv/>} />
          {/* e invoice end */}

          {/* E way bill start */}

          <Route path="ewaybill" element={<ListTable/>} />
          <Route path="ewaybill/add" element={<Ewaybill/>} />
          <Route path="ewaybill/view/:ewaybillno" element={<ViewEwayBill/>} />
          <Route path="ewaybill/partb" element={<PartbVehicle/>} />

          {/* E way bill end */}
          <Route path={ROUTES.AuditLog} element={<AuditLog/>} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;
