import React, { useEffect, useState } from "react";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import {
  customerGetPost,
  employeeGetpagePost,
  generateQuoteIDPost,
  getProjectApi,
  itemGetItemPost,
  quoteAddPost,
} from "../../../constants/api";
import axios from "axios";
import { initialData } from "../../../constants/resData";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { BsPlusCircle } from "react-icons/bs";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch } from "react-redux";
import Select from "react-select";

const AddQuote = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  let userName = localData.name;
  let org = localData.org;

  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showEmptyError, setShowEmptyError] = useState(false);
  const [customerIDArray, setCustmerIDArray] = useState([]);
  const [projectArray, setProjectArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [items, setItems] = useState([]);
  const [showGST, setShowGST] = useState(false);
  const [category, setCategory] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [quoteIDData, setQuoteIDData] = useState([]);

  const [data, setData] = useState({
    customerid: "",
    customername: "",
    customerdetails: "",
    bacountry: "",
    bastate: "",
    bacity: "",
    baaddress: "",
    bapincode: "",
    bdgstno: "",
    phone: "",
    saleperson: "",
    quoteno: "",
    quotedate: "",
    project: "",
    projectid: "",
    createdby: userName,
    expiredby: "",
    itemarray: [],
    subtotal: "",
    discount: "",
    discountamount: "",
    tax: "",
    adjustment: "",
    finalamount: "",
    tandc: "",
    branchid: branchID,
    companyid: companyID,
    userid: userID,
    org: org,
    status: "",
    isshowgst: "",
  });

  useEffect(() => {
    // Customer ID Array
    axios
      .post("https://mis.saanviavs.com/api/v1/customer/getallcustomer", {
        userid: userID,
      })
      .then((res) => {
        const initialData =
          res.data && res.data && res.data.length > 0 ? res.data : [];
        setCustmerIDArray(
          initialData.map((customer) => ({
            ...customer,
            value: customer.customerid,
            label: `${customer.customerid}-${
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany
            }`,
            name:
              customer.customertype == "Retail"
                ? customer.fullname
                : customer.bdcompany,
          }))
        );
      })
      .catch((err) => console.log(err));

    // Customer ID Related Projects
    axios
      .post(getProjectApi, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "",
      })
      .then((res) => {
        setProjectArray(
          initialData(res).filter((ele) => ele.customerid == data.customerid)
        );
      })
      .catch((err) => console.log(err));

    // All Items
    axios
      .post(itemGetItemPost, {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "",
      })
      .then((res) => {
        setItems(initialData(res));
      })
      .catch((err) => console.log(err));
  }, [data.customerid]);

  useEffect(() => {
    //dynamic category generate
    axios
      .post("https://mis.saanviavs.com/api/v1/item/category", {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "₹ΘθϖΠ" || "",
      })
      .then((res) => {
        setCategory(initialData(res));
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //dynamic brand generate
    axios
      .post(employeeGetpagePost, {
        userid: userID,
        branchid: branchID,
        companyid: companyID,
        keyword: "₹ΘθϖΠ" || "",
      })
      .then((res) => {
        setEmployee(
          initialData(res).filter(
            (emp) => emp.issale == "Yes" && emp.status == "Active"
          )
        );
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));
  }, []);

  const handleLeftArrow = () => {
    navigate("/sales/quotation");
  };

  function handleAddNewRow() {
    setShowForm(true);
    setTableData((prevTableData) => [...prevTableData, {}]);
  }

  const handleChange = (e) => {
    if (e.value) {
      setData((prev) => ({
        ...prev,
        customername: e.name,
        customerid: e.value,
        customerdetails: e.label,
        bacountry: e.bacountry,
        bastate: e.bastate,
        bacity: e.bacity,
        baaddress: e.baaddress,
        bapincode: e.bapincode,
        bdgstno: e.bdgstno,
        phone: e.phone,
      }));
    } else {
      const { name, value } = e.target;
      if (name == "project") {
        const selectedProject = projectArray.find(
          (itm) => itm.projectname === value
        );
        if (selectedProject) {
          setData((prevData) => ({
            ...prevData,
            projectid: selectedProject.projectid,
            [name]: value,
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            projectid: "",
            [name]: value,
          }));
        }
      }

      // if (name == "discount") {
      //   setData((prevData) => ({
      //     ...prevData,
      //     discountamount: (data.subtotal * Number(value)) / 100,
      //     [name]: value,
      //   }));
      // }
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // if (name == "customerid") {
    //   const selectedCustomer = customerIDArray.find(
    //     (itm) => itm.customerid === value
    //   );
    //   if (selectedCustomer && selectedCustomer.customertype == "Retail") {
    //     setData((prevData) => ({
    //       ...prevData,
    //       customername: selectedCustomer.fullname,
    //       bacountry: selectedCustomer.bacountry,
    //       bastate: selectedCustomer.bastate,
    //       bacity: selectedCustomer.bacity,
    //       baaddress: selectedCustomer.baaddress,
    //       bapincode: selectedCustomer.bapincode,
    //       bdgstno: selectedCustomer.bdgstno,
    //       phone: selectedCustomer.phone,
    //       [name]: value,
    //     }));
    //   } else if (
    //     selectedCustomer &&
    //     selectedCustomer.customertype == "Institutional"
    //   ) {
    //     setData((prevData) => ({
    //       ...prevData,
    //       customername: selectedCustomer.bdcompany,
    //       bacountry: selectedCustomer.bacountry,
    //       bastate: selectedCustomer.bastate,
    //       bacity: selectedCustomer.bacity,
    //       baaddress: selectedCustomer.baaddress,
    //       bapincode: selectedCustomer.bapincode,
    //       bdgstno: selectedCustomer.bdgstno,
    //       phone: selectedCustomer.phone,
    //       [name]: value,
    //     }));
    //   } else {
    //     setData((prevData) => ({
    //       ...prevData,
    //       customername: "",
    //       bacountry: "",
    //       bastate: "",
    //       bacity: "",
    //       baaddress: "",
    //       bapincode: "",
    //       bdgstno: "",
    //       phone: "",
    //       [name]: value,
    //     }));
    //   }
    // }

    // if (name == "project") {
    //   const selectedProject = projectArray.find(
    //     (itm) => itm.projectname === value
    //   );
    //   if (selectedProject) {
    //     setData((prevData) => ({
    //       ...prevData,
    //       projectid: selectedProject.projectid,
    //       [name]: value,
    //     }));
    //   } else {
    //     setData((prevData) => ({
    //       ...prevData,
    //       projectid: "",
    //       [name]: value,
    //     }));
    //   }
    // }

    // // if (name == "discount") {
    // //   setData((prevData) => ({
    // //     ...prevData,
    // //     discountamount: (data.subtotal * Number(value)) / 100,
    // //     [name]: value,
    // //   }));
    // // }
    // setData((prevData) => ({
    //   ...prevData,
    //   [name]: value,
    // }));

    setShowEmptyError(false);
  };

  const handleTableRowChange = (e, rowIndex) => {
    const { name, value } = e.target;

    let selectedItem;
    if (name == "itemname") {
      selectedItem = items.find((item) => item.itemname == value);
      if (selectedItem) {
        setTableData((prevTableData) => {
          const updatedTableData = [...prevTableData];
          updatedTableData[rowIndex] = {
            ...updatedTableData[rowIndex],
            [name]: value,
            ...selectedItem,
            itemid: selectedItem.itemid,
            image: selectedItem.image[0],
            unit: selectedItem.unit,
            gst: selectedItem.gst,
            rateinclgst: (
              Number(selectedItem.rateperunit) +
              Number(selectedItem.rateperunit * (selectedItem.gst / 100))
            ).toFixed(2),
            rate: parseFloat(selectedItem.rateperunit).toFixed(2),
          };
          return updatedTableData;
        });
      }
    }

    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowIndex] = {
        ...updatedTableData[rowIndex],
        [name]: value,
      };
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
        subtotal: updatedTableData.reduce((total, row) => {
          return (
            total +
            (row.qty * row.rate - (row.qty * row.rate * row.discount) / 100 ||
              0)
          );
        }, 0),
        tax: updatedTableData.reduce((total, row) => {
          return (
            total +
            (row.qty * row.rate - (row.qty * row.rate * row.discount) / 100 ||
              0) *
              (row.gst / 100)
          );
        }, 0),
        finalamount:
          Number(prevData.subtotal) +
          Number(prevData.tax) +
          Number(prevData.adjustment).toFixed(2),
      }));

      return updatedTableData;
    });
  };

  const handleShowGSTChange = (e) => {
    setShowGST(e.target.checked);
    const { name, value, checked } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
      isshowgst: checked ? "Yes" : "",
    }));
  };

  const handleDraft = async () => {
    if (
      data.customerid == "" ||
      data.quotedate == "" ||
      //data.project == "" ||
      data.saleperson == "" ||
      data.expiredby == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    const res = await axios.post(quoteAddPost, {
      ...data,
      status: "Drafted",
    });
    if (res.statusText == "OK") {
      notifySuccess("Quotation saved as Draft.");
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  const handleSubmit = async () => {
    if (
      data.customerid == "" ||
      data.quotedate == "" ||
      //data.project == "" ||
      data.saleperson == "" ||
      data.expiredby == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    const res = await axios.post(quoteAddPost, {
      ...data,
      status: "Created",
    });
    if (res.statusText == "OK") {
      notifySuccess(`Quotation ${data.quoteno} creation Successful.`);
      handleLeftArrow();
    } else {
      notifyError(res.data.message);
    }
  };

  //dynamic quotation id generate
  // axios
  // .post(generateQuoteIDPost, {
  //   userid: userID,
  //   companyid: companyID,
  //   branchid: branchID,
  // })
  // .then((res) => {
  //   let count = +res.data.count + 1;
  //   let paddedCount = count.toString().padStart(3, "0");
  //   let result = `QT\\SAANVI\\${paddedCount}`;
  //   setData((prevData) => ({
  //     ...prevData,
  //     quoteno: result,
  //   }));
  // })
  // .catch((err) => console.log(err));

  useEffect(() => {
    axios
      .post("https://mis.saanviavs.com/api/v1/quote/getpage", {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || "₹ΘθϖΠ",
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setQuoteIDData(initialData);
      })
      .catch((err) => console.log(err));
  }, []);

  const generateCategoryID = () => {
    if (quoteIDData.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = quoteIDData[0].quoteno.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      quoteno: `QT/SAANVI/${paddedCount}`,
    }));
  }, [quoteIDData]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Create <span className="form-title main"> Quotation</span>
          </h1>
          <Container fluid>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Customer ID <span className="star">*</span>
                  </Form.Label>
                  {/* <Form.Select
                    name="customerid"
                    value={data.customerid}
                    onChange={handleChange}
                    isInvalid={data.customerid === "" && showEmptyError}
                  >
                    <option value="">Select Customer ID</option>

                    {customerIDArray.length > 0 &&
                      customerIDArray.map((ele, index) => (
                        <option key={index} value={ele.customerid}>
                          {ele.customerid} &nbsp; -
                          {ele.customertype == "Retail"
                            ? ele.fullname
                            : ele.bdcompany}
                        </option>
                      ))}
                  </Form.Select> */}
                  <Select
                    // name="customerid"
                    value={{
                      value: data.customerid,
                      label: data.customerdetails,
                    }}
                    onChange={handleChange}
                    options={customerIDArray}
                    isSearchable
                  />
                  <Form.Control.Feedback type="invalid">
                    Select Customer ID
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Customer Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="customername"
                    value={data.customername}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Quotation No</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="quoteno"
                    value={data.quoteno}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Quote Date <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="quotedate"
                    value={data.quotedate}
                    onChange={handleChange}
                    isInvalid={data.quotedate === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Select Quotation Date
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Project</Form.Label>
                  <Form.Select
                    name="project"
                    value={data.project}
                    onChange={handleChange}
                  >
                    <option value="">Select Project</option>
                    {projectArray.length > 0 &&
                      projectArray.map((ele, index) => (
                        <option key={index} value={ele.projectname}>
                          {ele.projectname}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Project
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Sales Person <span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    name="saleperson"
                    value={data.saleperson}
                    onChange={handleChange}
                    isInvalid={data.saleperson === "" && showEmptyError}
                  >
                    <option value="">Select</option>
                    {employee.length > 0 &&
                      employee.map((ele, index) => (
                        <option key={index} value={ele.fullname}>
                          {ele.fullname}
                        </option>
                      ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Sales Person
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>Created By</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="createdby"
                    value={data.createdby}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicID">
                  <Form.Label>
                    Expiry Date <span className="star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="expiredby"
                    value={data.expiredby}
                    onChange={handleChange}
                    isInvalid={data.expiredby === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Select Expiry Date
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
        <hr />
        <Form.Check
          inline
          label="Show GST"
          name="gstcheck"
          type="checkbox"
          onChange={handleShowGSTChange}
        />
        <Table responsive className="table-main-container">
          <TableHeading
            data={
              data.isshowgst == "Yes"
                ? [
                    "#",
                    "Category",
                    "Brand",
                    "Item Name",
                    "Item ID",
                    "Unit",
                    "Qty",

                    "Rate",
                    "GST",
                    "Discount in %",
                    "Amount",
                  ]
                : [
                    "#",
                    "Category",
                    "Brand",
                    "Item Name",
                    "Item ID",
                    "Unit",
                    "Qty",
                    "Rate (incl. of gst)",
                    "Rate",
                    "Discount in %",
                    "Amount",
                  ]
            }
          />
          {showForm && tableData.length > 0 ? (
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>
                    <Form.Control
                      className="text-center"
                      disabled
                      readOnly
                      value={index + 1}
                    />
                  </td>
                  {/*  <td>
                    <Image
                      src={row.image}
                      fluid
                      thumbnail
                      style={{ width: "100px", height: "50px" }}
                    />
                  </td> */}
                  <td>
                    <Form.Select
                      name="category"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.category}
                      isInvalid={row.category === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      {category.length > 0 &&
                        category.map((ele, index) => (
                          <option key={index} value={ele.category}>
                            {ele.category}
                          </option>
                        ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      name="brand"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.brand}
                      isInvalid={row.brand === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      {items.length > 0 &&
                        items
                          .filter((item) => item.category === row.category)
                          .reduce((uniqueBrands, item) => {
                            if (!uniqueBrands.includes(item.brand)) {
                              uniqueBrands.push(item.brand);
                            }
                            return uniqueBrands;
                          }, [])
                          .map((brand, index) => (
                            <option key={index} value={brand}>
                              {brand}
                            </option>
                          ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Select
                      name="itemname"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.itemname}
                      isInvalid={row.itemname === "" && showEmptyError}
                    >
                      <option value="">--Select--</option>
                      {items.length > 0 &&
                        items
                          .filter((item) => item.brand == row.brand)
                          .map((ele, index) => (
                            <option key={index} value={ele.itemname}>
                              {ele.itemname}
                            </option>
                          ))}
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Control
                      disabled
                      className="text-center"
                      name="itemid"
                      placeholder="Item ID"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.itemid}
                    />
                  </td>
                  <td>
                    {" "}
                    <Form.Control
                      disabled
                      className="text-center"
                      name="unit"
                      placeholder="unit"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.unit}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      className="text-center"
                      name="qty"
                      placeholder="qty"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.qty}
                    />
                  </td>
                  {!data.isshowgst && (
                    <td>
                      <Form.Control
                        disabled
                        type="number"
                        className="text-center"
                        name="rateinclgst"
                        placeholder="rateinclgst"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.rateinclgst}
                      />
                    </td>
                  )}
                  <td>
                    <Form.Control
                      disabled
                      type="number"
                      className="text-center"
                      name="rate"
                      placeholder="rate"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.rate}
                    />
                  </td>
                  {data.isshowgst == "Yes" && (
                    <td>
                      <Form.Control
                        disabled
                        type="text"
                        className="text-center"
                        name="gst"
                        placeholder="gst"
                        onChange={(e) => handleTableRowChange(e, index)}
                        value={row.gst}
                      />
                    </td>
                  )}
                  <td>
                    <Form.Control
                      type="number"
                      className="text-center"
                      name="discount"
                      placeholder="discount"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={row.discount}
                    />
                  </td>
                  <td>
                    <Form.Control
                      disabled
                      type="number"
                      className="text-center"
                      name="amount"
                      placeholder="amount"
                      onChange={(e) => handleTableRowChange(e, index)}
                      value={(row.amount =
                        row.qty * row.rate -
                        (row.qty * row.rate * row.discount) / 100).toFixed(2)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <NoRecordFoud count="9" name="Items" />
          )}
        </Table>
        <div className="d-flex justify-content-between">
          <span className="form-title table-row" onClick={handleAddNewRow}>
            <BsPlusCircle /> Add New Line
          </span>
          <span></span>
        </div>
        <Row>
          <Col></Col>
          <Col style={{ textAlign: "right" }}>
            <Row>
              <Col>
                <Form.Label className="mt-2">Sub Total:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  className="mt-2"
                  disabled
                  type="number"
                  value={data.subtotal}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Form.Label className="mt-2">Discount:</Form.Label>
              </Col>
              <Col>
                {/*<Form.Control
                  className="mt-2"
                  type="text"
                  name="discount"
                  value={data.discount}
                  onChange={handleChange}
          />
              </Col>
              <Col>
                <Form.Control
                  className="mt-2"
                  disabled
                  type="text"
                  value={data.discountamount}
                />
              </Col>
            </Row> */}
            {data.isshowgst == "Yes" && (
              <Row>
                <Col>
                  <Form.Label className="mt-2">Tax:</Form.Label>
                </Col>
                <Col></Col>
                <Col>
                  <Form.Control
                    className="mt-2"
                    disabled
                    type="number"
                    value={
                      // (data.tax = tableData
                      //   ?.reduce((acc, curr) => {
                      //     return acc + (curr.amount * curr.gst) / 100;
                      //   }, 0)
                      //   .toFixed(2))
                      data.tax
                    }
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Form.Label className="mt-2">Adjustment:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  className="mt-2"
                  type="number"
                  name="adjustment"
                  value={data.adjustment}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label className="mt-2">Final Amount:</Form.Label>
              </Col>
              <Col></Col>
              <Col>
                <Form.Control
                  className="mt-2"
                  disabled
                  type="number"
                  value={(data.finalamount =
                    Number(data.subtotal) +
                    Number(data.tax) +
                    Number(data.adjustment)).toFixed(2)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="formBasicDescription">
              <Form.Label>Terms and Conditions</Form.Label>
              <textarea
                className="form-control"
                rows="4"
                name="tandc"
                value={data.tandc}
                onChange={handleChange}
                required
              ></textarea>
            </Form.Group>
          </Col>
        </Row>
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-5">
          <Button
            disabled={data.itemarray.length <= 0}
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleDraft}
          >
            Draft
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleLeftArrow}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default AddQuote;
