import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { Country, State, City } from "country-state-city";

import { useDispatch, useSelector } from 'react-redux';
import { extractAndFormatURLPart } from '../../../../constants/breadCrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { notifyError } from '../../../../constants/toastAlert';
import { validateEmail, validateGSTNumber, validatePhoneNumber } from '../../../../constants/validations';
import { companyData } from '../../../../redux/actions/company.actions';
import { addBranch, branchAutoCount, branchPagiData, updateBranch } from '../../../../redux/actions/branch.action';
import axios from 'axios';
const states = State.getStatesOfCountry("IN");

function EditBranch() {
  const URL = extractAndFormatURLPart("admin");
  const { id } = useParams();

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const allCompanyName = useSelector(state => state.companyData.companyData.data) || [];
  const companyName = allCompanyName && allCompanyName.filter((item) => item.status == "active");
  
  //state
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    branchid: "",
    branchname: "",
    company: "",
    gstno: "",
    address: "",
    status: "",
    state: "",
    city: "",
    pin: "",
    userid: '',
    companyid: '',
  });
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  //default data backend needed
  const companybackendData = {
    keyword: "₹ΘθϖΠ",
    companyid,
    userid,
    companyname
  }

  const fetchData = async () => {
    try {

      const editItem = await axios.post("https://mis.saanviavs.com/api/v1/master/branchbyrealid", { id: id });

      setData((prev) => ({
        ...prev,
        ...editItem.data.length > 0 ? editItem.data[0] : '',
      }));
    } catch (error) {
      console.log(`Edit Branch fetch error ${error}`);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])
  useEffect(() => {
    setLoading(!loading);
    dispatch(companyData(companybackendData, setLoading));
    if (data.state) {
      const selectedState = states.find((state) => state.name === data.state);
      const isoCode = selectedState ? selectedState.isoCode : '';
      const citiesOfSelectedState = City.getCitiesOfState("IN", isoCode);
      const cityNames = citiesOfSelectedState.map((city) => city.name);

      setCities(cityNames);
    } else {
      setCities([]);
    }
  }, [dispatch, loadData, data.state]);



  //Navigate part
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name == "state") {
      if (value) {
        setData((prev) => ({
          ...prev,
          [name]: value,
          city: "",
        }));
      } else {
        // setSelectedState("");
        setData((prev) => ({
          ...prev,
          state: "",
          city: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {

    let validateErrCount = 0;
    if (
      data.branchid == "" ||
      data.branchname == "" ||
      data.company == "" ||
      data.gstno == "" ||
      data.address == "" ||
      data.status == "" ||
      data.state == "" ||
      data.city == "" ||
      data.pin == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    //---for validation start---
    const validateAndNotify = (field, validator) => {
      if (data[field]) {
        const validationMsg = validator(data[field]);
        if (validationMsg !== "Done") {
          validateErrCount++;
          return notifyError(validationMsg);
        }
      }
    };

    validateAndNotify("gstno", validateGSTNumber);

    if (validateErrCount > 0) {
      return;
    }

    //---for validation end---
    dispatch(updateBranch(data, navigate));
  };


  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="form-container">
        <Col sm={12}>
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/admin/branch")}
            />
            Edit <span className="form-title"> Branch</span>
          </h1>
        </Col>
        <hr />
        <Col sm={12}>
          <Form className="form-lable-name">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchid">
                  <Form.Label>
                    Branch ID<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ID"
                    name="branchid"
                    value={data.branchid}
                    onChange={handleChange}
                    isInvalid={data.branchid === "" && showEmptyError}
                    disabled
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Branch ID
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchname">
                  <Form.Label>
                    Branch Name<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Branch Name"
                    name="branchname"
                    value={data.branchname}
                    onChange={handleChange}
                    isInvalid={data.branchname == "" && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Branch Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>
                    Company Name<span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="company"
                    name="company"
                    value={data.company}
                    onChange={handleChange}
                    isInvalid={data.company === "" && showEmptyError}
                    required
                  >
                    <option value="">Select</option>
                    {companyName && companyName.length > 0 ? (
                      companyName.map((item, index) => {
                        const { companyname } = item;
                        return (
                          <option key={index} value={companyname}>
                            {companyname}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Company available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Company Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="gstno">
                  <Form.Label>
                    GSTIN<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your GSTIN"
                    name="gstno"
                    value={data.gstno}
                    onChange={handleChange}
                    isInvalid={data.gstno == "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter GST No.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>
                    Address<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                    isInvalid={data.address == "" && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Your Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="state"
                    >
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="state"
                        value={data.state}
                        onChange={handleChange}
                        isInvalid={data.state == "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option
                              value={state.name}
                              key={state.isoCode}
                            >
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="city"
                    >
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                        isInvalid={data.city == "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {cities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="pin"
                    >
                      <Form.Label>
                        Pincode <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="pin"
                        value={data.pin}
                        onChange={handleChange}
                        isInvalid={data.pin == "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
        <hr />
        <Col sm={12} className="d-flex justify-content-start gap-2">

          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
              : (
                "Update"
              )}
          </Button>
          <Button className="commonBtn smallBtn cancelBtn" onClick={() => navigate("/admin/branch")}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default EditBranch