import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { HiViewColumns } from "react-icons/hi2";
// import { coacoabysitepost } from "../../../../constants/api";
import axios from "axios";
import { AiFillEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import Select from "react-select";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const ListTable = () => {
  const pdfRef = useRef(null);

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userid = localData.userid;
  let branchid = localData.branchid;
  let companyid = localData.companyid;
  let financialyear = localStorage.financialyear;
  // const financialyear = JSON.parse(financialyearString);

  const loadData = useSelector((state) => state.loadReducer);
  const navigate = useNavigate();

  // for by default date as a month this is connected to bellow date wise filtering functions
  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);

  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [account, setAccount] = useState([]);
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [actType, setActType] = useState([]);
  const [parentTypeDropDown, setParentTytpeDropDown] = useState("");
  const [accoutTypeDropDown, setAccoutTypeDropDown] = useState("");
  const [loading, setLoading] = useState(false);
  const [inventoryAllData, setInventoryAllData] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [accTypeDropDown, setAccTypeDropDown] = useState([]);
  const [addInventoryReferesh, setAddInventoryReferesh] = useState(false)
  //For multi select start
  const locationoptions = inventoryAllData.length > 0 && inventoryAllData.map((data) => ({
    value: data.location,
    label: data.location,
  }));

  const itemoptions = inventoryAllData.length > 0 && inventoryAllData.map((data) => ({
    value: data.itemname,
    label: data.itemname,
  }));

  const categoryoptions = inventoryAllData.length > 0 && inventoryAllData.map((data) => ({
    value: data.category,
    label: data.category,
  }));

  const brandoptions = [
    { value: "tata", label: "TATA" },
    { value: "milton", label: "Milton" },
    { value: "hp", label: "HP" },
    { value: "asus", label: "ASUS" },
  ];

  const [multiLocationName, setMultiLocationName] = useState([]);
  const [multiItemName, setMultiItemName] = useState([]);
  const [multiCategoryName, setMultiCategoryName] = useState([]);
  const [multiBrandName, setMultiBrandName] = useState([]);

  const handleLocationChange = (multiLocationName) => {
    setMultiLocationName(multiLocationName || []);
  };
  const handleItemChange = (multiItemName) => {
    setMultiItemName(multiItemName);
  };
  const handleCategoryChange = (multiCategoryName) => {
    setMultiCategoryName(multiCategoryName);
  };
  const handleBrandChange = (multiBrandName) => {
    setMultiBrandName(multiBrandName);
  };
  // For multi select end

  const columnData = [
    { name: "date", label: "Date" },
    // { name: "Due Date", label: "Due Date" },
   
    { name: "itemname", label: "Item Name", default: true },
    { name: "category", label: "Category" },
    { name: "qty", label: "Quantity" },
    { name: "Action", label: "Action", default: true },
    // { name: "location", label: "Location" },
    // { name: "Brand", label: "Brand" },
    // { name: "Amount", label: "Amount" },
    // { name: "Action", label: "Action", default: true },
  ];

  const handleSelectedCategory = (e) => {
    // setSearchValue(e.target.value);
  };

  // for parentr type filter fir clear value
  const handleParentTypeChange = (e) => {
    // setAccoutTypeDropDown("");
    // setSearchValue(e.target.value);
    setParentTytpeDropDown(e.target.value);
  };
  // accoutn type dropdown for clear value
  const handleAccoutTypeChange = (e) => {
    // setParentTytpeDropDown("");
    // setSearchValue(e.target.value);
    setAccoutTypeDropDown(e.target.value);
  };

  const [selectedColumns, setSelectedColumns] = useState(
    columnData.map((name) => ({ name, selected: true }))
  );
  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.name === columnName
          ? { ...column, selected: !column.selected }
          : column
      )
    );
  };

  useEffect(()=>{
    axios.post(`https://mis.saanviavs.com/api/v1/inventory/add`, {companyid})
    .then((res)=>{
      setAddInventoryReferesh(!addInventoryReferesh)
    })
    .catch((err)=>console.log(err))
  },[])

  useEffect(() => {
    // handleThisMonth()
    setLoading(true);

    axios

      .post(`https://mis.saanviavs.com/api/v1/inventory/getfilter`, {

        multiLocationName,
        multiItemName,
        multiCategoryName,
        fromDate,
        toDate,
        companyid
      })
      .then((res) => {

        setData(res.data.message ? [] : res.data);
        setLoading(false);
        filterData1();
      })
      .catch((err) => console.log(err));

    //  get all data

    axios

      .post(`https://mis.saanviavs.com/api/v1/inventory/get`, {companyid})

      .then((res) => {
        setInventoryAllData(res.data);
      })
      .catch((err) => console.log(err));
  }, [
    fromDate,
    toDate,
    loadData,
    multiLocationName,
    multiItemName,
    multiCategoryName,
    addInventoryReferesh
  ]);


  const handleApply = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleLeftArrow = () => {
    navigate("/reports");
  };
  const ledgerName = data.length > 0 ? data[0].ledger : "";

  // check box for visible start

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });

  // Remove the useEffect block related to localStorage

  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  const handleShow = () => {
    setShow(true);
  };
  useEffect(() => {}, [columnVisibility]);

  const handleSubmit = () => {
    handleClose();
  };

  // debu dropdown date range filter start

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;
  

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end

  // financila year should be in that same year to choose from date range start
  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 32);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);


  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };
  // financila year should be in that same year to choose from date range end

  // inithially show monthly data
  useEffect(() => {
    filterData1();
    handleThisMonth();
  }, []);

  const filterData1 = () => {
    const filtered = data.filter((item) => {
      // Check if any column contains the search value
      const containsSearchValue = Object.values(item).some((value) =>
        value
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchValue.toLowerCase().trim())
      );

      // Check if the selected category matches
      const matchesSelectedCategory =
        !selectedCategory || item.category === selectedCategory;

      // Check if the parent type matches
      const matchesParentType =
        !parentTypeDropDown || item.category === parentTypeDropDown;

      // Check if the account type matches
      const matchesAccountType =
        !accoutTypeDropDown || item.subcategory === accoutTypeDropDown;

      return (
        containsSearchValue &&
        matchesSelectedCategory &&
        matchesParentType &&
        matchesAccountType
      );
    });

    setFilteredData(filtered);
  };

  const parseIndianStyleDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    filterData1();
    //   const fltr = filterData();
    // setFilteredData(fltr);
  }, [
    fromDate,
    toDate,
    data,
    searchValue,
    parentTypeDropDown,
    accoutTypeDropDown,
  ]);

  // for date range end

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  // handle view another component

  const handleViewAction = (gledger) => {

    navigate(`/reports/trialBalance/accountTransactions`, {
      state: { gledger },
    });
  };


  // downnload as a pdf

  // const handleDownload = () => {
  //   if (pdfRef.current) {
  //     const element = pdfRef.current;
  //     const options = {
  //       margin: 10,
  //       filename: "purchasereport.pdf",
  //       image: { type: "jpeg", quality: 2.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };

  //     html2pdf(element, options);
  //   }
  // };

  const handleDownload = () => {
    const element = pdfRef.current;
    const options = {
        margin: 10,
        filename: "inventory_report.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const content = `
    <div style="font-family: Arial, sans-serif;">
    <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
      List of All <span style="color: #ffa000;">Inventory Movement</span>
    </p>
    <table style="width: 100%; border-collapse: collapse;">
      <thead>
        <tr style="background-color: #f0f0f0;">
        <th style="border: 1px solid black; padding: 6px;">Date</th>
        <th style="border: 1px solid black; padding: 6px;">Item Name</th>
        <th style="border: 1px solid black; padding: 6px;">Category</th>
        <th style="border: 1px solid black; padding: 6px;">Quantity</th>
        <th style="border: 1px solid black; padding: 6px;">Action</th>
       
        </tr>
      </thead>
      <tbody>
      ${data
        .map(
          (item, index) => `
        <tr key=${index}>
          <td style="border: 1px solid #ddd; padding: 6px;">${
      item.date
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
           item.itemname
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.category
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.qty
          }</td>
          <td style="border: 1px solid #ddd; padding: 6px;">${
            item.action
          }</td>
        
        </tr>
      `
        )
        .join("")}
    </tbody>
    </table>
  </div>
  `;
    //html2pdf(element, options);
    html2pdf().from(content).set(options).save();
};

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={handleLeftArrow}
                />
              </p>
            </Col>
            <Col lg={2}>
              <div className="inputFieldIcon">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="basic-addon2"
                  className="mt-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="iconPosition">
                  <BiSearch />
                </span>
              </div>
            </Col>
            <Col lg={2} className="d-flex gap-4">
              <span>
                {" "}
                {/* <ReactHTMLTableToExcel
                  id="exportButton"
                  className="commonBtn smallBtn3 cancelBtn mt-2"
                  table="table-to-export"
                  filename={`purchase-report-${formatDateToDDMMYYYY(
                    fromDate
                  )} - ${formatDateToDDMMYYYY(toDate)}`}
                  sheet="trial_report"
                  buttonText={
                    <>
                      <BiSolidFileExport /> Export
                    </>
                  }
                /> */}


<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

              </span>
              <span>
                <Button
                  className="commonBtn smallBtn3 cancelBtn mt-2"
                  onClick={handleDownload}
                >
                  Save pdf
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={3}>
          <Form.Label className="section-title form-section ptag">
            Inventory Movement
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col style={{ flex:" 0 0 auto", width: "27.33333333%"}}>
          <Form.Label className="form-section ptag text-align-center">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
              // disabled={!isCurrentYear}
            >
              <option disabled={!isCurrentYear} value="Today">
                Today
              </option>
              <option disabled={!isCurrentYear} value="Yesterday">
                Yesterday
              </option>
              <option disabled={!isCurrentYear} value="This Week">
                This Week
              </option>
              <option disabled={!isCurrentYear} value="This Month">
                This Month
              </option>
              <option disabled={!isCurrentYear} value="This Quarter">
                This Quarter
              </option>
              <option disabled={!isCurrentYear} value="This Year">
                This Year
              </option>
              <option value="Date Range">Date Range</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">From</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="from"
              // value={data.requestdate}
              value={fromDate}
              onChange={handleFromDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">To</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="to"
              // value={data.requestdate}
              value={toDate}
              onChange={handleToDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">Select Column</Form.Label>
            <span>
              <HiViewColumns
                onClick={handleShow}
                className="column-select-icon"
              />
            </span>
          </Form.Group>
        </Col>
      </Row>

      <Row className="justify-content-center table-wrapper-container" lg={12}>
        {/* <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4  text-color">Location Name</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={locationoptions}
              onChange={handleLocationChange}
              value={multiLocationName}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),
                
                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col> */}
        <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4 text-color">Item Name</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={itemoptions}
              onChange={handleItemChange}
              value={multiItemName}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),
                
                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4 text-color">Category</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={categoryoptions}
              onChange={handleCategoryChange}
              value={multiCategoryName}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),
                
                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        {/* <Col lg={3}>
          <Form.Group controlId="formMonth">
            <Form.Label className="mt-4 text-color">Brand</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={brandoptions}
              onChange={handleBrandChange}
              value={multiBrandName}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),
              }}
            />
          </Form.Group>
        </Col> */}
        {/* <Col>
        <Button onClick={handleApply}>Apply</Button>
        </Col> */}
      </Row>

      <Row>
        <Col md={12} className="table-main-container" ref={pdfRef}>
          <Table id="table-to-export" responsive>
            <thead className="tableContainer">
              <tr>
                {columnData.map(
                  (column) =>
                    columnVisibility[column.name] && (
                      <th key={column.index}>{column.label}</th>
                    )
                )}
              </tr>
            </thead>

            <tbody>
              {loadData || loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnData.map(
                      (column) =>
                        columnVisibility[column.name] && (
                          <React.Fragment key={column.index}>
                            {column.name === "Action1" ? (
                              <td key={column.index}>
                                {/* Eye icon for viewing */}
                                <span
                                  onClick={() => handleViewAction(item.gledger)}
                                >
                                  <AiFillEye className="editIcon" />
                                </span>
                              </td>
                            ) : (
                              <td key={column.index}>
                                {item[column.name.toLowerCase()]}
                              </td>
                            )}
                          </React.Fragment>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9}>
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*  //---------------Modal-----------------------------// */}
      <Modal
        show={show}
        size="sm"
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <h4>Select Column</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {columnData.map((column) => (
                <div key={column.index} className="mb-3">
                  {column.default ? (
                    <span style={{fontSize:"14px"}}>{column.label}</span>
                  ) : (
                    <Form.Check
                      type="checkbox"
                      id={column.name}
                      label={column.label}
                      checked={columnVisibility[column.name]}
                      onChange={() => handleCheckboxChange(column.name)}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center">
          <Button
            type="button"
            className="commonBtn smallBtn"
            //onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Container>
  );
};

export default ListTable;
