import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { FaArrowLeftLong } from "react-icons/fa6";
import { salesTargetGetById } from "../../../constants/api";
import axios from "axios";
import { notifyError } from "../../../constants/toastAlert";
import { branchPagiData } from "../../../redux/actions/branch.action";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { updateSalesTarget } from "../../../redux/actions/salesTarget.actions";
import { companyData } from "../../../redux/actions/company.actions";
import { employeeData } from "../../../redux/actions/employee.actions";

const EditSalesTarget = () => {
  const { id } = useParams();
  const financialYear = localStorage.getItem("financialyear") || "";
  const URL = extractAndFormatURLPart("sales");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const companyMaster =
  useSelector((state) => state.companyData.companyData.data) || [];
  const branchMaster =
    useSelector((state) => state.branchData.branchPagiData.data) || [];
  const employeeMaster =
    useSelector((state) => state.employeeData.employeeData.data) || [];
  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);

  const [data, setData] = useState({
    name: "",
    campno: "",
    campname: "",
    targetfor: "",
    validfrom: "",
    validupto: "",
    targetarray: [],
    branchid: branchid,
    companyid: companyid,
    userid: userid,
    org: "₹ΘθϖΠ",
    financialyear: financialYear,
    checkarr: [],
    id: id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "targetfor") {
      setData((prev) => ({
        ...prev,
        [name]: value,
        name: "",
        targetarray: [],
        checkarr: [],
      }));
    }
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedTargetArr = data.targetarray.map((item, i) =>
      i == ind ? { ...item, [name]: value } : item
    );

    setData((prev) => ({
      ...prev,
      targetarray: updatedTargetArr,
    }));
  };

  const handleDeleteRow = (index) => {
    let filtereTargetArr = data.targetarray.filter(
      (item, ind) => ind !== index
    );

    setData((prevData) => ({
      ...prevData,
      targetarray: filtereTargetArr,
    }));
  };

  const handleCheckArr = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setData((prev) => ({ ...prev, checkarr: [...data.checkarr, value] }));
    } else {
      const filteredCheckArr = data.checkarr.filter((check) => check != value);
      setData((prev) => ({ ...prev, checkarr: filteredCheckArr }));
    }
  };

  const handleAddNewRow = () => {
    if (
      (data.checkarr.length > 0 && data.checkarr.includes("Item")) ||
      data.checkarr.includes("Category")
    ) {
      let obj = {
        category: "",
        item: "",
        salesperson: "",
        setby: "Amount",
        target: "",
      };
      setData((prev) => ({ ...prev, targetarray: [...data.targetarray, obj] }));
    } else {
      notifyError("Please tick any checkbox either category or item");
      return;
    }
  };

  const handleSave = () => {
    if (
      !data.name ||
      !data.validfrom ||
      !data.validupto ||
      !data.campname ||
      !data.campno ||
      !data.targetfor
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    if (data.targetfor && data.targetarray.length == 0) {
      notifyError("Please add a target before submitting form");
      return;
    }

    dispatch(updateSalesTarget(data, navigate));
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(salesTargetGetById, { id });
      if (res.data.length > 0) {
        setData((prev) => ({
          ...prev,
          ...res.data[0],
        }));
      } else {
        setData((prev) => ({ ...prev }));
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  const fetchItem = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/getitem", {

        userid,
        branchid,
        companyid,
        keyword: "",
      });
      res.data.data ? setItemMaster(res.data.data) : setItemMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategory = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/category", {

        userid,
        branchid,
        companyid,
        keyword: "",
      });
      res.data.data ? setCategoryMaster(res.data.data) : setCategoryMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchItem();
    fetchCategory();
  }, []);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      branchPagiData(
        { userid, branchid, companyid, branch, keyword: "" },
        setLoading
      )
    );
    dispatch(companyData({ userid, companyid, keyword: "" }, setLoading));
    dispatch(
      employeeData({ userid, companyid, branchid, keyword: "" }, setLoading)
    );
  }, [dispatch, loadData]);



  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/sales/salesTarget/")}
            />
            Edit <span className="form-title main"> Sales Target </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Campaign No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="campno"
                            value={data.campno}
                            isInvalid={!data.campno && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Campaign Name<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter campaign name"
                            type="text"
                            name="campname"
                            value={data.campname}
                            isInvalid={!data.campname && showEmptyError}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Valid From<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="validfrom"
                            value={data.validfrom}
                            onChange={handleChange}
                            isInvalid={!data.validfrom && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Valid Upto<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="validupto"
                            value={data.validupto}
                            onChange={handleChange}
                            isInvalid={!data.validupto && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Target For <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="targetfor"
                            value={data.targetfor}
                            onChange={handleChange}
                            isInvalid={!data.targetfor && showEmptyError}
                          >
                            <option value="">Select</option>
                            <option value="Branch">Branch</option>
                            <option value="Franchise">Franchise</option>
                            <option value="Sub-Dealer">Sub-Dealer</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      {data.targetfor == "Branch" ? (
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Name <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="name"
                              value={data.name}
                              onChange={handleChange}
                              isInvalid={!data.name && showEmptyError}
                            >
                              <option value="">Select</option>
                              {branchMaster.map((branch) => (
                                <option
                                  value={branch.branchname}
                                  key={branch.id}
                                >
                                  {branch.branchname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      ) : data.targetfor == "Franchise" ? (
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Name <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="name"
                              value={data.name}
                              onChange={handleChange}
                              isInvalid={!data.name && showEmptyError}
                            >
                              <option value="">Select</option>
                              {companyMaster.filter((company)=>company.type=="Franchise").map((company) => (
                                <option
                                  value={company.companyname}
                                  key={companyname.id}
                                >
                                  {company.companyname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              Name <span className="star">*</span>
                            </Form.Label>
                            <Form.Select
                              name="name"
                              value={data.name}
                              onChange={handleChange}
                              isInvalid={!data.name && showEmptyError}
                            >
                              <option value="">Select</option>
                              {companyMaster.filter((company)=>company.type=="Sub-Dealer").map((company) => (
                                <option
                                  value={company.companyname}
                                  key={companyname.id}
                                >
                                  {company.companyname}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col></Col>
                </Row>
                <br />
                <Row>
                  <h3>
                    Select from the following fields to set target columns
                  </h3>
                </Row>
                <br />
                <div className="d-flex justify-content-start align-items-center gap-3">
                  <Form.Check
                    inline
                    type="checkbox"
                    disabled={data.targetfor != "Branch"}
                    label="Sales Person"
                    value="Sales Person"
                    checked={data.checkarr.includes("Sales Person")}
                    onChange={handleCheckArr}
                  />
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Category"
                    value="Category"
                    checked={data.checkarr.includes("Category")}
                    onChange={handleCheckArr}
                  />
                  <Form.Check
                    inline
                    type="checkbox"
                    label="Item"
                    value="Item"
                    checked={data.checkarr.includes("Item")}
                    onChange={handleCheckArr}
                  />
                </div>
              </Container>
            </div>
          </div>
        </Form>
        <br />
        {data.targetfor ? (
          <span
            className="fs-4 custom-cursor text-warning"
            onClick={handleAddNewRow}
          >
            &nbsp; Add New Sales Target <IoAddCircleSharp />
          </span>
        ) : null}
        <br />
        <br />
        <Col md={12} className="table-main-container">
          {data.targetarray.length > 0 ? (
            <Table responsive>
              <thead className="tableContainer">
                <tr>
                  <th>SI No.</th>
                  {data.checkarr.includes("Sales Person") ? (
                    <th>Sales Person</th>
                  ) : null}
                  {data.checkarr.includes("Category") ? (
                    <th>Category</th>
                  ) : null}
                  {data.checkarr.includes("Item") ? <th>Item</th> : null}
                  <th>Set By</th>
                  <th>Target</th>
                </tr>
              </thead>
              <tbody>
                {data.targetarray.map((target, ind) => (
                  <tr key={ind}>
                    <td>
                      <Form.Control
                        type="text"
                        disabled
                        value={ind + 1}
                        className="text-center"
                      />
                    </td>
                    {data.checkarr.includes("Sales Person") ? (
                      <td>
                        <Form.Select
                          name="salesperson"
                          value={target.salesperson}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!target.salesperson && showEmptyError}
                          className="form-control "
                        >
                          <option value=""> Select </option>
                          {employeeMaster
                            .filter((employee) => employee.issale == "Yes")
                            .map((employee) => (
                              <option value={employee.fullname} id={employee.id}> {employee.fullname} </option>
                            ))}
                        </Form.Select>
                      </td>
                    ) : null}
                    {data.checkarr.includes("Category") ? (
                      <td>
                        <Form.Select
                          name="category"
                          value={target.category}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!target.category && showEmptyError}
                          className="form-control "
                        >
                          <option value=""> Select </option>
                          {categoryMaster?.map((category, i) => (
                            <option key={i} value={category.category}>
                              {category.category}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    ) : null}
                    {data.checkarr.includes("Item") ? (
                      <td>
                        <Form.Select
                          name="item"
                          value={target.item}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!target.item && showEmptyError}
                          className="form-control "
                        >
                          <option value=""> Select </option>
                          {itemMaster?.map((item) => (
                            <option key={item.id} value={item.itemname}>
                              {item.itemname}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    ) : null}
                    <td>
                      <Form.Select
                        name="setby"
                        value={target.setby}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.setby && showEmptyError}
                        className="form-control"
                      >
                        <option value="Amount"> Amount </option>
                        <option value="Qty"> Qty </option>
                      </Form.Select>
                    </td>
                    <td className="d-flex gap-2">
                      <Form.Control
                        type="number"
                        name="target"
                        value={target.target}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.target && showEmptyError}
                      />
                      <MdDelete
                        onClick={() => handleDeleteRow(ind)}
                        className="fs-1 text-danger"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </Col>
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/sales/salesTarget")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditSalesTarget;
