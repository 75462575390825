import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import TableHeading from "../../../constants/TableHeading";
import {
  stockPagiDataPost,
  warehousePagiDataPost,
} from "../../../constants/api";
import axios from "axios";
import { initialData } from "../../../constants/resData";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import { MdEdit } from "react-icons/md";

const QualityCheckModal = ({ array, grn, from }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let branchID = localData.branchid;
  let companyID = localData.companyid;
  const ReqBody = {
    keyword: "",
    branchid: branchID,
    userid: userID,
    companyid: companyID,
  };
  const [show, setShow] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [rakes, setRakes] = useState([]);
  const [selectedrack, setSelectedrack] = useState("");
  const [tableData, setTableData] = useState([]);
  const [viewtable, setViewTable] = useState([false]);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setTableData([]);
  };

  useEffect(() => {
    axios

      .post("https://mis.saanviavs.com/api/v1/receivegoods/getitemroom", {
        grn: grn,
        userid: ReqBody.userid,
        branchid: ReqBody.branchid,
        companyid: ReqBody.companyid,
      })
      .then((res) => {
        setViewTable(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    //get all warehouse
    axios
      .post(warehousePagiDataPost, ReqBody)
      .then((res) => {
        setWarehouses(initialData(res));
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    //get all Stock Locations
    axios
      .post(stockPagiDataPost, ReqBody)
      .then((res) => {
        setRooms(
          initialData(res).filter((ele) => ele.warehouse == selectedWarehouse)
        );
        setRakes(
          initialData(res).filter(
            (ele) =>
              ele.warehouse == selectedWarehouse && ele.roomno == selectedRoom
          )
        );
      })
      .catch((err) => console.log(err));
  }, [selectedWarehouse, array, selectedRoom]);

  const handleTableRowChange = (e, rowindex) => {
    const { name, value } = e.target;
    const item = array[rowindex];

    if (name == "warehouse") {
      setSelectedWarehouse(value);
    }
    if (name == "roomno") {
      setSelectedRoom(value);
    }
    if (name == "rackno") {
      setSelectedrack(value);
    }

    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
        itemname: item.itemname,
        itemid: item.itemid,
        category: item.category,
        //warehouse: warehouse,
        qty: from == "Return From Customer" ? item.receivedqty : item.qty,
        finalqty:
          (from == "Return From Customer" ? item.receivedqty : item.qty || 0) -
          parseInt(updatedTableData[rowindex]?.defective || 0) -
          parseInt(updatedTableData[rowindex]?.shortage || 0),
      };

      const TotalDefective = updatedTableData[rowindex]?.defective || 0;
      const TotalShortage = updatedTableData[rowindex]?.shortage || 0;

      updatedTableData[rowindex] = {
        ...updatedTableData[rowindex],
        [name]: value,
        finalqty:
          (from == "Return From Customer" ? item.receivedqty : item.qty || 0) -
          TotalDefective -
          TotalShortage,
      };
      return updatedTableData;
    });
  };

  const handleSave = async () => {
    if (tableData.length <= 0) {
      return notifyError("You have empty field");
    }
    const res = await axios.post(
      "https://mis.saanviavs.com/api/v1/receivegoods/roomupdate",

      {
        itemarray: tableData,
        userid: userID,
        branchid: branchID,
        companyid: companyID,
      }
    );
    if (res.data.message == "Storage and qc updated") {
      notifySuccess("Storage and qc updated");
      handleClose();
    } else {
      notifyError(res.data.message);
    }
  };

  return (
    <>
      {" "}
      <MdEdit className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>
              QC & Storage <span className="form-title main"> Details</span>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-main-container" responsive>
            <TableHeading
              data={[
                "SL No",
                "Item Name",
                "Item ID",
                "Category",
                "Received Qty",
                "Defective",
                "Shortage",
                "Final Qty",
                "Warehouse",
                "Room No",
                "Rack Details",
              ]}
            />
            <tbody>
              {array.length > 0
                ? array.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          className="text-center"
                          disabled
                          readOnly
                          value={index + 1}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemname"
                          className="text-center"
                          disabled
                          value={item.itemname}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="itemid"
                          className="text-center"
                          disabled
                          value={item.itemid}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="category"
                          className="text-center"
                          disabled
                          value={item.category}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        {from == "Return From Customer" ? (
                          <Form.Control
                            name={"receivedqty"}
                            //disabled
                            className="text-center"
                            value={item.receivedqty}
                            onChange={(e) => handleTableRowChange(e, index)}
                          />
                        ) : (
                          <Form.Control
                            name={"qty"}
                            //disabled
                            className="text-center"
                            value={item.qty}
                            onChange={(e) => handleTableRowChange(e, index)}
                          />
                        )}
                      </td>
                      <td>
                        <Form.Control
                          name="defective"
                          className="text-center"
                          type="number"
                          value={item.defective}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="shortage"
                          className="text-center"
                          type="number"
                          value={item.shortage}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          name="finalqty"
                          disabled
                          className="text-center"
                          type="number"
                          value={tableData[index]?.finalqty || ""}
                          onChange={(e) => handleTableRowChange(e, index)}
                        />
                      </td>
                      <td>
                        <Form.Select
                          name="warehouse"
                          className="text-center"
                          value={item.warehouse}
                          onChange={(e) => handleTableRowChange(e, index)}
                        >
                          <option value="">---</option>
                          {warehouses.length > 0 &&
                            Array.from(
                              new Set(
                                warehouses.map((ele) => ele.warehousename)
                              )
                            ).map((warehousename, index) => (
                              <option key={index} value={warehousename}>
                                {warehousename}
                              </option>
                            ))}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          name="roomno"
                          className="text-center"
                          value={item.roomno}
                          onChange={(e) => handleTableRowChange(e, index)}
                        >
                          <option value="">---</option>
                          {rooms.length > 0 &&
                            Array.from(
                              new Set(rooms.map((ele) => ele.roomno))
                            ).map((roomno, index) => (
                              <option key={index} value={roomno}>
                                {roomno}
                              </option>
                            ))}
                        </Form.Select>
                      </td>
                      <td>
                        <Form.Select
                          name="rakeno"
                          className="text-center"
                          value={item.rakeno}
                          onChange={(e) => handleTableRowChange(e, index)}
                        >
                          <option value="">---</option>
                          {rakes.length > 0 &&
                            Array.from(
                              new Set(rakes.map((ele) => ele.rakeno))
                            ).map((rakeno, index) => (
                              <option key={index} value={rakeno}>
                                {rakeno}
                              </option>
                            ))}
                        </Form.Select>
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QualityCheckModal;
