import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import profile_image from "../../../assets/profile_image.jpg";

const PrintPayment = ({
  item,
  companyname,
  branch,
  name,
  hoaddress,
  city,
  pincode,
  state,
  gstno,
  emailid,
  phoneno,
  pan,
}) => {
  const {
    commonname,
    commonid,
    billarray,
    invoicetype,
    paymentno,
    paymentdate,
    paymentmode,
    amountreceived,
    amountinpayment,
    createdby,
    excessamount,
    total,
  } = item || {};


  const viewpageTitle = {
    fontSize: "1.7rem",
    fontWeight: 500,
  };
  const commonBtn = {
    width: "100%",
    border: "none",
    color: "#fafafa",
    fontSize: "1rem",
    fontWeight: 600,
    //borderRadius: "0.5rem",
    padding: "0.2rem 0rem",
    background: "#ffa000",
    boxShadow: "0px 0px 0.1rem 0px #1d2939",
    letterSpacing: "0.1rem",
  };
  const textBold = {
    fontWeight: "bold",
  };
  const title = {
    fontSize: "1.25rem",
    fontWeight: "bold",
  };
  const container = {
    display: "flex",
    justifyContent: "space-around",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
  };
  const tableBorder = {
    border: "1px solid black",
    backgroundColor: "#496989",
  };

  const tableBody = {
    textAlign: "center",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  };

  return (
    <Container style={{ fontFamily: "arial, sans-serif" }}>
      <div style={{ display: "flex", borderBottom: "1px solid black" }}>
        <div style={{ width: "20%" }}>
          <div>
            <img
              src="https://vs1-storage.blr1.digitaloceanspaces.com/SAANVI/ITEM/1710593699325-CLOTH%20ORGANISER.png"
              alt="Your Image"
              style={{ width: "100px", height: "auto" }}
            />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <h2>{companyname ? companyname : "--"}</h2>
          <p>{hoaddress ? hoaddress : "--"}</p>
          <p>{city ? city : "--"}</p>
          <p>
            {state ? state : "--"}-{pincode ? pincode : "--"}
          </p>
        </div>
        <div style={{ width: "30%", marginTop: "20px" }}>
          <Button style={commonBtn}>PAYMENT RECEIPT</Button>
          <p>
            Phone No:- <span style={textBold}>{phoneno ? phoneno : "--"}</span>
          </p>
          <p>
            Website:- <span style={textBold}>{"www.saanviavs.com"}</span>
          </p>
          <p>
            Email:- <span style={textBold}>{emailid ? emailid : "--"}</span>
          </p>
        </div>
      </div>

      <div style={container}>
        <p>
          <span style={title}>GSTIN:</span> <span>{gstno ? gstno : "--"}</span>
        </p>
        <p>
          <span style={title}>PAN:</span> <span>{pan ? pan : "--"}</span>
        </p>
        <p>
          <span>Payment No:</span>{" "}
          <span style={title}>{paymentno ? paymentno : "--"}</span>
        </p>
      </div>
      <p style={{ marginLeft: "70px" }}>
        {invoicetype == "Customer" && "Customer"}
        {invoicetype == "Other Company" && "Other Company"}
        {invoicetype == "Sub-Dealer" && "Sub-Dealer"}
        {invoicetype == "Franchise" && "Franchise"}
        {invoicetype == "Return To Vendor" && "Vendor"} Details
      </p>
      <div style={container}>
        <div>
          <p>
            Bill To:- <span>{commonname ? commonname : "--"}</span>
          </p>
          <p>
            Address:- <span>{emailid ? emailid : "--"}</span>
          </p>
          <p>
            Phone:- <span>{emailid ? emailid : "--"}</span>
          </p>
          <p>
            GSTIN:- <span>{"Customer GST Number"}</span>
          </p>
        </div>
        <div style={{ borderLeft: "2px solid black" }}></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={title}>Over Payment: {excessamount}</p>
        </div>
      </div>
      <div style={container}>
        <div>
          <p>
            {invoicetype == "Customer" && "Customer"}
            {invoicetype == "Other Company" && "Other Company"}
            {invoicetype == "Sub-Dealer" && "Sub-Dealer"}
            {invoicetype == "Franchise" && "Franchise"}
            {invoicetype == "Return To Vendor" && "Vendor"} ID:-{" "}
            <span>{commonid ? commonid : "--"}</span>
          </p>
          <p>
            Amount Received:-{" "}
            <span>{amountreceived ? amountreceived : "--"}</span>
          </p>
          <p>
            Payment Amount:-{" "}
            <span>{amountinpayment ? amountinpayment : "--"}</span>
          </p>
          <p>
            Created By:- <span>{createdby ? createdby : "--"}</span>
          </p>
        </div>
        <div
          style={{ borderLeft: "2px solid black", visibility: "hidden" }}
        ></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>
            Payment Date:- <span>{paymentdate ? paymentdate : "--"}</span>
          </p>
        </div>
      </div>
      <div>
        <table
          style={{
            width: "100%",
            margin: "auto",
            padding: 0,
            fontSize: "1rem",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={tableBorder}>SL No</th>
              <th style={tableBorder}>Invoice Date</th>
              <th style={tableBorder}>Invoice No</th>
              <th style={tableBorder}>Invoice Amount</th>
              <th style={tableBorder}>Amount Due</th>
              <th style={tableBorder}>Payment Amount</th>
            </tr>
          </thead>
          <tbody>
            {billarray.length > 0 &&
              billarray.map((bill, ind) => (
                <tr key={ind}>
                  <td style={tableBody}>{ind + 1}</td>
                  <td style={tableBody}>
                    {bill.invoicedate ? indianFormatDate(bill.invoicedate) : ""}
                  </td>
                  <td style={tableBody}>{bill.invoiceno}</td>
                  <td style={tableBody}>{bill.grandtotal}</td>
                  <td style={tableBody}>{bill.balancedue}</td>
                  <td style={tableBody}>{bill.paymentamount}</td>
                </tr>
              ))}
            <tr style={{ textAlign: "center", border: "1px solid black" }}>
              <td></td>
              <td></td>
              <td>Total</td>
              <td></td>
              <td></td>
              <td>{total}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderTop: "1px solid black",
          borderBottom: "1px solid black",
          borderLeft: "1px solid black",
          borderRight: "1px solid black",
          padding: "5px 5px",
        }}
      >
        <div style={{ width: "46%" }}>
          <p
            style={{
              width: "100%",
              margin: "auto",
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid black",
            }}
          >
            Terms and conditions
          </p>
          <br />
          <div>
            "By accepting this invoice, the customer agrees to pay the specified
            amount within the stated timeframe. Any disputes must be raised
            within 30 days. Late payments may incur interest charges. Goods
            remain property of the seller until fully paid. All sales are
            final."
          </div>
        </div>
        <div style={{ borderLeft: "2px solid black" }}></div>
        <div style={{ width: "46%" }}>
          <div style={{ borderBottom: "1px solid black", textAlign: "center" }}>
            Certified that particulars given above are true and correct
            <p style={{ fontWeight: "bold" }}>For {companyname}</p>
          </div>
          <div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <p
            style={{
              width: "100%",
              margin: "auto",
              textAlign: "center",
              fontWeight: "bold",
              border: "1px solid black",
            }}
          >
            Authorized Signature
          </p>
        </div>
      </div>
    </Container>
  );
};

export default PrintPayment;
