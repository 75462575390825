import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdDelete } from "react-icons/md";
import {
  salesOrderPagiData,
  termsAndConditionData,
} from "../../../redux/actions/salesOrder.actions";
import { projectData } from "../../../redux/actions/projects.actions";
import { customerPagiData } from "../../../redux/actions/customer.actions";
import { itemPagiData } from "../../../redux/actions/item.actions";
import { paymentTermData } from "../../../redux/actions/pt.actions";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { companyData } from "../../../redux/actions/company.actions";
import { purchaseOrderData } from "../../../redux/actions/purchaseOrder.actions";
import {
  issueGoodsPagiData,
  salesInvoiceAdd,
  salesInvoiceAutoCount,
  salesInvoiceDocmnentUpload,
  salesInvoicePagiData,
  salesInvoiceSOItemData,
} from "../../../redux/actions/salesInvoice.actions";
import { employeeData } from "../../../redux/actions/employee.actions";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { purchaseBillsData } from "../../../redux/actions/purchaseBills.actions";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { tdsData } from "../../../redux/actions/tds.actions";
import { tcsData } from "../../../redux/actions/tcs.actions";
import getMonthName from "../../../constants/getMonthName";
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from "../../../constants/toastAlert";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import AddCustomerTypeModal from "./AddCustomerTypeModal";
import CustomerInfoInSI from "./CustomerInfo";
import TermsMaster from "../../Purchase/PurchaseOrder/TermsMaster";
import Select from "react-select";
import { saleInvoiceItemData, salesInvoicePagiDataPost } from "../../../constants/api";

function AddSalesInvoice() {
  const URL = extractAndFormatURLPart("sales");
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear?.trim().split("-");
  let finalResult = finalYear
    ?.map((str) => str.replace(/"/g, "").slice(-2))
    .join("-");

  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const navigate = useNavigate();

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, branch, org, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { gstno: usergstno, state } =
    useSelector(
      (state) =>
        state.authManager.userData && state.authManager.userData.companyaddress
    ) || {};
  const { data: salesInvoiceData } = useSelector(
    (state) => state.salesInvoiceData.salesInvoicePagiData || []
  );
  const { ptData: paymentAllData } = useSelector((state) => state.ptData) || [];
  const { data: vendorAllData } =
    useSelector((state) => state.vendorData.vendorData) || [];
  const { data: companyAllData } =
    useSelector((state) => state.companyData.companyData) || [];
  const { data: salesOrderAllData } =
    useSelector((state) => state.salesOrderData.salesOrderPagiData) || [];
  const { data: customerAllData } =
    useSelector((state) => state.customerData.customerPagiData) || [];
  const { data: issueGoodsAllData } =
    useSelector((state) => state.salesInvoiceData.issueGoodsPagiData) || [];
  const { data: employeeAllData } =
    useSelector((state) => state.employeeData.employeeData) || [];
  const { salesInvoiceCount, salesInvoiceDocument } =
    useSelector((state) => state.salesInvoiceData) || [];
  const { data: purchaseBillAllData } = useSelector(
    (state) => state.purchaseBillsData.purchaseBillsData
  );
  const { data: coaAllData } =
    useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const tdsMaster = useSelector((state) => state.tdsData.tdsData.data) || [];
  const tcsMaster = useSelector((state) => state.tcsData.tcsData.data) || [];
  const { data: projectAllData } = useSelector((state) => state.projectData.projectData) || [];
  const { termsAndConditionAllData } = useSelector((state) => state.salesOrderData) || [];
  // const salesOrderItemArrayData = useSelector((state) => state.salesInvoiceData.salesInvoiceItemData) || [];

  // const salesInvoiceAllData = !salesInvoiceData ? [] : salesInvoiceData;
  const employeeActiveData = !employeeAllData
    ? []
    : employeeAllData.filter((item) => item.status == "Active");
  const termAndCondition = termsAndConditionAllData?.filter(
    (item) => item.status === "Active" && item.type == "Sales Invoice"
  );
  const termAndConditionDesc =
    termAndCondition.length > 0 && termAndCondition[0]?.description
      ? JSON.parse(termAndCondition[0].description)
      : "";
  const sitandc =
    (termAndConditionDesc.blocks && termAndConditionDesc.blocks[0].text) || "";

  let words = companyname.split(" ") || "Saanvi Enterprisers";
  let firstLetters = words.map((word) => word.charAt(0));
  let companynameFirstWord = firstLetters.join("");

  // const companyName = useSelector((state) => state.companyData.companyData.data) || [];
  // const { salesOrderCount, salesOrderDocument } = useSelector((state) => state.salesOrderData) || 0;
  // const { data: quotationAllData } = useSelector((state) => state.quotationData.quotationPagiData) || [];
  // const { data: categoryAllData } = useSelector((state) => state.categoryData.categoryPagiData) || [];
  // const { data: itemAllData } = useSelector((state) => state.itemData.itemPagiData) || [];
  // const suggestedItemMaster = useSelector((state) => state.suggestedItemData.suggestedItemData.data) || [];
  // const { purchaseOrderData: purchaseOrderAllData } = useSelector((state) => state.purchaseOrderData) || [];

  //state part
  const [data, setData] = useState({
    invoicetype: "Customer",
    commonname: "",
    commonid: "",
    commonnamealldata: [],
    gino: "",
    saleorderno: "",
    purchaseorderno: "",
    purchaseinvoiceno: "",
    invoiceno: "",
    invoicedate: "",
    paymentterms: "",
    paymentduedate: "",
    transportertype: "",
    vehicleno: "",
    salesperson: "",
    ewaybillno: "",
    einvoiceno: "",
    createdby: "",
    documents: "",
    uploads: [],
    remarks: "",
    architect: "",
    contractor: "",
    showgst: true,
    itemarray: [],
    subtotal: 0.0,
    // discount: 0,
    // discountamount: 0.0,
    // discountaccount: "",
    // taxableamount: 0.0,
    cgst: 0.0,
    sgst: 0.0,
    igst: 0,
    tcs: "",
    tds: "",
    tcsvalue: "",
    tdsvalue: "",
    tsamount: 0,
    isfrightcharges: false,
    frightcharges: 0,
    adjustmentamount: 0,
    grandtotal: 0.0,
    tandc: "",
    // istandc: "",
    status: "",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    org: org,
    financialyear: finalcialYear,
    company: companyname,
    branch: branch,
    month: "",
    btstate: "",
    btcity: "",
    btpin: "",
    btaddress: "",
    bacountry: "",
    ststate: "",
    stcity: "",
    stpin: "",
    staddress: "",
    stcountry: "",
    transpotername: "",
    samestate: false,
    account: "",
  });
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [customergst, setCustomergst] = useState(0);
  const [selectedCustomerInfo, setSelectedCustomerInfo] = useState({});
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);

  // const sameState =
  //   // (customergst.length > 0 && customergst[0] == usergstno.length > 0 && usergstno[0] && customergst[1] == usergstno[1]) ||
  //   (state == data.invoicetype === "Return To Vendor" ? data?.commonnamealldata[0]?.billingstate :
  //     data.invoicetype === "Other Company" ? data?.commonnamealldata[0]?.state :
  //       data.invoicetype === "Sub-Dealer" ? data?.commonnamealldata[0]?.state :
  //         data.invoicetype === "Franchise" ? data?.commonnamealldata[0]?.state :
  //           data.invoicetype == "Customer" && data.commonnamealldata[0]?.bastate);

  // sahid code for day closure start
  const userData = JSON.parse(localStorage.getItem("userData")).data[0];
  const localData = JSON.parse(localStorage.getItem("userData")).companyaddress;
  const today = new Date();

  const [closureDate, setClosureDate] = useState(today);
  const localId = localData && localData.id !== undefined && localData.id;
  const closureDateObj = new Date(closureDate);
  const nextDay = new Date(closureDateObj);
  nextDay.setDate(closureDateObj.getDate() + 1);

  // for signature upload start
  const [signature, setSignature] = useState(null);
  const [signatureFromCompany, setSignatureFromCompany] = useState(null);
  const [load, setLoad] = useState(false);

  const handleUploadPhoto = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(
        "https://mis.saanviavs.com/api/v1/help/upload",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const handleSignatureUpload = async () => {
    const signatureUrl = await handleUploadPhoto(signature);
    axios

      .post(`https://mis.saanviavs.com/api/v1/master/signature`, {
        signature: signatureUrl.file,
        id: localId,
      })
      .then((res) => {
        notifySuccess(res.data.message);
        setLoad(!load);
      })
      .catch((err) => console.log(err));
  };
  // for signature upload end
  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/master/getcomapanypage`, {
        userid,
        keyword: org,
      })
      .then((res) => {
        const data =
          res.data.data.length > 0 &&
          res.data.data?.filter((elem) => {
            return elem.id == localId;
          });
        // setCompanyDropData(res.data.data);
        setClosureDate(data[0].closuredate);
        setSignatureFromCompany(data[0].signature);
      })
      .catch((err) => console.log(err));
  }, [localId, load]);

  // sahid code for day closure end

  //onchange part start
  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;

    if (name === "invoicetype") {
      setData((prevData) => ({
        ...prevData,
        commonname: "",
        commonid: "",
        gino: "",
        purchaseinvoiceno: "",
        saleorderno: "",
        architect: "",
        contractor: "",
        transportertype: "",
        vehicleno: "",
        itemarray: [],
      }));
    }

    // if (name === "commonname") {
    //   let selectedCommon;
    //   let selectinvoice;
    //   if (data.invoicetype === "Return To Vendor") {
    //     selectedCommon = vendorAllData?.find((item) => item.fullname === value);
    //     selectinvoice = purchaseBillAllData?.find(
    //       (item) => item.vendor === selectedCommon.fullname
    //     );
    //   } else if (data.invoicetype === "Sub-Dealer") {
    //     selectedCommon = companyAllData?.find(
    //       (item) => item.companyname === value && item.type === "Sub-Dealer"
    //     );
    //   } else if (data.invoicetype === "Franchise") {
    //     selectedCommon = companyAllData?.find(
    //       (item) => item.companyname === value && item.type === "Franchise"
    //     );
    //   } else if (data.invoicetype === "Other Company") {
    //     selectedCommon = companyAllData?.find(
    //       (item) => item.companyname === value && item.type === "Other Company"
    //     );
    //   } else {
    //     selectedCommon = customerAllData?.find(
    //       (item) => item.bdcompany === value || item.fullname === value
    //     )
    //     setSelectedCustomerInfo(selectedCommon);
    //   }

    //   if (selectedCommon || selectinvoice) {
    //     // Filter the desired key from the selected common item
    //     let filteredKey;
    //     let invoiceno;
    //     let filterBaState;
    //     let filterBaCity;
    //     let filterBaPincode;
    //     let filterBaAddress;
    //     let filterSaState;
    //     let filterSaCity;
    //     let filterSaPincode;
    //     let filterSaAddress;

    //     if (data.invoicetype === "Return To Vendor") {
    //       // Filter the key you want from selectedCommon for "Return To Vendor" type
    //       filteredKey = selectedCommon.vendorid;
    //       invoiceno = selectinvoice?.invoiceno;
    //       filterBaState = selectedCommon.billingstate;
    //       filterBaCity = selectedCommon.billingcity;
    //       filterBaPincode = selectedCommon.billingpincode;
    //       filterBaAddress = selectedCommon.billingaddress;
    //       filterSaState = selectedCommon.shippingstate;
    //       filterSaCity = selectedCommon.shippingcity;
    //       filterSaPincode = selectedCommon.shippingpincode;
    //       filterSaAddress = selectedCommon.shippingaddress;
    //     } else if (data.invoicetype === "Sub-Dealer") {
    //       // Filter the key you want from selectedCommon for "Sub-Dealer", "Franchise", "Other Company" types
    //       filteredKey = selectedCommon.companyid;;
    //       filterBaState = selectedCommon.state;
    //       filterBaCity = selectedCommon.city;
    //       filterBaPincode = selectedCommon.pincode;
    //       filterBaAddress = selectedCommon.hoaddress;
    //       filterSaState = selectedCommon.state;
    //       filterSaCity = selectedCommon.city;
    //       filterSaPincode = selectedCommon.pincode;
    //       filterSaAddress = selectedCommon.hoaddress;
    //     } else if (data.invoicetype === "Franchise") {
    //       filteredKey = selectedCommon.companyid;;
    //       filterBaState = selectedCommon.state;
    //       filterBaCity = selectedCommon.city;
    //       filterBaPincode = selectedCommon.pincode;
    //       filterBaAddress = selectedCommon.hoaddress;
    //       filterSaState = selectedCommon.state;
    //       filterSaCity = selectedCommon.city;
    //       filterSaPincode = selectedCommon.pincode;
    //       filterSaAddress = selectedCommon.hoaddress;
    //     } else if (data.invoicetype === "Other Company") {
    //       filteredKey = selectedCommon.companyid;
    //       filterBaState = selectedCommon.state;
    //       filterBaCity = selectedCommon.city;
    //       filterBaPincode = selectedCommon.pincode;
    //       filterBaAddress = selectedCommon.hoaddress;
    //       filterSaState = selectedCommon.state;
    //       filterSaCity = selectedCommon.city;
    //       filterSaPincode = selectedCommon.pincode;
    //       filterSaAddress = selectedCommon.hoaddress;
    //     } else {
    //       // Customer case
    //       filteredKey = selectedCommon.customerid;
    //       setCustomergst(selectedCommon.bdgstno);
    //       filterBaState = selectedCommon.bastate;
    //       filterBaCity = selectedCommon.bacity;
    //       filterBaPincode = selectedCommon.bapincode;
    //       filterBaAddress = selectedCommon.baaddress;
    //       filterSaState = selectedCommon.sastate;
    //       filterSaCity = selectedCommon.sacity;
    //       filterSaPincode = selectedCommon.sapincode;
    //       filterSaAddress = selectedCommon.saaddress;
    //     }
    //     setData((prevData) => ({
    //       ...prevData,
    //       commonid: filteredKey,
    //       ...(prevData.invoicetype === "Return To Vendor" && {
    //         purchaseinvoiceno: invoiceno,
    //       }),
    //       saleorderno: "",
    //       salesperson: "",
    //       remarks: "",
    //       architect: "",
    //       contractor: "",
    //       itemarray: [],
    //       btstate: filterBaState,
    //       btcity: filterBaCity,
    //       btpin: filterBaPincode,
    //       btaddress: filterBaAddress,
    //       ststate: filterSaState,
    //       stcity: filterSaCity,
    //       stpin: filterSaPincode,
    //       staddress: filterSaAddress,
    //       commonnamealldata: [selectedCommon],

    //     }));
    //   }
    // }

    if (name === "gino") {
      let gidata = issueGoodsAllData?.find((item) => item.gino == value);
      const itemGiData = gidata?.itemarray?.map((item) => ({
        ...item,
        itemamount: 0,
      }));
      setData((prevData) => ({
        ...prevData,
        vehicleno: gidata?.vehicleno,
        transportertype: gidata?.vehicletype,
        transpotername: gidata?.transportername,
        itemarray: itemGiData,
      }));
    }

    if (name === "invoicedate") {
      setData((prevData) => ({
        ...prevData,
        invoicedate: value,
      }));
      return;
    }

    if (name == "saleorderno") {
      const selectedSalesOrder =
        salesOrderAllData &&
        salesOrderAllData?.find((item) => item.saleorderno === value);
      const contractorandArchietecture =
        projectAllData &&
        projectAllData.find((item) => {
          return item.projectid === selectedSalesOrder?.projectid;
        });

      //For new api call start
      // axios.post(saleInvoiceItemData, { saleorderno: value })
      //   .then(response => {
      //     const normalize = (str) => str.trim().toLowerCase();
      //     const invoiceQtyKey = new Map(response?.data?.map(item => [normalize(item.itemid), item.invoiceqty]));
      //     setData({
      //       ...data,
      //       saleorderno: value,
      //       salesperson: selectedSalesOrder ? selectedSalesOrder.salesperson : "",
      //       remarks: selectedSalesOrder ? selectedSalesOrder.customernote : "",
      //       architect: contractorandArchietecture
      //         ? contractorandArchietecture.architect
      //         : "",
      //       contractor: contractorandArchietecture
      //         ? contractorandArchietecture.contractor
      //         : "",
      //       // itemarray: selectedSalesOrder ? selectedSalesOrder.itemarray : [],
      //       // itemarray: selectedSalesOrder ? selectedSalesOrder.itemarray.map(item => ({ ...item, orderqty: item.qty, })) : [],
      //       itemarray: selectedSalesOrder?.itemarray?.map(item => {
      //         // const normalize = (str) => str.trim().toLowerCase();
      //         const normalizedItemId = normalize(item.itemid);
      //         const invoiceQty = invoiceQtyKey.get(normalizedItemId) || 0;
      //         return {
      //           ...item,
      //           invoiceqty: invoiceQty,
      //           orderqty: item.qty
      //         };
      //       })
      //     });
      //   })
      //   .catch(error => {
      //     console.error('Error fetching data:', error);
      //   });

      //For new api call end

      setData({
        ...data,
        salesperson: selectedSalesOrder ? selectedSalesOrder.salesperson : "",
        remarks: selectedSalesOrder ? selectedSalesOrder.customernote : "",
        architect: contractorandArchietecture
          ? contractorandArchietecture.architect
          : "",
        contractor: contractorandArchietecture
          ? contractorandArchietecture.contractor
          : "",
        // itemarray: selectedSalesOrder ? selectedSalesOrder.itemarray : [],
        itemarray: selectedSalesOrder ? selectedSalesOrder.itemarray.map(item => ({ ...item, orderqty: item.qty, invoiceqty: 0, })) : [],
      });
    }

    if (name === "isfrightcharges") {
      setData((prevData) => ({
        ...prevData,
        isfrightcharges: checked,
      }));
      return;
    }

    if (name == "showgst") {
      setData((prevData) => ({
        ...prevData,
        showgst: e.target.checked,
      }));
      return;
    }

    if (name === "documents") {
      dispatch(salesInvoiceDocmnentUpload(files));
    }

    if (name === "tcs") {
      const isChecked = e.target.checked;
      if (isChecked) {
        setData((prevData) => ({
          ...prevData,
          tcs: isChecked,
          tds: !isChecked,
        }));
      }
    }

    if (name === "tds") {
      const isChecked = e.target.checked;
      if (isChecked) {
        setData((prevData) => ({
          ...prevData,
          tcs: !isChecked,
          tds: isChecked,
        }));
      }
    }

    // if (name == "tcsvalue") {
    //   setData((prev) => ({ ...prev, tcsvalue: value, tsamount: 0 }));

    //   if (value) {
    //     let findTcs = tcsMaster?.find((tcs) => tcs.taxname == value);
    //     const calculatedtsamount = (
    //       (Number(data.subtotal) * Number(findTcs.ratepercent)) /
    //       100
    //     ).toFixed(2);

    //     setData((prev) => ({ ...prev, tsamount: calculatedtsamount }));
    //     return;
    //   } else {
    //     setData((prev) => ({ ...prev, tsamount: 0 }));
    //     return;
    //   }
    // }

    // if (name == "tdsvalue") {
    //   setData((prev) => ({ ...prev, tcsvalue: value, tsamount: 0 }));

    //   if (value) {
    //     let findTds = tdsMaster.find((tds) => tds.taxname == value);
    //     const tsamount = (
    //       (Number(data.subtotal) * Number(findTds.ratepercent)) /
    //       100
    //     ).toFixed(2);
    //     setData((prev) => ({ ...prev, tsamount: `-${tsamount}` }));
    //   } else {
    //     setData((prev) => ({ ...prev, tsamount: 0 }));
    //   }
    // }

    if (name == "paymentterms") {
      if (value) {
        const findPt = paymentAllData.find((pt) => pt.termname == value);
        const dynamicDueDate = new Date(
          data.invoicedate ? data.invoicedate : new Date()
        );
        dynamicDueDate.setDate(dynamicDueDate.getDate() + Number(findPt.days));
        const formattedDate = dynamicDueDate.toISOString().split("T")[0];
        setData((prev) => ({
          ...prev,
          paymentduedate: formattedDate,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          paymentduedate: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //onchange part end

  // Define different options arrays for common name start
  const customerOptions =
    customerAllData &&
    customerAllData?.map((customer) => {
      if (customer.customertype === "Retail") {
        return {
          value: customer.fullname,
          label: customer.fullname,
        };
      } else {
        return {
          value: customer.bdcompany,
          label: customer.bdcompany,
        };
      }
    });

  const vendorOptions = vendorAllData?.map((vendor) => ({
    value: vendor.fullname,
    label: vendor.fullname,
  }));

  const franchiseCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Franchise")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  // For Other Company companies
  const otherCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Other Company")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  const subDealerCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Sub-Dealer")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  const updateOptions = (invoiceType) => {
    switch (invoiceType) {
      case "Return To Vendor":
        setOptions(vendorOptions);
        break;
      case "Other Company":
        setOptions(otherCompanyOptions);
        break;
      case "Sub-Dealer":
        setOptions(subDealerCompanyOptions);
        break;
      case "Franchise":
        setOptions(franchiseCompanyOptions);
        break;
      default:
        setOptions(customerOptions);
        break;
    }
  };
  // Define different options arrays for common name end

  //useEffect part start
  //default data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    branch: branch,
    companyname: companyname,
    org: org,
  };

  useEffect(() => {
    setLoading(!loading);

    dispatch(paymentTermData(backendData, setLoading));
    dispatch(vendorData(backendData, setLoading));
    dispatch(companyData(backendData, setLoading));
    dispatch(salesOrderPagiData(backendData, setLoading));
    dispatch(purchaseOrderData(backendData, setLoading));
    dispatch(customerPagiData(backendData, setLoading));
    dispatch(issueGoodsPagiData(backendData, setLoading));
    dispatch(employeeData(backendData, setLoading));
    // dispatch(salesInvoiceAutoCount(backendData, setLoading));
    dispatch(salesInvoicePagiData(backendData, setLoading));
    dispatch(purchaseBillsData(backendData, setLoading));
    dispatch(coaPagiData(backendData, setLoading));
    dispatch(tdsData(backendData, setLoading));
    dispatch(tcsData(backendData, setLoading));
    dispatch(itemPagiData(backendData, setLoading));
    dispatch(projectData(backendData, setLoading));
    dispatch(termsAndConditionData(backendData, setLoading));

    axios.post(salesInvoicePagiDataPost, backendData)
      .then((res) => {
        let currentCount = res.data && res.data.data && res.data.data.length > 0 ?
          String(parseInt(res.data.data[0].invoiceno.split("/")[2]) + 1)
          : "1";
        let paddedCount = currentCount.toString().padStart(4, "0");
        let result = `${companynameFirstWord}/${finalResult}/${paddedCount}`;
        setData({
          ...data,
          createdby: name,
          month: getMonthName(data.invoicedate),
          invoiceno: result,
        });
      })
      .catch(e => console.log(e));
    
  }, [dispatch, loadData, salesInvoiceCount, data.invoicedate]);

  useEffect(() => {
    if (data.commonname) {
      let selectedCommon;
      let selectinvoice;
      if (data.invoicetype === "Return To Vendor") {
        selectedCommon = vendorAllData?.find(
          (item) => item.fullname === data.commonname
        );
        selectinvoice = purchaseBillAllData?.find((item) => {
          return item.vendor === selectedCommon.fullname;
        });
      } else if (data.invoicetype === "Sub-Dealer") {
        selectedCommon = companyAllData?.find(
          (item) =>
            item.companyname === data.commonname && item.type === "Sub-Dealer"
        );
      } else if (data.invoicetype === "Franchise") {
        selectedCommon = companyAllData?.find(
          (item) =>
            item.companyname === data.commonname && item.type === "Franchise"
        );
      } else if (data.invoicetype === "Other Company") {
        selectedCommon = companyAllData?.find(
          (item) =>
            item.companyname === data.commonname &&
            item.type === "Other Company"
        );
      } else {
        selectedCommon = customerAllData?.find(
          (item) =>
            item.bdcompany === data.commonname ||
            item.fullname === data.commonname
        );
        setSelectedCustomerInfo(selectedCommon);
      }

      if (selectedCommon || selectinvoice) {
        // Filter the desired key from the selected common item
        let filteredKey;
        let invoiceno;
        let filterBaState;
        let filterBaCity;
        let filterBaPincode;
        let filterBaAddress;
        let filterSaState;
        let filterSaCity;
        let filterSaPincode;
        let filterSaAddress;

        if (data.invoicetype === "Return To Vendor") {
          // Filter the key you want from selectedCommon for "Return To Vendor" type
          filteredKey = selectedCommon.vendorid;
          invoiceno = selectinvoice?.invoiceno;
          filterBaState = selectedCommon.billingstate;
          filterBaCity = selectedCommon.billingcity;
          filterBaPincode = selectedCommon.billingpincode;
          filterBaAddress = selectedCommon.billingaddress;
          filterSaState = selectedCommon.shippingstate;
          filterSaCity = selectedCommon.shippingcity;
          filterSaPincode = selectedCommon.shippingpincode;
          filterSaAddress = selectedCommon.shippingaddress;
        } else if (data.invoicetype === "Sub-Dealer") {
          // Filter the key you want from selectedCommon for "Sub-Dealer", "Franchise", "Other Company" types
          filteredKey = selectedCommon.companyid;
          filterBaState = selectedCommon.state;
          filterBaCity = selectedCommon.city;
          filterBaPincode = selectedCommon.pincode;
          filterBaAddress = selectedCommon.hoaddress;
          filterSaState = selectedCommon.state;
          filterSaCity = selectedCommon.city;
          filterSaPincode = selectedCommon.pincode;
          filterSaAddress = selectedCommon.hoaddress;
        } else if (data.invoicetype === "Franchise") {
          filteredKey = selectedCommon.companyid;
          filterBaState = selectedCommon.state;
          filterBaCity = selectedCommon.city;
          filterBaPincode = selectedCommon.pincode;
          filterBaAddress = selectedCommon.hoaddress;
          filterSaState = selectedCommon.state;
          filterSaCity = selectedCommon.city;
          filterSaPincode = selectedCommon.pincode;
          filterSaAddress = selectedCommon.hoaddress;
        } else if (data.invoicetype === "Other Company") {
          filteredKey = selectedCommon.companyid;
          filterBaState = selectedCommon.state;
          filterBaCity = selectedCommon.city;
          filterBaPincode = selectedCommon.pincode;
          filterBaAddress = selectedCommon.hoaddress;
          filterSaState = selectedCommon.state;
          filterSaCity = selectedCommon.city;
          filterSaPincode = selectedCommon.pincode;
          filterSaAddress = selectedCommon.hoaddress;
        } else {
          // Customer case
          filteredKey = selectedCommon.customerid;
          setCustomergst(selectedCommon.bdgstno);
          filterBaState = selectedCommon.bastate;
          filterBaCity = selectedCommon.bacity;
          filterBaPincode = selectedCommon.bapincode;
          filterBaAddress = selectedCommon.baaddress;
          filterSaState = selectedCommon.sastate;
          filterSaCity = selectedCommon.sacity;
          filterSaPincode = selectedCommon.sapincode;
          filterSaAddress = selectedCommon.saaddress;
        }

        setData((prevData) => ({
          ...prevData,
          commonid: filteredKey,
          ...(prevData.invoicetype === "Return To Vendor" && {
            purchaseinvoiceno: invoiceno,
          }),
          saleorderno: "",
          salesperson: "",
          remarks: "",
          architect: "",
          contractor: "",
          itemarray: [],
          btstate: filterBaState,
          btcity: filterBaCity,
          btpin: filterBaPincode,
          btaddress: filterBaAddress,
          ststate: filterSaState,
          stcity: filterSaCity,
          stpin: filterSaPincode,
          staddress: filterSaAddress,
          commonnamealldata: [selectedCommon],
          samestate:
            state ==
            (data.invoicetype === "Return To Vendor"
              ? selectedCommon?.billingstate
              : data.invoicetype === "Other Company"
                ? selectedCommon?.state
                : data.invoicetype === "Sub-Dealer"
                  ? selectedCommon?.state
                  : data.invoicetype === "Franchise"
                    ? selectedCommon?.state
                    : data.invoicetype == "Customer" && selectedCommon?.bastate),
          tcs: selectedCommon.tcs != "" ? true : false,
          tds: selectedCommon.tds != "" ? true : false,
          tcsvalue: selectedCommon.tcs,
          tdsvalue: selectedCommon.tds,
        }));
      }
    }
  }, [data.commonname]);

  useEffect(() => {
    const calculateTsAmount = (subtotal, ratePercent) => {
      return ((Number(subtotal) * Number(ratePercent || 0)) / 100).toFixed(2);
    };

    if (data.tdsvalue) {
      let findTds = tdsMaster.find((tds) => tds.taxname === data.tdsvalue);
      if (findTds) {
        const tsamount = calculateTsAmount(data.subtotal, findTds.ratepercent);
        setData((prev) => ({ ...prev, tsamount: `-${tsamount}` }));
      } else {
        setData((prev) => ({ ...prev, tsamount: 0 }));
      }
    } else if (data.tcsvalue) {
      let findTcs = tcsMaster.find((tcs) => tcs.taxname === data.tcsvalue);
      if (findTcs) {
        const calculatedtsamount = calculateTsAmount(data.subtotal, findTcs.ratepercent);
        setData((prev) => ({ ...prev, tsamount: calculatedtsamount }));
      } else {
        setData((prev) => ({ ...prev, tsamount: 0 }));
      }
    } else {
      setData((prev) => ({ ...prev, tsamount: 0 }));
    }
  }, [data.tcsvalue, data.tdsvalue, data.itemarray]);
  //useEffect part end

  //Table part start
  function handleAddNewRow() {
    setData((prevTableData) => ({
      ...prevTableData,
      itemarray: [...prevTableData.itemarray, {}],
    }));
  }

  const handleTableRowChange = (e, rowIndex) => {
    const { name, value } = e.target;

    setData((prevData) => {
      const updatedData = [...prevData.itemarray]; // Copy the itemarray from the previous state

      // validation check start
      const currentRow = updatedData[rowIndex];
      if (name === 'invoiceqty' && parseFloat(value) > parseFloat(currentRow.remainingqty)) {
        // If the new invoiceqty is greater than orderqty, do not update the state and return the previous state
        notifyWarning('Invoice quantity cannot be greater than remaining quantity');
        return prevData;
      }
      // validation check end

      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [name]: value,
      };
      return {
        ...prevData,
        itemarray: updatedData,
      };
    });
  };

  const handleTableRowDelete = (rowIndex) => {
    setData((prevTableData) => {
      const updatedTableData = [...prevTableData.itemarray];
      updatedTableData.splice(rowIndex, 1);
      return { ...prevTableData, itemarray: updatedTableData };
    });
  };
  //Table part end

  // Manage Terms Start
  const handleClose = () => setShow(false);
  // Manage Terms End

  //Sales invoice footer part start

  const handleSaveAsApproved = () => {
    if (
      !data.invoicetype ||
      !data.commonname ||
      (data.invoicetype != "Customer" && !data.gino) ||
      !data.paymentterms ||
      !data.paymentduedate ||
      !data.invoicedate ||
      !data.account
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    const formData = {
      ...data,
      status: "Unpaid",
      uploads: salesInvoiceDocument ? salesInvoiceDocument : [],
      balancedue: data.grandtotal,
    };
    dispatch(salesInvoiceAdd(formData, navigate));
  };

  const handleSaveAsDraft = () => {
    if (
      !data.invoicetype ||
      !data.commonname ||
      (data.invoicetype != "Customer" && !data.gino) ||
      !data.paymentterms ||
      !data.paymentduedate ||
      !data.invoicedate ||
      !data.account
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    const formData = {
      ...data,
      status: "Draft",
      uploads: salesInvoiceDocument ? salesInvoiceDocument : [],
      balancedue: data.grandtotal,
    };
    dispatch(salesInvoiceAdd(formData, navigate));
  };

  const handleCancel = () => {
    navigate(`/sales/salesInvoice`);
  };

  //Sales order footer part end
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12} className="d-flex justify-content-between">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/sales/salesInvoice")}
              />
              Add <span className="form-title"> Sales Invoice</span>
            </h1>
          </Col>
          <hr />
          <Col sm={12}>
            <Form className="form-lable-name">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="invoiccetype">
                    <Form.Label>
                      Invoice Type<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="invoicetype"
                      onChange={handleChange}
                      value={data.invoicetype}
                      isInvalid={data.invoicetype === "" && showEmptyError}
                    >
                      <option value="Customer">Customer</option>
                      <option value="Other Company">Other Company</option>
                      <option value="Sub-Dealer">Sub-Dealer</option>
                      <option value="Franchise">Franchise</option>
                      <option value="Return To Vendor">Return To Vendor</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Invoice Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="commonname">
                    <Form.Label className="d-flex justify-content-between">
                      <span>
                        {data.invoicetype == "Return To Vendor"
                          ? "Vendor"
                          : data.invoicetype == "Other Company"
                            ? "Company"
                            : data.invoicetype == "Sub-Dealer"
                              ? "Sub-Dealer"
                              : data.invoicetype == "Franchise"
                                ? "Franchise"
                                : "Customer Name"}
                        <span className="star"> *</span>
                        {data.invoicetype == "Customer" && (
                          <CustomerInfoInSI
                            selectedCustomerInfo={selectedCustomerInfo}
                          />
                        )}
                      </span>
                      {data.invoicetype == "Customer" && (
                        <AddCustomerTypeModal />
                      )}
                    </Form.Label>
                    {/* <Form.Select
                      aria-label="Default select example"
                      name="commonname"
                      value={data.commonname}
                      onChange={handleChange}
                      isInvalid={data.commonname === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {data.invoicetype === "Return To Vendor" ? (
                        vendorAllData && vendorAllData.length > 0 ? (
                          vendorAllData.map((item, index) => {
                            const { fullname } = item;
                            return (
                              <option key={index} value={fullname}>
                                {fullname}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">
                            No Return To Vendor Available
                          </option>
                        )
                      ) : data.invoicetype === "Sub-Dealer" ? (
                        companyAllData && companyAllData.length > 0 ? (
                          companyAllData.map((item, index) => {
                            const { companyname, type } = item;
                            if (type === "Sub-Dealer") {
                              return (
                                <option key={index} value={companyname}>
                                  {companyname}
                                </option>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <option value="">No Sub-Dealer Available</option>
                        )
                      ) : data.invoicetype === "Franchise" ? (
                        companyAllData && companyAllData.length > 0 ? (
                          companyAllData.map((item, index) => {
                            const { companyname, type } = item;
                            if (type === "Franchise") {
                              return (
                                <option key={index} value={companyname}>
                                  {companyname}
                                </option>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <option value="">No Franchise Available</option>
                        )
                      ) : data.invoicetype === "Customer" ? (
                        customerAllData && customerAllData.length > 0 ? (
                          customerAllData.map((item, index) => {
                            const { bdcompany, fullname, customertype } = item;
                            return (
                              customertype == "Retail" ? (
                                <option key={index} value={fullname}> {fullname}</option>
                              ) : (
                                <option key={index} value={bdcompany}>{bdcompany}</option>
                              )
                            );
                          })
                        ) : (
                          <option value="">No Customer Available</option>
                        )
                      ) : companyAllData && companyAllData.length > 0 ? (
                        companyAllData.map((item, index) => {
                          const { companyname, type } = item;
                          if (type === "Other Company") {
                            return (
                              <option key={index} value={companyname}>
                                {companyname}
                              </option>
                            );
                          }
                          return null;
                        })
                      ) : (
                        <option value="">No Other Company Available</option>
                      )}
                    </Form.Select> */}
                    <Select
                      options={options}
                      value={
                        options &&
                        options?.find(
                          (option) => option.value === data.commonname
                        )
                      }
                      onChange={(selectedOption) =>
                        setData((prevData) => ({
                          ...prevData,
                          commonname: selectedOption
                            ? selectedOption.value
                            : "",
                        }))
                      }
                      onFocus={() => updateOptions(data.invoicetype)}
                      placeholder="Select Name"
                      isSearchable
                      className={
                        data.commonname === "" && showEmptyError
                          ? "empty-feild"
                          : ""
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Select{" "}
                      {data.invoicetype == "Return To Vendor"
                        ? "Vendor"
                        : data.invoicetype == "Other Company"
                          ? "Company"
                          : data.invoicetype == "Sub-Dealer"
                            ? "Sub-Dealer"
                            : data.invoicetype == "Franchise"
                              ? "Franchise"
                              : "Customer Name"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="commonid">
                    <Form.Label>
                      {data.invoicetype == "Return To Vendor"
                        ? "Vendor ID"
                        : data.invoicetype == "Other Company"
                          ? "Company ID"
                          : data.invoicetype == "Sub-Dealer"
                            ? "Sub-Dealer ID"
                            : data.invoicetype == "Franchise"
                              ? "Franchise ID"
                              : "Customer ID"}{" "}
                      <span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter ID"
                      name="commonid"
                      value={data.commonid}
                      onChange={handleChange}
                      required
                      disabled
                      isInvalid={data.commonid === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter{" "}
                      {data.invoicetype == "Return To Vendor"
                        ? "Vendor ID"
                        : data.invoicetype == "Other Company"
                          ? "Company ID"
                          : data.invoicetype == "Sub-Dealer"
                            ? "Sub-Dealer ID"
                            : data.invoicetype == "Franchise"
                              ? "Franchise ID"
                              : "Customer ID"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {data.invoicetype != "Customer" && (
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="gino">
                      <Form.Label>
                        GI No<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="gino"
                        value={data.gino}
                        onChange={handleChange}
                        isInvalid={data.gino === "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {issueGoodsAllData && issueGoodsAllData.length > 0 ? (
                          issueGoodsAllData.map((item, index) => {
                            const { gino, issuetoname } = item;
                            if (issuetoname === data.commonname) {
                              return (
                                <option key={index} value={gino}>
                                  {gino}
                                </option>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <option value="">
                            No GI NO. available on that Customer
                          </option>
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select GI No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                {data.invoicetype == "Customer" ? (
                  <Col md={3}>
                    {/* <Form.Group controlId="saleorderno">
                      <Form.Label>Sales Order No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Sales Order No"
                        name="saleorderno"
                        value={data.saleorderno}
                        onChange={handleChange}
                        required
                        disabled
                      // isInvalid={data.prefno === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Sales Order No
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group className="mb-3" controlId="saleorderno">
                      <Form.Label>
                        Sales Order No<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="saleorderno"
                        value={data.saleorderno}
                        onChange={handleChange}
                        onFocus={() =>
                          data.commonid == "" &&
                          notifyWarning("First Select Customer Name")
                        }
                        isInvalid={data.saleorderno === "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {salesOrderAllData && salesOrderAllData.length > 0 ? (
                          salesOrderAllData.map((item, index) => {
                            const { customerid, saleorderno } = item;
                            if (customerid === data.commonid) {
                              return (
                                <option key={index} value={saleorderno}>
                                  {saleorderno}
                                </option>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <option value="">
                            No Sales Order available on that Customer
                          </option>
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Sales Order No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ) : data.invoicetype == "Return To Vendor" ? (
                  <Col md={3}>
                    <Form.Group controlId="purchaseinvoiceno">
                      <Form.Label>Purchase Invoice No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Purchase Invoice No"
                        name="purchaseinvoiceno"
                        value={data.purchaseinvoiceno}
                        onChange={handleChange}
                        required
                        disabled
                      // isInvalid={data.prefno === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Purchase Invoice No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col md={3}>
                    <Form.Group controlId="purchaseorderno">
                      <Form.Label>Purchase Order No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Purchase Order No"
                        name="purchaseorderno"
                        value={data.purchaseorderno}
                        onChange={handleChange}
                        required
                        disabled
                      // isInvalid={data.prefno === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Purchase Order No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
                <Col md={3}>
                  <Form.Group controlId="invoiceno">
                    <Form.Label>Invoice No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Invoice No"
                      name="invoiceno"
                      value={data.invoiceno}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.prefno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Invoice No
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="invoicedate">
                    <Form.Label>
                      Invoice Date<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="invoicedate"
                      value={data.invoicedate}
                      min={nextDay.toISOString().split("T")[0]}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.invoicedate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Choose Invoice Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="paymentterms">
                    <Form.Label className="d-flex justify-content-between">
                      <span>
                        Payment Terms<span className="star"> *</span>
                      </span>
                      <TermsMaster
                        show={show}
                        setShow={setShow}
                        handleClose={handleClose}
                      />
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="paymentterms"
                      value={data.paymentterms}
                      onChange={handleChange}
                      isInvalid={data.paymentterms === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {paymentAllData && paymentAllData.length > 0 ? (
                        paymentAllData.map((item, index) => {
                          const { termname } = item;
                          return (
                            <option key={index} value={termname}>
                              {termname}
                            </option>
                          );
                          return null;
                        })
                      ) : (
                        <option value="">No Payment Terms Available</option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Payment Terms
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="paymentduedate">
                    <Form.Label>
                      Payment Due Date<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select Payment Due Date"
                      name="paymentduedate"
                      value={data.paymentduedate}
                      // min={`${new Date().toISOString().split("T")[0]}`}
                      // max={`${parseInt(finalYear[1].replace('"', ""),10)}-03-31`}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.paymentduedate === "" && showEmptyError}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Select Payment Due Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="transportertype">
                    <Form.Label>Transporter Type</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter transporter type"
                      name="transportertype"
                      value={data.transportertype}
                      onChange={handleChange}
                      required
                      disabled={data.invoicetype != "Customer"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Transporter Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="vehicleno">
                    <Form.Label>Vehicle No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Vehicle No."
                      name="vehicleno"
                      value={data.vehicleno}
                      onChange={handleChange}
                      required
                      disabled={data.invoicetype != "Customer"}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Vehicle No.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {data.invoicetype === "Customer" && (
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="salesperson">
                      <Form.Label>Sales Person</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Sales Person"
                        name="salesperson"
                        value={data.salesperson}
                        onChange={handleChange}
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Sales Person
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="ewaybillno">
                    <Form.Label>E-way Bill No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter E-way Bill No."
                      name="ewaybillno"
                      value={data.ewaybillno}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter E-way Bill No.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="einvoiceno">
                    <Form.Label>E-Invoice No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter E-Invoice No."
                      name="einvoiceno"
                      value={data.einvoiceno}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter E-Invoice No.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="createdby">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Created By"
                      name="createdby"
                      value={data.createdby}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter createdby
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="documents">
                    <Form.Label>Upload File</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Select File"
                      name="documents"
                      value={data.documents}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Upload Your File
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="remarks">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Remarks"
                      name="remarks"
                      value={data.remarks}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Remarks
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Label>
                    Account<span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="account"
                    value={data.account}
                    onChange={handleChange}
                    isInvalid={data.account === "" && showEmptyError}
                    className="form-control "
                  >
                    <option value="">--Select--</option>
                    {coaAllData && coaAllData.length > 0 ? (
                      Object.entries(
                        coaAllData.reduce((groups, item) => {
                          if (!groups[item.accounttype]) {
                            groups[item.accounttype] = [];
                          }
                          groups[item.accounttype].push(item.accountname);
                          return groups;
                        }, {})
                      ).map(([accounttype, accountname], index) => (
                        <optgroup key={index} label={accounttype}>
                          {accountname.map((name, nameIndex) => (
                            <option key={nameIndex} value={name}>
                              {name}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    ) : (
                      <option value="">No Account available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account
                  </Form.Control.Feedback>
                </Col>
                {data.invoicetype === "Customer" && (
                  <Col md={3}>
                    <Form.Group controlId="architect">
                      <Form.Label>Architect</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Architect"
                        name="architect"
                        value={data.architect}
                        onChange={handleChange}
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Architect
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
                {data.invoicetype === "Customer" && (
                  <Col md={3}>
                    <Form.Group controlId="contractor">
                      <Form.Label>Contractor</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Contractor"
                        name="contractor"
                        value={data.contractor}
                        onChange={handleChange}
                        required
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Contractor
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Form>
          </Col>
          <hr />
          <Col md={12}>
            <Row>
              <Col md={2}>
                <Form.Check
                  type="checkbox"
                  name="showgst"
                  label="Show GST"
                  checked={data.showgst}
                  onChange={handleChange}
                />
              </Col>
              <Col md={2}>
                <Form.Check
                  type="checkbox"
                  name="isfrightcharges"
                  label="Freight/Labor Charges"
                  checked={data.isfrightcharges}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
          <hr />
          <Col md={12} className="table-main-container ">
            <Table responsive>
              <thead className="tableContainer ">
                <tr>
                  <th>#</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Item Name</th>
                  <th>Item ID</th>
                  <th>UOM</th>
                  <th>Order QTY</th>
                  <th>Invoice QTY</th>
                  <th>Remaining QTY</th>
                  {!data.showgst && <th>Rate Incl. GST</th>}
                  <th>Rate</th>
                  {data.showgst && <th>GST%</th>}
                  <th>Dis %</th>
                  <th>Add. Dis %</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <DataSpinner count="15" />
                  </>
                ) : data?.itemarray?.length > 0 ? (
                  data.itemarray.map((row, index) => {
                    let {
                      asondate,
                      billingtype,
                      branchid,
                      brand,
                      category,
                      cessvaluationtype,
                      chartofaccount,
                      companyid,
                      dispatchqty,
                      qty,
                      orderqty,
                      invoiceqty,
                      remainingqty,
                      rateinclgst,
                      discount,
                      grade,
                      gst,
                      hsncode,
                      hsndescription,
                      id,
                      image,
                      inhandquantity,
                      inventoryaccount,
                      inventorydescription,
                      inventorytype,
                      itemdescription,
                      itemid,
                      itemname,
                      mpn,
                      openingqty,
                      org,
                      purchaseaccount,
                      purchasedescription,
                      rake,
                      rateperunit,
                      reorderpoint,
                      saleaccount,
                      saledescription,
                      size,
                      sku,
                      taxability,
                      thickness,
                      unit,
                      rate,
                      updatedprice,
                      userid,
                      value,
                      warehouse,
                      account,
                      amount,
                      additionaldiscount,
                    } = row;
                    return (
                      <tr key={index}>
                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="slno"
                              className="text-center"
                              name="slno"
                              value={index + 1}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              // isInvalid={data.prefno === "" && showEmptyError}
                              title={index + 1}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="category"
                              name="category"
                              value={category}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              // isInvalid={data.prefno === "" && showEmptyError}
                              title={category}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Brand"
                              name="brand"
                              value={brand}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={brand}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Item Name"
                              name="itemname"
                              value={itemname}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={itemname}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Item Id"
                              name="itemid"
                              value={itemid}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={itemid}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="UOM"
                              name="uom"
                              value={unit}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={unit}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="orderqty"
                              value={orderqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={orderqty}
                              className="text-center"
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="invoiceqty"
                              value={invoiceqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={invoiceqty}
                              className="text-center"
                              required
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="remainingqty"
                              value={remainingqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={remainingqty}
                              className="text-center"
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        {!data.showgst && (
                          <td>
                            <Form.Group controlId="formBasicId">
                              <Form.Control
                                type="number"
                                placeholder="Rate Incl GST"
                                name="rateinclgst"
                                value={
                                  (row.rateinclgst = (
                                    Number(row.rate) +
                                    (Number(row.rate) * Number(row.gst)) / 100
                                  ).toFixed(2))
                                }
                                onChange={(e) => handleTableRowChange(e, index)}
                                title={row.rateinclgst}
                                className="text-center"
                                required
                                disabled
                              />
                            </Form.Group>
                          </td>
                        )}

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="Rate"
                              name="rate"
                              value={rate}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={row.rate}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        {data.showgst && (
                          <td>
                            <Form.Group controlId="formBasicId">
                              <Form.Control
                                type="text"
                                placeholder="GST%"
                                name="gst"
                                value={gst}
                                onChange={(e) => handleTableRowChange(e, index)}
                                title={row.gst}
                                className="text-center"
                                required
                                disabled
                              />
                            </Form.Group>
                          </td>
                        )}

                        <td>
                          <Form.Control
                            type="number"
                            placeholder="0.00"
                            name="discount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={row.discount}
                            className="text-center"
                            title={row.discount}
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td>
                          <Form.Control
                            className="text-center"
                            type="number"
                            placeholder="0.00"
                            name="additionaldiscount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={row.additionaldiscount}
                            title={row.additionaldiscount}
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td>
                          <Form.Control
                            disabled
                            className="text-right"
                            type="number"
                            placeholder="0.00"
                            name="amount"
                            value={
                              data.showgst
                                ? (row.amount = (
                                  Number(invoiceqty) *
                                  (Number(rate) -
                                    (Number(rate) *
                                      (Number(discount || 0) +
                                        Number(additionaldiscount || 0))) /
                                    100)
                                ).toFixed(2))
                                : (row.amount = (
                                  Number(invoiceqty) *
                                  (Number(rateinclgst) -
                                    (Number(rateinclgst) *
                                      (Number(discount || 0) +
                                        Number(additionaldiscount || 0))) /
                                    100)
                                ).toFixed(2))
                            }
                            onChange={(e) => handleTableRowChange(e, index)}
                            title={row.amount}
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td className="d-flex align-items-center">
                          <span className="mt-3">
                            <MdDelete
                              className="deleteIcon table-delete"
                              onClick={() => handleTableRowDelete(index)}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecordFoud count="15" name="Items" />
                )}
              </tbody>
            </Table>
          </Col>
          <hr />
          <Col md={12} className="">
            <Row>
              <Col sm={4} className="my-auto">
                {data.showgst && (
                  <Col className="table-main-container">
                    <h3>GST Break Down :</h3>
                    <Table responsive>
                      <thead className="tableContainer">
                        <tr>
                          <th>CGST(%)</th>
                          <th>SGST(%)</th>
                          <th>IGST(%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.itemarray?.map((item, ind) =>
                          data.samestate ? (
                            <tr key={ind}>
                              <td>{Number(item.gst) / 2}</td>
                              <td>{Number(item.gst) / 2}</td>
                              <td>0</td>
                            </tr>
                          ) : (
                            <tr key={ind}>
                              <td>0</td>
                              <td>0</td>
                              <td>{Number(item.gst)}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                      {data.samestate ? (
                        <thead>
                          <tr>
                            <th>Total : {data.cgst}</th>
                            <th>{data.sgst}</th>
                            <th>{0}</th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr>
                            <th>Total : {0}</th>
                            <th>{0}</th>
                            <th>{data.igst}</th>
                          </tr>
                        </thead>
                      )}
                    </Table>
                  </Col>
                )}
              </Col>
              <Col className="">
                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Sub Total:</Form.Label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder="0.00"
                          name="subtotal"
                          value={Number(
                            (data.subtotal =
                              data.itemarray?.length > 0
                                ? data.itemarray?.reduce(
                                  (acc, curr) =>
                                    acc + (Number(curr.amount) || 0),
                                  0
                                )
                                : 0)
                          ).toFixed(2)}
                          onChange={handleChange}
                          title={data.subtotal}
                          required
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {data.isfrightcharges ? (
                  <Row className="mt-3">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>Freight/Labor Charges:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="frightcharges"
                            value={data.frightcharges}
                            onChange={handleChange}
                            title={data.frightcharges}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {data.showgst && data.samestate ? (
                  <Row className="mt-4">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>CGST:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="cgst"
                            disabled
                            // value={
                            //   data.showgst
                            //     ? (data.cgst = data.samestate
                            //       ? data.itemarray
                            //         ?.reduce((acc, curr) => {
                            //           // return acc + (curr.qty * ((curr.rate * curr.gst) / 100)) / 2
                            //           return (
                            //             acc +
                            //             (curr.amount * curr.gst) / 100 / 2
                            //           );
                            //         }, 0)
                            //         ?.toFixed(2)
                            //       : 0)
                            //     : (data.cgst = 0)
                            // }
                            value={
                              data.showgst
                                ? (data.cgst = data.samestate
                                  ? (function () {
                                    var itemGST = data.itemarray?.reduce((acc, curr) => {
                                      return acc + (curr.amount * curr.gst) / 100 / 2;
                                    }, 0) || 0;

                                    // Calculate the GST for the fright charges
                                    var frightGST = (data.frightcharges * 18) / 100 / 2;

                                    // Sum the two GST amounts and round to two decimal places
                                    return Number((itemGST + frightGST).toFixed(2));
                                  })()
                                  : 0)
                                : (data.cgst = 0)
                            }
                            onChange={handleChange}
                            title={data.cgst}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                {data.showgst && data.samestate ? (
                  <Row className="mt-3">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>SGST:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="sgst"
                            disabled
                            // value={
                            //   data.showgst
                            //     ? (data.sgst = data.samestate
                            //       ? data.itemarray
                            //         ?.reduce((acc, curr) => {
                            //           // return acc + (curr.qty * ((curr.rate * curr.gst) / 100)) / 2
                            //           return (
                            //             acc +
                            //             (curr.amount * curr.gst) / 100 / 2
                            //           );
                            //         }, 0)
                            //         ?.toFixed(2)
                            //       : 0)
                            //     : (data.sgst = 0)
                            // }
                            value={
                              data.showgst
                                ? (data.sgst = data.samestate
                                  ? (function () {
                                    var itemGST = data.itemarray?.reduce((acc, curr) => {
                                      return acc + (curr.amount * curr.gst) / 100 / 2;
                                    }, 0) || 0;

                                    // Calculate the GST for the fright charges
                                    var frightGST = (data.frightcharges * 18) / 100 / 2;

                                    // Sum the two GST amounts and round to two decimal places
                                    return Number((itemGST + frightGST).toFixed(2));
                                  })()
                                  : 0)
                                : (data.sgst = 0)
                            }
                            onChange={handleChange}
                            title={data.sgst}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                {(data.showgst && !data.samestate) && (
                  <Row className="mt-3">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>IGST:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="igst"
                            value={
                              data.showgst
                                ? (data.igst = data.samestate
                                  ? 0
                                  : (function () {
                                    var itemGST = data.itemarray?.reduce((acc, curr) => {
                                      return acc + (curr.amount * curr.gst) / 100;
                                    }, 0) || 0;

                                    // Calculate the GST for the fright charges
                                    var frightGST = (data.frightcharges * 18) / 100;

                                    // Sum the two GST amounts and round to two decimal places
                                    return Number((itemGST + frightGST).toFixed(2));
                                  })())
                                : (data.igst = 0)
                            }
                            disabled
                            onChange={handleChange}
                            title={data.igst}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          type="radio"
                          className="mt-3"
                          label="TCS"
                          name="tcs"
                          checked={data.tcs}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          className="mt-3"
                          label="TDS"
                          name="tds"
                          checked={data.tds}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={6}>
                        {data.tcs ? (
                          <Form.Group className="mb-3" controlId="tcsvalue">
                            <label htmlFor="tcs">TCS</label>
                            <Form.Select
                              aria-label="tcsvalue"
                              name="tcsvalue"
                              value={data.tcsvalue}
                              onChange={handleChange}
                              title={data.tcsvalue}
                              // onChange={(e) => SetTdsPercent(+e.target.value)}
                              disabled
                            >
                              <option value="">--Select--</option>
                              {tcsMaster?.map((tcs) => {
                                return (
                                  <option key={tcs.id} value={tcs.taxname}>
                                    {`${tcs.taxname}[${tcs.ratepercent}]`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : data.tds ? (
                          <Form.Group className="mb-3" controlId="tdsvalue">
                            <label htmlFor="tds">TDS</label>
                            <Form.Select
                              aria-label="tdsvalue"
                              name="tdsvalue"
                              value={data.tdsvalue}
                              onChange={handleChange}
                              title={data.tdsvalue}
                              // onChange={(e) => setTcsPercent(+e.target.value)}
                              disabled
                            >
                              <option value="">--Select--</option>
                              {tdsMaster?.map((tds) => {
                                return (
                                  <option key={tds.id} value={tds.taxname}>
                                    {`${tds.taxname} [${tds.ratepercent}]`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          name="tsamount"
                          value={data.tsamount}
                          onChange={handleChange}
                          title={data.tsamount}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Adjustment:</Form.Label>
                      </Col>
                      <Col>
                        <Col>
                          <OverlayTrigger
                            className="mt-5"
                            placement="right"
                            overlay={renderTooltip(
                              "Add any other +ve or -ve charges that need to be applied to adjust the total amount of the transaction. Eg. +10 or -10"
                            )}
                          >
                            <span className="question-icon">
                              <AiOutlineQuestionCircle />
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Col>
                      <Col></Col>
                      <Col>
                        <Form.Control
                          type="number"
                          name="adjustmentamount"
                          value={data.adjustmentamount}
                          onChange={handleChange}
                          title={data.adjustmentamount}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Grand Total:</Form.Label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                      {/* Number((Number(data.subtotal) + Number(data.cgst)  + Number(data.sgst)  + Number(data.igst)  + Number(data.tsamount) + Number(data.adjustment) + Number(data.frightcharges)))?.toFixed(2) */}
                      <Col>
                        <Form.Control
                          type="number"
                          name="grandtotal"
                          disabled
                          value={
                            data.showgst
                              ? (data.grandtotal = (() => {
                                let grandtotal = Number(
                                  Number(data.subtotal) +
                                  Number(data.cgst) +
                                  Number(data.sgst) +
                                  Number(data.igst) +
                                  Number(data.tsamount) +
                                  Number(data.adjustmentamount) +
                                  Number(data.frightcharges)
                                )?.toFixed(2);
                                let decimalpart =
                                  grandtotal - Math.floor(grandtotal);
                                if (decimalpart > 0.5) {
                                  grandtotal = Math.ceil(grandtotal);
                                } else {
                                  grandtotal = Math.floor(grandtotal);
                                }
                                return grandtotal;
                              })())
                              : (data.grandtotal = (() => {
                                let grandtotal = Number(
                                  Number(data.subtotal) +
                                  Number(data.tsamount) +
                                  Number(data.frightcharges) +
                                  Number(data.adjustmentamount)
                                )?.toFixed(2);
                                let decimalpart =
                                  grandtotal - Math.floor(grandtotal);
                                if (decimalpart > 0.5) {
                                  grandtotal = Math.ceil(grandtotal);
                                } else {
                                  grandtotal = Math.floor(grandtotal);
                                }
                                return grandtotal;
                              })())
                          }
                          title={data.grandtotal}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <hr />
          <Row md={12}>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="tandc">
                <Form.Label>Terms and Conditions</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  name="tandc"
                  value={data.tandc = sitandc}
                  disabled
                // isInvalid={data.description === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Terms and Conditions
                </Form.Control.Feedback>
                {/* <Form.Check
                inline
                type="checkbox"
                name="istandc"
                label="Use this in future for all Sales Order."
                value="Yes"
                checked={data.istandc === "Yes"}
                onChange={handleChange}
              /> */}
                {/* <Form.Label>
              
            </Form.Label> */}
              </Form.Group>
            </Col>
            <Col md={6}>
              {userid == "saanvi" && (
                <Col
                  md={3}
                  className="d-flex justify-content-center gap-3"
                  style={{ marginLeft: "21px" }}
                >
                  <input
                    type="file"
                    onChange={(e) => setSignature(e.target.files[0])}
                    style={{ fontSize: "14px" }}
                  />
                  {/* <Button style={{height:"30px"}} onClick={handleSignatureUpload}>upload</Button>   */}
                  {signature && (
                    <FaCloudUploadAlt
                      style={{
                        height: "20px",
                        width: "170px",
                        cursor: "pointer",
                      }}
                      onClick={handleSignatureUpload}
                    />
                  )}
                </Col>
              )}
              <Col md={3} className="m-4">
                <img
                  src={signatureFromCompany}
                  alt=""
                  style={{ width: "180px", height: "105px" }}
                />
                <hr></hr>
                <h3>Signature</h3>
              </Col>
            </Col>
          </Row>
          <hr />
          <Col sm={12} className="d-flex gap-3">
            <span className="w-15">
              <Button className="commonBtn" onClick={handleSaveAsApproved}>
                {loadData ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Save as Approve"
                )}
              </Button>
            </span>
            <span className="w-15">
              <Button
                className="commonBtn cancelBtn"
                onClick={handleSaveAsDraft}
              >
                Save as Draft
              </Button>
            </span>
            <span>
              <Button
                className="commonBtn smallBtn cancelBtn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AddSalesInvoice;
