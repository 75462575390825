import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  validateBankAccountNumber,
  validateIFSCCode,
} from "../../../constants/validations";
import { notifyError } from "../../../constants/toastAlert";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { addBank, bankAutoCount, bankPagiData } from "../../../redux/actions/bank.actions";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import axios from "axios";
import { BankPagiDataPost } from "../../../constants/api";

const AddBank = () => {
  const URL = extractAndFormatURLPart("banking");
  const navigate = useNavigate();

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { bankCount } = useSelector((state) => state.bankData) || 0;
  const { data: coaAllData } =
    useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const cashAndCashEquivalent = coaAllData && coaAllData.filter((item) => item.accounttype === "Cash and Cash equivalents");
  // const { data: bankData } = useSelector((state) => state.bankData.bankPagiData) || [];
  // const bankAllData = !bankData ? [] : bankData;

  //state
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    bank: "",
    bankid: "",
    accountnumber: "",
    branchname: "",
    ifsccode: "",
    address: "",
    accounttype: "",
    account: "",
    org: org,
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  });

  //default data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  };

  useEffect(() => {
    setLoading(!loading);
    // dispatch(bankAutoCount(backendData, setLoading));
    // dispatch(bankPagiData(backendData, setLoading));
    dispatch(coaPagiData(backendData, setLoading));

    // let count = +bankCount;
    // let count = autoGenerateID();
    // let paddedCount = count.toString().padStart(4, "0");
    // let result = `BANK/SAANVI/${paddedCount}`;

    axios.post(BankPagiDataPost, backendData)
      .then((res) => {
        let currentCount = res.data && res.data.data && res.data.data.length > 0 ?
          String(parseInt(res.data.data[0].bankid.split("/")[2]) + 1)
          : "1";
        let paddedCount = currentCount.toString().padStart(4, "0");
        let result = `BANK/SAANVI/${paddedCount}`;
        setData({
          ...data,
          bankid: result,
        });
      })
      .catch(e => console.log(e));

  }, [dispatch, loadData, bankCount]);

  const handleLeftArrow = () => {
    navigate("/banking/bank");
  };

  const handleCancel = () => {
    navigate("/banking/bank");
  };

  const handleSave = async () => {
    if (
      data.bank == "" ||
      data.accountnumber == "" ||
      data.branchname == "" ||
      data.ifsccode == "" ||
      data.address == "" ||
      data.accounttype == "" ||
      data.account == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    let count = 0;
    const validateAndNotify = (field, validator) => {
      if (data[field]) {
        const validationMsg = validator(data[field]);
        if (validationMsg !== "Done") {
          count++;
          return notifyError(validationMsg);
        }
      }
    };

    validateAndNotify("accountnumber", validateBankAccountNumber);
    validateAndNotify("ifsccode", validateIFSCCode);

    if (count > 0) return;

    dispatch(addBank(data, handleCancel));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setShowEmptyError(false);
  };

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="form-container">
        <Col sm={12}>
          <h1>
            <FaArrowLeftLong className="left-arrow" onClick={handleLeftArrow} />
            Add <span className="form-title"> Bank</span>
          </h1>
        </Col>
        <hr />
        <Col sm={12}>
          <Form className="form-lable-name">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Bank<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Bank Name"
                    name="bank"
                    value={data.bank}
                    onChange={handleChange}
                    isInvalid={data.bank === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Bank Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Bank ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="bankid"
                    value={data.bankid}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account Number<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Account Number"
                    name="accountnumber"
                    value={data.accountnumber}
                    onChange={handleChange}
                    isInvalid={data.accountnumber === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Account Number
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Branch Name<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Branch Name"
                    name="branchname"
                    value={data.branchname}
                    onChange={handleChange}
                    isInvalid={data.branchname === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Branch Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    IFSC Code<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter IFSC Code"
                    name="ifsccode"
                    value={data.ifsccode}
                    onChange={handleChange}
                    isInvalid={data.ifsccode === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter IFSC Code
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Address<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                    isInvalid={data.address === "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Bank Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account Type<span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Account Type"
                    name="accounttype"
                    value={data.accounttype}
                    onChange={handleChange}
                    isInvalid={data.accounttype === "" && showEmptyError}
                  >
                    <option value="">Select</option>
                    <option value="Cash Credit">Cash Credit</option>
                    <option value="Overdraft">Overdraft</option>
                    <option value="Current Account">Current Account</option>
                    <option value="Savings Account">Savings Account</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account Type
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Account<span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Account"
                    name="account"
                    value={data.account}
                    onChange={handleChange}
                    isInvalid={data.account === "" && showEmptyError}
                  >
                    <option value="">Select</option>
                    {cashAndCashEquivalent &&
                      cashAndCashEquivalent.length > 0 ? (
                      cashAndCashEquivalent.map((item, index) => (
                        <option key={index} value={item.accountname}>
                          {item.accountname}
                        </option>
                      ))
                    ) : (
                      <option value="">No Accounts available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Col>
        <hr />
        <Col sm={12} className="d-flex justify-content-start gap-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AddBank;
