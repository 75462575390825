import axios from "axios";
import { issueGoodgetPagePost } from "../../constants/api";
import { notifyError, notifyWarning } from "../../constants/toastAlert";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { FETCH_INVOICE_OR_CHALLAN, FETCH_ISSUED_ITEM } from "../actionTypes/mtout.actiontypes";

//Get all issued item data using pagination wise
export const issueGoodPagiData = (backendData, setLoading) => async (dispatch) => {
    try {
      const res = await axios.post(issueGoodgetPagePost, backendData);
      const initialData =
        res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
      if (initialData) {
        dispatch({ type: FETCH_ISSUED_ITEM, payload: initialData });
      } else {
        notifyError(res.data.message);
      }
      dispatch({ type: RELOAD, payload: false });
      setLoading(false);
    } catch (error) {
      console.log(`Error in fetching item data ${error}`);
      notifyError(error.message);
    }
  };

//Action dispatcher for document upload
export const invoiceOrChallanUpload = (backendData) => async (dispatch) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < backendData.length; i++) {
        formData.append("file", backendData[i]);
      }
      const res = await axios.post(

        "https://mis.saanviavs.com/api/v1/item/fileupload",

        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      if (res.data.files.length > 0) {
        const fileurl = res.data.files;
        dispatch({ type: FETCH_INVOICE_OR_CHALLAN, payload: fileurl });
      } else {
        notifyWarning("INV/Challan not uploaded");
      }
      dispatch({ type: RELOAD, payload: true });
    } catch (error) {
      console.error("Error uploading INV/Challan:", error.message);
      throw error;
      notifyError(error.message);
    }
  };