import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { FaArrowLeftLong } from "react-icons/fa6";
import { purchaseTargetGetById } from "../../../constants/api";
import axios from "axios";
import { notifyError } from "../../../constants/toastAlert";
import { companyData } from "../../../redux/actions/company.actions";
import { IoAddCircleSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import {  updatePurchaseTarget } from "../../../redux/actions/purchaseTarget.actions";

const EditPurchaseTarget = () => {
  const { id } = useParams();
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const URL = extractAndFormatURLPart("purchase");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];
  const companyMaster =
    useSelector((state) => state.companyData.companyData.data) || [];

  const [itemMaster, setItemMaster] = useState([]);
  const [categoryMaster, setCategoryMaster] = useState([]);
  const [brandMaster, setBrandMaster] = useState([]);

  const [data, setData] = useState({
    vendorname: "",
    schemeno: "",
    schemename: "",
    createdfor: "",
    validfrom: "",
    validupto: "",
    targetarray: [],
    branchid: branchid,
    companyid: companyid,
    userid: userid,
    org: "₹ΘθϖΠ",
    financialyear: finalcialYear,
    targettype: "",
    id:id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "targettype") {
      setData((prev) => ({ ...prev, [name]: value, targetarray: [] }));
    }
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedTargetArr = data.targetarray.map((item, i) =>
      i == ind ? { ...item, [name]: value } : item
    );

    setData((prev) => ({
      ...prev,
      targetarray: updatedTargetArr,
    }));
  };

  const handleSave = () => {
    if (
      !data.createdfor ||
      !data.schemename ||
      !data.vendorname ||
      !data.schemeno ||
      !data.targettype ||
      !data.validfrom ||
      !data.validupto
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    if (data.targettype && data.targetarray.length == 0) {
      notifyError("Please add a target before submitting form");
      return;
    }

    dispatch(updatePurchaseTarget(data, navigate));
  };

  const handleAddRow = () => {
    if (data.targettype == "Category") {
      let obj = {
        category: "",
        brand: "",
        setby: "Amount",
        tier1: "",
        tier2: "",
        tier3: "",
      };
      setData((prev) => ({ ...prev, targetarray: [...data.targetarray, obj] }));
    }
    if (data.targettype == "Item") {
      let obj = {
        item: "",
        brand: "",
        setby: "Amount",
        tier1: "",
        tier2: "",
        tier3: "",
      };
      setData((prev) => ({ ...prev, targetarray: [...data.targetarray, obj] }));
    }
  };

  const handleDeleteRow = (index) => {
    let filtereTargetArr = data.targetarray.filter(
      (item, ind) => ind !== index
    );

    setData((prevData) => ({
      ...prevData,
      targetarray: filtereTargetArr,
    }));
  };

  const fetchData = async () => {
    try {
      const res = await axios.post(purchaseTargetGetById,{id});
      if(res.data.length>0){
        setData((prev)=>({
          ...prev,
          ...res.data[0]
        }))
      }else{
        setData((prev)=>({...prev}))
      }
    } catch (error) {
      console.log(error);
      return notifyError(error.message);
    }
  };

  const fetchItem = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/getitem", {

        userid,
        branchid,
        companyid,
        keyword: "",
      });
      res.data.data ? setItemMaster(res.data.data) : setItemMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCategory = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/category", {

        userid,
        branchid,
        companyid,
        keyword: "",
      });
      res.data.data ? setCategoryMaster(res.data.data) : setCategoryMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBrand = async () => {
    try {

      const res = await axios.post("https://mis.saanviavs.com/api/v1/item/brand", {

        userid,
        branchid,
        companyid,
        keyword: "",
      });
      res.data ? setBrandMaster(res.data) : setBrandMaster([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchItem();
    fetchBrand();
    fetchCategory();
  }, []);

  useEffect(() => {
    setLoading(!loading);
    dispatch(vendorData({ companyid, userid,keyword:"" }, setLoading));
    dispatch(
      companyData({ companyid, userid, branchid, keyword: "" }, setLoading)
    );
  }, [dispatch, loadData]);

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <div className="form-container">
        <Form className="form-lable-name">
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/purchase/purchaseTarget/")}
            />
            Edit <span className="form-title main"> Purchase Target </span>
          </h1>
          <hr />
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Vendor <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="vendorname"
                            value={data.vendorname}
                            onChange={handleChange}
                            isInvalid={!data.vendorname && showEmptyError}
                          >
                            <option value="">Select</option>
                            {vendorMaster?.map((vendor) => {
                              return (
                                <option value={vendor.fullname} key={vendor.id}>
                                  {vendor.fullname}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Scheme No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="schemeno"
                            value={data.schemeno}
                            isInvalid={!data.schemeno && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Scheme Name<span className="star"> *</span>
                      </Form.Label>
                      <Form.Control
                        placeholder="Enter Scheme Name"
                        type="text"
                        name="schemename"
                        value={data.schemename}
                        onChange={handleChange}
                        isInvalid={!data.schemename && showEmptyError}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Created For <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="createdfor"
                            value={data.createdfor}
                            onChange={handleChange}
                            isInvalid={!data.createdfor && showEmptyError}
                          >
                            <option value="">Select</option>
                            {companyMaster?.map((company) => {
                              return (
                                <option
                                  value={company.companyname}
                                  key={company.id}
                                >
                                  {company.companyname}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Valid From<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="validfrom"
                            value={data.validfrom}
                            onChange={handleChange}
                            isInvalid={!data.validfrom && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Valid Upto<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="validupto"
                            value={data.validupto}
                            onChange={handleChange}
                            isInvalid={!data.validupto && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Apply Purchase Target On
                            <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="targettype"
                            value={data.targettype}
                            onChange={handleChange}
                            isInvalid={!data.targettype && showEmptyError}
                          >
                            <option value="">Select</option>
                            <option value="Category">Category</option>
                            <option value="Item">Item</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </Form>
        <br />
        {data.targettype ? (
          <Col md={12} className="table-main-container">
            <Table responsive>
              <thead className="tableContainer">
                <tr>
                  <th>SI No.</th>
                  <th>{data.targettype}</th>
                  <th>Brand</th>
                  <th>Set By</th>
                  <th>Tier I</th>
                  <th>Tier II</th>
                  <th>Tier III</th>
                </tr>
              </thead>
              <tbody>
                {data.targetarray.map((target, ind) => (
                  <tr key={ind}>
                    <td>
                      <Form.Control
                        type="text"
                        disabled
                        value={ind + 1}
                        className="form-control"
                      />
                    </td>
                    {data.targettype != "Category" ? (
                      <td>
                        <Form.Select
                          name="item"
                          value={target.item}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!target.item && showEmptyError}
                          className="form-control "
                        >
                          <option value=""> Select </option>
                          {itemMaster?.map((item) => (
                            <option key={item.id} value={item.itemname}>
                              {item.itemname}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    ) : (
                      <td>
                        <Form.Select
                          name="category"
                          value={target.category}
                          onChange={(e) => handleTableRowChange(e, ind)}
                          isInvalid={!target.category && showEmptyError}
                          className="form-control "
                        >
                          <option value=""> Select </option>
                          {categoryMaster?.map((category, i) => (
                            <option key={i} value={category.category}>
                              {category.category}
                            </option>
                          ))}
                        </Form.Select>
                      </td>
                    )}
                    <td>
                      <Form.Select
                        name="brand"
                        value={target.brand}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.brand && showEmptyError}
                        className="form-control "
                      >
                        <option value=""> Select </option>
                        {brandMaster?.map((brand, i) => (
                          <option key={i} value={brand.brand}>
                            {brand.brand}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        name="setby"
                        value={target.setby}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.setby && showEmptyError}
                        className="form-control"
                      >
                        <option value="Amount"> Amount </option>
                        <option value="Qty"> Qty </option>
                      </Form.Select>
                    </td>
                    <td>
                      {" "}
                      <Form.Control
                        type="number"
                        name="tier1"
                        value={target.tier1}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.tier1 && showEmptyError}
                      />
                    </td>
                    <td>
                      {" "}
                      <Form.Control
                        type="number"
                        name="tier2"
                        value={target.tier2}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.tier2 && showEmptyError}
                      />
                    </td>
                    <td className="d-flex gap-2">
                      <Form.Control
                        type="number"
                        name="tier3"
                        value={target.tier3}
                        onChange={(e) => handleTableRowChange(e, ind)}
                        isInvalid={!target.tier3 && showEmptyError}
                      />
                      <MdDelete
                        onClick={() => handleDeleteRow(ind)}
                        className="fs-1 text-danger"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <span
              className="fs-4 custom-cursor text-warning"
              onClick={handleAddRow}
            >
              Add New Target {data.targettype} <IoAddCircleSharp />
            </span>
          </Col>
        ) : null}
        <hr />
        <Modal.Footer className="d-flex justify-content-start gap-2 mt-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/purchase/purchaseTarget")}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </div>
    </Container>
  );
};

export default EditPurchaseTarget;
