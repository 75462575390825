import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import FilterField from "../../../constants/FilterField";
import AddPageButton from "../../../constants/AddPageButton";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import DataSpinner from "../../../constants/DataSpinner";
import { useDispatch, useSelector } from "react-redux";
import { itemDeleteItemPost, itemGetItemPost } from "../../../constants/api";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import GlobalPagination from "../../../constants/GlobalPagination";
import ViewModal from "../../../constants/ViewModal";
import ViewItem from "./ViewItem";
import { MdModeEditOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import EditItem from "./EditItem";

const ListItem = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  let org = localData.org;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadData = useSelector((state) => state.loadReducer);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [brand, setBrand] = useState([{ className: "", value: "", text: "" }]);
  const [categories, setCategories] = useState([
    { className: "", value: "", text: "" },
  ]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesFromAPI, setTotalPagesFromAPI] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [itemId, setItemid] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  const TableHeadings = [
    "Item No",
    "Item Name",
    "Category",
    "Brand",
    "Series",
    "Unit",
    "In Hand Stock",
    "Reorder Point",
    "Action",
  ];

  const handleShow = (item) => {
    const { id, itemid } = item;
    setShow(true);
    setItemid(id);
    setSelectedItemNo(itemid);
  };
  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(itemGetItemPost, {
          page: currentPage,
          pageSize: pageSize,
          userid: userID,
          branchid: branchID,
          companyid: companyID,
          keyword: searchKeyword || selectedBrand || selectedCategory || org,
        });
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];

        setData(initialData);
        const uniqueBrands = [...new Set(initialData.map((ele) => ele.brand))];
        setBrand(
          uniqueBrands.map((brand) => {
            return {
              className: "",
              value: brand,
              text: brand,
            };
          })
        );
        setTotalPagesFromAPI({
          totalPages: res.data.totalPages ? res.data.totalPages : 0,
          totalRecords: res.data.totalItems ? res.data.totalItems : 0,
        });
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    //
    //dynamic category generate
    axios

      .post("https://mis.saanviavs.com/api/v1/item/category", {
        userid: userID,
        companyid: companyID,
        branchid: branchID,
        keyword: "" || org,
      })

      .then((res) => {
        setCategories(
          res.data &&
            res.data.data.map((cat) => {
              return {
                className: "",
                value: cat.category,
                text: cat.category,
              };
            })
        );
      })
      .catch((err) => console.log(err));
  }, [
    loadData,
    pageSize,
    currentPage,
    searchKeyword,
    selectedBrand,
    selectedCategory,
  ]);

  // const filteredData = !data
  //   ? []
  //   : data
  //       // .filter((item) =>
  //       //   searchKeyword
  //       //     ? Object.values(item).some((value) =>
  //       //         value
  //       //           ?.toString()
  //       //           ?.toLowerCase()
  //       //           ?.includes(searchKeyword.toLowerCase().trim())
  //       //       )
  //       //     : true
  //       // )
  //       .filter((item) => {
  //         if (selectedBrand == "") {
  //           return true;
  //         } else {
  //           return item.brand === selectedBrand;
  //         }
  //       })
  //       .filter((item) => {
  //         if (selectedCategory == "") {
  //           return true;
  //         } else {
  //           return item.category === selectedCategory;
  //         }
  //       });
  const handleEdit = (id) => {
    navigate(`/inventory/item/editItem/${id}`);
  };

  const handleDelete = () => {
    const deleteData = async () => {
      try {
        const res = await axios.post(itemDeleteItemPost, {
          id: itemId,
          itemid: selectedItemNo,
          companyid: companyID,
          userid: userID,
          org: org,
        });

        if (res.data.message == "Item deleted successfully") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyError("Error Deleting user");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    deleteData();
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {
        userid: userID,
        submodule: "Items",
        companyid: companyID,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={4}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Items</span>
            </p>
          </Col>
          <Col lg={2}>
            <FilterField
              label="Brand"
              islable={true}
              emptyValue="All"
              selectedFilterValue={selectedBrand}
              handleSelectedFilter={(e) => setSelectedBrand(e.target.value)}
              filterWords={brand}
            />
          </Col>
          <Col lg={2}>
            <FilterField
              label="Category"
              islable={true}
              emptyValue="All"
              selectedFilterValue={selectedCategory}
              handleSelectedFilter={(e) => setSelectedCategory(e.target.value)}
              filterWords={categories}
            />
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <AddPageButton text="Item" link={`/inventory/item/addItem`} />
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading data={TableHeadings} />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="9" />
            ) : data.length > 0 ? (
              data.map((item, index) => (
                <tr key={index}>
                  <td>{item.itemid}</td>
                  <td>{item.itemname}</td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td>{item.series}</td>
                  <td>{item.unit}</td>
                  <td>{item.inhandquantity}</td>
                  <td>{item.reorderpoint}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <ViewItem item={item} />
                      {crud && crud.includes("u") && (
                        // <MdModeEditOutline
                        //   className="editIcon mr-2"
                        //   onClick={() => handleEdit(item.id)}
                        // />
                        <EditItem id={item.id}/>
                      )}
                      {crud && crud.includes("d") && (
                        <DeleteConfirmation
                          show={show}
                          handleShow={() => handleShow(item)}
                          handleClose={() => setShow(false)}
                          handleDelete={handleDelete}
                          name="Item"
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="9" name="Items" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListItem;
