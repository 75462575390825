import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import { TiAttachmentOutline } from "react-icons/ti";
import { BiSolidFileExport } from "react-icons/bi";
import { salesInvoicePagiData } from "../../../redux/actions/salesInvoice.actions";
import ViewDeliveryReceipt from "./ViewDeliveryReceipt";
import { RiSendPlane2Fill } from "react-icons/ri";
import copy from "copy-to-clipboard";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";
import * as XLSX from "xlsx";

const ListTableDeliveryReceipt = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const data = useSelector(
    (state) => state.salesInvoiceData.salesInvoicePagiData
  ) || [];

  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed

  const backendData = {
    companyid: companyid,
    userid: userid,
    keyword: searchKeyword || "₹ΘθϖΠ",
    page: currentPage,
    pageSize: pageSize,
  };

 

  const handleShow = (id) => {
    setShow(true);
    setSelectedItemId(id);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCopyLink=async(id)=>{
 
    const linkToCopy = `http://localhost:3000/deliveryReceipt/signatureRequired/${id}`;
    copy(linkToCopy);
    notifySuccess("Link copied to clipboard!");

    try {
      await navigator.share({ url: linkToCopy });
      // notifySuccess("Link shared successfully!");
    } catch (error) {
      console.error("Error sharing link:", error);
      notifyError("Failed to share link. Please try again.");
    }

  }

  const handleExport=()=>{

    if (filteredData.length == 0) {
       notifyError(
        "No Sales Invoice Available to export"
      );
      return;
    } else {
      const items = filteredData.map((item, ind) => ({
        "SI NO": ind + 1,
        "Invoice No": item.invoiceno,
        "Invoice Date": item.invoicedate,
        "Invoice Type": item.invoicetype,
        "Name": item.commonname,
        "Transporter Type": item.transportertype,
        "Transporter Type":"N/A",
        "Amount":item.grandtotal

      }));

      const worksheet = XLSX.utils.json_to_sheet(items);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Delivery Receipts");
      XLSX.writeFile(workbook, "Delivery Receipts.xlsx");
    }

  }


  useEffect(() => {
    setLoading(!loading);
    dispatch(salesInvoicePagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);



  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Delivery Receipts</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            <Button className="commonBtn mt-2" onClick={handleExport}>
              <BiSolidFileExport /> Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Customer Type</th>
              <th>Name</th>
              <th>Invoice No.</th>
              <th>Sale Order No.</th>
              <th>Transporter Type</th>
              <th>Transporter</th>
              <th>Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="9" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  status,
                  invoicetype,
                  invoicedate,
                  commonname,
                  invoiceno,
                  saleorderno,
                  transportertype,transpotername,
                  grandtotal,
                } = item;
                return (
                  <tr key={index} className="bg-red">
                    <td>{!invoicedate ? "--" : invoicedate}</td>
                    <td>{!invoicetype ? "--" : invoicetype}</td>
                    <td>{!commonname ? "--" : commonname}</td>
                    <td>{!invoiceno ? "--" : invoiceno}</td>
                    <td>{!saleorderno ? "--" : saleorderno}</td>
                    <td>{!transportertype ? "--" : transportertype}</td>
                    <td>{!transpotername?"--":transpotername}</td>
                    <td>{!grandtotal ? "--" : grandtotal}</td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <div >
                        <ViewDeliveryReceipt item={item} />
                      </div>
                      <div className="text-warning" onClick={()=>handleCopyLink(item.id)}>
                        <TiAttachmentOutline />
                      </div>
                      <div className="text-primary">
                        <RiSendPlane2Fill />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="9">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableDeliveryReceipt;
