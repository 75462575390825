import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { branchPagiData } from "../../../../redux/actions/branch.action";
import { notifyError, notifyWarning } from "../../../../constants/toastAlert";
import { updateContraEntry } from "../../../../redux/actions/contraEntry.actions";

const refCheck = ["Bank To Bank", "Cash Withdrawl", "Cash Deposit"];
const remarksCheck = ["Cash To Cash(Send)", "Cash To Cash(Receive)"];

const EditContraForm = ({
    showEditModal,
    handleEditModal,
    editItem,
    handleCloseEditModal,
}) => {
    const finalcialYear = localStorage.getItem("financialyear");
    const finalYear = finalcialYear.trim().split("-");
    const firstFinalcialYear = finalYear[0].replace('"', '')
    const secondFinalcialYear = finalYear[1].replace('"', '')

    //Redux part
    const dispatch = useDispatch();
    const loadData = useSelector((state) => state.loadReducer);
    const { companyid, userid, companyname, branchid, org } = useSelector((state) => state.authManager.userData.data[0]) || {};
    const { paymentCount } = useSelector((state) => state.paymentData) || 0;
    const { data: bankAllData } = useSelector((state) => state.bankData.bankPagiData) || [];
    const { data: branchAllData } = useSelector((state) => state.branchData.branchPagiData) || [];

    // filter data
    let bankArr = bankAllData && bankAllData.map(ele => `${ele.bank},${ele.branchname},${ele.accountnumber}`);
    let branchArr = branchAllData && branchAllData.map(ele => `${ele.branchname}`);
    let cashArr = ["Saanvi Cash Account"];
    // setBankArr([...temp]);

    //state
    const paymentData = ["Cash", "Bank Transfer", "Cheque", "UPI"];
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [allPaidThrough, setAllPaidThrough] = useState([]);
    const [bankdata, setBankdata] = useState([]);
    const [paidTodata, setPaidToData] = useState([]);

    const [siteArr, setSiteArr] = useState([]);
    // const [bankArr, setBankArr] = useState([]);
    // const [cashArr, setCashArr] = useState([]);

    const [toArray, setToArray] = useState([]);
    const [fromArray, setFromArray] = useState([]);

    const [isrefnoMandatory, setIsrefnoMandatory] = useState(false);
    const [isRemarksMandatory, setIsRemarksMandatory] = useState(false);
    const [loadState, setLoadState] = useState(false);
    // const [toArray, setToArray] = useState([]);
    // const [fromArray, setFromArray] = useState([]);

    // const[isrefnoMandatory,setIsrefnoMandatory] = useState(false);
    // const[isRemarksMandatory,setIsRemarksMandatory] = useState(false);
    const [data, setData] = useState({
        id: editItem ? editItem.id : "",
        contraid: editItem ? editItem.contraid : "",
        date: editItem ? editItem.date : "",
        month: editItem ? editItem.month : "",
        contratype: editItem ? editItem.contratype : "",
        amount: editItem ? editItem.amount : "",
        prefno: editItem ? editItem.prefno : "",
        from: editItem ? editItem.from : "",
        to: editItem ? editItem.to : "",
        remark: editItem ? editItem.remark : "",
        branchid: editItem ? editItem.branchid : "",
        userid: editItem ? editItem.userid : "",
        companyid: editItem ? editItem.companyid : "",
        org: editItem ? editItem.org : "",
        financialyear: editItem ? editItem.financialyear : "",
    });

    const fetchEditData = () => {
        setData({
            id: editItem ? editItem.id : "",
            contraid: editItem ? editItem.contraid : "",
            date: editItem ? editItem.date : "",
            month: editItem ? editItem.month : "",
            contratype: editItem ? editItem.contratype : "",
            amount: editItem ? editItem.amount : "",
            prefno: editItem ? editItem.prefno : "",
            from: editItem ? editItem.from : "",
            to: editItem ? editItem.to : "",
            remark: editItem ? editItem.remark : "",
            branchid: editItem ? editItem.branchid : "",
            userid: editItem ? editItem.userid : "",
            companyid: editItem ? editItem.companyid : "",
            org: editItem ? editItem.org : "",
            financialyear: editItem ? editItem.financialyear : "",
        });
    };

    //default data backend needed
    const backendData = {
        keyword: "₹ΘθϖΠ",
        userid: userid,
        companyid: companyid,
        branchid: branchid
    }


    useEffect(() => {
        setLoading(!loading);
        dispatch(bankPagiData(backendData, setLoading));
        dispatch(branchPagiData(backendData, setLoading));
        fetchEditData();
    }, [show, loadState, editItem]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "contratype") {
            setData((prevState) => ({
                ...prevState,
                from: "",
                to: "",
            }));
        }

        if (name == "date") {
            const dateObj = new Date(value);
            const month = dateObj.toLocaleString('default', { month: 'long' });
            setData((prev) => ({
                ...prev,
                month: month,
            }))
        }


        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            data.contratype == "" ||
            data.amount == "" ||
            data.date == "" ||
            data.from == "" ||
            data.to == ""
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        }
        if (data.contratype == "Bank To Bank" && data.from == data.to) {
            return notifyWarning("Sender and Receive account can not be the same");
        }

        if (
            (refCheck.includes(data.contratype) && data.prefno == "") ||
            (remarksCheck.includes(data.contratype) && data.remark == "")
        ) {
            setShowEmptyError(true);
            return notifyError("You have empty field");
        } else {
            dispatch(updateContraEntry(data, handleCloseEditModal))
        }
    };



    return (
        <>
            <MdEdit className="editIcon" onClick={handleEditModal} />
            <Modal
                size="xl"
                show={showEditModal}
                onHide={handleCloseEditModal}
                backdrop="static"
                keyboard={false}
                centered={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit <span className="form-title">Contra Entry</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="form-lable-name">
                        <Container fluid>
                            <Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formBasicId">
                                            <Form.Label>Contra No.</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Contra No."
                                                name="contraid"
                                                value={data.contraid}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                Contra type<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="contratype"
                                                onChange={handleChange}
                                                value={data.contratype}
                                                isInvalid={data.contratype === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>
                                                <option value="Bank To Bank">Bank To Bank</option>
                                                <option value="Cash Deposit">Cash Deposit</option>
                                                <option value="Cash Withdrawl">Cash Withdrawl</option>
                                                <option value="Cash To Cash(Send)">
                                                    Cash To Cash(Send)
                                                </option>
                                                <option value="Cash To Cash(Receive)">
                                                    Cash To Cash(Receive)
                                                </option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select Contra type
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} lg={6}>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicId">
                                                    <Form.Label>
                                                        Date<span className="star"> *</span>
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Select Date"
                                                        name="date"
                                                        value={data.date}
                                                        min={`${finalcialYear[0]}-04-01`}
                                                        max={`${new Date().toISOString().split("T")[0]}`}
                                                        onChange={handleChange}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        required
                                                        isInvalid={data.date === "" && showEmptyError}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Select Date
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group controlId="formBasicId">
                                                    {/* <Form.Label>Reference No/Cheque No</Form.Label> */}
                                                    {refCheck.includes(data.contratype) ? (
                                                        <Form.Label>
                                                            Reference No/Cheque No{" "}
                                                            <span className="star"> *</span>
                                                        </Form.Label>
                                                    ) : (
                                                        <Form.Label>Reference No/Cheque No</Form.Label>
                                                    )}

                                                    <Form.Control
                                                        type="text"
                                                        name="prefno"
                                                        value={data.prefno}
                                                        onChange={handleChange}
                                                        isInvalid={
                                                            data.prefno === "" &&
                                                            showEmptyError &&
                                                            refCheck.includes(data.contratype)
                                                        }
                                                    />
                                                    {refCheck.includes(data.contratype) ||
                                                        data.prefno == "" ? (
                                                        <Form.Control.Feedback type="invalid">
                                                            Enter Reference No./Cheque No.
                                                        </Form.Control.Feedback>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Form.Group controlId="formBasicId">
                                            <Form.Label>
                                                Amount <span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Enter Amount"
                                                name="amount"
                                                value={data.amount}
                                                onChange={handleChange}
                                                required
                                                isInvalid={data.amount === "" && showEmptyError}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Amount
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                From<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="from"
                                                onChange={handleChange}
                                                value={data.from}
                                                isInvalid={data.from === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>

                                                {data.contratype == "Cash Withdrawl"
                                                    ? bankArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}

                                                {data.contratype == "Cash Deposit"
                                                    ? cashArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}
                                                {data.contratype == "Cash To Cash(Send)"
                                                    ? cashArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}

                                                {data.contratype == "Cash To Cash(Receive)"
                                                    ? branchArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}
                                                {data.contratype === "Bank To Bank"
                                                    ? data.to
                                                        ? bankArr
                                                            .filter((ele) => ele !== data.to)
                                                            .map((val, i) => (
                                                                <option value={val} key={i}>
                                                                    {val}
                                                                </option>
                                                            ))
                                                        : bankArr.map((val, i) => (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        ))
                                                    : null}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select List
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicSite">
                                            <Form.Label>
                                                To<span className="star"> *</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                name="to"
                                                onChange={handleChange}
                                                value={data.to}
                                                isInvalid={data.to === "" && showEmptyError}
                                            >
                                                <option value="">-- Select --</option>

                                                {data.contratype == "Cash Withdrawl"
                                                    ? cashArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}

                                                {data.contratype == "Cash Deposit"
                                                    ? bankArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}
                                                {data.contratype == "Cash To Cash(Send)"
                                                    ? branchArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}

                                                {data.contratype == "Cash To Cash(Receive)"
                                                    ? cashArr.map((val, i) => {
                                                        return (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        );
                                                    })
                                                    : null}
                                                {data.contratype === "Bank To Bank"
                                                    ? data.from
                                                        ? bankArr
                                                            .filter((ele) => ele !== data.from)
                                                            .map((val, i) => (
                                                                <option value={val} key={i}>
                                                                    {val}
                                                                </option>
                                                            ))
                                                        : bankArr.map((val, i) => (
                                                            <option value={val} key={i}>
                                                                {val}
                                                            </option>
                                                        ))
                                                    : null}
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Select List
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group controlId="formBasicId">
                                            {remarksCheck.includes(data.contratype) ? (
                                                <Form.Label>
                                                    Remarks<span className="star"> *</span>
                                                </Form.Label>
                                            ) : (
                                                <Form.Label>Remarks</Form.Label>
                                            )}

                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Remarks"
                                                name="remark"
                                                value={data.remark}
                                                onChange={handleChange}
                                                isInvalid={
                                                    data.remark === "" &&
                                                    showEmptyError &&
                                                    remarksCheck.includes(data.contratype)
                                                }
                                            />

                                            {remarksCheck.includes(data.contratype) ||
                                                data.contratype == "" ? (
                                                <Form.Control.Feedback type="invalid">
                                                    Add Remarks
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Row>
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start gap-2">
                    <Button
                        type="button"
                        className="commonBtn smallBtn"
                        onClick={handleSubmit}
                    >
                        {loadData ? (<Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />)
                            : (
                                "Update"
                            )}
                    </Button>
                    <Button
                        type="button"
                        className="commonBtn smallBtn cancelBtn"
                        onClick={handleCloseEditModal}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditContraForm;
