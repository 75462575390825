import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import {
  deletePurchasePaymentData,
  purchasePaymentData,
} from "../../../redux/actions/purchasePayment.actions";
import ViewPurchasePayment from "./ViewPurchasePayment";
import axios from "axios";
// import ViewPurchaseOrder from "./ViewPurchaseOrder";

const ListTablePurchasePayment = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [type, setType] = useState("");

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const data = useSelector(
    (state) => state.purchasePaymentData.purchasePaymentData
  );
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const backendData = {
    companyid: companyid,
    userid: userid,
    keyword: searchKeyword || "₹ΘθϖΠ",
    page: currentPage,
    pageSize: pageSize,
    type: type,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(purchasePaymentData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, type]);

  const handleShow = (id) => {
    setShow(true);
    setSelectedItemId(id);
  };

  const handleClose = () => {
    setShow(false);
  };


   // crud functionality to show and hide create, edit, delete icons start
   const [crud, setCrud] = useState(["c", "d", "u"]);

   useEffect(() => {
     axios

       .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

         userid,
         submodule: "Purchase Payment",
         companyid,
       })
       .then((res) => {
         setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
       })
       .catch((err) => console.log(err));
   }, [loadData]);
 
   // crud functionality to show and hide create, edit, delete icons end
  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row className="d-flex align-items-center ">
          <Col lg={6}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Purchase Payments</span>
            </p>
          </Col>
          <Col lg={2}>
            <Form.Select
              name="type"
              value={type}
              onChange={(e)=>setType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Vendor Advance">Vendor Advance</option>
              <option value="Bill Payment">Bill Payment</option>
            </Form.Select>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
          { crud && crud.includes("c") && <Button
              className="commonBtn mt-2"
              onClick={() =>
                navigate("/purchase/purchasePayment/addPurchasePayment")
              }
            >
              Add Payment
            </Button>}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Payment No.</th>
              <th>Vendor Name</th>
              <th>Purchase Bill No</th>
              <th>Payment Mode</th>
              <th>Paid Through</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Unused Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  vendor,
                  paymentno,
                  paymentdate,
                  paymentmode,
                  paidthrough,
                  createdby,
                  referenceno,
                  paymentmade,
                  paymenttype,
                  uploads,
                  billarray,
                  total,
                  paymentammount,
                  excessamount,
                  billno,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!paymentdate ? "--" : paymentdate}</td>
                    <td>{!paymentno ? "--" : paymentno}</td>
                    <td>{!vendor ? "--" : vendor}</td>
                    <td>
                      {billarray.length > 0
                        ? billarray.map((bill) => bill.purchasebillno).join(",")
                        : "--"}
                    </td>
                    <td>{!paymentmode ? "--" : paymentmode}</td>
                    <td>{!paidthrough ? "--" : paidthrough}</td>
                    <td>{paymenttype}</td>
                    <td>{!paymentmade ? "--" : paymentmade}</td>
                    <td>{excessamount ? excessamount : 0.0}</td>
                    <td >
                      <ViewPurchasePayment item={item} />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTablePurchasePayment;
