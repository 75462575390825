import React, { useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useSelector } from "react-redux";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import ListSalePayment from "./ListSalePayment";
import PrintPayment from "./PrintPayment";
import ReactDOM from "react-dom";

const ViewSalePayment = ({ item }) => {
  const contentRef = useRef(null);
  const { companyname, branch, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { hoaddress, city, state, pincode, gstno, emailid, phoneno, pan } =
    useSelector((state) => state.authManager.userData.companyaddress) || {};
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const {
    commonname,
    commonid,
    billarray,
    invoicetype,
    paymentno,
    paymentdate,
    paymentmode,
    amountreceived,
    amountinpayment,
    createdby,
    excessamount,
  } = item || {};

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=1000,height=1000");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
      <head>
      <link rel="stylesheet" type="text/css" href="ReceiveGoods.css" media="print">
      <style>
      </style>
      <title>Print</title>
      </head>
      <body>
      <div id="printContent"></div>
      </body></html>`);
    ReactDOM.render(
      <PrintPayment
        item={item}
        companyname={companyname}
        branch={branch}
        name={name}
        hoaddress={hoaddress}
        city={city}
        pincode={pincode}
        state={state}
        gstno={gstno}
        emailid={emailid}
        phoneno={phoneno}
        pan={pan}
      />,
      printWindow.document.getElementById("printContent")
    );
    printWindow.print();
    printWindow.close();
  };

  return (
    <>
      <MdOutlineRemoveRedEye className="editIcon" onClick={handleShow} />
      <Modal
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=" d-flex w-100 justify-content-between gap-10">
            <div className="form-title ">Payment Receipt</div>
            <div className="d-flex gap-3">
              <Button className="commonBtn px-5">Send</Button>
              <Button
                className="commonBtn cancelBtn px-5"
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={contentRef}>
          <br />
          <Container>
            <Row lg={1} md={1} sm={1}>
              <Col lg={4} md={6} sm={12}>
                <h2 className=" mb-2">From</h2>
                <p className="viewpage-title mb-2">
                  {companyname ? companyname : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  {hoaddress ? hoaddress : "--"}
                </p>
              </Col>
            </Row>
            <hr />
            <Row lg={1} className="d-flex justify-content-between gap-4">
              <Col lg={4} md={6} sm={6}>
                <p className="viewpage-title mb-2">
                  Bill To:- {commonname ? commonname : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  {invoicetype == "Customer" && "Customer"}
                  {invoicetype == "Other Company" && "Other Company"}
                  {invoicetype == "Sub-Dealer" && "Sub-Dealer"}
                  {invoicetype == "Franchise" && "Franchise"}
                  {invoicetype == "Return To Vendor" && "Vendor"} ID:-{" "}
                  {commonid ? commonid : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  {billarray ? billarray[0].btaddress : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  City:- {billarray ? billarray[0].btcity : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  State:- {billarray ? billarray[0].btstate : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  PIN:- {billarray ? billarray[0].btpin : "--"}
                </p>
              </Col>
              <Col lg={4} md={6} sm={6}>
                <p className="viewpage-title mb-2">
                  Payment No:- {paymentno ? paymentno : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Date:- {paymentdate ? paymentdate : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Mode:- {paymentmode ? paymentmode : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Amount Received:- {amountreceived ? amountreceived : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Payment Amount:- {amountinpayment ? amountinpayment : "--"}
                </p>
                <p className="viewpage-title mb-2">
                  Created By:- {createdby ? createdby : "--"}
                </p>
              </Col>
            </Row>
            <hr />
            <Row className="d-flex justify-content-end">
              <Col
                lg={4}
                md={6}
                sm={12}
                className="viewpage-title mb-2 text-muted"
              >
                Over Payment :- {excessamount ? excessamount : 0.0}
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col className="viewpage-title mb-2">
                <p>Payment For</p>
              </Col>
              <Col md={12} lg={12} sm={12} className="table-main-container">
                <Table responsive>
                  <TableHeading
                    data={[
                      "Sl No",
                      "Invoice Date",
                      "Invoice No",
                      "Invoice Amount",
                      "Amount Due",
                      "Payment Amount",
                    ]}
                  />
                  <tbody>
                    {billarray.length > 0 ? (
                      billarray.map((bill, ind) => (
                        <tr key={ind}>
                          <td>{ind + 1}</td>
                          <td>{bill.invoicedate ? bill.invoicedate : ""}</td>
                          <td>{bill.invoiceno}</td>
                          <td>{bill.grandtotal}</td>
                          <td>{bill.balancedue}</td>
                          <td>{bill.paymentamount}</td>
                        </tr>
                      ))
                    ) : (
                      <NoRecordFoud count="6" name="Sale Invoices" />
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewSalePayment;
