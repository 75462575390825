import React from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import sanviLogo from "../../../../assets/sanvi_logo.png";
import QRCode from "qrcode.react";
import "./Ewaybill.css"
const PrintEwayBill = React.forwardRef(({ data }, ref) => {
  return (
    <div style={{position:"relative"}} ref={ref}>
         {data.status == "CNL" && <div className="overlay-canceled">CANCELLED</div>}
      <div className="d-flex justify-content-between align-items-center bb p-2">
        <div style={{ width: "20%" }}>
          <img
            src={sanviLogo}
            alt="logo"
            style={{ width: "70%", height: "50%" }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <h2>SAANVI ENTERPRISES</h2>
          <h4>Ewb No: {data.ewbNo}</h4>
          <h4>Ewb Date: {data.ewayBillDate}</h4>
        </div>
        <div style={{ width: "30%" }}>
          <QRCode
            value={`EWB No - ${data.ewbNo},  Invoice Date - ${data.docDate}, From GSTIN - ${data.fromGstin}`}
            size={120} // Size of the QR code
            bgColor={"#FFFFFF"} // Background color
            fgColor={"#000000"}
            // width={90}
            // height={10}
          />
          <h5>Website: www.saanviavs.com</h5>
          <h5>Invoice: {data.docNo}</h5>
        </div>
      </div>
      <div className="d-flex justify-content-beteen gap-2 align-items-center p-2 bb w-100">
        <div style={{ width: "auto" }}>
          {/* <h4>E-way Bill No :- &nbsp; {data.ewbNo ? data.ewbNo : "--"}</h4>
          <h4>
            E-way Bill Date :- &nbsp;{" "}
            {data.ewayBillDate ? data.ewayBillDate : "--"}
          </h4>
          <h4>Invoice No :- &nbsp; {data.docNo ? data.docNo : "--"}</h4> */}
          {/* <h4>
            Transporter : &nbsp;{" "}
            {data.transporterName ? data.transporterName : "--"}
          </h4>
          <h4>
            Transporter Doc No : &nbsp;{" "}
            {data.VehiclListDetails.length > 0
              ? data.VehiclListDetails[0].transDocNo
              : "-"}
          </h4> */}
          <h4>
            Valid From : &nbsp;{" "}
            {data.VehiclListDetails.length > 0
              ? data.VehiclListDetails[0].enteredDate
              : "-"}
          </h4>
          <h4>
            Distance (in Km) : &nbsp;{" "}
            {data.actualDist ? data.actualDist : "--"}
          </h4>
        </div>
        <div style={{ width: "auto", paddingLeft: "20px" }}>
          {/* <h4>
            GSTIN Supplier :- &nbsp; {data.userGstin ? data.userGstin : "--"}
          </h4>
          <h4>Place Of Dispatch :- &nbsp; {data.fromAddr1}</h4>
          <h4>GSTIN Customer :- &nbsp; {data.toGstin ? data.toGstin : "--"}</h4> */}
          <h4>
            Place Of Delivery : &nbsp; {data.toAddr1 ? data.toAddr1 : "--"}
          </h4>
          <h4>
            Vehicle No : &nbsp;{" "}
            {data.VehiclListDetails.length > 0
              ? data.VehiclListDetails[0].vehicleNo
              : "-"}
          </h4>
          <h4>Valid Upto : &nbsp; {data.validUpto ? data.validUpto : "--"}</h4>
          <h4>
            Validity (in Days) : &nbsp;{" "}
            {(() => {
              const parseDate = (dateStr) => {
                const parts = dateStr.split("/");
                if (parts.length === 3) {
                  const day = parseInt(parts[0], 10);
                  const month = parseInt(parts[1], 10) - 1; // Month is zero-based in Date object
                  const year = parseInt(parts[2], 10);
                  return new Date(year, month, day); // Create Date object (year, month, day)
                }
                return null;
              };

              const validUptoDate = parseDate(data.validUpto);
              const enteredDate =
                data.VehiclListDetails.length > 0
                  ? parseDate(data.VehiclListDetails[0].enteredDate)
                  : null;

              if (
                validUptoDate &&
                enteredDate &&
                !isNaN(validUptoDate) &&
                !isNaN(enteredDate)
              ) {
                const differenceMs = validUptoDate - enteredDate;
                const differenceDays = differenceMs / (1000 * 60 * 60 * 24);
                return Math.floor(differenceDays);
              } else {
                return "Invalid date(s)";
              }
            })()}
          </h4>
        </div>
      </div>
      <Row className="d-flex justify-content-between align-items-center bb p-2 ">
        <Col>
          <h4>Supplier Details</h4>
        </Col>
        <Col>
          <h4>Customer Details</h4>
        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center bb p-2">
        <Col style={{ borderRight: "1px solid black" }}>
          <Row>
            <Col>
              <h4>Bill From: </h4>
            </Col>
            <Col>
              <h4>{data.fromTrdName}</h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >GSTIN</h4>
            </Col>
            <Col >
              <h4 >
                {data.fromGstin ? data.fromGstin : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Address</h4>
            </Col>
            <Col >
              <h4 >
                {data.fromAddr1 ? data.fromAddr1 : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Location</h4>
            </Col>
            <Col >
              <h4 >
                {data.fromPlace ? data.fromPlace : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Pincode</h4>
            </Col>
            <Col >
              <h4 >
                {data.fromPincode ? data.fromPincode : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Statecode</h4>
            </Col>
            <Col >
              <h4 >
                {data.fromStateCode ? data.fromStateCode : "-"}
              </h4>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <h4>Bill To: </h4>
            </Col>
            <Col>
              <h4>{data.toTrdName}</h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >GSTIN</h4>
            </Col>
            <Col >
              <h4 >
                {data.toGstin ? data.toGstin : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Address</h4>
            </Col>
            <Col >
              <h4 >
                {data.toAddr1 ? data.toAddr1 : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Location</h4>
            </Col>
            <Col >
              <h4 >
                {data.toPlace ? data.toPlace : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Pincode</h4>
            </Col>
            <Col >
              <h4 >
                {data.toPincode ? data.toPincode : "-"}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col >
              <h4 >Statecode</h4>
            </Col>
            <Col >
              <h4 >
                {data.toStateCode ? data.toStateCode : "-"}
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table responsive className="table-color fs-4 text-center">
        <thead>
          <tr>
            <th className="th-color">S.I</th>
            <th className="th-color">Item Name</th>
            <th className="th-color">HSN Code</th>
            <th className="th-color">Unit</th>
            <th className="th-color">Qty</th>
            <th className="th-color">Amount</th>
          </tr>
        </thead>
        <tbody>
          {data.itemList.length > 0 ? (
            data.itemList.map((item, ind) => {
              return (
                <tr key={ind}>
                  <td>{ind + 1}</td>
                  <td>{item.productName}</td>
                  <td>{item.hsnCode}</td>
                  <td>{item.qtyUnit}</td>
                  <td>{item.quantity}</td>
                  <td>{item.taxableAmount}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="6">
                <div className="datanotfound-center-text">No Item Found 🤔</div>
              </td>
            </tr>
          )}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <hr />
            </td>
          </tr>
          <tr>
            <td className="tddata-right">Sub Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.totalValue}</td>
          </tr>
          <tr>
            <td className="tddata-right">CGST</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.cgstValue}</td>
          </tr>
          <tr>
            <td className="tddata-right">SGST </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.sgstValue}</td>
          </tr>
          <tr>
            <td className="tddata-right">IGST </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="font-bold td-top-border">{data.igstValue}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td></td>
            <td></td>
            <td className="tddata-right ">Total</td>
            <td className="font-bold"></td>
            <td>
              {" "}
              {data.itemList.reduce((acc, curr) => {
                return Number(acc) + Number(curr.quantity);
              }, 0)}{" "}
            </td>
            <td className="font-bold td-top-border ">{data.totInvValue}</td>
          </tr>
        </tbody>
      </Table>
      {/* <div>
        <div className="d-flex" style={{ border: "1px solid black" }}>
          <div
            style={{ width: "65%", borderRight: "1px solid black" }}
            className="d-flex align-items-center justify-content-center"
          >
            <h4 className="text-center font-bold">Terms & Conditions</h4>
          </div>
          <div style={{ width: "35%" }}>
            <h4 className="text-center font-bold">
              Certified that particulars given above are true & correct
            </h4>
            <h2 className="text-center font-bold">For SAANVI ENTERPRISES</h2>
          </div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}>
            <h5 className="text-center font-bold">
              1.We declare that this invoice shows the actual price of the goods
              described and that all particulars are true and correct. 2.Goods
              once sold will not be returnable.(Handles and Mica) 3.Interest
              @36% P.A. will be charged if the payment is not made with in 15
              days from the date of delivery of goods. 4.Return of goods shall
              considered within 15 days of billing. 5. Thereafter 15% will be
              deducted and after 1 month no consideration will be entertained.
              6. Our Udyam Registaration No. is UDYAM-OD-30-0003179
            </h5>
          </div>
          <div style={{ width: "35%" }}></div>
        </div>
        <div
          className="d-flex"
          style={{ border: "1px solid black", borderTop: "none" }}
        >
          <div style={{ width: "65%", borderRight: "1px solid black" }}></div>
          <div style={{ width: "35%" }}>
            <h3 className="text-center">Authorised Signature</h3>
          </div>
        </div>
      </div>
      <div className="text-center">
        <h2>SUBJECT TO ROURKELA JURISDICTION</h2>
        <h4>This is a Computer Generated Invoice</h4>
      </div> */}
    </div>
  );
});

export default PrintEwayBill;
