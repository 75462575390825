import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListTableName from "../../../constants/ListTableName";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { MdModeEditOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import GlobalPagination from "../../../constants/GlobalPagination";
import ViewSalesOrder from "./ViewSalesOrder";
import {
  salesOrderDelete,
  salesOrderPagiData,
} from "../../../redux/actions/salesOrder.actions";
import axios from "axios";
import convertToIndianDateFormat from "../../../constants/IndianDate";

function ListTableSalesOrder() {

  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);
  const [status, setStatus] = useState("");
  const statusOptions = ["Draft", "Close", "Approved", "Rejected"];

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data =
    useSelector((state) => state.salesOrderData.salesOrderPagiData) || [];
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
    status: status,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(salesOrderPagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, status]);

  //search logic

  const handleShow = (item) => {
    const { id, saleorderno } = item;
    setShow(true);
    setDeleteItemId(id);
    setSelectedItemNo(saleorderno);
  };

  const handleEdit = (id) => {
    navigate(`/sales/salesOrder/editSalesOrder/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      salesOrderDelete(
        {
          id: deleteItemId,
          saleorderno: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Sales Order",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);
  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">

        <Row>
          <Col lg={6}>
            <ListTableName name="Sales Order" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Status</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                {statusOptions.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                    className={
                      item == "Approved"
                        ? "Approved-status"
                        : item === "Rejected"
                        ? "Rejected-status"
                        : item === "Closed"
                        ? "Closed-status"
                        : item === "Draft"
                        ? "Draft-status"
                        : ""
                    }
                  >
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() => navigate("/sales/salesOrder/addSalesOrder")}
              >
                Add Sales Order
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Sales Order No.</th>
              <th>Quotation No.</th>
              <th>Customer Name</th>
              <th>Sales Person</th>
              <th>Order Status</th>
              <th>Invoiced Status</th>
              <th>SO Amount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="9" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  saleorderdate,
                  saleorderno,
                  quoteno,
                  customername,
                  salesperson,
                  orderstatus,
                  invoicestatus,
                  finalamount,
                } = item;
                return (
                  <tr key={index}>
                    <td>
                      {!saleorderdate
                        ? "--"
                        : convertToIndianDateFormat(saleorderdate)}
                    </td>
                    <td>{!saleorderno ? "--" : saleorderno}</td>
                    <td>{!quoteno ? "--" : quoteno}</td>
                    <td>{!customername ? "--" : customername}</td>
                    <td>{!salesperson ? "--" : salesperson}</td>
                    <td
                      className={
                        orderstatus == "Approved"
                          ? "Approved-status"
                          : orderstatus === "Rejected"
                          ? "Rejected-status"
                          : orderstatus === "Closed"
                          ? "Closed-status"
                          : orderstatus === "Draft"
                          ? "Draft-status"
                          : ""
                      }
                    >
                      {orderstatus ? orderstatus : "--"}
                    </td>
                    <td>{!invoicestatus ? "--" : invoicestatus}</td>
                    <td>{!finalamount ? "--" : finalamount}</td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <span>
                        <MdOutlineRemoveRedEye
                          className="editIcon"
                          onClick={() =>
                            navigate(`/sales/salesOrder/viewSalesOrder/${id}`)
                          }
                        />
                      </span>

                      <span className="mx-1">
                        {crud && crud.includes("u") && (
                          <MdModeEditOutline
                            className="editIcon mr-2"
                            onClick={() => handleEdit(id)}
                          />
                        )}
                      </span>

                      <span>
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            name="Sales Order"
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="9" name="Sales Order" />
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
}

export default ListTableSalesOrder;
