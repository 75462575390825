import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ListTableName from "../../../constants/ListTableName";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import {
  salesInvoiceDelete,
  salesInvoicePagiData,
} from "../../../redux/actions/salesInvoice.actions";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import GlobalPagination from "../../../constants/GlobalPagination";
import { MdModeEditOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyWarning } from "../../../constants/toastAlert";
import * as XLSX from "xlsx";
import { BiSolidFileExport } from "react-icons/bi";
import axios from "axios";
import { FaInternetExplorer } from "react-icons/fa";
import convertToIndianDateFormat from "../../../constants/IndianDate";

function ListTableSalesInvoice() {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState("");
  const [selectedItemNo, setSelectedItemNo] = useState(null);
  const [status, setStatus] = useState("");
  const statusOptions = [
    "Unpaid",
    "Paid",
    "Partially Paid",
    "Overdue",
    "Draft",
  ];

  //Redux part
  const dispatch = useDispatch();
  const { companyid, userid, companyname, branchid, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const data =
    useSelector((state) => state.salesInvoiceData.salesInvoicePagiData) || [];
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const backendData = {
    keyword: searchKeyword || "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
    status: status,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(salesInvoicePagiData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword, status]);

  function handleExport() {
    if (filteredData.length == 0) {
      return notifyWarning("No Sales Invoice Record to Export");
    } else {
      const invoice = filteredData.map((item, ind) => {
        const {
          id,
          invoicetype,
          commonname,
          commonid,
          gino,
          saleorderno,
          invoiceno,
          invoicedate,
          paymentterms,
          paymentduedate,
          transportertype,
          vehicleno,
          salesperson,
          ewaybillno,
          einvoiceno,
          createdby,
          remarks,
          itemarray,
          subtotal,
          discount,
          discountamount,
          taxableamount,
          cgst,
          sgst,
          igst,
          tcs,
          tds,
          tsamount,
          frightcharges,
          adjustmentamount,
          grandtotal,
          balancedue,
          tandc,
          istandc,
          status,
          userid,
          companyid,
          branchid,
          org,
          month,
          financialyear,
        } = item;

        return {
          ID: id,
          INVOICETYPE: invoicetype,
          COMMONNAME: commonname,
          COMMONID: commonid,
          GINO: gino,
          SALEORDERNO: saleorderno,
          INVOICENO: invoiceno,
          INVOICEDATE: invoicedate,
          PAYMENTTERMS: paymentterms,
          PAYMENTDUEDATE: paymentduedate,
          TRANSPORTERTYPE: transportertype,
          VEHICLENO: vehicleno,
          SALESPERSON: salesperson,
          EWAYBILLNO: ewaybillno,
          EINVOICENO: einvoiceno,
          CREATEDBY: createdby,
          REMARKS: remarks,
          ITEMARRAY: itemarray,
          SUBTOTAL: subtotal,
          DISCOUNT: discount,
          DISCOUNTAMOUNT: discountamount,
          TAXABLEAMOUNT: taxableamount,
          CGST: cgst,
          SGST: sgst,
          IGST: igst,
          TCS: tcs,
          TDS: tds,
          TSAMOUNT: tsamount,
          FRIGHTCHARGES: frightcharges,
          ADJUSTMENTAMOUNT: adjustmentamount,
          GRANDTOTAL: grandtotal,
          BALANCEDUE: balancedue,
          TANDC: tandc,
          ISTANDC: istandc,
          STATUS: status,
          USERID: userid,
          COMPANYID: companyid,
          BRANCHID: branchid,
          ORG: org,
          MONTH: month,
          FINANCIALYEAR: financialyear,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(invoice);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sale Invoice");
      XLSX.writeFile(workbook, "Sale Invoice.xlsx");
    }
  }

  const handleShow = (item) => {
    const { id, invoiceno } = item;
    setShow(true);
    setDeleteItemId(id);
    setSelectedItemNo(invoiceno);
  };

  const handleEdit = (id) => {
    navigate(`/sales/salesInvoice/editSalesInvoice/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      salesInvoiceDelete(
        {
          id: deleteItemId,
          invoiceno: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Sales Invoice",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);
  // crud functionality to show and hide create, edit, delete icons end

  // For E invoice start
  const handleEinvoice = (invoiceno) => {
    navigate("/einvoice/add", { state: { invoiceno: invoiceno } });
  };
  // For E invoice end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={5}>
            <ListTableName name="Sales Invoice" />
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Status</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">All</option>
                {statusOptions.map((item, index) => (
                  <option
                    key={index}
                    value={item}
                    className={
                      item == "Partially Paid"
                        ? "Approved-status"
                        : item == "Paid"
                        ? "Approved-status"
                        : item === "Overdue"
                        ? "Rejected-status"
                        : item === "Unpaid"
                        ? "Unpaid-status"
                        : item === "Draft"
                        ? "Draft-status"
                        : ""
                    }
                  >
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() => navigate("/sales/salesInvoice/addSalesInvoice")}
              >
                Add Sales Invoice
              </Button>
            )}
          </Col>
          <Col lg={1}>
            <Button
              style={{ boxShadow: "none" }}
              className="commonBtn mt-2 cancelBtn"
              onClick={handleExport}
            >
              <span>
                <BiSolidFileExport />
              </span>
              Export
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>Invoice No.</th>
              <th>Sale Order No.</th>
              <th>GI No</th>
              <th>Invoice Type</th>
              <th>Name</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Balance Due</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <>
                <DataSpinner count="12" />
              </>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  invoicedate,
                  invoiceno,
                  gino,
                  invoicetype,
                  commonname,
                  paymentduedate,
                  status,
                  grandtotal,
                  balancedue,

                  commonid,
                  saleorderno,
                  paymentterms,
                  transportertype,
                  vehicleno,
                  salesperson,
                  ewaybillno,
                  einvoiceno,
                  createdby,
                  remarks,
                  itemarray,
                  subtotal,
                  discount,
                  discountamount,
                  taxableamount,
                  cgst,
                  sgst,
                  igst,
                  tcs,
                  tds,
                  tsamount,
                  frightcharges,
                  adjustmentamount,
                  tandc,
                  istandc,
                  userid,
                  companyid,
                  branchid,
                  org,
                  month,
                  financialyear,
                } = item;

                return (
                  <tr key={index}>
                    <td>
                      {!invoicedate
                        ? "--"
                        : convertToIndianDateFormat(invoicedate)}
                    </td>
                    <td>{!invoiceno ? "--" : invoiceno}</td>
                    <td>{!saleorderno ? "--" : saleorderno}</td>
                    <td>{!gino ? "--" : gino}</td>
                    <td>{!invoicetype ? "--" : invoicetype}</td>
                    <td>{!commonname ? "--" : commonname}</td>
                    <td>{!paymentduedate ? "--" : paymentduedate}</td>
                    <td
                      className={
                        status == "Partially Paid"
                          ? "Approved-status"
                          : status == "Unpaid"
                          ? "Unpaid-status"
                          : status === "Overdue"
                          ? "Rejected-status"
                          : status === "Paid"
                          ? "Approved-status"
                          : status === "Draft"
                          ? "Draft-status"
                          : ""
                      }
                    >
                      {!status ? "--" : status}
                    </td>
                    <td>{!grandtotal ? "--" : grandtotal}</td>
                    <td>
                      {!balancedue
                        ? Number(grandtotal).toFixed(2)
                        : Number(balancedue).toFixed(2)}
                    </td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <span>
                        <MdOutlineRemoveRedEye
                          className="editIcon"
                          onClick={() =>
                            navigate(
                              `/sales/salesInvoice/viewSalesInvoice/${id}`
                            )
                          }
                        />
                      </span>

                      {status != "Paid" && crud && crud.includes("u") ? (
                        <span>
                          <MdModeEditOutline
                            className="editIcon mr-2"
                            onClick={() => handleEdit(id)}
                          />
                        </span>
                      ) : (
                        <span>
                          <MdModeEditOutline
                            className="editIcon"
                            style={{ opacity: "0" }}
                          />
                        </span>
                      )}

                      {crud && crud.includes("d") && (
                        <span>
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            name="Sales Invoice"
                          />
                        </span>
                      )}
                      {/* for E invoice */}
                      <span>
                        <FaInternetExplorer
                          onClick={() => handleEinvoice(invoiceno)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordFoud count="12" name="Sales Invoice" />
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
}

export default ListTableSalesInvoice;
