import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react";
import html2pdf from "html2pdf.js";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";

import "../Ewaybill/Ewaybill.css";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import SaanviLogo from "../../../../assets/images/saanvi.png";
import DataSpinner from "../../../../constants/DataSpinner";
import NoRecordFoud from "../../../../constants/NoRecordFoud";
import { jwtDecode } from "jwt-decode";

function ViewEinvoice() {
  const loadData = useSelector((state) => state.loadReducer);

  const pdfRef = useRef(null);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const { companyid, userid, companyname, branchid, branch, org, name } =
    useSelector((state) => state.authManager.userData.data[0]) || {};

  const { irn } = useParams();
  const [data, setData] = useState([]);
  const [decodedSignedInvoice, setDecodedSignedInvoice] = useState(null);
  const [decodedSignedQrCode, setDecodedSignedQrCode] = useState(null);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/einvoice/getbyirn`, { irn })
      .then((res) => {
        // setData(res.data.data)
        setData(res.data.length > 0 ? res.data[0] : []);


        setDecodedSignedInvoice(
          jwtDecode(JSON.stringify(res.data[0].signedinvoice))
        );
        setDecodedSignedQrCode(
          jwtDecode(JSON.stringify(res.data[0].signedqrcode))
        );
        setLoading(!loading);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // Decoded data to show in e invoice start
  // Decoded data to show in e invoice start
  const singneData = decodedSignedInvoice
    ? JSON.parse(decodedSignedInvoice.data)
    : null;
  const {
    Irn,
    AckNo,
    AckDt,
    BuyerDtls,
    DocDtls,
    ItemList,
    SellerDtls,
    ValDtls,
    TranDtls,
  } = singneData || {};
  // Decoded data to show in e invoice end
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="form-container">
        <Col sm={12}>
          <Row>
            <Col>
              <h1>
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={() => navigate("/einvoice")}
                />
                E <span className="form-title">Invoice</span>
              </h1>
            </Col>
            <Col></Col>
            <Col className="d-flex gap-3 justify-content-end">
              <span>
                <ReactToPrint
                  trigger={() => (
                    <Button className="commonBtn smallBtn cancelBtn ">
                      Print
                    </Button>
                  )}
                  content={() => containerRef.current}
                />
              </span>
            </Col>
          </Row>
        </Col>
        <hr />
        <Col>
     
          <Row>
            <Col style={{position:"relative"}} ref={containerRef}>
            {data.status == "CNL" && <div className="overlay-canceled">CANCELLED</div>}

              <div className="print-section">
                <table>
                  <tr className="first-section">
                    <td className="saanvi_logo">
                      <img src={SaanviLogo} alt="Saanvi Logo" />
                    </td>
                    <td className="saanvi-brand-container">
                      <h1 className="saanvi-brand">SAANVI ENTERPRISES</h1>
                      <p className="saanvi-deails">
                        <span className="saanvi-gst">IRN :</span>{" "}
                        <span className="saanvi-deails so">{irn || ""} </span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-gst">AckNo :</span>{" "}
                        <span className="saanvi-deails so">{AckNo || ""} </span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-gst">AckDt :</span>{" "}
                        <span className="saanvi-deails so">{AckDt} </span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-gst">Invoice Type :</span>{" "}
                        <span className="saanvi-deails so">{DocDtls&&DocDtls.Typ} </span>
                      </p>
                    </td>
                    <td className="saanvi-contact-details">
                      <QRCode
                        value={decodedSignedQrCode && decodedSignedQrCode.data}
                        size={128} // Size of the QR code
                        bgColor={"#FFFFFF"} // Background color
                        fgColor={"#000000"} // Foreground color
                      />
                      {/* <p className="saanvi-deails">
                        <span className="saanvi-details-section">
                          Phone No:{" "}
                        </span>
                        <span className="saanvi-contact">+91-9437178212</span>
                      </p> */}
                      <p className="saanvi-deails">
                        <span className="saanvi-details-section">
                          Website:{" "}
                        </span>
                        <span>www.saanviavs.com</span>
                      </p>
                      <p className="saanvi-deails">
                        <span className="saanvi-details-section">
                          Invoice No:{" "}
                        </span>
                        <span>{DocDtls && DocDtls.No}</span>
                      </p>
                    </td>
                  </tr>

                  <tr className="third-section">
                    <td className="customer-details-section" colSpan="3">
                      <span className="saanvi-deails">Details</span>
                    </td>
                  </tr>
                  <tr className="four-section">
                    <td colSpan="3">
                      <div className="address-section">
                        <div className="first-col">
                          <p>
                            <span className="btitle">Bill From :</span>
                            <span className="desc">
                              {SellerDtls && SellerDtls.LglNm}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Address :</span>
                            <span className="desc">
                              {SellerDtls && SellerDtls.Addr1}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Location :</span>
                            <span className="desc part">
                              {SellerDtls && SellerDtls.Loc}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Pin code :</span>
                            <span className="desc part">
                              {SellerDtls && SellerDtls.Pin}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">State Code :</span>
                            <span className="desc part">
                              {SellerDtls && SellerDtls.Stcd}
                            </span>
                          </p>

                          <p>
                            <span className="btitle">GSTIN</span>
                            <span className="desc">
                              {SellerDtls && SellerDtls.Gstin}
                            </span>
                          </p>
                        </div>
                        <div className="second-col">
                          <p>
                            <span className="btitle">Bill To:</span>
                            <span className="desc">
                              {BuyerDtls && BuyerDtls.LglNm}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Address :</span>
                            <span className="desc">
                              {BuyerDtls && BuyerDtls.Addr1}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Location :</span>
                            <span className="desc part">
                              {BuyerDtls && BuyerDtls.Loc}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">Pin code :</span>
                            <span className="desc part">
                              {BuyerDtls && BuyerDtls.Pin}
                            </span>
                          </p>
                          <p>
                            <span className="btitle">State Code :</span>
                            <span className="desc part">
                              {BuyerDtls && BuyerDtls.Stcd}
                            </span>
                          </p>

                          <p>
                            <span className="btitle">GSTIN</span>
                            <span className="desc">
                              {BuyerDtls && BuyerDtls.Gstin}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="five-section">
                    <td colSpan="3">
                      <div className="payment-section">
                        <div
                          className="payment-details"
                          style={{ width: "35%" }}
                        >
                          <p className="mb-0">
                            <span className="btitle cd">E-Way Bill:</span>
                            <span className="desc">{data.ewbno}</span>
                          </p>

                          <p className="mb-0">
                            <span className="btitle cd">    E-Way Bill Date:</span>
                            <span className="desc">{data.ewbdt}</span>
                          </p>
                          <p className="mb-0">
                            <span className="btitle cd">
                              E-Way Bill Valid Till:
                            </span>
                            <span className="desc">{data.ewbvalidtill}</span>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="six-section">
                    <td colSpan="3">
                      <div>
                        <Table responsive className="table-color fs-4">
                          <thead>
                            <tr className="table-head-color">
                              <th>#</th>
                              <th>Item Name</th>
                              <th>HSN/SAC</th>
                              <th>UOM</th>
                              <th>QTY</th>

                              <th>Unit/Rate</th>
                              <th>GST RT</th>
                              <th>Discount</th>
                              <th>CGST</th>
                              <th>IGST</th>
                              <th>SGST</th>
                              <th>Ass Amount</th>
                              <th>Total Amount</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody className="tablebody">
                            {ItemList &&
                              ItemList.map((item, index) => (
                                <tr key={index}>
                                  <td>{+index + 1}</td>
                                  <td>{item.PrdDesc}</td>
                                  <td>{item.HsnCd}</td>
                                  <td>{item.Unit}</td>
                                  <td>{item.Qty}</td>
                                  <td>{item.UnitPrice}</td>
                                  <td>{item.GstRt}</td>
                                  <td>{item.Discount}</td>
                                  <td>{item.CgstAmt}</td>
                                  <td>{item.IgstAmt}</td>
                                  <td>{item.SgstAmt}</td>
                                  <td>{item.AssAmt}</td>
                                  <td>{item.TotAmt}</td>
                                  <td>{item.TotItemVal}</td>
                                </tr>
                              ))}
                          
                          </tbody>
                          <tbody className="tablebody">
                          <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total CGST:</b> {ValDtls && ValDtls.CgstVal || 0}</td>
                              {/* <td>{ValDtls && ValDtls.CgstVal || 0}</td> */}
                           
                            </tr>
                            <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total SGST:</b> {ValDtls && ValDtls.SgstVal}</td>
                              {/* <td>{ValDtls && ValDtls.SgstVal}</td> */}
                             
                            </tr>
                            <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total IGST:</b> {ValDtls && ValDtls.IgstVal || 0}</td>
                              {/* <td>{ValDtls && ValDtls.IgstVal || 0}</td> */}
                           
                            </tr>
                            <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total Discount:</b> {ValDtls && ValDtls.discount ||0}</td>
                              {/* <td>{ValDtls && ValDtls.discount ||0}</td> */}
                             
                            </tr>
                            <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total Ass Amount:</b> {ValDtls && ValDtls.AssVal}</td>
                              {/* <td>{ValDtls && ValDtls.AssVal}</td> */}
                           
                            </tr>
                            <tr>
                              <td colSpan="13"></td>
                              <td className="text-start"><b>Total Amount:</b> {ValDtls && ValDtls.TotInvVal}</td>
                              {/* <td>{ValDtls && ValDtls.TotInvVal}</td> */}
                             
                            </tr>
                          </tbody>
                        </Table>
                        {/* <tr>
                              <td colSpan="3" className="text-end">
                                <h3>
                                  Total Discount:{" "}
                                  {(ValDtls && ValDtls.Discount) || 0}
                                </h3>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" className="text-end">
                                <h3>
                                  Total Amount: {ValDtls && ValDtls.TotInvVal}
                                </h3>
                              </td>
                            </tr> */}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewEinvoice;