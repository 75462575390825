import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  ModalFooter,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { vendorData } from "../../../../redux/actions/vendor.actions";
import axios from "axios";
import { notifyError } from "../../../../constants/toastAlert";
import { PurchasePaymentIdCountApi } from "../../../../constants/api";
import { purchaseBillsData } from "../../../../redux/actions/purchaseBills.actions";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import {
  addPurchasePayment,
  purchasePaymentData,
} from "../../../../redux/actions/purchasePayment.actions";
import { BiErrorCircle } from "react-icons/bi";

import { indianFormatDate } from "../../../../constants/indianFormatDate";

import { IoDocumentAttach } from "react-icons/io5";


const BillPaymentForm = () => {
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [show, setShow] = useState(false);
  const [isApplyCredit, setIsApplyCredit] = useState(false);
  const [showExcess, setShowExcess] = useState(false);
  const [totalamountdue, setTotalAmountDue] = useState(0.0);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];

  const purchaseBillMaster =
    useSelector((state) => state.purchaseBillsData.purchaseBillsData.data) ||
    [];

  const purchasePaymentMaster =
    useSelector(
      (state) => state.purchasePaymentData.purchasePaymentData.data
    ) || [];

  const bankMaster =
    useSelector((state) => state.bankData.bankPagiData.data) || [];
  const [selectedVendorAdvanceHistory, setSelectedVendorAdvanceHistory] =
    useState([]);

  const [data, setData] = useState({
    vendor: "",
    vendorid: "",
    vendordetails: {},
    paymentno: "",
    paymentdate: "",
    paymentmode: "",
    paidthrough: "",
    createdby: name,
    referenceno: "",
    paymentmade: 0.0,
    uploads: [],
    billarray: [],
    total: 0.0,
    paymentammount: "",
    paymenttype: "Bill Payment",
    adjustamount: 0.0,
    excessamount: 0.0,
    billno: "",
    companyid: companyid,
    branchid: branchid,
    userid: userid,
    month: "",
    financialyear: finalcialYear,
    org: org,
    depositto: "",
    remarks: "",
    appliedadvance: 0.0,
  });

  const handleCancel = () => {
    setShow(true);
  };

  const handleExcessCancel = () => {
    setShowExcess(true);
  };

  const handleStay = () => {
    setShow(false);
  };

  const handleDiscard = () => {
    navigate("/purchase/purchasePayment");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "paymentmode") {
      setData((prev) => ({ ...prev, paymentmode: value, paidthrough: "" }));
    }
    if (name == "vendor") {
      setTotalAmountDue(0.00)
      if (value) {
        const findVendor = vendorMaster.find((vendor) => vendor.id == value);
        const updatedVendorAdvanceHistory = purchasePaymentMaster.filter(
          (pp) =>
            pp.paymenttype == "Vendor Advance" &&
            pp.vendor == findVendor.fullname
        );

        setData((prev) => ({
          ...prev,
          vendorid: `VD-${findVendor.id}`,
          vendor: findVendor.fullname,
          vendordetails: findVendor,
        }));
        setSelectedVendorAdvanceHistory((prev) => updatedVendorAdvanceHistory);
      } else {
        setData((prev) => ({
          ...prev,
          vendorid: "",
          vendor: "",
          vendordetails: {},
        }));
        setSelectedVendorAdvanceHistory((prev) => []);
      }
    }
  };

  const handlePaymentMadeChange = (e) => {
    if (e.target.value >= totalamountdue) {
      const mappedBillArr = data.billarray.map((bill) => ({
        ...bill,
        paymentamount: bill.dueamount,
      }));
      setData((prev) => ({
        ...prev,
        paymentmade: e.target.value,
        billarray: mappedBillArr,
      }));
    } else {
      const mappedBillArr = data.billarray.map((bill) => ({
        ...bill,
        paymentamount: 0.0,
      }));
      setData((prev) => ({
        ...prev,
        paymentmade: e.target.value,
        billarray: mappedBillArr,
      }));
    }
  };

  const handleTableRowChange = (e, ind) => {
    const { name, value } = e.target;
    const updatedBillArr = data.billarray
      .filter((bill) => bill.dueamount != 0)
      .map((item, i) => (i == ind ? { ...item, [name]: value } : item));

    setData((prev) => ({
      ...prev,
      billarray: updatedBillArr,
    }));
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    handleUploadFile(files);
    // handleUploadFile(data.documents);
  };

  const handleUploadFile = async (files) => {
    try {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }
      const response = await axios.post(

        "https://mis.saanviavs.com/api/v1/paymentmade/fileupload",

        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      response.data.files.length > 0
        ? setData((prev) => ({ ...prev, uploads: response.data.files }))
        : setData((prev) => ({ ...prev, uploads: [] }));

      return response.data.files; // Return the entire response object
    } catch (error) {
      console.error("Error uploading file:", error.message);
      throw error;
    }
  };

  const handleSave = () => {

    if (
      !data.vendor ||
      !data.paymentno ||
      !data.paymentdate ||
      !data.paymentmode ||
      !data.paidthrough
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    if (data.excessamount < 0) {
      notifyError("Total Amount Can not gretaer than payment made...");
      return;
    }
    if (data.excessamount > 0) {
      handleExcessCancel();
      return;
    }

    dispatch(addPurchasePayment(data, navigate));
  };

  const continueSave = () => {
    dispatch(addPurchasePayment(data, navigate));
  };

  const saveApplied = () => {
    let totalAdvanceOfVendor=selectedVendorAdvanceHistory.reduce(
      (acc, curr) => Number(acc) + Number(curr.paymentmade),
      0
    );
    if(totalAdvanceOfVendor>=data.appliedadvance){
      setIsApplyCredit(false);
      if (data.appliedadvance >= totalamountdue) {
        const mappedBillArr = data.billarray.map((bill) => ({
          ...bill,
          paymentamount: bill.dueamount,
        }));
        setData((prev) => ({
          ...prev,
          billarray: mappedBillArr,
          excessamount: 0.0,
        }));
      }else{
        setData((prev)=>({...prev,paymentmade:data.appliedadvance}))
      }
    }else{
      notifyError("Applied advance can't greater than vendor advance")
    }
    
  };

  const generateCategoryID = () => {
    if (purchasePaymentMaster.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = purchasePaymentMaster[0].paymentno.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      paymentno: `PM/SAANVI/${paddedCount}`,
    }));
  }, []);


  useEffect(() => {
    setLoading(!loading);
    //fetchData();
    dispatch(
      purchaseBillsData(
        { companyid, userid, branchid, keyword: "" },
        setLoading
      )
    );
    dispatch(
      bankPagiData({ companyid, userid, branchid, keyword: "" }, setLoading)
    );
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
  }, [dispatch, loadData]);

  useEffect(() => {
    if (data.vendor) {
      const findBills = purchaseBillMaster.filter(
        (bill) => bill.vendor == data.vendor
      );
      const updatedFindbills = findBills
        .filter((bill) => bill.dueamount != 0 && bill.status!="Draft")
        .map((item) => ({
          ...item,
          paymentamount: 0.0,
        }));
      setData((prev) => ({ ...prev, billarray: updatedFindbills }));
    } else {
      setData((prev) => ({ ...prev, billarray: [] }));
    }
  }, [data.vendor]);

  // calculation part
  useEffect(() => {
    let total = data.billarray.reduce((acc, curr) => {
      return Number(acc) + Number(curr.paymentamount);
    }, 0).toFixed(2);

    let totalAmountDue = data.billarray.reduce((acc, curr) => {
      return Number(acc) + Number(curr.dueamount);
    }, 0).toFixed(2);

    totalAmountDue=+totalAmountDue;

    let excessAmount = (Number(data.paymentmade) - Number(total)).toFixed(2);

    if (data.appliedadvance > 0 && data.paymentmade == 0) {
      setData((prev) => ({
        ...prev,
        total: total,
        adjustamount: total,
        // totalamountdue: totalAmountDue,
        excessamount: 0,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        total: total,
        adjustamount: total,
        // totalamountdue: totalAmountDue,
        excessamount: excessAmount,
      }));
    }
    setTotalAmountDue((prev) => totalAmountDue);
  }, [data.billarray]);

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      purchasePaymentData({ companyid, userid, keyword: "₹ΘθϖΠ" }, setLoading)
    );
  }, [dispatch, loadData]);

  return (
    <Container fluid>
      <div className="form-container">
        <Form className="form-lable-name">
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row lg={2} md={1} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Vendor <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="vendor"
                        // value={data.vendor}
                        onChange={handleChange}
                        isInvalid={!data.vendor && showEmptyError}
                      >
                        <option value="">Select</option>
                        {vendorMaster?.map((vendor) => {
                          return (
                            <option value={vendor.id} key={vendor.id}>
                              {vendor.fullname}-VD-{vendor.id}
                              {"\n"}
                              {vendor.companyname}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="paymentno"
                            value={data.paymentno}
                            // onChange={handleChange}
                            isInvalid={!data.paymentno && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Date.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="paymentdate"
                            value={data.paymentdate}
                            onChange={handleChange}
                            isInvalid={!data.paymentdate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Mode <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="paymentmode"
                            value={data.paymentmode}
                            onChange={handleChange}
                            isInvalid={!data.paymentmode && showEmptyError}
                          >
                            <option value="">Select</option>
                            <option value="Cash">Cash</option>
                            <option value="Net Banking">Net Banking</option>
                            <option value="UPI">UPI</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="CC Account">CC Account</option>
                            <option value="Cheque">Cheque</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Paid Through <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="paidthrough"
                            value={data.paidthrough}
                            onChange={handleChange}
                            isInvalid={!data.paidthrough && showEmptyError}
                          >
                            <option value="">Select</option>
                            {data.paymentmode === "Cash" ? (
                              <option value="Saanvi Cash Account">
                                Saanvi Cash Account
                              </option>
                            ) : null}

                            {data.paymentmode && data.paymentmode !== "Cash"
                              ? bankMaster.map((bank) => (
                                  <option value={bank.bank} key={bank.id}>
                                    {bank.bank}
                                  </option>
                                ))
                              : null}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Created By</Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="createdby"
                            value={data.createdby}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Refference No</Form.Label>
                          <Form.Control
                            type="text"
                            name="referenceno"
                            value={data.referenceno}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Payment Made</Form.Label>
                      <Form.Control
                        type="number"
                        name="paymentmade"
                        value={data.paymentmade}
                        onChange={handlePaymentMadeChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formFile">
                          <Form.Label>Upload Files</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={handleFileChange}
                            name="uploads"
                            // value={data.documents}
                            multiple
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label>Uploaded Files</Form.Label>
                        <div className="uploaded-data">
                          {data.uploads.length > 0 ? (
                            data.uploads.map((item, index) => {
                              return (
                                <>
                                  <span>
                                    <a href={item} target="blank">
                                      <IoDocumentAttach />
                                    </a>
                                  </span>
                                </>
                              );
                            })
                          ) : (
                            <div className="uploaded-data">
                              <span>No Document Found</span>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Label>
                      Credits Available:-{" "}
                      {selectedVendorAdvanceHistory.reduce(
                        (acc, curr) => Number(acc) + Number(curr.paymentmade),
                        0
                      )}{" "}
                      &nbsp;{" "}
                      <span
                        className="text-primary text-decoration-underline"
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsApplyCredit(true)}
                      >
                        Apply Credits
                      </span>
                    </Form.Label>
                  </Col>
                </Row>
                <hr />
              </Container>
            </div>
          </div>
        </Form>

        <Col md={12} className="table-main-container">
          <Table responsive>
            <thead className="tableContainer">
              <tr>
                <th>SI NO.</th>
                <th>Bill Date</th>
                <th>Purchase Bill No</th>
                <th>Bill Amount</th>
                <th>Amount Due</th>
                <th>Payment Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.billarray.length > 0 ? (
                data.billarray.map((bill, ind) => (
                  <tr key={ind} className="text-center">
                    <td>{ind + 1}</td>
                    <td className="d-flex flex-column">
                      <div className="mb-2">
                        {bill.billdate ? indianFormatDate(bill.billdate) : "--"}
                      </div>
                      <div className="text-muted">
                        Due Date:-&nbsp;
                        {bill.paymentduedate ? indianFormatDate(bill.paymentduedate) : "--"}
                      </div>
                    </td>
                    <td>{bill.purchasebillno ? bill.purchasebillno : "--"}</td>
                    <td>{bill.finalamount ? bill.finalamount : "--"}</td>
                    <td>{bill.dueamount ? bill.dueamount : "--"}</td>
                    <td>
                      <Form.Control
                        type="number"
                        value={bill.paymentamount}
                        name="paymentamount"
                        min={"0"}
                        className="text-center"
                        onChange={(e) => handleTableRowChange(e, ind)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">
                    <div className="datanotfound-center-text">
                      No bill Found 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
        <Row className="d-flex justify-content-end align-items-center mb-3">
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Total : </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  value={data.total}
                  name="total"
                  min={"0"}
                  className="text-center"
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end align-items-center mb-3">
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Payment Made : </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  value={data.paymentmade}
                  name="paymentmade"
                  min={"0"}
                  className="text-center"
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end align-items-center mb-3">
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Amount adjust against invoice: </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  value={data.adjustamount}
                  name="adjustamount"
                  min={"0"}
                  disabled
                  className="text-center"
                  // onChange={handleChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end align-items-center mb-3">
          <Col></Col>
          <Col>
            <Row>
              <Col>
                <Form.Label>Amount in excess: </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="number"
                  value={data.excessamount}
                  name="excessamount"
                  min={"0"}
                  className="text-center"
                  disabled
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Col sm={12} className="d-flex justify-content-start gap-2">
          <Button
            className="commonBtn smallBtn"
            onClick={handleSave}
            disabled={data.billarray.length == 0}
          >
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </div>
      <Modal show={show} size="md" backdrop="static">
        <Modal.Body>
          <div style={{ margin: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                fontSize: "26px",
              }}
            >
              <span style={{ color: "#FB6514" }}>
                <BsFillExclamationTriangleFill />
              </span>
              <span className="mt-2"> Leave this page ?</span>
            </div>
            <div
              className="mt-4"
              style={{ textAlign: "center", fontSize: "18px" }}
            >
              If you leave, your unsaved changes will be discarded.
            </div>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <Button className="commonBtn smallBtn" onClick={handleStay}>
                Stay Here
              </Button>
              <Button className="commonBtn cancelBtn" onClick={handleDiscard}>
                Leave and Discard Changes
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showExcess} size="md" backdrop="static">
        <Modal.Body>
          <div style={{ margin: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <span style={{ color: "#FB6514", fontSize: "80px" }}>
                <BiErrorCircle />
              </span>
              <span className="mt-2" style={{ fontSize: "18px" }}>
                {" "}
                You’re about to record an excess payment of Rs.
                {data.excessamount} This will be stored as unused amount for the
                vendor. Do you want to continue?
              </span>
            </div>
            <div
              className="mt-4"
              style={{ textAlign: "center", fontSize: "18px" }}
            >
              Note: The excess amount will be deposited in the Advance Tax.
            </div>
            <div className="d-flex justify-content-center gap-3 mt-4">
              <Button className="commonBtn " onClick={continueSave}>
                Continue To Save
              </Button>
              <Button
                className="commonBtn cancelBtn"
                onClick={() => setShowExcess(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isApplyCredit} size="xl" backdrop="static">
        <Modal.Header>
          <Modal.Title>
            Apply Credits For{" "}
            <span className="form-title ">
              {data.paymentno ? data.paymentno : "N/A"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="viewpage-title d-flex justify-content-end mb-3">
              <Col md={4}></Col>
              <Col md={6} lg={4} sm={8}>
                Bill Balance :-{" "}
                <span className="form-label">{totalamountdue}</span>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr className="bg-secondary">
                      <th>SI No.</th>
                      <th>Credit Details</th>
                      <th>Advance Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedVendorAdvanceHistory.length > 0 ? (
                      selectedVendorAdvanceHistory.map((vendorAdvance, ind) => (
                        <tr>
                          <td>{ind + 1}</td>
                          <td className="d-flex flex-column align-items-center justify-content-between">
                            <span>{vendorAdvance.paymenttype}</span>
                            <span>{vendorAdvance.paymentdate}</span>
                          </td>
                          <td>{vendorAdvance.paymentmade}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <div className="datanotfound-center-text">
                            No Advance Found for{" "}
                            {data.vendor ? data.vendor : "No"} Vendor 🤔
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="viewpage-title d-flex justify-content-end mb-3">
              <Col md={4}></Col>
              <Col md={6} lg={4} sm={8}>
                <span>Total Vendor Advance :-</span>{" "}
                <span className="form-label">
                  {selectedVendorAdvanceHistory.reduce(
                    (acc, curr) => Number(acc) + Number(curr.paymentmade),
                    0
                  )}
                </span>
              </Col>
            </Row>
            <Row className="viewpage-title d-flex justify-content-end mb-3">
              <Col></Col>
              <Col
                md={12}
                lg={12}
                sm={12}
                className="d-flex justify-content-end align-items-center"
              >
                <div>
                  <span>Total Amount Applied :-</span>{" "}
                </div>
                <div>
                  <Form.Control
                    type="number"
                    name="appliedadvance"
                    value={data.appliedadvance}
                    onChange={handleChange}
                    min={0}
                    // isInvalid={!data.paymentno && showEmptyError}
                  />
                </div>
              </Col>
            </Row>
            <Row className="viewpage-title d-flex justify-content-end mb-3">
              <Col></Col>
              <Col
                md={12}
                lg={12}
                sm={12}
                className="d-flex justify-content-end align-items-center"
              >
                <div>
                  <span>Final Bill Balance :-</span>{" "}
                </div>
                <div>
                  <Form.Control
                    type="number"
                    value={Number(totalamountdue) - Number(data.appliedadvance)}
                    min={0}
                    // isInvalid={!data.paymentno && showEmptyError}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-start gap-2 mt-2"
          style={{ borderTop: "0px" }}
        >
          <div className="d-flex gap-3">
            <Button className="commonBtn px-5" onClick={saveApplied}>
              Save
            </Button>
            <Button
              className="commonBtn cancelBtn px-5"
              onClick={() => setIsApplyCredit(false)}
            >
              Back
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default BillPaymentForm;
