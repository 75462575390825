import axios from "axios";
import {
  addVendorApi,
  deleteVendorApi,
  editVendorApi,
  employeeAddPost,
  employeeGetpagePost,
  employeeUpdatePost,
  employeeUpdateStatus,
  getVendorPagiApi,
} from "../../constants/api";
import {
  FETCH_EMPLOYEE_DATA,
  ADD_EMPLOYEE_DATA,
  SWITCH_EMPLOYEE_STATUS,
  UPDATE_EMPLOYEE_DATA,
  FETCH_EMPLOYEE_IMAGE,
  FETCH_AADHAR_IMAGE,
  FETCH_ID_IMAGE,
} from "../actionTypes/employee.actiontypes";
import { RELOAD } from "../actionTypes/load.actiontypes";
import { notifyError, notifySuccess, notifyWarning } from "../../constants/toastAlert";
import { initialData } from "../../constants/resData";

//Get all Employee data using pagination wise
export const employeeData = (backendData, setLoading) => async (dispatch) => {
  try {
    const res = await axios.post(employeeGetpagePost, backendData);
    const initialData =
      res.data && res.data.data && res.data.data.length > 0 ? res.data : [];
    if (initialData) {
      dispatch({ type: FETCH_EMPLOYEE_DATA, payload: initialData });
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: false });
    setLoading(false);
  } catch (error) {
    console.log(`Error in fetching Employee data ${error}`);
    notifyError(error.message);
  }
};

//Acion dispatcher for adding new employee data
export const addEmployee = (employeedata, navigate) => async (dispatch) => {
  try {
    const res = await axios.post(employeeAddPost, employeedata);
    if (res.statusText === "OK") {
      dispatch({ type: ADD_EMPLOYEE_DATA });
      notifySuccess(res.data.message);
      navigate("/employeemanagement/employee");
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in adding Employee data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for updating Employee data
export const updateEmployee = (employeeData, navigate) => async (dispatch) => {
  try {
    const res = await axios.post(employeeUpdatePost, employeeData);

    if (res.statusText === "OK") {
      dispatch({ type: UPDATE_EMPLOYEE_DATA });
      notifySuccess(res.data.message);
      navigate("/employeemanagement/employee");
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in updating Employee data ${error}`);
    notifyError(error.response.data.message);
  }
};

//Acion dispatcher for changing Employee status
export const switchEmployee = (employeeData) => async (dispatch) => {
  try {
    const res = await axios.post(employeeUpdateStatus, employeeData);

    if (res.statusText === "OK") {
      dispatch({ type: SWITCH_EMPLOYEE_STATUS });
      notifySuccess(res.data.message);
    } else {
      notifyError(res.data.message);
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.log(`Error in switching Employee ${error}`);
    notifyError(error.response.data.message);
  }
};

//Action dispatcher for document upload
export const empImageUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    
    const res = await axios.post(

      "https://mis.saanviavs.com/api/v1/employee/photo",

      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_EMPLOYEE_IMAGE, payload: fileurl });
    } else {
      notifyWarning("Image not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading emp image:", error.message);
    throw error;
    notifyError(error.message);
  }
};

export const empIDUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
    
    const res = await axios.post(

      "https://mis.saanviavs.com/api/v1/employee/idproof",

      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_ID_IMAGE, payload: fileurl });
    } else {
      notifyWarning("ID not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading emp ID:", error.message);
    throw error;
    notifyError(error.message);
  }
};

export const empAadharUpload = (backendData) => async (dispatch) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < backendData.length; i++) {
      formData.append("file", backendData[i]);
    }
   
    const res = await axios.post(

      "https://mis.saanviavs.com/api/v1/employee/aadhar",

      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    if (res.data.files.length > 0) {
      const fileurl = res.data.files;
      dispatch({ type: FETCH_AADHAR_IMAGE, payload: fileurl });
    } else {
      notifyWarning("Aadhar not uploaded");
    }
    dispatch({ type: RELOAD, payload: true });
  } catch (error) {
    console.error("Error uploading emp Aadhar:", error.message);
    throw error;
    notifyError(error.message);
  }
};

