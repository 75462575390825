import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { extractAndFormatURLPart } from '../../../constants/breadCrumb';
import DataSpinner from '../../../constants/DataSpinner';
import NoRecordFoud from '../../../constants/NoRecordFoud';
import { reloadPage } from '../../../redux/actions/load.action';
import { indianFormatDate } from '../../../constants/indianFormatDate';
import ReactToPrint from 'react-to-print';
import SaanviLogo from "../../../assets/images/saanvi.png"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { creditNotePagiData, creditNoteUpdate } from '../../../redux/actions/creditNote.action';
import { IoDocumentAttach } from 'react-icons/io5';

function ViewCreditNote() {
  const URL = extractAndFormatURLPart("sales");
  const { id } = useParams();
  const containerRef = useRef(null);

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, branch, org, name } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { data: creditNoteAllData } = useSelector((state) => state.creditNoteData.creditNotePagiData) || [];
  const creditNoteData = !creditNoteAllData ? [] : creditNoteAllData.find((item) => item.id == id);
  const commondata = creditNoteData && creditNoteData.commonnamealldata && creditNoteData.commonnamealldata[0];

  //state
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    id: creditNoteData ? creditNoteData.id : "--",
    creditnotetype: creditNoteData ? creditNoteData.creditnotetype : "--",
    commonname: creditNoteData ? creditNoteData.commonname : "--",
    commonid: creditNoteData ? creditNoteData.commonid : "--",
    commonnamealldata: creditNoteData ? creditNoteData.commonnamealldata : [],
    salesinvoiceno: creditNoteData ? creditNoteData.salesinvoiceno : "--",
    salesinvoicedate: creditNoteData ? creditNoteData.salesinvoicedate : "--",
    creditnoteno: creditNoteData ? creditNoteData.creditnoteno : "--",
    creditnotedate: creditNoteData ? creditNoteData.creditnotedate : "--",
    salesperson: creditNoteData ? creditNoteData.salesperson : "--",
    createdby: creditNoteData ? creditNoteData.createdby : "--",
    account: creditNoteData ? creditNoteData.account : "--",
    remarks: creditNoteData ? creditNoteData.remarks : "--",
    customernotes: creditNoteData ? creditNoteData.customernotes : "--",
    documents: creditNoteData ? creditNoteData.documents : "--",
    uploadfile: creditNoteData ? creditNoteData.uploadfile : [],
    showgst: creditNoteData ? creditNoteData.showgst == "true" ? true : false : true,
    itemarray: creditNoteData ? creditNoteData.itemarray : [],
    subtotal: creditNoteData ? creditNoteData.subtotal : 0.0,
    cgst: creditNoteData ? creditNoteData.cgst : 0.0,
    sgst: creditNoteData ? creditNoteData.sgst : 0.0,
    igst: creditNoteData ? creditNoteData.igst : 0.0,
    tcs: creditNoteData ? creditNoteData.tcs == "on" ? true : false : false,
    tds: creditNoteData ? creditNoteData.tds == "on" ? true : false : false,
    tcsvalue: creditNoteData ? creditNoteData.tcsvalue : "--",
    tdsvalue: creditNoteData ? creditNoteData.tdsvalue : "--",
    tsamount: creditNoteData ? creditNoteData.tsamount : 0.0,
    adjustmentamount: creditNoteData ? creditNoteData.adjustmentamount : 0.0,
    grandtotal: creditNoteData ? creditNoteData.grandtotal : "--",
    tandc: creditNoteData ? creditNoteData.tandc : "--",
    status: creditNoteData ? creditNoteData.status : "--",
    userid: creditNoteData ? creditNoteData.userid : "--",
    companyid: creditNoteData ? creditNoteData.companyid : "--",
    branchid: creditNoteData ? creditNoteData.branchid : "--",
    org: creditNoteData ? creditNoteData.org : "--",
    financialyear: creditNoteData ? creditNoteData.financialyear : "--",
    company: creditNoteData ? creditNoteData.company : "--",
    branch: creditNoteData ? creditNoteData.branch : "--",
    samestate: creditNoteData ? creditNoteData.samestate == "true" ? true : false : true,
    account: creditNoteData ? creditNoteData.account : "--"
  });
  const [loading, setLoading] = useState(false);
  //default data backend needed
  const backendData = {
    id: id,
    keyword: "₹ΘθϖΠ",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    branch: branch,
    companyname: companyname,
    org: org,
  }
  //useeffect part start
  useEffect(() => {
    dispatch(reloadPage(true));
  }, [])

  useEffect(() => {
    setLoading(!loading);
    dispatch(creditNotePagiData(backendData, setLoading));
    setData({
      id: creditNoteData ? creditNoteData.id : "--",
      creditnotetype: creditNoteData ? creditNoteData.creditnotetype : "--",
      commonname: creditNoteData ? creditNoteData.commonname : "--",
      commonid: creditNoteData ? creditNoteData.commonid : "--",
      commonnamealldata: creditNoteData ? creditNoteData.commonnamealldata : [],
      salesinvoiceno: creditNoteData ? creditNoteData.salesinvoiceno : "--",
      salesinvoicedate: creditNoteData ? creditNoteData.salesinvoicedate : "--",
      creditnoteno: creditNoteData ? creditNoteData.creditnoteno : "--",
      creditnotedate: creditNoteData ? creditNoteData.creditnotedate : "--",
      salesperson: creditNoteData ? creditNoteData.salesperson : "--",
      createdby: creditNoteData ? creditNoteData.createdby : "--",
      account: creditNoteData ? creditNoteData.account : "--",
      remarks: creditNoteData ? creditNoteData.remarks : "--",
      customernotes: creditNoteData ? creditNoteData.customernotes : "--",
      documents: creditNoteData ? creditNoteData.documents : "--",
      uploadfile: creditNoteData ? creditNoteData.uploadfile : [],
      showgst: creditNoteData ? creditNoteData.showgst == "true" ? true : false : true,
      itemarray: creditNoteData ? creditNoteData.itemarray : [],
      subtotal: creditNoteData ? creditNoteData.subtotal : 0.0,
      cgst: creditNoteData ? creditNoteData.cgst : 0.0,
      sgst: creditNoteData ? creditNoteData.sgst : 0.0,
      igst: creditNoteData ? creditNoteData.igst : 0.0,
      tcs: creditNoteData ? creditNoteData.tcs == "on" ? true : false : false,
      tds: creditNoteData ? creditNoteData.tds == "on" ? true : false : false,
      tcsvalue: creditNoteData ? creditNoteData.tcsvalue : "--",
      tdsvalue: creditNoteData ? creditNoteData.tdsvalue : "--",
      tsamount: creditNoteData ? creditNoteData.tsamount : 0.0,
      adjustmentamount: creditNoteData ? creditNoteData.adjustmentamount : 0.0,
      grandtotal: creditNoteData ? creditNoteData.grandtotal : "--",
      tandc: creditNoteData ? creditNoteData.tandc : "--",
      status: creditNoteData ? creditNoteData.status : "--",
      userid: creditNoteData ? creditNoteData.userid : "--",
      companyid: creditNoteData ? creditNoteData.companyid : "--",
      branchid: creditNoteData ? creditNoteData.branchid : "--",
      org: creditNoteData ? creditNoteData.org : "--",
      financialyear: creditNoteData ? creditNoteData.financialyear : "--",
      company: creditNoteData ? creditNoteData.company : "--",
      branch: creditNoteData ? creditNoteData.branch : "--",
      samestate: creditNoteData ? creditNoteData.samestate == "true" ? true : false : true,
      account: creditNoteData ? creditNoteData.account : "--"
    });
  }, [dispatch, loadData])
  //useeffect part end
  //Navigate part
  const navigate = useNavigate();
  const handleApprovedAndSend = () => {
    const formData = {
      ...data,
      status: "Paid"
    };

    dispatch(creditNoteUpdate(formData, navigate));
  };

  return (
    <>
      <Container fluid >
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <Row>
              <Col>
                <h1>
                  <FaArrowLeftLong
                    className="left-arrow"
                    onClick={() => navigate("/sales/creditNote")}
                  />
                  Credit <span className="form-title">Note</span>
                </h1>
              </Col>
              <Col>
                <h3 className='mr-2'>
                  Status:
                  <span className={
                    data.status == "Paid" ? "Approved-status" :
                      data.status === "Draft" ? "Draft-status" : ""
                  }

                  >
                    {data.status}
                  </span>
                </h3>
              </Col>
              <Col className="d-flex gap-3 justify-content-end">
                {data.status != "Paid" && <span className="w-50">
                  <Button className="commonBtn" onClick={handleApprovedAndSend}>
                    {loadData ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      data.status === "Draft" ? "Approve and Send" : "Send"
                    )}
                  </Button>

                </span>}
                <span>
                  <ReactToPrint
                    trigger={() => (
                      <Button className="commonBtn smallBtn cancelBtn ">
                        Print
                      </Button>
                    )}
                    content={() => containerRef.current}
                  />
                </span>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col >
            <Row>
              <Col md={6}>
                <p>
                  <span className="viewpage-title from-SalesOrder">Bill To:</span>{" "}
                </p>
                <p className='mb-0'>
                  <span className="viewpage-title companyname">{!data.commonname ? "--" : data.commonname}</span>
                </p>
                <p className='mb-0'>
                  <span className="viewpage-value d-block">{commondata?.baaddress}</span>
                  <span className="viewpage-value d-block">{commondata?.bacity}, {commondata?.bastate}, {commondata?.bacountry}</span>
                  <span className="viewpage-value d-block">{commondata?.bapincode}</span>
                </p>

                <p className='mt-5'>
                  <span className="viewpage-title heading-title">Credit Note Type</span>{" "}
                  <span className="viewpage-value">{!data.creditnotetype ? "--" : data.creditnotetype}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">{data.creditnotetype == "Other Company" ? "Company ID" : data.creditnotetype == "Sub-Dealer" ? "Sub-Dealer ID" : data.creditnotetype == "Franchise" ? "Franchise ID" : "Customer ID"}:</span>{" "}
                  <span className="viewpage-value">{!data.commonid ? "--" : data.commonid}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Sales Invoice No:</span>{" "}
                  <span className="viewpage-value">{!data.salesinvoiceno ? "--" : data.salesinvoiceno}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Credit Note No.:</span>{" "}
                  <span className="viewpage-value">{!data.creditnoteno ? "--" : data.creditnoteno}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Sales Person:</span>{" "}
                  <span className="viewpage-value">{!data.salesperson ? "--" : data.salesperson}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Account:</span>{" "}
                  <span className="viewpage-value">{!data.account ? "--" : data.account}</span>
                </p>
                <p>
                  <span className="viewpage-title heading-title">Customer Notes:</span>{" "}
                  <span className="viewpage-value">{!data.customernotes ? "--" : data.customernotes}</span>
                </p>
                <p>
                  <span className="viewpage-title heading-title">Show GST:</span>{" "}
                  <span className="viewpage-value">{data.showgst == true ? "True" : "False"}</span>
                </p>
              </Col>
              <Col md={6}>
                <p>
                  <span className="viewpage-title from-SalesOrder">Ship To:</span>{" "}
                </p>
                <p className='mb-0'>
                  <span className="viewpage-title companyname">{!data.commonname ? "--" : data.commonname}</span>
                </p>
                <p className='mb-0'>
                  <span className="viewpage-value d-block">{commondata?.saaddress}</span>
                  <span className="viewpage-value d-block">{commondata?.sacity}, {commondata?.sastate}, {commondata?.sacountry}</span>
                  <span className="viewpage-value d-block">{commondata?.sapincode}</span>
                </p>

                <p className='mt-5'>
                  <span className="viewpage-title heading-title">{data.creditnotetype == "Other Company" ? "Company" : data.creditnotetype == "Sub-Dealer" ? "Sub-Dealer" : data.creditnotetype == "Franchise" ? "Franchise" : "Customer Name"}:</span>{" "}
                  <span className="viewpage-value">{data.commonname}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Customer Type:</span>{" "}
                  <span className="viewpage-value">{commondata?.customertype}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Sales Invoice Date:</span>{" "}
                  <span className="viewpage-value">{!data.salesinvoicedate ? "--" : indianFormatDate(data.salesinvoicedate)}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Credit Note Date:</span>{" "}
                  <span className="viewpage-value">{!data.creditnotedate ? "--" : indianFormatDate(data.creditnotedate)}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Created By:</span>{" "}
                  <span className="viewpage-value">{data.createdby}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Remarks:</span>{" "}
                  <span className="viewpage-value">{data.remarks}</span>
                </p>

                <p>
                  <span className="viewpage-title heading-title">Uploaded Files:</span>{" "}
                  <span className="viewpage-value">
                    {
                      data.uploadfile?.length > 0 ? data.uploadfile?.map((item, index) => {
                        const { } = item;

                        return (
                          <>
                            <span><a href={item} target="blank"><IoDocumentAttach /></a></span>
                          </>
                        );

                      }) :
                        <span>No Document Found</span>

                    }
                  </span>
                </p>
              </Col>
            </Row>
            <hr />
            <Row >
              <Col md={12} className="table-main-container print-table">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr>
                      <th>#</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Item Name</th>
                      <th>Item ID</th>
                      <th>UOM</th>
                      <th>Order QTY</th>
                      <th>Invoice QTY</th>
                      <th>Received QTY</th>
                      {!data.showgst && <th>Rate Incl. GST</th>}
                      <th>Rate</th>
                      {data.showgst && <th>GST%</th>}
                      <th>Discount %</th>
                      <th>Add. Discount %</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadData || loading ? (
                      <>
                        <DataSpinner count="13" />
                      </>
                    ) : data.itemarray && data.itemarray.length > 0 ? (
                      data.itemarray.map((row, index) => {
                        let {
                          asondate,
                          billingtype,
                          branchid,
                          brand,
                          category,
                          series,
                          cessvaluationtype,
                          chartofaccount,
                          companyid,
                          dispatchqty,
                          receivedqty,
                          qty,
                          orderqty,
                          invoiceqty,
                          rateinclgst,
                          discount,
                          grade,
                          gst,
                          hsncode,
                          hsndescription,
                          id,
                          image,
                          inhandquantity,
                          inventoryaccount,
                          inventorydescription,
                          inventorytype,
                          itemdescription,
                          itemid,
                          itemname,
                          mpn,
                          openingqty,
                          org,
                          purchaseaccount,
                          purchasedescription,
                          rake,
                          rateperunit,
                          reorderpoint,
                          saleaccount,
                          saledescription,
                          size,
                          sku,
                          taxability,
                          thickness,
                          unit,
                          rate,
                          updatedprice,
                          userid,
                          value,
                          warehouse,
                          account,
                          additionaldiscount,
                          amount
                        } = row;

                        return (
                          <tr key={index} >
                            <td>
                              {index + 1}
                            </td>

                            <td>
                              {!category ? "--" : category}
                            </td>

                            <td >
                              {!brand ? "--" : brand}
                            </td>

                            <td >
                              {!itemname ? "--" : itemname}
                            </td>

                            <td>
                              {!itemid ? "--" : itemid}
                            </td>

                            <td>
                              {!unit ? "--" : unit}
                            </td>

                            <td>
                              {!orderqty ? "--" : orderqty}
                            </td>

                            <td>
                              {!invoiceqty ? "--" : invoiceqty}
                            </td>

                            <td>
                              {!receivedqty ? "--" : receivedqty}
                            </td>

                            {!data.showgst && <td>
                              {!rateinclgst ? "--" : rateinclgst}
                            </td>}

                            <td>
                              {!rate ? "--" : rate}
                            </td>

                            {data.showgst && <td>
                              {!gst ? "--" : gst}
                            </td>}

                            <td>
                              {!discount ? "--" : discount}
                            </td>

                            <td>
                              {!additionaldiscount ? "--" : additionaldiscount}
                            </td>

                            <td>
                              {(!amount || amount == "NaN") ? "--" : amount}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecordFoud count="13" name="Sales Invoice" />

                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className='justify-content-between'>
              <Col className="table-main-container d-flex ">
                {data.showgst && <div className='my-auto w-75'>
                  <h3>GST Break Down :</h3>
                  <Table responsive>
                    <thead className="tableContainer">
                      <tr>
                        <th>CGST(%)</th>
                        <th>SGST(%)</th>
                        <th>IGST(%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.itemarray?.map((item, ind) =>
                        data.samestate ? (
                          <tr key={ind}>
                            <td>{Number(item.gst) / 2}</td>
                            <td>{Number(item.gst) / 2}</td>
                            <td>0</td>
                          </tr>
                        ) : (
                          <tr key={ind}>
                            <td>0</td>
                            <td>0</td>
                            <td>{Number(item.gst)}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    {data.samestate ? (
                      <thead>
                        <tr>
                          <th>Total : {data.cgst}</th>
                          <th>{data.sgst}</th>
                          <th>{0}</th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr>
                          <th>Total : {0}</th>
                          <th>{0}</th>
                          <th>{data.igst}</th>
                        </tr>
                      </thead>
                    )}
                  </Table>
                </div>}
              </Col>
              <Col className='text-end'>
                <p className='mt-3 mr-5'>
                  <span className="viewpage-title calculation-header">Sub Total:</span>{" "}
                  <span className="viewpage-value calculation-value subtotal">{Number(data.subtotal).toFixed(2)}</span>
                </p>

                {(data.showgst && data.samestate) && <p className='w-100'>
                  <span className="viewpage-title calculation-header">CGST:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.cgst}</span>
                </p>}

                {(data.showgst && data.samestate) && <p className='w-100'>
                  <span className="viewpage-title calculation-header">SGST:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.sgst}</span>
                </p>}

                {(data.showgst && !data.samestate) && <p className='w-100'>
                  <span className="viewpage-title calculation-header">IGST:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.igst}</span>
                </p>}

                {data.tcs && <p className='w-100'>
                  <span className="viewpage-title calculation-header">TCS Amount:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.tsamount}</span>
                </p>}

                {data.tds && <p className='w-100'>
                  <span className="viewpage-title calculation-header">TDS Amount:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.tsamount}</span>
                </p>}

                <p className='w-100'>
                  <span className="viewpage-title calculation-header">Adjustment:</span>{" "}
                  <span className="viewpage-value calculation-value">{data.adjustmentamount}</span>
                </p>

                <p className=''>
                  <span className="viewpage-title calculation-header">Grand Total:</span>{" "}
                  <span className="viewpage-value calculation-value grandtotal">{data.grandtotal}</span>
                </p>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <h2>Journal</h2>
              </Col>
              <hr />
              <Col>
                <h3>Invoice ({data.salesinvoiceno})</h3>
              </Col>
            </Row> */}
            <Row className='d-none'>
              <Col ref={containerRef}>
                <div className="print-section">
                  <table>
                    <tr className="first-section">
                      <td className="saanvi_logo">
                        <img src={SaanviLogo} alt="Saanvi Logo" className='saanvi-logo' />
                      </td>
                      <td className="saanvi-brand-container">
                        <h1 className="saanvi-brand">SAANVI ENTERPRISES</h1>
                        <p className="saanvi-deails">M-8, Basanti Colony, Rourkela</p>
                        <p className='saanvi-deails'>
                          <span className='saanvi-gst'>GST :</span> <span className='saanvi-deails so'>21AEYPC5887R1ZH </span>
                          <span className="saanvi-gst">PAN :</span> <span>AEYPC5887R</span>
                        </p>
                        <p className='saanvi-deails'>
                          <span className='saanvi-deails'>State Name :</span> <span className='saanvi-deails'>Odisha, </span>
                          <span className='saanvi-deails'>Code :</span> <span className='saanvi-deails'>21</span>
                        </p>
                        <p className='saanvi-deails'>
                          <span className='saanvi-deails'>Email : saanvienterprises.rkl@gmail.com</span>
                        </p>
                      </td>
                      <td className="saanvi-contact-details">
                        <p className="printButton">CREDIT NOTE</p>
                        <p className="saanvi-deails">
                          <span className="saanvi-details-section cd">Phone No: </span>
                          <span className="saanvi-contact">+91-9437178212</span>
                        </p>
                        <p className="saanvi-deails">
                          <span className="saanvi-details-section cd">Website: </span>
                          <span>www.saanviavs.com</span>
                        </p>
                        <p className="saanvi-deails">
                          <span className="saanvi-details-section cd">Credit Note No.:  </span>
                          <span>{data.creditnoteno}</span>
                        </p>
                      </td>
                    </tr>
                    {/* <tr className="second-section">
                    <td className="gst-section" colSpan="2">
                      <span className="gst saanvi-deails">GSTIN: {gstno}</span>
                      <span className='saanvi-deails'>PAN: {pan}</span>
                    </td>
                    <td className="invoiceno-section">
                      <span className='saanvi-deails'>Invoice No: {data.invoiceno}</span>
                    </td>
                  </tr> */}
                    <tr className="third-section">
                      <td className="customer-details-section" colSpan="3">
                        <span className='saanvi-deails'>Customer Details</span>
                      </td>
                    </tr>
                    <tr className="four-section">
                      <td colSpan="3">
                        <div className="address-section">
                          <div className="first-col">
                            <p>
                              <span className="btitle">Bill To :</span>
                              <span className="desc">{data.commonname}</span>
                            </p>
                            <p>
                              <span className="btitle">Address :</span>
                              <span className="desc">{commondata?.baaddress}, {commondata?.bacity}</span>
                            </p>
                            <p>
                              <span className="btitle">State :</span>
                              <span className="desc part">{commondata?.bastate} - {commondata?.bapincode}, {commondata?.bacountry}</span>
                            </p>
                            <p>
                              <span className="btitle">Phone</span>
                              <span className="desc part">{
                                data.creditnotetype == "Other Company" ? commondata?.phoneno :
                                  data.creditnotetype == "Sub-Dealer" ? commondata?.phoneno :
                                    data.creditnotetype == "Franchise" ? commondata?.phoneno :
                                      data.creditnotetype == "Customer" ? commondata?.phone :
                                        "--"
                              }</span>
                            </p>
                            <p>
                              <span className="btitle">GSTIN</span>
                              <span className="desc">{
                                data.creditnotetype == "Return To Vendor" ? commondata?.gstno :
                                  data.creditnotetype == "Other Company" ? commondata?.gstno :
                                    data.creditnotetype == "Sub-Dealer" ? commondata?.gstno :
                                      data.creditnotetype == "Franchise" ? commondata?.gstno :
                                        data.creditnotetype == "Customer" ? commondata?.bdgstno :
                                          "--"
                              }</span>
                            </p>
                          </div>
                          <div className="second-col">
                            <p>
                              <span className="btitle">Ship To:</span>
                              <span className="desc">{data.commonname}</span>
                            </p>
                            <p>
                              <span className="btitle">Address</span>
                              <span className="desc">{commondata?.saaddress}, {commondata?.sacity}</span>
                            </p>
                            <p>
                              <span className="btitle">State</span>
                              <span className="desc">{commondata?.sastate} - {commondata?.sapincode}, {commondata?.sacountry}</span>
                            </p>
                            <p>
                              <span className="btitle">Phone</span>
                              <span className="desc part">{
                                data.creditnotetype == "Other Company" ? commondata?.phoneno :
                                  data.creditnotetype == "Sub-Dealer" ? commondata?.phoneno :
                                    data.creditnotetype == "Franchise" ? commondata?.phoneno :
                                      data.creditnotetype == "Customer" ? commondata?.phone :
                                        "--"
                              }</span>
                            </p>
                            <p>
                              <span className="btitle">GSTIN</span>
                              <span className="desc">{
                                data.creditnotetype == "Other Company" ? commondata?.gstno :
                                  data.creditnotetype == "Sub-Dealer" ? commondata?.gstno :
                                    data.creditnotetype == "Franchise" ? commondata?.gstno :
                                      data.creditnotetype == "Customer" ? commondata?.bdgstno :
                                        "--"
                              }</span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="five-section">
                      <td colSpan="3">
                        <div className="payment-section">
                          <div className="payment-details" style={{ width: "40%" }}>
                            <p className='mb-0'>
                              <span className="btitle cd">{data.creditnotetype == "Return To Vendor" ? "Vendor ID" : data.creditnotetype == "Other Company" ? "Company ID" : data.creditnotetype == "Sub-Dealer" ? "Sub-Dealer ID" : data.creditnotetype == "Franchise" ? "Franchise ID" : "Customer ID"}:</span>
                              <span className="desc ">{data.commonid}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle cd">Sales Person:</span>
                              <span className="desc">{data.salesperson}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle cd">Credit Note Date:</span>
                              <span className="desc">{indianFormatDate(data.creditnotedate || "2024/04/25")}</span>
                            </p>
                          </div>
                          <div className="payment-details" style={{ width: "40%" }}>
                            <p className='mb-0'>
                              <span className="btitle cd">Invoice No.:</span>
                              <span className="desc">{data.salesinvoiceno}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle cd">Invoice Date:</span>
                              <span className="desc">{indianFormatDate(data.salesinvoicedate || "2024/04/25")}</span>
                            </p>
                            <p className='mb-0'>
                              <span className="btitle cd">Created By:</span>
                              <span className="desc">{data.createdby}</span>
                            </p>
                          </div>
                          <div className="payment-details" style={{ width: "20%" }}>
                            <p className='mb-0'>
                              <span className="btitle cd">Remarks:</span>
                              <span className="desc">{data.remarks}</span>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="six-section">
                      <td colSpan="3">
                        <div>
                          <Table responsive className="table-color fs-4">
                            <thead>
                              <tr className="table-head-color">
                                <th >#</th>
                                <th>Item Name</th>
                                <th>HSN/SAC</th>
                                <th>UOM</th>
                                <th>QTY</th>
                                <th>
                                  <span className='d-block'>Received</span>
                                  <span className=''>Qty</span>
                                </th>
                                {!data.showgst && <th>
                                  <span className='d-block'>Rate</span>
                                  <span className=''>(Include of tax)</span>
                                </th>}
                                <th>Rate</th>
                                {data.showgst && <th>GST%</th>}
                                <th>Dis. %</th>
                                <th><span className='d-block'>Additional</span>
                                  <span className=''>Dis. %</span></th>

                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody className='tablebody'>
                              {loadData || loading ? (
                                <>
                                  <DataSpinner count="12" />

                                </>
                              ) : data.itemarray && data.itemarray.length > 0 ? (
                                data.itemarray.map((row, index) => {
                                  let {
                                    asondate,
                                    billingtype,
                                    branchid,
                                    brand,
                                    category,
                                    cessvaluationtype,
                                    chartofaccount,
                                    companyid,
                                    dispatchqty,
                                    qty,
                                    receivedqty,
                                    rateinclgst,
                                    discount,
                                    grade,
                                    gst,
                                    hsncode,
                                    hsndescription,
                                    id,
                                    image,
                                    inhandquantity,
                                    inventoryaccount,
                                    inventorydescription,
                                    inventorytype,
                                    itemdescription,
                                    itemid,
                                    itemname,
                                    mpn,
                                    openingqty,
                                    org,
                                    purchaseaccount,
                                    purchasedescription,
                                    rake,
                                    rateperunit,
                                    reorderpoint,
                                    saleaccount,
                                    saledescription,
                                    size,
                                    sku,
                                    taxability,
                                    thickness,
                                    unit,
                                    rate,
                                    updatedprice,
                                    userid,
                                    value,
                                    warehouse,
                                    account,

                                    additionaldiscount,

                                    amount
                                  } = row;

                                  return (
                                    <tr key={index} >
                                      <td>
                                        {index + 1}
                                      </td>

                                      <td >
                                        {!itemname ? "--" : itemname}
                                      </td>


                                      <td>
                                        {!hsncode ? "--" : hsncode}
                                      </td>

                                      <td>{!unit ? "--" : unit}</td>


                                      <td>
                                        {!qty ? "--" : qty}
                                      </td>

                                      <td>
                                        {!receivedqty ? "--" : receivedqty}
                                      </td>


                                      {!data.showgst && <td>
                                        {!rateinclgst ? "--" : rateinclgst}
                                      </td>}

                                      <td>
                                        {!rate ? "--" : rate}
                                      </td>


                                      {data.showgst && <td>
                                        {!gst ? "--" : gst}
                                      </td>}

                                      <td>
                                        {!discount ? "--" : discount}
                                      </td>


                                      <td>{!additionaldiscount ? "--" : additionaldiscount}</td>

                                      <td>
                                        {(!amount || amount == "NaN") ? "--" : amount}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <NoRecordFoud count="13" name="Sales Invoice" />
                              )}
                              {new Array(2).fill("")?.map((emtr, i) => (
                                <tr key={i}>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  {!data.showgst && <td></td>}
                                  <td></td>
                                  {data.showgst && <td></td>}
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td><hr /></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className="print-total">Sub Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{Number(data.subtotal).toFixed(2)}</td>
                              </tr>
                              {(data.showgst && data.samestate) && <tr>
                                <td></td>
                                <td className="print-total">CGST</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{data.cgst}</td>
                              </tr>}
                              {(data.showgst && data.samestate) && <tr>
                                <td></td>
                                <td className="print-total">SGST</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{data.sgst}</td>
                              </tr>}
                              {(data.showgst && !data.samestate) && <tr>
                                <td></td>
                                <td className="print-total">IGST</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{data.igst}</td>
                              </tr>}
                              {data.tcs && <tr>
                                <td></td>
                                <td className="print-total">TCS</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{Number(data.tsamount).toFixed(2)}</td>
                              </tr>}
                              {data.tds && <tr>
                                <td></td>
                                <td className="print-total">TDS</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{Number(data.tsamount).toFixed(2)}</td>
                              </tr>}
                              {(data.adjustmentamount != "0" && data.adjustmentamount != "") && <tr>
                                <td></td>
                                <td className="print-total">Adjustment</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}
                                <td></td>
                                <td></td>
                                <td className="font-bold td-top-border">{data.adjustmentamount}</td>
                              </tr>}
                              {new Array(8).fill("")?.map((emtr, i) => (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  {!data.showgst && <td></td>}
                                  <td></td>
                                  {data.showgst && <td></td>}
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                </tr>
                              ))}
                              <tr className="tableContainer">
                                <td></td>
                                <td className="print-total">Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="font-bold">
                                  {data?.itemarray?.reduce((receivedqty, curr) => {
                                    return Number(receivedqty) + Number(curr.receivedqty || 0);
                                  }, 0)}
                                </td>
                                <td ></td>
                                {!data.showgst && <td></td>}
                                <td></td>
                                {data.showgst && <td></td>}

                                <td></td>
                                <td className="font-bold">{data.grandtotal}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                      </td>
                    </tr >
                    <tr className="eight-section">
                      <td colSpan="3">
                        <div className='saanvi-info-container'>
                          <div className='saanvi-info'>
                            <div className='tandc'>
                              <Form.Group className="mb-3" controlId="tandc">
                                <Form.Label>
                                  Terms and Conditions
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  as="textarea"
                                  rows={6}
                                  name="tandc"
                                  value={data.tandc}
                                  className='tandcbox'
                                />
                                <Form.Control.Feedback type="invalid">
                                  Enter Terms and Conditions
                                </Form.Control.Feedback>

                              </Form.Group>
                            </div>
                          </div>
                          <div className='saanvi-info'>
                            <p className='saanv-certificate-section'>
                              <span className='certified'>Certified that particulars given above are true</span>
                              <span className='certified-section'>For SAANVI Enterprises</span>
                            </p>
                            <p className='authorized sign'>Authorized Signature </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="ten-section">
                      <td colSpan={3}>
                        <div className='text-center'>
                          <h4>SUBJECT TO ROURKELA JURISDICTION</h4>
                          <h5>This is a Computer Generated Invoice </h5>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row >
      </Container >
    </>
  );
}

export default ViewCreditNote;
