import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdDelete } from "react-icons/md";
import { salesOrderPagiData, termsAndConditionData } from "../../../redux/actions/salesOrder.actions";
import { projectData } from "../../../redux/actions/projects.actions";
import { customerPagiData } from "../../../redux/actions/customer.actions";
import { itemPagiData } from "../../../redux/actions/item.actions";
import { paymentTermData } from "../../../redux/actions/pt.actions";
import { vendorData } from "../../../redux/actions/vendor.actions";
import { companyData } from "../../../redux/actions/company.actions";
import { purchaseOrderData } from "../../../redux/actions/purchaseOrder.actions";
import {
  issueGoodsPagiData,
  salesInvoiceAdd,
  salesInvoiceAutoCount,
  salesInvoiceDocmnentUpload,
  salesInvoicePagiData,
} from "../../../redux/actions/salesInvoice.actions";
import { employeeData } from "../../../redux/actions/employee.actions";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { purchaseBillsData } from "../../../redux/actions/purchaseBills.actions";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { tdsData } from "../../../redux/actions/tds.actions";
import { tcsData } from "../../../redux/actions/tcs.actions";
import getMonthName from "../../../constants/getMonthName";
import { notifyError, notifySuccess, notifyWarning } from "../../../constants/toastAlert";
import axios from "axios";
import { FaCloudUploadAlt } from "react-icons/fa";
import CustomerInfoInSI from "../SalesInvoice/CustomerInfo";
// import TermsMaster from "../../Purchase/PurchaseOrder/TermsMaster";
import Select from "react-select";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { creditNoteAdd, creditNoteDocmnentUpload, creditNotePagiData, creditNoteUpdate } from "../../../redux/actions/creditNote.action";
import { IoDocumentAttach } from "react-icons/io5";


function EditCreditNote() {
  const URL = extractAndFormatURLPart("sales");
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear?.trim().split("-");
  let finalResult = finalYear?.map(str => str.replace(/"/g, '').slice(-2)).join('-');

  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  const { id } = useParams();
  const navigate = useNavigate();

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, branch, org, name } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const { gstno: usergstno, state } = useSelector((state) => state.authManager.userData && state.authManager.userData.companyaddress) || {};
  const { data: salesInvoiceAllData } = useSelector((state) => state.salesInvoiceData.salesInvoicePagiData || []);

  // const { ptData: paymentAllData } = useSelector((state) => state.ptData) || [];
  // const { data: vendorAllData } = useSelector((state) => state.vendorData.vendorData) || [];
  const { data: companyAllData } = useSelector((state) => state.companyData.companyData) || [];
  // const { data: salesOrderAllData } = useSelector((state) => state.salesOrderData.salesOrderPagiData) || [];
  const { data: customerAllData } = useSelector((state) => state.customerData.customerPagiData) || [];
  const { data: creditNoteAllData } = useSelector((state) => state.creditNoteData.creditNotePagiData) || [];
  // const { data: issueGoodsAllData } = useSelector((state) => state.salesInvoiceData.issueGoodsPagiData) || [];
  // const { data: employeeAllData } = useSelector((state) => state.employeeData.employeeData) || [];
  const { creditNoteDocument } = useSelector((state) => state.creditNoteData) || [];
  // const { data: purchaseBillAllData } = useSelector((state) => state.purchaseBillsData.purchaseBillsData);
  const { data: coaAllData } = useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const tdsMaster = useSelector((state) => state.tdsData.tdsData.data) || [];
  const tcsMaster = useSelector((state) => state.tcsData.tcsData.data) || [];
  // const { data: projectAllData } = useSelector((state) => state.projectData.projectData) || [];
  // const { termsAndConditionAllData } = useSelector((state) => state.salesOrderData) || [];
  const creditNoteData = !creditNoteAllData ? [] : creditNoteAllData.find((item) => item.id == id);

  // const employeeActiveData = !employeeAllData ? [] : employeeAllData.filter((item) => item.status == "Active");
  // const termAndCondition = termsAndConditionAllData?.filter((item) => item.status === "Active" && item.type == "Sales Invoice");
  // const termAndConditionDesc = termAndCondition.length > 0 && termAndCondition[0]?.description ? JSON.parse(termAndCondition[0].description) : ""
  // const sitandc = termAndConditionDesc.blocks && termAndConditionDesc.blocks[0].text || ""

  let words = companyname.split(" ") || "Saanvi Enterprisers";
  let firstLetters = words.map(word => word.charAt(0));
  let companynameFirstWord = firstLetters.join("");

  // const companyName = useSelector((state) => state.companyData.companyData.data) || [];
  // const { salesOrderCount, salesOrderDocument } = useSelector((state) => state.salesOrderData) || 0;
  // const { data: quotationAllData } = useSelector((state) => state.quotationData.quotationPagiData) || [];
  // const { data: categoryAllData } = useSelector((state) => state.categoryData.categoryPagiData) || [];
  // const { data: itemAllData } = useSelector((state) => state.itemData.itemPagiData) || [];
  // const suggestedItemMaster = useSelector((state) => state.suggestedItemData.suggestedItemData.data) || [];
  // const { purchaseOrderData: purchaseOrderAllData } = useSelector((state) => state.purchaseOrderData) || [];

  //state part
  const [data, setData] = useState({
    id: creditNoteData ? creditNoteData.id : "--",
    creditnotetype: creditNoteData ? creditNoteData.creditnotetype : "--",
    commonname: creditNoteData ? creditNoteData.commonname : "--",
    commonid: creditNoteData ? creditNoteData.commonid : "--",
    commonnamealldata: creditNoteData ? creditNoteData.commonnamealldata : [],
    salesinvoiceno: creditNoteData ? creditNoteData.salesinvoiceno : "--",
    salesinvoicedate: creditNoteData ? creditNoteData.salesinvoicedate : "--",
    creditnoteno: creditNoteData ? creditNoteData.creditnoteno : "--",
    creditnotedate: creditNoteData ? creditNoteData.creditnotedate : "--",
    salesperson: creditNoteData ? creditNoteData.salesperson : "--",
    createdby: creditNoteData ? creditNoteData.createdby : "--",
    account: creditNoteData ? creditNoteData.account : "--",
    remarks: creditNoteData ? creditNoteData.remarks : "--",
    customernotes: creditNoteData ? creditNoteData.customernotes : "--",
    documents: creditNoteData ? creditNoteData.documents : "--",
    uploadfile: creditNoteData ? creditNoteData.uploadfile : [],
    showgst: creditNoteData ? creditNoteData.showgst == "true" ? true : false : true,
    itemarray: creditNoteData ? creditNoteData.itemarray : [],
    subtotal: creditNoteData ? creditNoteData.subtotal : 0.0,
    cgst: creditNoteData ? creditNoteData.cgst : 0.0,
    sgst: creditNoteData ? creditNoteData.sgst : 0.0,
    igst: creditNoteData ? creditNoteData.igst : 0.0,
    tcs: creditNoteData ? creditNoteData.tcs == "on" ? true : false : false,
    tds: creditNoteData ? creditNoteData.tds == "on" ? true : false : false,
    tcsvalue: creditNoteData ? creditNoteData.tcsvalue : "--",
    tdsvalue: creditNoteData ? creditNoteData.tdsvalue : "--",
    tsamount: creditNoteData ? creditNoteData.tsamount : 0.0,
    adjustmentamount: creditNoteData ? creditNoteData.adjustmentamount : 0.0,
    grandtotal: creditNoteData ? creditNoteData.grandtotal : "--",
    tandc: creditNoteData ? creditNoteData.tandc : "--",
    status: creditNoteData ? creditNoteData.status : "--",
    userid: creditNoteData ? creditNoteData.userid : "--",
    companyid: creditNoteData ? creditNoteData.companyid : "--",
    branchid: creditNoteData ? creditNoteData.branchid : "--",
    org: creditNoteData ? creditNoteData.org : "--",
    financialyear: creditNoteData ? creditNoteData.financialyear : "--",
    company: creditNoteData ? creditNoteData.company : "--",
    branch: creditNoteData ? creditNoteData.branch : "--",
    samestate: creditNoteData ? creditNoteData.samestate == "true" ? true : false : true,
    account: creditNoteData ? creditNoteData.account : "--"
  });
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [selectedCustomerInfo, setSelectedCustomerInfo] = useState({});
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);

  // const sameState =
  //   // (
  //   //   // Comparing the first elements of customergst and usergstno arrays
  //   //   customergst.length > 0 && customergst[0] == usergstno.length > 0 && usergstno[0] && customergst[1] == usergstno[1]
  //   // ) || 
  //   (
  //     // Comparing state based on creditnotetype
  //     state == (
  //       data.creditnotetype === "Other Company" ? data?.commonnamealldata[0]?.state :
  //         data.creditnotetype === "Sub-Dealer" ? data?.commonnamealldata[0]?.state :
  //           data.creditnotetype === "Franchise" ? data?.commonnamealldata[0]?.state :
  //             data.creditnotetype === "Customer" && data.commonnamealldata[0]?.bastate
  //     )
  //   );



  //onchange part start
  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;

    if (name === "creditnotetype") {
      setData((prevData) => ({
        ...prevData,
        commonname: "",
        commonid: "",
        salesinvoiceno: "",
        salesinvoicedate: "",
        creditnotedate: "",
        salesperson: "",
        account: "",
        remarks: "",
        customernotes: "",
        itemarray: [],
        cgst: "",
        sgst: "",
        igst: "",
        tcs: "",
        tds: "",
        tcsvalue: "",
        tdsvalue: "",
        tsamount: "",
        adjustmentamount: "",
        grandtotal: "",
        tandc: ""
      }));
    }

    if (name === "salesinvoiceno") {
      const selectedSalesInvoice = salesInvoiceAllData && salesInvoiceAllData?.find((item) => item.invoiceno === value);

      setData((prevData) => ({
        ...prevData,
        salesinvoicedate: selectedSalesInvoice?.invoicedate,
        salesperson: selectedSalesInvoice?.salesperson,
        itemarray: selectedSalesInvoice?.itemarray,
        showgst: selectedSalesInvoice?.showgst == "true" ? true : false,
        tandc: selectedSalesInvoice?.tandc,
        cgst: "",
        sgst: "",
        igst: "",
        adjustmentamount: "",
        grandtotal: "",
      }));

    }

    if (name === "documents") {
      dispatch(creditNoteDocmnentUpload(files));
    }

    if (name === "tcs") {
      const isChecked = e.target.checked;
      if (isChecked) {
        setData((prevData) => ({
          ...prevData,
          tcs: isChecked,
          tds: !isChecked,
          tdsvalue: "",
          tcsvalue: "",
          tsamount: ""
        }));
      }
    }

    if (name === "tds") {
      const isChecked = e.target.checked;
      if (isChecked) {
        setData((prevData) => ({
          ...prevData,
          tcs: !isChecked,
          tds: isChecked,
          tdsvalue: "",
          tcsvalue: "",
          tsamount: ""
        }));
      }
    }
    // if (name == "tcsvalue") {
    //   setData((prev) => ({ ...prev, tcsvalue: value, tsamount: 0 }));

    //   if (value) {
    //     let findTcs = tcsMaster?.find((tcs) => tcs.taxname == value);
    //     const calculatedtsamount = (
    //       (Number(data.subtotal) * Number(findTcs.ratepercent)) /
    //       100
    //     ).toFixed(2);

    //     setData((prev) => ({ ...prev, tsamount: calculatedtsamount }));
    //     return;
    //   } else {
    //     setData((prev) => ({ ...prev, tsamount: 0 }));
    //     return;
    //   }
    // }

    // if (name == "tdsvalue") {
    //   setData((prev) => ({ ...prev, tcsvalue: value, tsamount: 0 }));

    //   if (value) {
    //     let findTds = tdsMaster.find((tds) => tds.taxname == value);
    //     const tsamount = (
    //       (Number(data.subtotal) * Number(findTds.ratepercent)) /
    //       100
    //     ).toFixed(2);
    //     setData((prev) => ({ ...prev, tsamount: `-${tsamount}` }));
    //   } else {
    //     setData((prev) => ({ ...prev, tsamount: 0 }));
    //   }
    // }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //onchange part end

  // Define different options arrays for common name start
  const customerOptions = customerAllData && customerAllData?.map((customer) => {
    if (customer.customertype === "Retail") {
      return {
        value: customer.fullname,
        label: customer.fullname,
      };
    }
    else {
      return {
        value: customer.bdcompany,
        label: customer.bdcompany,
      };
    }
  });

  const franchiseCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Franchise")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  // For Other Company companies
  const otherCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Other Company")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  const subDealerCompanyOptions = companyAllData
    ?.filter((company) => company.type === "Sub-Dealer")
    .map((company) => ({
      value: company.companyname,
      label: company.companyname,
    }));

  const updateOptions = (creditnotetype) => {
    switch (creditnotetype) {
      case "Other Company":
        setOptions(otherCompanyOptions);
        break;
      case "Sub-Dealer":
        setOptions(subDealerCompanyOptions);
        break;
      case "Franchise":
        setOptions(franchiseCompanyOptions);
        break;
      default:
        setOptions(customerOptions);
        break;
    }
  };
  // Define different options arrays for common name end

  //useEffect part start
  //default data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    branch: branch,
    companyname: companyname,
    org: org,
  };

  useEffect(() => {
    updateOptions(creditNoteData ? creditNoteData.creditnotetype : "--");
    setLoading(!loading);

    dispatch(companyData(backendData, setLoading));
    dispatch(customerPagiData(backendData, setLoading));
    dispatch(salesInvoicePagiData(backendData, setLoading));
    dispatch(creditNotePagiData(backendData, setLoading));
    dispatch(coaPagiData(backendData, setLoading));
    dispatch(tdsData(backendData, setLoading));
    dispatch(tcsData(backendData, setLoading));
    dispatch(creditNotePagiData(backendData, setLoading));

    setData({
      id: creditNoteData ? creditNoteData.id : "--",
      creditnotetype: creditNoteData ? creditNoteData.creditnotetype : "--",
      commonname: creditNoteData ? creditNoteData.commonname : "--",
      commonid: creditNoteData ? creditNoteData.commonid : "--",
      commonnamealldata: creditNoteData ? creditNoteData.commonnamealldata : [],
      salesinvoiceno: creditNoteData ? creditNoteData.salesinvoiceno : "--",
      salesinvoicedate: creditNoteData ? creditNoteData.salesinvoicedate : "--",
      creditnoteno: creditNoteData ? creditNoteData.creditnoteno : "--",
      creditnotedate: creditNoteData ? creditNoteData.creditnotedate : "--",
      salesperson: creditNoteData ? creditNoteData.salesperson : "--",
      createdby: creditNoteData ? creditNoteData.createdby : "--",
      account: creditNoteData ? creditNoteData.account : "--",
      remarks: creditNoteData ? creditNoteData.remarks : "--",
      customernotes: creditNoteData ? creditNoteData.customernotes : "--",
      documents: creditNoteData ? creditNoteData.documents : "--",
      uploadfile: creditNoteData ? creditNoteData.uploadfile : [],
      showgst: creditNoteData ? creditNoteData.showgst == "true" ? true : false : true,
      itemarray: creditNoteData ? creditNoteData.itemarray : [],
      subtotal: creditNoteData ? creditNoteData.subtotal : 0.0,
      cgst: creditNoteData ? creditNoteData.cgst : 0.0,
      sgst: creditNoteData ? creditNoteData.sgst : 0.0,
      igst: creditNoteData ? creditNoteData.igst : 0.0,
      tcs: creditNoteData ? creditNoteData.tcs == "on" ? true : false : false,
      tds: creditNoteData ? creditNoteData.tds == "on" ? true : false : false,
      tcsvalue: creditNoteData ? creditNoteData.tcsvalue : "--",
      tdsvalue: creditNoteData ? creditNoteData.tdsvalue : "--",
      tsamount: creditNoteData ? creditNoteData.tsamount : 0.0,
      adjustmentamount: creditNoteData ? creditNoteData.adjustmentamount : 0.0,
      grandtotal: creditNoteData ? creditNoteData.grandtotal : "--",
      tandc: creditNoteData ? creditNoteData.tandc : "--",
      status: creditNoteData ? creditNoteData.status : "--",
      userid: creditNoteData ? creditNoteData.userid : "--",
      companyid: creditNoteData ? creditNoteData.companyid : "--",
      branchid: creditNoteData ? creditNoteData.branchid : "--",
      org: creditNoteData ? creditNoteData.org : "--",
      financialyear: creditNoteData ? creditNoteData.financialyear : "--",
      company: creditNoteData ? creditNoteData.company : "--",
      branch: creditNoteData ? creditNoteData.branch : "--",
      samestate: creditNoteData ? creditNoteData.samestate == "true" ? true : false : true,
      account: creditNoteData ? creditNoteData.account : "--"
    });
  }, [
    dispatch,
    loadData
  ]);

  useEffect(() => {
    if (data.commonname) {

      let selectedCommon;
      let selectinvoice;

      if (data.creditnotetype === "Sub-Dealer") {
        selectedCommon = companyAllData?.find(
          (item) => item.companyname === data.commonname && item.type === "Sub-Dealer"
        );
      } else if (data.creditnotetype === "Franchise") {
        selectedCommon = companyAllData?.find(
          (item) => item.companyname === data.commonname && item.type === "Franchise"
        );
      } else if (data.creditnotetype === "Other Company") {
        selectedCommon = companyAllData?.find(
          (item) => item.companyname === data.commonname && item.type === "Other Company"
        );
      } else {
        selectedCommon = customerAllData?.find(
          (item) => item.bdcompany === data.commonname || item.fullname === data.commonname
        )
        setSelectedCustomerInfo(selectedCommon);
      }

      if (selectedCommon || selectinvoice) {
        // Filter the desired key from the selected common item
        let filteredKey;
        // let invoiceno;
        if (data.creditnotetype === "Sub-Dealer") {
          // Filter the key you want from selectedCommon for "Sub-Dealer", "Franchise", "Other Company" types
          filteredKey = selectedCommon.companyid;;
        } else if (data.invoicetype === "Franchise") {
          filteredKey = selectedCommon.companyid;
        } else if (data.invoicetype === "Other Company") {
          filteredKey = selectedCommon.companyid;
        } else {
          // Customer case
          filteredKey = selectedCommon.customerid;

        }
        setData((prevData) => ({
          ...prevData,
          // commonname:data.commonname,
          commonid: filteredKey,
          // salesinvoiceno: "",
          // salesinvoicedate: "",
          // creditnotedate: "",
          // salesperson: "",
          // account: "",
          // remarks: "",
          // customernotes: "",
          // itemarray: [],
          commonnamealldata: [selectedCommon],
          samestate: (
            // Comparing state based on creditnotetype and commonname selection
            state == (
              data.creditnotetype === "Other Company" ? selectedCommon?.state :
                data.creditnotetype === "Sub-Dealer" ? selectedCommon?.state :
                  data.creditnotetype === "Franchise" ? selectedCommon?.state :
                    data.creditnotetype === "Customer" && selectedCommon?.bastate
            )
          ),
          tcs: selectedCommon.tcs != "" ? true : false,
          tds: selectedCommon.tds != "" ? true : false,
          tcsvalue: selectedCommon.tcs,
          tdsvalue: selectedCommon.tds,
        }));
      }
    }
  }, [data.commonname])

  useEffect(() => {
    const calculateTsAmount = (subtotal, ratePercent) => {
      return ((Number(subtotal) * Number(ratePercent || 0)) / 100).toFixed(2);
    };

    if (data.tdsvalue) {
      let findTds = tdsMaster.find((tds) => tds.taxname === data.tdsvalue);
      if (findTds) {
        const tsamount = calculateTsAmount(data.subtotal, findTds.ratepercent);
        setData((prev) => ({ ...prev, tsamount: `-${tsamount}` }));
      } else {
        setData((prev) => ({ ...prev, tsamount: 0 }));
      }
    } else if (data.tcsvalue) {
      let findTcs = tcsMaster.find((tcs) => tcs.taxname === data.tcsvalue);
      if (findTcs) {
        const calculatedtsamount = calculateTsAmount(data.subtotal, findTcs.ratepercent);
        setData((prev) => ({ ...prev, tsamount: calculatedtsamount }));
      } else {
        setData((prev) => ({ ...prev, tsamount: 0 }));
      }
    } else {
      setData((prev) => ({ ...prev, tsamount: 0 }));
    }
  }, [data.tcsvalue, data.tdsvalue, data.itemarray]);
  //useEffect part end

  //Auto generate Id start
  function autoGenerateID() {
    if (creditNoteAllData?.length === 0) {
      return "1";
    } else {
      const currentId = creditNoteAllData && creditNoteAllData[0]?.creditnoteno?.split("/")[2];
      return String(parseInt(currentId) + 1);
    }
  };

  //Auto generate Id end

  //Table part start
  function handleAddNewRow() {
    setData((prevTableData) => ({
      ...prevTableData,
      itemarray: [...prevTableData.itemarray, {}],
    }));
  }

  const handleTableRowChange = (e, rowIndex) => {
    const { name, value } = e.target;

    setData((prevData) => {
      const updatedData = [...prevData.itemarray]; // Copy the itemarray from the previous state

      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [name]: value,
      };

      return {
        ...prevData,
        itemarray: updatedData,
      };
    });

  };

  const handleTableRowDelete = (rowIndex) => {
    setData((prevTableData) => {
      const updatedTableData = [...prevTableData.itemarray];
      updatedTableData.splice(rowIndex, 1);
      return { ...prevTableData, itemarray: updatedTableData };
    });
  };
  //Table part end

  // Manage Terms Start
  const handleClose = () => setShow(false);
  // Manage Terms End

  //Sales invoice footer part start

  const handleUpdateAsApproved = () => {
    if (
      !data.creditnotetype ||
      !data.commonname ||
      !data.salesinvoiceno ||
      !data.salesinvoicedate ||
      !data.creditnotedate ||
      !data.account

    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    const formData = {
      ...data,
      status: "Paid",
      uploadfile: creditNoteDocument ? creditNoteDocument : [],
      // balancedue: data.grandtotal,

    };

    dispatch(creditNoteUpdate(formData, navigate));
  };

  const handleUpdateAsDraft = () => {
    if (
      !data.creditnotetype ||
      !data.commonname ||
      !data.salesinvoiceno ||
      !data.salesinvoicedate ||
      !data.creditnotedate ||
      !data.account

    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    const formData = {
      ...data,
      status: "Draft",
      uploadfile: creditNoteDocument ? creditNoteDocument : [],
      // balancedue: data.grandtotal,

    };

    dispatch(creditNoteUpdate(formData, navigate));
  };

  const handleCancel = () => {
    navigate(`/sales/creditNote`);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12} className="d-flex justify-content-between">
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/sales/creditNote")}
              />
              Edit <span className="form-title">Credit Note</span>
            </h1>
          </Col>
          <hr />
          <Col sm={12}>
            <Form className="form-lable-name">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="creditnotetype">
                    <Form.Label>
                      Credit Note Type<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="creditnotetype"
                      onChange={handleChange}
                      value={data.creditnotetype}
                      isInvalid={data.creditnotetype === "" && showEmptyError}
                    >
                      <option value="Customer">Customer</option>
                      <option value="Other Company">Other Company</option>
                      <option value="Sub-Dealer">Sub-Dealer</option>
                      <option value="Franchise">Franchise</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="commonname">
                    <Form.Label className="d-flex justify-content-between">
                      <span>
                        {
                          // data.creditnotetype == "Return To Vendor"
                          //   ? "Vendor"
                          //   : 
                          data.creditnotetype == "Other Company"
                            ? "Company"
                            : data.creditnotetype == "Sub-Dealer"
                              ? "Sub-Dealer"
                              : data.creditnotetype == "Franchise"
                                ? "Franchise"
                                : "Customer Name"
                        }
                        <span className="star"> *</span>
                        {data.creditnotetype == "Customer" && <CustomerInfoInSI selectedCustomerInfo={selectedCustomerInfo} />}
                      </span>
                    </Form.Label>
                    <Select
                      options={options}
                      value={options && options?.find((option) => option.value === data.commonname)}
                      onChange={(selectedOption) =>
                        setData((prevData) => ({
                          ...prevData,
                          commonname: selectedOption ? selectedOption.value : "",
                        }))
                      }
                      placeholder="Select Name"
                      isSearchable
                      className={data.commonname === "" && showEmptyError ? "empty-feild" : ""}
                      onFocus={() => updateOptions(data.creditnotetype)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Select{" "}
                      {
                        // data.creditnotetype == "Return To Vendor"
                        //   ? "Vendor"
                        // : 
                        data.creditnotetype == "Other Company"
                          ? "Company"
                          : data.creditnotetype == "Sub-Dealer"
                            ? "Sub-Dealer"
                            : data.creditnotetype == "Franchise"
                              ? "Franchise"
                              : "Customer Name"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="salesinvoiceno">
                    <Form.Label>
                      Sales Invoice No<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="salesinvoiceno"
                      value={data.salesinvoiceno}
                      onChange={handleChange}
                      onFocus={() => data.commonname == "" && notifyWarning(`First Select ${data.creditnotetype} Name`)}
                      isInvalid={data.salesinvoiceno === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {salesInvoiceAllData && salesInvoiceAllData.length > 0 ? (
                        salesInvoiceAllData.map((item, index) => {
                          const { commonid, invoiceno } = item;
                          if (commonid === data.commonid) {
                            return (
                              <option key={index} value={invoiceno}>
                                {invoiceno}
                              </option>
                            );
                          }
                          return null;
                        })
                      ) : (
                        <option value="">
                          No Sales Invoice available
                        </option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Sales Invoice No
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="salesinvoicedate">
                    <Form.Label>Sales Invoice Date<span className="star"> *</span></Form.Label>
                    <Form.Control
                      type="date"
                      name="salesinvoicedate"
                      value={data.salesinvoicedate}
                      // min={nextDay.toISOString().split("T")[0]}
                      // max={new Date().toISOString().split("T")[0]}
                      onChange={handleChange}
                      disabled
                      isInvalid={data.salesinvoicedate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Choose Sales Invoice Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* {data.invoicetype != "Customer" && <Col md={3}>
                <Form.Group className="mb-3" controlId="gino">
                  <Form.Label>
                    GI No<span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="gino"
                    value={data.gino}
                    onChange={handleChange}
                    isInvalid={data.gino === "" && showEmptyError}
                  >
                    <option value="">Select</option>
                    {issueGoodsAllData && issueGoodsAllData.length > 0 ? (
                      issueGoodsAllData.map((item, index) => {
                        const { gino, issuetoname } = item;
                        if (issuetoname === data.commonname) {
                          return (
                            <option key={index} value={gino}>
                              {gino}
                            </option>
                          );
                        }
                        return null;
                      })
                    ) : (
                      <option value="">
                        No GI NO. available on that Customer
                      </option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select GI No
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>} */}
              </Row>
              <Row>
                {/* {data.invoicetype == "Customer" ? (
                  <Col md={3}>
                    <Form.Group controlId="saleorderno">
                    <Form.Label>Sales Order No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sales Order No"
                      name="saleorderno"
                      value={data.saleorderno}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.prefno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Sales Order No
                    </Form.Control.Feedback>
                  </Form.Group>
                    <Form.Group className="mb-3" controlId="saleorderno">
                      <Form.Label>
                        Sales Order No<span className="star"> *</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="saleorderno"
                        value={data.saleorderno}
                        onChange={handleChange}
                        onFocus={() => data.commonid == "" && notifyWarning("First Select Customer Name")}
                        isInvalid={data.saleorderno === "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {salesOrderAllData && salesOrderAllData.length > 0 ? (
                          salesOrderAllData.map((item, index) => {
                            const { customerid, saleorderno } = item;
                            if (customerid === data.commonid) {
                              return (
                                <option key={index} value={saleorderno}>
                                  {saleorderno}
                                </option>
                              );
                            }
                            return null;
                          })
                        ) : (
                          <option value="">
                            No Sales Order available on that Customer
                          </option>
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Select Sales Order No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ) : data.invoicetype == "Return To Vendor" ? (
                  <Col md={3}>
                    <Form.Group controlId="purchaseinvoiceno">
                      <Form.Label>Purchase Invoice No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Purchase Invoice No"
                        name="purchaseinvoiceno"
                        value={data.purchaseinvoiceno}
                        onChange={handleChange}
                        required
                        disabled
                      // isInvalid={data.prefno === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Purchase Invoice No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ) : (
                  <Col md={3}>
                    <Form.Group controlId="purchaseorderno">
                      <Form.Label>Purchase Order No</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Purchase Order No"
                        name="purchaseorderno"
                        value={data.purchaseorderno}
                        onChange={handleChange}
                        required
                        disabled
                      // isInvalid={data.prefno === "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Purchase Order No
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                )} */}
                <Col md={3}>
                  <Form.Group controlId="creditnoteno">
                    <Form.Label>Credit Note No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Credit Note No"
                      name="creditnoteno"
                      value={data.creditnoteno}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.prefno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Credit Note No.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="creditnotedate">
                    <Form.Label>Credit Note Date<span className="star"> *</span></Form.Label>
                    <Form.Control
                      type="date"
                      name="creditnotedate"
                      value={data.creditnotedate}
                      min={`${parseInt(
                        finalYear[0].replace('"', ""),
                        10
                      )}-04-01`}
                      max={`${parseInt(
                        finalYear[1].replace('"', ""),
                        10
                      )}-03-31`}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.creditnotedate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Choose Credit Note Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="salesperson">
                    <Form.Label>Sales Person</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sales Person"
                      name="salesperson"
                      value={data.salesperson}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Sales Person
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="createdby">
                    <Form.Label>Created By</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Created By"
                      name="createdby"
                      value={data.createdby}
                      onChange={handleChange}
                      required
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter createdby
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label>
                    Account<span className="star"> *</span>
                  </Form.Label>
                  <Form.Select
                    type="text"
                    name="account"
                    value={data.account}
                    onChange={handleChange}
                    isInvalid={data.account === "" && showEmptyError}
                    className="form-control "
                  >
                    <option value="">--Select--</option>
                    {coaAllData && coaAllData.length > 0 ? (
                      Object.entries(
                        coaAllData.reduce((groups, item) => {
                          if (!groups[item.accounttype]) {
                            groups[item.accounttype] = [];
                          }
                          groups[item.accounttype].push(
                            item.accountname
                          );
                          return groups;
                        }, {})
                      ).map(([accounttype, accountname], index) => (
                        <optgroup key={index} label={accounttype}>
                          {accountname.map((name, nameIndex) => (
                            <option key={nameIndex} value={name}>
                              {name}
                            </option>
                          ))}
                        </optgroup>
                      ))
                    ) : (
                      <option value="">No Account available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Account
                  </Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="remarks">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Remarks"
                      name="remarks"
                      value={data.remarks}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Remarks
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="customernotes">
                    <Form.Label>Customer Notes</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Customernotes"
                      name="customernotes"
                      value={data.customernotes}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Customer Notes
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="documents">
                    <Form.Label>Upload File</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Select File"
                      name="documents"
                      // value={data.documents}
                      onChange={handleChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Upload Your File
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="documents">
                    <Form.Label>
                      Uploaded Documents
                    </Form.Label>
                    {
                      data.uploadfile?.length > 0 ? data.uploadfile?.map((item, index) => {
                        const { } = item;

                        return (
                          <>
                            <div className="uploaded-data" key={id}>
                              <span><a href={item} target="blank"><IoDocumentAttach /></a></span>
                            </div>
                          </>
                        );

                      }) :
                        <div className="uploaded-data">
                          <span>No Document Found</span>
                        </div>

                    }
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
          <hr />
          <Col md={12} className="table-main-container ">
            <Table responsive>
              <thead className="tableContainer ">
                <tr>
                  <th>#</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Item Name</th>
                  <th>Item ID</th>
                  <th>UOM</th>
                  <th>Order QTY</th>
                  <th>Invoice QTY</th>
                  <th>Received QTY</th>
                  {!data.showgst && <th>Rate Incl. GST</th>}
                  <th>Rate</th>
                  {data.showgst && <th>GST%</th>}
                  <th>Discount %</th>
                  <th>Additional Discount %</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <DataSpinner count="13" />
                  </>
                ) : data?.itemarray?.length > 0 ? (
                  data.itemarray.map((row, index) => {
                    let {
                      asondate,
                      billingtype,
                      branchid,
                      brand,
                      category,
                      cessvaluationtype,
                      chartofaccount,
                      companyid,
                      dispatchqty,
                      qty,
                      orderqty,
                      invoiceqty,
                      receivedqty,
                      rateinclgst,
                      discount,
                      grade,
                      gst,
                      hsncode,
                      hsndescription,
                      id,
                      image,
                      inhandquantity,
                      inventoryaccount,
                      inventorydescription,
                      inventorytype,
                      itemdescription,
                      itemid,
                      itemname,
                      mpn,
                      openingqty,
                      org,
                      purchaseaccount,
                      purchasedescription,
                      rake,
                      rateperunit,
                      reorderpoint,
                      saleaccount,
                      saledescription,
                      size,
                      sku,
                      taxability,
                      thickness,
                      unit,
                      rate,
                      updatedprice,
                      userid,
                      value,
                      warehouse,
                      account,
                      amount,
                      additionaldiscount
                    } = row;
                    return (
                      <tr key={index}>
                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="slno"
                              className="text-center"
                              name="slno"
                              value={index + 1}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={index + 1}
                              required
                              disabled
                            // isInvalid={data.prefno === "" && showEmptyError}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="category"
                              name="category"
                              value={category}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={category}
                              required
                              disabled
                            // isInvalid={data.prefno === "" && showEmptyError}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Brand"
                              name="brand"
                              value={brand}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={brand}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Item Name"
                              name="itemname"
                              value={itemname}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={itemname}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="Item Id"
                              name="itemid"
                              value={itemid}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={itemid}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="UOM"
                              name="uom"
                              value={unit}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={unit}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="orderqty"
                              value={row.orderqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={row.orderqty}
                              className="text-center"
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="invoiceqty"
                              value={row.invoiceqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={row.invoiceqty}
                              className="text-center"
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="0"
                              name="receivedqty"
                              value={row.receivedqty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={row.receivedqty}
                              className="text-center"
                              required
                            />
                          </Form.Group>
                        </td>

                        {!data.showgst && <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="Rate Incl GST"
                              name="rateinclgst"
                              value={row.rateinclgst = Number(row.rate) + ((Number(row.rate) * Number(row.gst)) / 100)}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={row.rateinclgst}
                              className="text-center"
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>}

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              placeholder="Rate"
                              name="rate"
                              value={rate}
                              onChange={(e) => handleTableRowChange(e, index)}
                              title={rate}
                              required
                              disabled
                            />
                          </Form.Group>
                        </td>

                        {data.showgst && (
                          <td>
                            <Form.Group controlId="formBasicId">
                              <Form.Control
                                type="text"
                                placeholder="GST%"
                                name="gst"
                                value={gst}
                                onChange={(e) => handleTableRowChange(e, index)}
                                title={gst}
                                className="text-center"
                                required
                                disabled
                              />
                            </Form.Group>
                          </td>
                        )}

                        <td>
                          <Form.Control
                            type="number"
                            placeholder="0.00"
                            name="discount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            title={row.discount}
                            value={row.discount}
                            className="text-center"
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td>
                          <Form.Control
                            className="text-center"
                            type="number"
                            placeholder="0.00"
                            name="additionaldiscount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={row.additionaldiscount}
                            title={row.additionaldiscount}
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td>
                          <Form.Control
                            disabled
                            className="text-right"
                            type="number"
                            placeholder="0.00"
                            name="amount"
                            value={
                              data.showgst ?
                                row.amount = (Number(receivedqty) * (Number(rate) - (Number(rate) * (Number(discount || 0) + Number(additionaldiscount || 0)) / 100))).toFixed(2) :
                                row.amount = (Number(receivedqty) * (Number(rateinclgst) - (Number(rateinclgst) * (Number(discount || 0) + Number(additionaldiscount || 0)) / 100))).toFixed(2)
                            }
                            onChange={(e) => handleTableRowChange(e, index)}
                            title={row.amount}
                          //isInvalid={tillDate === "" && showEmptyError}
                          />
                        </td>

                        <td className="d-flex align-items-center">
                          <span className="mt-3">
                            <MdDelete
                              className="deleteIcon table-delete"
                              onClick={() => handleTableRowDelete(index)}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecordFoud count="13" name="Items" />
                )}
              </tbody>
            </Table>
          </Col>
          <hr />
          <Col md={12} className="">
            <Row>
              <Col sm={4} className="my-auto">
                {data.showgst && <Col className="table-main-container">
                  <h3>GST Break Down :</h3>
                  <Table responsive>
                    <thead className="tableContainer">
                      <tr>
                        <th>CGST(%)</th>
                        <th>SGST(%)</th>
                        <th>IGST(%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.itemarray?.map((item, ind) =>
                        data.samestate ? (
                          <tr key={ind}>
                            <td>{Number(item.gst) / 2}</td>
                            <td>{Number(item.gst) / 2}</td>
                            <td>0</td>
                          </tr>
                        ) : (
                          <tr key={ind}>
                            <td>0</td>
                            <td>0</td>
                            <td>{Number(item.gst)}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    {data.samestate ? (
                      <thead>
                        <tr>
                          <th>Total : {data.cgst}</th>
                          <th>{data.sgst}</th>
                          <th>{0}</th>
                        </tr>
                      </thead>
                    ) : (
                      <thead>
                        <tr>
                          <th>Total : {0}</th>
                          <th>{0}</th>
                          <th>{data.igst}</th>
                        </tr>
                      </thead>
                    )}
                  </Table>
                </Col>}
              </Col>
              <Col className="">
                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Sub Total:</Form.Label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder="0.00"
                          name="subtotal"
                          value={Number(data.subtotal = data.itemarray?.length > 0 ? data.itemarray?.reduce((acc, curr) => acc + (Number(curr.amount) || 0), 0) : 0).toFixed(2)}
                          onChange={handleChange}
                          title={data.subtotal}
                          required
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {data.showgst && data.samestate ? (
                  <Row className="mt-4">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>CGST:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="cgst"
                            placeholder="0.00"
                            disabled
                            value={data.showgst ? data.cgst = data.samestate ? (data.itemarray?.reduce((acc, curr) => {
                              // return acc + (curr.qty * ((curr.rate * curr.gst) / 100)) / 2
                              return acc + ((curr.amount * curr.gst) / 100) / 2
                            }, 0))?.toFixed(2) : 0 : data.cgst = 0}
                            onChange={handleChange}
                            title={data.cgst}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                {data.showgst && data.samestate ? (
                  <Row className="mt-3">
                    <Col sm={3}></Col>
                    <Col>
                      <Row>
                        <Col>
                          <Form.Label>SGST:</Form.Label>
                        </Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <Form.Control
                            type="number"
                            name="sgst"
                            placeholder="0.00"
                            disabled
                            value={data.showgst ? data.sgst = data.samestate ? (data.itemarray?.reduce((acc, curr) => {
                              // return acc + (curr.qty * ((curr.rate * curr.gst) / 100)) / 2
                              return acc + ((curr.amount * curr.gst) / 100) / 2
                            }, 0))?.toFixed(2) : 0 : data.sgst = 0}
                            onChange={handleChange}
                            title={data.sgst}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null}
                {(data.showgst && !data.samestate) && <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>IGST:</Form.Label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                      <Col>
                        <Form.Control
                          type="number"
                          name="igst"
                          placeholder="0.00"
                          value={data.showgst ? data.igst = data.samestate ? 0 : (data.itemarray?.reduce((acc, curr) => {
                            // return acc + (curr.qty * ((curr.rate * curr.gst) / 100));
                            return acc + ((curr.amount * curr.gst) / 100);
                          }, 0))?.toFixed(2) : data.igst = 0}

                          disabled
                          onChange={handleChange}
                          title={data.igst}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>}
                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          type="radio"
                          className="mt-3"
                          label="TCS"
                          name="tcs"
                          checked={data.tcs}
                          onChange={handleChange}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          className="mt-3"
                          label="TDS"
                          name="tds"
                          checked={data.tds}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={6}>
                        {data.tcs ? (
                          <Form.Group className="mb-3" controlId="tcsvalue">
                            <label htmlFor="tcs">TCS</label>
                            <Form.Select
                              aria-label="tcsvalue"
                              name="tcsvalue"
                              value={data.tcsvalue}
                              onChange={handleChange}
                            // onChange={(e) => SetTdsPercent(+e.target.value)}
                            >
                              <option value="">--Select--</option>
                              {tcsMaster?.map((tcs) => {
                                return (
                                  <option key={tcs.id} value={tcs.taxname}>
                                    {`${tcs.taxname} [${tcs.ratepercent}]`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : data.tds ? (
                          <Form.Group className="mb-3" controlId="tdsvalue">
                            <label htmlFor="tds">TDS</label>
                            <Form.Select
                              aria-label="tdsvalue"
                              name="tdsvalue"
                              value={data.tdsvalue}
                              onChange={handleChange}
                            // onChange={(e) => setTcsPercent(+e.target.value)}
                            >
                              <option value="">--Select--</option>
                              {tdsMaster?.map((tds) => {
                                return (
                                  <option key={tds.id} value={tds.taxname}>
                                    {`${tds.taxname} [${tds.ratepercent}]`}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder="0.00"
                          name="tsamount"
                          value={data.tsamount}
                          onChange={handleChange}
                          title={data.tsamount}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Adjustment:</Form.Label>
                      </Col>
                      <Col>
                        <Col>
                          <OverlayTrigger
                            className="mt-5"
                            placement="right"
                            overlay={renderTooltip(
                              "Add any other +ve or -ve charges that need to be applied to adjust the total amount of the transaction. Eg. +10 or -10"
                            )}
                          >
                            <span className="question-icon">
                              <AiOutlineQuestionCircle />
                            </span>
                          </OverlayTrigger>
                        </Col>
                      </Col>
                      <Col></Col>
                      <Col>
                        <Form.Control
                          type="number"
                          placeholder="0.00"
                          name="adjustmentamount"
                          value={data.adjustmentamount}
                          onChange={handleChange}
                          title={data.adjustmentamount}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col sm={3}></Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label>Grand Total:</Form.Label>
                      </Col>
                      <Col></Col>
                      <Col></Col>
                      {/* Number((Number(data.subtotal) + Number(data.cgst)  + Number(data.sgst)  + Number(data.igst)  + Number(data.tsamount) + Number(data.adjustment) + Number(data.frightcharges)))?.toFixed(2) */}
                      <Col>
                        <Form.Control
                          type="number"
                          name="grandtotal"
                          placeholder="0.00"
                          disabled
                          value={
                            data.showgst
                              ? (data.grandtotal = (() => {
                                let grandtotal = Number(
                                  Number(data.subtotal) +
                                  Number(data.cgst) +
                                  Number(data.sgst) +
                                  Number(data.igst) +
                                  Number(data.tsamount) +
                                  Number(data.adjustmentamount)
                                )?.toFixed(2);
                                let decimalpart =
                                  grandtotal - Math.floor(grandtotal);
                                if (decimalpart > 0.5) {
                                  grandtotal = Math.ceil(grandtotal);
                                } else {
                                  grandtotal = Math.floor(grandtotal);
                                }
                                return grandtotal;
                              })())
                              : (data.grandtotal = (() => {
                                let grandtotal = Number(
                                  Number(data.subtotal) +
                                  Number(data.tsamount) +
                                  Number(data.adjustmentamount)
                                )?.toFixed(2);
                                let decimalpart =
                                  grandtotal - Math.floor(grandtotal);
                                if (decimalpart > 0.5) {
                                  grandtotal = Math.ceil(grandtotal);
                                } else {
                                  grandtotal = Math.floor(grandtotal);
                                }
                                return grandtotal;
                              })())
                          }
                          title={data.grandtotal}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <hr />
          <Row md={12}>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="tandc">
                <Form.Label>Terms and Conditions</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={3}
                  name="tandc"
                  value={data.tandc}
                  disabled
                // isInvalid={data.description === "" && showEmptyError}
                />
                <Form.Control.Feedback type="invalid">
                  Enter Terms and Conditions
                </Form.Control.Feedback>
                {/* <Form.Check
              inline
              type="checkbox"
              name="istandc"
              label="Use this in future for all Sales Order."
              value="Yes"
              checked={data.istandc === "Yes"}
              onChange={handleChange}
            /> */}
                {/* <Form.Label>
            
          </Form.Label> */}
              </Form.Group>
            </Col>
            <Col md={6}>


              {/* <Col md={3} className="m-4">
                <img src={signatureFromCompany} alt="" style={{ width: "180px", height: "105px" }} />
                <hr></hr>
                <h3>Signature</h3>
              </Col> */}
            </Col>

          </Row>
          <hr />
          <Col sm={12} className="d-flex gap-3">
            <span className="w-15">
              <Button className="commonBtn" onClick={handleUpdateAsApproved}>
                {loadData ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Update as Approve"
                )}
              </Button>
            </span>
            <span className="w-15">
              <Button
                className="commonBtn cancelBtn"
                onClick={handleUpdateAsDraft}
              >
                Update as Draft
              </Button>
            </span>
            <span>
              <Button
                className="commonBtn smallBtn cancelBtn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </span>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default EditCreditNote