import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import { MdModeEditOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import {
  deletePurchaseOrderData,
  purchaseOrderData,
} from "../../../redux/actions/purchaseOrder.actions";
import ViewPurchaseOrder from "./ViewPurchaseOrder";
import axios from "axios";

const ListTablePurchaseOrder = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const data = useSelector(
    (state) => state.purchaseOrderData.purchaseOrderData
  );
  const loadData = useSelector((state) => state.loadReducer);
  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const org = userData[0].org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    keyword: searchKeyword,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(purchaseOrderData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleShow = (item) => {
    const { id, purchaseorderno } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(purchaseorderno);
  };

  const handleEdit = (id) => {
    navigate(`/purchase/purchaseorder/editPurchaseOrder/${id}`);
  };

  const handleView = (id) => {
    navigate(`/purchase/purchaseorder/viewPurchaseOrder/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      deletePurchaseOrderData(
        {
          id: selectedItemId,
          purchaseorderno: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

        userid,
        submodule: "Purchase Order",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);
  // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Purchase Orders</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() =>
                  navigate("/purchase/purchaseorder/addPurchaseOrder")
                }
              >
                Add Purchase Order
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Date</th>
              <th>PO No.</th>
              <th>Vendor Name</th>
              <th>Due Date</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Expected Delivery Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="8" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  status,
                  vendorname,
                  purchaseorderno,
                  purchasedate,
                  paymentduedate,
                  tcsamount,
                  finalamount,
                  expecteddeliverydate,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!purchasedate ? "--" : purchasedate}</td>
                    <td>{!purchaseorderno ? "--" : purchaseorderno}</td>
                    <td>{!vendorname ? "--" : vendorname}</td>
                    <td>{!paymentduedate ? "--" : paymentduedate}</td>
                    <td
                      style={{
                        color: status == "Draft" ? "red" : "green",
                      }}
                    >
                      {!status ? "--" : status}
                    </td>
                    <td>{!finalamount ? "--" : finalamount}</td>
                    <td>
                      {!expecteddeliverydate ? "--" : expecteddeliverydate}
                    </td>
                    <td className="d-flex justify-content-center align-items-center gap-2">
                      <div>
                        <MdOutlineRemoveRedEye
                          className="editIcon"
                          onClick={() => handleView(item.id)}
                        />

                        {status == "Draft"
                          ? crud &&
                            crud.includes("u") && (
                              <MdModeEditOutline
                                className="editIcon mr-2"
                                onClick={() => handleEdit(item.id)}
                              />
                            )
                          : null}
                        {crud && crud.includes("d") && (
                          <DeleteConfirmation
                            show={show}
                            handleShow={() => handleShow(item)}
                            handleClose={handleClose}
                            handleDelete={handleDelete}
                            name={"Purchase Order"}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="8">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTablePurchaseOrder;
