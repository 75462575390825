import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Table,
  InputGroup,
  Image,
} from "react-bootstrap";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import { MdDelete } from "react-icons/md";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { quotationPagiData } from "../../../redux/actions/quotation.actions";
import {
  salesOrderAdd,
  salesOrderAutoCount,
  salesOrderDocmnentUpload,
  salesOrderPagiData,
  salesOrderUpdate,
  termsAndConditionData,
} from "../../../redux/actions/salesOrder.actions";
import { projectData } from "../../../redux/actions/projects.actions";
import { customerPagiData } from "../../../redux/actions/customer.actions";
import { categoryPagiData } from "../../../redux/actions/category.actions";
import { itemPagiData } from "../../../redux/actions/item.actions";
import CategoryListModal from "./CategoryListModal";
import { sampleData } from "../../../constants/sampleData";
import { suggestedItemData } from "../../../redux/actions/suggesteditems.actions";
import { reloadPage } from "../../../redux/actions/load.action";
import { IoDocumentAttach } from "react-icons/io5";
import { employeeData } from "../../../redux/actions/employee.actions";
import Select from "react-select";
import { notifyError, notifyWarning } from "../../../constants/toastAlert";
import getMonthName from "../../../constants/getMonthName";
import CustomerInfo from "./CustomerInfo";

function EditSalesOrder() {
  const URL = extractAndFormatURLPart("sales");
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");
  const categoryContainerRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid, branch, org, name } = useSelector((state) => state.authManager.userData.data[0]) || {};
  const companyName = useSelector((state) => state.companyData.companyData.data) || [];
  const { salesOrderCount, salesOrderDocument } = useSelector((state) => state.salesOrderData) || 0;
  const { data: quotationAllData } = useSelector((state) => state.quotationData.quotationPagiData) || [];
  const { data: projectAllData } = useSelector((state) => state.projectData.projectData) || [];
  const { data: customerAllData } = useSelector((state) => state.customerData.customerPagiData) || [];
  const { data: categoryAllData } = useSelector((state) => state.categoryData.categoryPagiData) || [];
  const { data: itemAllData } = useSelector((state) => state.itemData.itemPagiData) || [];
  const suggestedItemMaster = useSelector((state) => state.suggestedItemData.suggestedItemData.data) || [];
  const { data: salesOrderAllData } = useSelector((state) => state.salesOrderData.salesOrderPagiData) || [];
  const salesOrderData = !salesOrderAllData ? [] : salesOrderAllData.filter((item) => item.id == id);
  const { data: employeeAllData } = useSelector((state) => state.employeeData.employeeData) || [];
  const { termsAndConditionAllData } = useSelector((state) => state.salesOrderData) || [];

  const employeeActiveData = !employeeAllData ? [] : employeeAllData.filter((item) => (item.status === "Active" && item.issale === "Yes"));
  const termAndCondition = termsAndConditionAllData?.filter((item) => item.status === "Active" && item.type == "Sales Order")


  //state part
  const [data, setData] = useState({
    id: salesOrderData[0] ? salesOrderData[0].id : "--",
    customername: salesOrderData[0] ? salesOrderData[0].customername : "--",
    customerid: salesOrderData[0] ? salesOrderData[0].customerid : "--",
    project: salesOrderData[0] ? salesOrderData[0].project : "--",
    projectid: salesOrderData[0] ? salesOrderData[0].projectid : "--",
    quoteno: salesOrderData[0] ? salesOrderData[0].quoteno : "--",
    saleorderno: salesOrderData[0] ? salesOrderData[0].saleorderno : "",
    saleorderdate: salesOrderData[0] ? salesOrderData[0].saleorderdate : "",
    exshippingdate: salesOrderData[0] ? salesOrderData[0].exshippingdate : "--",
    deliverytype: salesOrderData[0] ? salesOrderData[0].deliverytype : "--",
    salesperson: salesOrderData[0] ? salesOrderData[0].salesperson : "--",
    createdby: salesOrderData[0] ? salesOrderData[0].createdby : "--",
    uploads: salesOrderData[0] ? salesOrderData[0].uploads : [],
    documents: "",
    customernote: salesOrderData[0] ? salesOrderData[0].customernote : "--",
    showgst: salesOrderData[0] ? salesOrderData[0].showgst == "true" ? true : false : true,
    itemarray: salesOrderData[0] ? salesOrderData[0].itemarray : [],
    subtotal: salesOrderData[0] ? salesOrderData[0].subtotal : "--",
    discount: salesOrderData[0] ? salesOrderData[0].discount : "--",
    discountamount: salesOrderData[0] ? salesOrderData[0].discountamount : "--",
    taxableamount: salesOrderData[0] ? salesOrderData[0].taxableamount : "--",
    gst: salesOrderData[0] ? salesOrderData[0].tax : "--",
    adjustment: salesOrderData[0] ? salesOrderData[0].adjustment : "--",
    grandtotal: salesOrderData[0] ? salesOrderData[0].grandtotal : "--",
    tax: salesOrderData[0] ? salesOrderData[0].tax : "--",
    finalamount: salesOrderData[0] ? salesOrderData[0].finalamount : "--",
    tandc: salesOrderData[0] ? salesOrderData[0].tandc : "--",
    istandc: salesOrderData[0] ? salesOrderData[0].istandc : "--",
    company: salesOrderData[0] ? salesOrderData[0].company : "--",
    companyid: salesOrderData[0] ? salesOrderData[0].companyid : "--",
    branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
    branchid: salesOrderData[0] ? salesOrderData[0].branchid : "--",
    userid: salesOrderData[0] ? salesOrderData[0].userid : "--",
    month: salesOrderData[0] ? salesOrderData[0].month : "--",
    financialyear: salesOrderData[0] ? salesOrderData[0].financialyear : "--",
    org: salesOrderData[0] ? salesOrderData[0].org : "--",
    orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
    invoicestatus: salesOrderData[0] ? salesOrderData[0].invoicestatus : "--",
    bacountry: salesOrderData[0] ? salesOrderData[0].bacountry : "--",
    bastate: salesOrderData[0] ? salesOrderData[0].bastate : "--",
    bacity: salesOrderData[0] ? salesOrderData[0].bacity : "--",
    baaddress: salesOrderData[0] ? salesOrderData[0].baaddress : "--",
    bapincode: salesOrderData[0] ? salesOrderData[0].bapincode : "--",
    //Category modal Data
    category: salesOrderData[0] ? salesOrderData[0].category : "--",
    brand: salesOrderData[0] ? salesOrderData[0].brand : "--",
    items: salesOrderData[0] ? salesOrderData[0].items : [],
    suggesteditems: salesOrderData[0] ? salesOrderData[0].suggesteditems : [],
    customernamealldata: salesOrderData[0] ? salesOrderData[0].customernamealldata : [],
  });

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showgst, setShowGst] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredQuotations, setFilteredQuotations] = useState([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [selectedCustomerInfo, setSelectedCustomerInfo] = useState({});
  const [isFilter, setIsFilter] = useState(false);

  //onchange part start
  const handleChange = (e) => {
    const { name, value, files, checked } = e.target;
    if (name === "project") {
      // Filter quotation based on selected project
      const quotationBasedOnProject = quotationAllData && quotationAllData?.filter((item) => item?.project === value) || [];
      const selectedProject = projectAllData && projectAllData?.find((item) => item?.projectname === value) || [];
      setData({
        ...data,
        projectid: selectedProject.projectid,
        baaddress: quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.baaddress : "",
        bacity: quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bacity : "",
        bacountry: quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bacountry : "",
        bapincode: quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bapincode : "",
        bastate: quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bastate : "",
      })
      setFilteredQuotations(quotationBasedOnProject);
    }

    if (name === "quoteno") {
      const selectedQuotation = quotationAllData.find(
        (item) => item.quoteno === value
      );
      if (selectedQuotation?.status == "Expired") {
        notifyWarning(`Quotation ${value} status has Expired`)
        setTableData([]);
        setData((prevData) => ({
          ...prevData,
          itemarray: [],
        }));
      }
      else {
        const items = selectedQuotation?.itemarray || [];
        setTableData(items);
        setData((prevData) => ({
          ...prevData,
          itemarray: items,
        }));
      }
    }

    if (name === "documents") {
      dispatch(salesOrderDocmnentUpload(files));
    }

    if (name === "saleorderdate") {
      const dateObj = new Date(value);
      const month = dateObj.toLocaleString("default", { month: "long" });
      setData((prevData) => ({
        ...prevData,
        month: getMonthName(value),
      }));
    }

    if (name == "showgst") {
      setData((prevData) => ({
        ...prevData,
        showgst: checked,
      }));

      return;
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  //onchange part end

  //Customer search feild start
  const options = customerAllData?.reduce((acc, customer) => {
    if (customer.customertype === "Retail") {
      acc.push({
        value: customer.fullname,
        label: customer.fullname,
      });
    } else if (customer.customertype === "Institutional") {
      acc.push({
        value: customer.bdcompany,
        label: customer.bdcompany,
      });
    }
    return acc;
  }, []) || [];
  //Customer search feild end

  //useEffect part start
  //default data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    branch: branch,
    companyname: companyname,
    org: org,
  };

  useEffect(() => {
    dispatch(reloadPage(true));
  }, [])

  useEffect(() => {
    // handleAddNewRow();
    setLoading(!loading);
    dispatch(quotationPagiData(backendData, setLoading));
    dispatch(projectData(backendData, setLoading));
    dispatch(termsAndConditionData(backendData, setLoading));
    dispatch(customerPagiData(backendData, setLoading));
    dispatch(categoryPagiData(backendData, setLoading));
    dispatch(itemPagiData(backendData, setLoading));
    dispatch(suggestedItemData(backendData, setLoading));
    dispatch(salesOrderPagiData(backendData, setLoading));
    dispatch(employeeData(backendData, setLoading));

    const items = salesOrderData[0] ? salesOrderData[0].itemarray : []
    setTableData(items);

    if (data.customername) {
      const selectedCustomer = customerAllData?.find((item) => item.bdcompany === data.customername || item.fullname === data.customername);
      setSelectedCustomerInfo(selectedCustomer);
      if (selectedCustomer) {
        setData((prevData) => ({
          ...prevData,
          customerid: selectedCustomer.customerid,

        }));
      }
      // Filter projects based on selected customer
      const projectsBasedOnCustomerName =
        projectAllData &&
        projectAllData?.filter((item) => item?.customerid === selectedCustomer?.customerid);
      setFilteredProjects(projectsBasedOnCustomerName);
      return
    }

    setData({
      ...data,
      id: salesOrderData[0] ? salesOrderData[0].id : "--",
      customername: salesOrderData[0] ? salesOrderData[0].customername : "--",
      customerid: salesOrderData[0] ? salesOrderData[0].customerid : "--",
      project: salesOrderData[0] ? salesOrderData[0].project : "--",
      projectid: salesOrderData[0] ? salesOrderData[0].projectid : "--",
      quoteno: salesOrderData[0] ? salesOrderData[0].quoteno : "--",
      saleorderno: salesOrderData[0] ? salesOrderData[0].saleorderno : "--",
      saleorderdate: salesOrderData[0] ? salesOrderData[0].saleorderdate : "",
      exshippingdate: salesOrderData[0] ? salesOrderData[0].exshippingdate : "",
      deliverytype: salesOrderData[0] ? salesOrderData[0].deliverytype : "--",
      salesperson: salesOrderData[0] ? salesOrderData[0].salesperson : "--",
      createdby: salesOrderData[0] ? salesOrderData[0].createdby : "--",
      uploads: salesOrderData[0] ? salesOrderData[0].uploads : [],
      customernote: salesOrderData[0] ? salesOrderData[0].customernote : "--",
      showgst: salesOrderData[0] ? salesOrderData[0].showgst == "true" ? true : false : true,
      itemarray: salesOrderData[0] ? salesOrderData[0].itemarray : [],
      subtotal: salesOrderData[0] ? salesOrderData[0].subtotal : "--",
      discount: salesOrderData[0] ? salesOrderData[0].discount : "--",
      discountamount: salesOrderData[0] ? salesOrderData[0].discountamount : "--",
      taxableamount: salesOrderData[0] ? salesOrderData[0].taxableamount : "--",
      gst: salesOrderData[0] ? salesOrderData[0].tax : "--",
      adjustment: salesOrderData[0] ? salesOrderData[0].adjustment : "--",
      grandtotal: salesOrderData[0] ? salesOrderData[0].grandtotal : "--",
      tax: salesOrderData[0] ? salesOrderData[0].tax : "--",
      finalamount: salesOrderData[0] ? salesOrderData[0].finalamount : "--",
      tandc: termAndCondition.length > 0 && termAndCondition[0]?.description !== undefined ? termAndCondition[0]?.description : "",
      istandc: salesOrderData[0] ? salesOrderData[0].istandc : "--",
      company: salesOrderData[0] ? salesOrderData[0].company : "--",
      companyid: salesOrderData[0] ? salesOrderData[0].companyid : "--",
      branch: salesOrderData[0] ? salesOrderData[0].branch : "--",
      branchid: salesOrderData[0] ? salesOrderData[0].branchid : "--",
      userid: salesOrderData[0] ? salesOrderData[0].userid : "--",
      month: salesOrderData[0] ? salesOrderData[0].month : "--",
      financialyear: salesOrderData[0] ? salesOrderData[0].financialyear : "--",
      org: salesOrderData[0] ? salesOrderData[0].org : "--",
      orderstatus: salesOrderData[0] ? salesOrderData[0].orderstatus : "--",
      invoicestatus: salesOrderData[0] ? salesOrderData[0].invoicestatus : "--",
      bacountry: salesOrderData[0] ? salesOrderData[0].bacountry : "--",
      bastate: salesOrderData[0] ? salesOrderData[0].bastate : "--",
      bacity: salesOrderData[0] ? salesOrderData[0].bacity : "--",
      baaddress: salesOrderData[0] ? salesOrderData[0].baaddress : "--",
      bapincode: salesOrderData[0] ? salesOrderData[0].bapincode : "--",

      //Category modal Data
      category: salesOrderData[0] ? salesOrderData[0].category : "--",
      brand: salesOrderData[0] ? salesOrderData[0].brand : "--",
      items: salesOrderData[0] ? salesOrderData[0].items : [],
      suggesteditems: salesOrderData[0] ? salesOrderData[0].suggesteditems : [],
      customernamealldata: salesOrderData[0] ? salesOrderData[0].customernamealldata : [],
    })
    // calculateGrandTotal();
  }, [dispatch, loadData, data.customername]);

  useEffect(() => {
    if (data.project) {
      const quotationBasedOnProject = quotationAllData && quotationAllData.filter((item) => item.project === data.project);

      setData({
        ...data,
        baaddress: quotationBasedOnProject && quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.baaddress : "",
        bacity: quotationBasedOnProject && quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bacity : "",
        bacountry: quotationBasedOnProject && quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bacountry : "",
        bapincode: quotationBasedOnProject && quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bapincode : "",
        bastate: quotationBasedOnProject && quotationBasedOnProject[0] ? quotationBasedOnProject[0]?.bastate : "",
      })
      setFilteredQuotations(quotationBasedOnProject);
      const items = data.itemarray || [];
      setTableData(items);
    }


    // if (data.quoteno) {
    //   const selectedQuotation = quotationAllData.find(
    //     (item) => item.quoteno === data.quoteno
    //   );


    // }
  }, [data.project])


  useEffect(() => {
    if (suggestedItemMaster.length == 0) {
      setData((prev) => ({ ...prev, suggesteditems: [] }));
    }

    if (data.items.length == 0) {
      setData((prev) => ({ ...prev, suggesteditems: [] }));
    } else {
      suggestedItemMaster.forEach((suggested) => {
        data.items.forEach((item) => {
          if (suggested.primaryitem == item.itemname) {

            setData((prev) => ({
              ...prev,
              suggesteditems: [...suggested.itemarray],
            }));
          }
        });
      });
    }
  }, [data.items, suggestedItemMaster]);

  // useEffect(() => {
  //   if (data.customername) {
  //     const selectedCustomer = customerAllData && customerAllData.find((item) => item.bdcompany === data.customername);
  //     if (selectedCustomer) {
  //       setData((prevData) => ({
  //         ...prevData,
  //         customerid: selectedCustomer.customerid,
  //       }));
  //     }
  //     // Filter projects based on selected customer
  //     const projectsBasedOnCustomerName =
  //       projectAllData &&
  //       projectAllData.filter((item) => item.company === data.customername);

  //     setFilteredProjects(projectsBasedOnCustomerName);
  //   }
  //   if (data.project) {
  //     // Filter quotation based on selected project
  //     const quotationBasedOnProject =
  //       quotationAllData &&
  //       quotationAllData.filter((item) => item.project === data.project);
  //     setFilteredQuotations(quotationBasedOnProject);
  //   }
  // }, [data.customername, data.project])
  //useEffect part end

  //Table part start
  function handleAddNewRow() {
    setShowForm(true);
    setTableData((prevTableData) => [...prevTableData, {}]);
  }

  const handleTableRowChange = (e, rowIndex) => {
    const { name, value } = e.target;
    //update row change
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowIndex] = {
        ...updatedTableData[rowIndex],
        [name]: value,
      };
      return updatedTableData;
    });
  };

  const handleTableRowDelete = (rowIndex) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData.splice(rowIndex, 1);
      setData((prevData) => ({
        ...prevData,
        itemarray: updatedTableData,
      }));
      return updatedTableData;
    });
  };
  //Table part end

  //category modal start
  const handleCategoryShow = () => {
    setShow(true);
    setIsFilter(false);
  };

  const handleCategoryCancel = () => {
    setData((prev) => ({
      ...prev,
      category: "",
      brand: "",
      items: []
    }))
    setIsFilter(true)
    setShow(false);
  };

  const handleAdd = () => {
    // setShow(false);
    handleCategoryCancel();
    const isDuplicate = data.items.some(item => tableData.some(tableItem => tableItem.id === item.id));
    if (isDuplicate) {
      notifyWarning('Selected Item already exist, Kindly update the quantity feild. ')
    }
    else {
      setTableData(prev => [...prev, ...data.items]);
    }
  };
  //category modal end

  //suggested Item start
  const handleSuggestedItem = (e, row) => {
    if (e.target.checked) {
      setTableData((prev) => ([...prev, { ...row, rate: row.mop }]))
    } else {
      const filteredItemArr = tableData.filter(
        (item) => item.id != row.id
      );
      setTableData((prev) => ([...filteredItemArr]))
    }
  };
  //suggested Item end

  //Sales order footer part start

  const handleUpdateAsApproved = () => {
    if (
      !data.customername ||
      !data.saleorderdate ||
      !data.deliverytype ||
      !data.createdby
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }


    const formData = {
      ...data,
      itemarray: tableData.map(item => ({ ...item, remainingqty: item.qty })),
      orderstatus: "Approved",
      finalamount: data.grandtotal,
      tax: data.showgst ? data.gst : 0,
      uploads: salesOrderDocument || [],
      id: id
    };
    dispatch(salesOrderUpdate(formData, navigate));
  };

  const handleUpdateAsDraft = () => {
    if (
      !data.customername ||
      !data.saleorderdate ||
      !data.deliverytype ||
      !data.createdby
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }
    const formData = {
      ...data,
      itemarray: tableData.map(item => ({ ...item, remainingqty: item.qty })),
      orderstatus: "Draft",
      finalamount: data.grandtotal,
      tax: data.showgst ? data.gst : 0,
      uploads: salesOrderDocument || [],
      id: id
    };

    dispatch(salesOrderUpdate(formData, navigate));
  };

  const handleCancel = () => {
    navigate(`/sales/salesOrder`);
  };

  //Sales order footer part end

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="colStyle p-0">
            <span>{URL}</span>
          </Col>
        </Row>
        <Row className="form-container">
          <Col sm={12}>
            <h1>
              <FaArrowLeftLong
                className="left-arrow"
                onClick={() => navigate("/sales/salesOrder")}
              />
              Update <span className="form-title"> Sales Order</span>
            </h1>
          </Col>
          <hr />
          <Col sm={12}>
            <Form className="form-lable-name">
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="customerName">
                    <Form.Label>
                      Customer Name<span className="star"> *</span> {data.customername && <CustomerInfo selectedCustomerInfo={selectedCustomerInfo} />}
                    </Form.Label>
                    {/* <Form.Select
                      aria-label="Default select example"
                      name="customername"
                      onChange={handleChange}
                      value={data.customername}
                      isInvalid={data.customername === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {customerAllData ? (
                        customerAllData.map((item, index) => {
                          const { bdcompany, fullname, customertype } = item;
                          
                          return (
                            customertype == "Retail" ? (
                              <option key={index} value={fullname}> {fullname}</option>
                            ) : (
                              <option key={index} value={bdcompany}>{bdcompany}</option>
                            )
                          );
                        })
                      ) : (
                        <option value="">No Company Name Available</option>
                      )}
                    </Form.Select> */}
                    <Select
                      options={options}
                      value={options.find((option) => option.value === data.customername)}
                      onChange={(selectedOption) =>
                        setData((prevData) => ({
                          ...prevData,
                          customername: selectedOption ? selectedOption.value : ""
                        }))
                      }
                      placeholder="Select Name"
                      isSearchable
                      className={data.customername === "" && showEmptyError ? "empty-feild" : ""}
                    />

                    <Form.Control.Feedback type="invalid">
                      Select Customer name
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formBasicId">
                    <Form.Label>Customer Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Customer Id"
                      name="customerid"
                      value={data.customerid}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.prefno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Customer Id
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="project">
                    <Form.Label>
                      Project
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="project"
                      onChange={handleChange}
                      onFocus={() => data.customername == "" && notifyWarning("First Select Customer Name")}
                      value={data.project}
                    // isInvalid={data.project === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      {filteredProjects ? (
                        filteredProjects.map((project, index) => {
                          const { projectname } = project;
                          return (
                            <option key={index} value={projectname}>
                              {projectname}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">
                          No Project Available on this Customer name
                        </option>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Project
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="quoteno">
                    <Form.Label>
                      Quotation ID
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="quoteno"
                      onChange={handleChange}
                      onFocus={() => data.project == "" && notifyWarning("First Select Project")}
                      value={data.quoteno}
                    >
                      <option value="">Select</option>
                      {filteredQuotations ? (
                        filteredQuotations.map((quotation, index) => {
                          const { quoteno } = quotation;
                          return (
                            <option key={index} value={quoteno}>
                              {quoteno}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">
                          No Quotation Available on Selected Project
                        </option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group controlId="saleorderno">
                    <Form.Label>Sales Order No</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Sales Order No"
                      name="saleorderno"
                      value={data.saleorderno}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.prefno === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Sales Order No
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="salesOrderDate">
                    <Form.Label>
                      Sales Order Date<span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="saleorderdate"
                      value={
                        data.saleorderdate
                      }
                      // min={`${new Date().toISOString().split("T")[0]}`}
                      max={`${new Date().toISOString().split("T")[0]}`}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.saleorderdate === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Choose Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="exshippingdate">
                    <Form.Label>
                      Expected Shipment Date
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Select Expected Shipment Date"
                      name="exshippingdate"
                      value={data.exshippingdate}
                      min={`${new Date().toISOString().split("T")[0]}`}
                      max={finalYear && finalYear.length > 1 ? `${parseInt(finalYear[1], 10)}-03-31` : ''}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                    // isInvalid={data.exshippingdate === "" && showEmptyError}
                    // required
                    />
                    {/* <Form.Control.Feedback type="invalid">
                      Enter Expected Shipment Date
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="deliverytype">
                    <Form.Label>
                      Delivery Type<span className="star"> *</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="deliverytype"
                      onChange={handleChange}
                      value={data.deliverytype}
                      isInvalid={data.deliverytype === "" && showEmptyError}
                    >
                      <option value="">Select</option>
                      <option value="EX">EX</option>
                      <option value="FOR">FOR</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Select Delivery Type
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group controlId="salesperson">
                    <Form.Label>Sales Person</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="salesperson"
                      onChange={handleChange}
                      value={data.salesperson}
                    >
                      <option value="">Select</option>
                      {employeeActiveData.length > 0 ? (
                        employeeActiveData.map((item, index) => {
                          const { fullname } = item
                          return (
                            <option key={index} value={fullname}>
                              {fullname}
                            </option>
                          );
                        })
                      ) : (
                        <>
                          <option value="">No Sales Person Available</option>
                        </>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="createdby">
                    <Form.Label>
                      Created By<span className="star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Created by"
                      name="createdby"
                      value={data.createdby}
                      onChange={handleChange}
                      required
                      disabled
                    // isInvalid={data.createdby === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Created By
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="documents">
                    <Form.Label>
                      Upload File
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Select File"
                      name="documents"
                      value={data.documents}
                      onChange={handleChange}
                      required
                    // isInvalid={data.documents === "" && showEmptyError}
                    />
                    <Form.Control.Feedback type="invalid">
                      Upload Your File
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="documents">
                    <Form.Label>
                      Uploaded Documents
                    </Form.Label>
                    {
                      data.uploads.length > 0 ? data.uploads.map((item, index) => {
                        const { } = item;

                        return (
                          <>
                            <div className="uploaded-data" key={id}>
                              <span><a href={item} target="blank"><IoDocumentAttach /></a></span>
                            </div>
                          </>
                        );

                      }) :
                        <div className="uploaded-data">
                          <span>No Document Found</span>
                        </div>

                    }
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="customernote">
                    <Form.Label>Customer Notes</Form.Label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type here..."
                      name="customernote"
                      value={data.customernote}
                      onChange={handleChange}
                      // isInvalid={data.customernote === "" && showEmptyError}
                      required
                    ></textarea>
                    <Form.Control.Feedback type="invalid">
                      Enter Customer Notes
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
          <hr />
          <Col md={12}>
            <Row>
              <Col md={10}>
                <Form.Check
                  type="checkbox"
                  name="showgst"
                  label="Show GST"
                  checked={data.showgst}
                  onChange={handleChange}
                />
              </Col>
              <Col md={2}>
                <div className="suggestedCategory-container">
                  <span className="companyname"></span>
                  <CategoryListModal
                    show={show}
                    handleCategoryShow={() => handleCategoryShow}
                    handleCategoryCancel={handleCategoryCancel}
                    handleAdd={handleAdd}
                    data={data}
                    setData={setData}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col md={12} className="table-main-container ">
            <Table responsive>
              <thead className="tableContainer ">
                <tr>
                  <th>SL No</th>
                  <th>Category</th>
                  <th>Brand</th>
                  <th>Item Name</th>
                  <th>Series</th>
                  <th>Item ID</th>
                  <th>UOM</th>
                  <th>QTY</th>
                  {!data.showgst && <th>Rate Incl. GST</th>}
                  <th>Rate</th>
                  {data.showgst && <th>GST%</th>}
                  <th>Discount %</th>
                  <th>Additional Discount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <DataSpinner count="14" />
                  </>
                ) : tableData?.length > 0 ? (
                  tableData.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td >
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              placeholder="slno"
                              name="slno"
                              value={index + 1}
                              onChange={(e) => handleTableRowChange(e, index)}
                              className="text-center"
                              required
                              disabled
                              title={index + 1}
                            // isInvalid={data.prefno === "" && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter Customer Id
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>

                        {/* <td>
                          <Image
                            src={row.image}
                            rounded
                            width="100"
                            height="45"
                            onChange={(e) => handleTableRowChange(e, index)}
                          />
                        </td> */}

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="category"
                              name="category"
                              value={row.category}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.category}
                            // isInvalid={data.prefno === "" && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter Customer Id
                            </Form.Control.Feedback>
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="Brand"
                              name="brand"
                              value={row.brand}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.brand}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="Item Name"
                              name="itemname"
                              value={row.itemname}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.itemname}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="Series"
                              name="series"
                              value={row.series}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.series}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="Item Id"
                              name="itemid"
                              value={row.itemid}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.itemid}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="text"
                              className="text-center"
                              placeholder="UOM"
                              name="uom"
                              value={row.unit}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.unit}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              className="text-center"
                              placeholder="0"
                              name="qty"
                              value={row.qty}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              title={row.qty}

                            />
                          </Form.Group>
                        </td>

                        {!data.showgst && <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              className="text-center"
                              placeholder="Rate Incl GST"
                              name="rateinclgst"
                              value={row.rateinclgst = Number(row.rate) + ((Number(row.rate) * Number(row.gst)) / 100)}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              disabled
                              title={row.rateinclgst}
                            />
                          </Form.Group>
                        </td>}

                        <td>
                          <Form.Group controlId="formBasicId">
                            <Form.Control
                              type="number"
                              className="text-center"
                              placeholder="Rate"
                              name="rate"
                              value={row.rate}
                              onChange={(e) => handleTableRowChange(e, index)}
                              required
                              // disabled
                              title={row.rate}
                            />
                          </Form.Group>
                        </td>

                        {data.showgst && (
                          <td>
                            <Form.Group controlId="formBasicId">
                              <Form.Control
                                type="number"
                                className="text-center"
                                placeholder="GST%"
                                name="gst"
                                value={row.gst}
                                onChange={(e) => handleTableRowChange(e, index)}
                                required
                                disabled
                                title={row.gst}
                              />
                            </Form.Group>
                          </td>
                        )}

                        <td>
                          <Form.Control
                            className="text-center"
                            type="number"
                            placeholder="0.00"
                            name="discount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={row.discount}
                            //isInvalid={tillDate === "" && showEmptyError}
                            title={row.discount}
                          />
                        </td>

                        <td>
                          <Form.Control
                            className="text-center"
                            type="number"
                            placeholder="0.00"
                            name="additionaldiscount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={row.additionaldiscount}
                            //isInvalid={tillDate === "" && showEmptyError}
                            title={row.additionaldiscount}
                          />
                        </td>

                        <td>
                          <Form.Control
                            disabled
                            className="text-center"
                            type="number"
                            placeholder="0.00"
                            name="amount"
                            onChange={(e) => handleTableRowChange(e, index)}
                            value={
                              data.showgst ?
                                row.amount = (Number(row.qty) * (Number(row.rate) - (Number(row.rate) * (Number(row.discount || 0) + Number(row.additionaldiscount || 0)) / 100))).toFixed(2) :
                                row.amount = (Number(row.qty) * (Number(row.rateinclgst) - (Number(row.rateinclgst) * (Number(row.discount || 0) + Number(row.additionaldiscount || 0)) / 100))).toFixed(2)
                            }
                            //isInvalid={tillDate === "" && showEmptyError}
                            title={row.amount}
                          />
                        </td>

                        <td className="d-flex align-items-center">
                          <span className="mt-3">
                            <MdDelete
                              className="deleteIcon table-delete"
                              onClick={() => handleTableRowDelete(index)}
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <NoRecordFoud count="14" name="Sales Order" />
                )}
              </tbody>
            </Table>
          </Col>
          <hr />
          <Col md={12} className="d-flex flex-column gap-3">
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Sub Total:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="number"
                      placeholder="0.00"
                      name="subtotal"
                      value={Number(data.subtotal = tableData?.length > 0 ? tableData?.reduce((acc, curr) => acc + (Number(curr.amount) || 0), 0) : 0).toFixed(2)}
                      onChange={handleChange}
                      required
                      disabled
                      title={data.subtotal}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Total Discount:</Form.Label>
                  </Col>
                  <Col>
                    
                  </Col>

                  <Col>
                    <Form.Control
                      type="number"
                      name="discountamount"
                      disabled
                      value={-1 * data.discountamount}
                    />
                  </Col>
                </Row>
              </Col>
            </Row> */}
            {/* <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Taxable Amount:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="taxableamount"
                      disabled
                      value={data.taxableamount}
                    />
                  </Col>
                </Row>
              </Col>
            </Row> */}
            {data.showgst && <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>GST:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="gst"
                      value={data.showgst ? (data.gst = tableData?.reduce((acc, curr) => {
                        // return acc + (curr.qty * ((curr.rate * curr.gst) / 100));
                        return acc + ((curr.amount * curr.gst) / 100);
                      }, 0))?.toFixed(2) : data.gst = 0}
                      disabled
                      title={data.gst}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>}
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Adjustment:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="adjustment"
                      value={data.adjustment}
                      onChange={handleChange}
                      title={data.adjustment}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Row>
                  <Col>
                    <Form.Label>Grand Total:</Form.Label>
                  </Col>
                  <Col></Col>
                  <Col>
                    <Form.Control
                      type="number"
                      name="grandtotal"
                      disabled
                      value={
                        data.showgst
                          ? (data.grandtotal = (() => {
                            let grandtotal = (
                              Number(data.subtotal) +
                              Number(data.gst || 0) +
                              Number(data.adjustment)
                            ).toFixed(2);
                            let decimalpart =
                              grandtotal - Math.floor(grandtotal);
                            if (decimalpart > 0.5) {
                              grandtotal = Math.ceil(grandtotal);
                            } else {
                              grandtotal = Math.floor(grandtotal);
                            }
                            return grandtotal;
                          })())
                          : (data.grandtotal = (() => {
                            let grandtotal = (
                              Number(data.subtotal) + Number(data.adjustment)
                            ).toFixed(2);

                            let decimalpart =
                              grandtotal - Math.floor(grandtotal);
                            if (decimalpart > 0.5) {
                              grandtotal = Math.ceil(grandtotal);
                            } else {
                              grandtotal = Math.floor(grandtotal);
                            }
                            return grandtotal;
                          })())
                      }
                      title={data.grandtotal}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <hr />
          <Col md={12}>
            <h1>Items Frequently Sold Together</h1>
            {data.suggesteditems.length > 0 ? (
              <Row>
                <Col lg={10} className="items-frequent">
                  {data.suggesteditems.map((item, ind) => (
                    <div key={ind}>
                      <Row className="d-flex align-items-center justify-content-between items-frequent-row">
                        <Col lg={4} md={4}>
                          <img
                            src={item.image[0]}
                            alt="img"
                            width={80}
                            height={80}
                            className="rounded-4"
                          />
                        </Col>
                        <Col lg={6} md={6}>
                          <h3>{item.itemname ? item.itemname : "--"}</h3>
                          <h4>
                            {item.itemdescription ? item.itemdescription : "--"}
                          </h4>
                          <h3>
                            {item.rateperunit ? item.rateperunit : "--"}/-
                          </h3>
                        </Col>
                        <Col lg={2} md={2}>
                          <input
                            type="checkbox"
                            checked={tableData.some(
                              (row) => row.id == item.id
                            )}
                            onChange={(e) => handleSuggestedItem(e, item)}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Col>
                <Col lg={2}></Col>
              </Row>
            ) : null}
          </Col>
          <hr />
          <Col md={6}>
            <Form.Group className="mb-3" controlId="tandc">
              <Form.Label>Terms and Conditions</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                name="tandc"
                value={data.tandc}
                disabled
              // isInvalid={data.description === "" && showEmptyError}
              />
              <Form.Control.Feedback type="invalid">
                Enter Terms and Conditions
              </Form.Control.Feedback>
              {/* <Form.Check
                inline
                type="checkbox"
                name="istandc"
                label="Use this in future for all Sales Order."
                value="Yes"
                checked={data.istandc === "Yes"}
                onChange={handleChange}
              /> */}
              {/* <Form.Label>
              
            </Form.Label> */}
            </Form.Group>
          </Col>
          <hr />
          <Col sm={12} className="d-flex gap-3">
            <span className="w-15">
              <Button className="commonBtn" onClick={handleUpdateAsApproved}>
                {loadData ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Update as Approve"
                )}
              </Button>
            </span>
            <span className="w-15">
              <Button
                className="commonBtn cancelBtn"
                onClick={handleUpdateAsDraft}
              >
                Update as Draft
              </Button>
            </span>
            <span>
              <Button
                className="commonBtn smallBtn cancelBtn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditSalesOrder;
