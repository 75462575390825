import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  Container,
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  Row,
  Table,
  Form,
  Modal,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { HiViewColumns } from "react-icons/hi2";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { salesInvoicePagiDataPost } from "../../../constants/api";
import DataSpinner from "../../../constants/DataSpinner";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import html2pdf from "html2pdf.js";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";

const ListTable = () => {
  const pdfRef = useRef(null);
  const navigate = useNavigate();
  const userData = useSelector((state) => state.authManager.userData.data);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [architects, setArchitects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [saleInvoice, setSaleInvoices] = useState([]);
  const [multiContractors, setMultiContractors] = useState([]);
  const [multiArchitects, setMultiArchitects] = useState([]);
  const [multiInvoices, setMultiInvoices] = useState([]);
  const [selectedRange, setSelectedRange] = useState("This Month");

  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);

  const columnData = [
    { name: "invoicedate", label: "Invoice Date" },
    { name: "invoiceno", label: "Invoice No", default: true },
    { name: "grandtotal", label: "Amount" },
    //{ name: "saleorderno", label: "Sale Order No" },
    //{ name: "gino", label: "GI No" },
    //{ name: "invoicetype", label: "Invoice Type" },
    //{ name: "commonname", label: "Name" },
    { name: "architect", label: "Architect" },
    { name: "commisionarchitect", label: "Commission(%)" },
    { name: "commisionamountarchitect", label: "Commission Amount" },
    { name: "contractor", label: "Contractor" },
    { name: "commision", label: "Commission(%)" },
    { name: "commisionamount", label: "Commission Amount" },
    //{ name: "paymentduedate", label: "Due Date" },
    //{ name: "status", label: "Status" },
  ];

  const [selectedColumns, setSelectedColumns] = useState(
    columnData.map((name) => ({ name, selected: true }))
  );
  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.name === columnName
          ? { ...column, selected: !column.selected }
          : column
      )
    );
  };
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });
  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const org = userData[0].org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    keyword: "" || org,
  };

  //Contractors and Architectures
  useEffect(() => {
    axios

      .post("https://mis.saanviavs.com/api/v1/vendor/getpage", backendData)

      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setVendors(initialData);
        const cont =
          initialData.length > 0 &&
          initialData.filter((vd) => vd.vendorcategory == "Contractor");
        setContractors(
          cont.map((item) => {
            return {
              value: item.fullname,
              label: item.fullname,
            };
          })
        );
        const arch =
          initialData.length > 0 &&
          initialData.filter((vd) => vd.vendorcategory == "Architect");
        setArchitects(
          arch.map((item) => {
            return {
              value: item.fullname,
              label: item.fullname,
            };
          })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  //Invoices
  useEffect(() => {
    axios
      .post(salesInvoicePagiDataPost, {
        ...backendData,
        status: "",
        keyword: searchKeyword || org,
      })
      .then((res) => {
        const initialData =
          res.data && res.data.data && res.data.data.length > 0
            ? res.data.data
            : [];
        setSaleInvoices(initialData);
        setInvoices(
          initialData.length > 0 &&
            initialData.map((item) => {
              return {
                value: item.invoiceno,
                label: item.invoiceno,
              };
            })
        );
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        //handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  //search & filter logic
  const filteredData = !saleInvoice
    ? []
    : saleInvoice
        .filter((item) =>
          searchKeyword
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchKeyword.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (!fromDate || !toDate) {
            return true; // No date range specified, don't filter based on dates
          } else {
            const invoiceDate = new Date(item.invoicedate);
            const fromDateObj = new Date(fromDate);
            const toDateObj = new Date(toDate);
            return invoiceDate >= fromDateObj && invoiceDate <= toDateObj;
          }
        })
        .filter((item) => {
          if (multiContractors.length === 0) {
            return true; // No contractors selected, don't filter
          } else {
            return multiContractors.some(
              (contractor) => item.contractor === contractor.value
            );
          }
        })
        .filter((item) => {
          if (multiArchitects.length === 0) {
            return true; // No architects selected, don't filter
          } else {
            return multiArchitects.some(
              (architect) => item.architect === architect.value
            );
          }
        })
        .filter((item) => {
          if (multiInvoices.length === 0) {
            return true; // No invoices selected, don't filter
          } else {
            return multiInvoices.some(
              (invoice) => item.invoiceno === invoice.value
            );
          }
        });

  const handleDownload = () => {
    const element = pdfRef.current;
    const options = {
      margin: 10,
      filename: "Sell Report(Commission).pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };
    const content = `
      <div style="font-family: Arial, sans-serif;">
      <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
        List of All <span style="color: #ffa000;">Sales Report(Commission)</span>
      </p>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr style="background-color: #f0f0f0;">
          <th style="border: 1px solid black; padding: 6px;">Invoice Date</th>
          <th style="border: 1px solid black; padding: 6px;">Invoice No</th>
          <th style="border: 1px solid black; padding: 6px;">Amount</th>
          <th style="border: 1px solid black; padding: 6px;">Architect</th>
          <th style="border: 1px solid black; padding: 6px;">Commission(%)</th>
          <th style="border: 1px solid black; padding: 6px;">Commission Amount</th>
          <th style="border: 1px solid black; padding: 6px;">Contractor</th>
          <th style="border: 1px solid black; padding: 6px;">Commission(%)</th>
          <th style="border: 1px solid black; padding: 6px;">Commission Amount</th>
          </tr>
        </thead>
        <tbody>
        ${filteredData
          .map(
            (item, index) => `
          <tr key=${index}>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.invoicedate
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.invoiceno ? item.invoiceno : ""
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.grandtotal
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.architect
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.commisionarchitect
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.commisionamountarchitect
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.contractor
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.commision
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.commisionamount
            }</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
      </table>
    </div>
    `;
    //html2pdf(element, options);
    html2pdf().from(content).set(options).save();
  };

  return (
    <Container fluid>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              {" "}
              <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={() => navigate("/reports")}
                />
              </p>
            </Col>
            <Col lg={2}>
              <SearchField
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
            <Col lg={1} className="d-flex gap-3">
              <span>
                <DownloadTableExcel
                  filename="Sell Report(Commission)"
                  sheet="Sales"
                  currentTableRef={pdfRef.current}
                >
                  <Button className="commonBtn smallBtn cancelBtn mt-2">
                    {" "}
                    Excel{" "}
                  </Button>
                </DownloadTableExcel>
              </span>
              <span>
                <Button
                  className="commonBtn smallBtn cancelBtn mt-2"
                  onClick={handleDownload}
                >
                  Save pdf
                </Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="section-title form-section ptag">
            Sales Reports(Commission)
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="form-section ptag text-align-center">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
            >
              <option
                //disabled={!isCurrentYear}
                value="Today"
              >
                Today
              </option>
              <option
                //disabled={!isCurrentYear}
                value="Yesterday"
              >
                Yesterday
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Week"
              >
                This Week
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Month"
              >
                This Month
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Quarter"
              >
                This Quarter
              </option>
              <option
                //disabled={!isCurrentYear}
                value="This Year"
              >
                This Year
              </option>
              <option value="Date Range">Date Range</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">From</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="from"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">To</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="to"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">Select Column</Form.Label>
            <span>
              <HiViewColumns
                onClick={handleShow}
                className="column-select-icon"
              />
            </span>
          </Form.Group>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Group controlId="formMonth" className="text-center">
            <Form.Label className="mt-4  text-color">
              Contracter Name
            </Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              value={multiContractors}
              options={contractors}
              onChange={(multiContractors) =>
                setMultiContractors(multiContractors || [])
              }
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="formMonth" className="text-center">
            <Form.Label className="mt-4 text-color">
              Architecture Name
            </Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={architects}
              onChange={(multiArchitects) =>
                setMultiArchitects(multiArchitects || [])
              }
              value={multiArchitects}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="formMonth" className="text-center">
            <Form.Label className="mt-4 text-color">Invoice No</Form.Label>
            <Select
              className="mt-2 p-2 w-100"
              options={invoices}
              onChange={(multiInvoices) =>
                setMultiInvoices(multiInvoices || [])
              }
              value={multiInvoices}
              isMulti
              styles={{
                control: (provided) => ({
                  ...provided,
                  fontSize: "16px",
                }),

                option: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      {/*Table*/}
      <Row>
        <Col md={12} className="table-main-container">
          <Table id="table-to-export" responsive ref={pdfRef}>
            <thead className="tableContainer">
              <tr>
                {columnData.map(
                  (column) =>
                    columnVisibility[column.name] && (
                      <th key={column.index}>{column.label}</th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <DataSpinner count="9" />
              ) : filteredData.length > 0 ? (
                filteredData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnData.map(
                      (column) =>
                        columnVisibility[column.name] && (
                          <td key={column.index}>
                            {item[column.name.toLowerCase()]}
                          </td>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <NoRecordFoud count="9" name="Record" />
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*  //---------------Modal-----------------------------// */}
      <Modal
        show={show}
        size="sm"
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <h4>Select Column</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {columnData.map((column) => (
                <div key={column.index} className="mb-3">
                  {column.default ? (
                    <span style={{ fontSize: "14px" }}>{column.label}</span>
                  ) : (
                    <Form.Check
                      type="checkbox"
                      id={column.name}
                      label={column.label}
                      checked={columnVisibility[column.name]}
                      onChange={() => handleCheckboxChange(column.name)}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ListTable;
