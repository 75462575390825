import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { HiViewColumns } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const DetailedAccountTransactions = () => {
  const pdfRef = useRef(null);

  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
    let userID = localData.userid;
    let branchID = localData.branchid;
    let companyID = localData.companyid;
    let org = localData.org;
 
  
    let financialyear= localStorage.financialyear;
    // const financialyear = JSON.parse(financialyearString);
  
  // for by default date as a month this is connected to bellow date wise filtering functions
  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);

  const loadData = useSelector((state) => state.loadReducer);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [extractedPart, setExtractedPart] = useState("");
  const [show, setShow] = useState(false);
  const location = useLocation();
  const accountname = location.state?.accountname || null;
  const ledger = location.state?.ledger || null;
  const [loading, setLoading] = useState(false);
 

  useEffect(() => {
    document.title = "Saanvi Enterprises App Reports Trail Balance";
    const currentURL = window.location.href; // Get the current URL
    const urlParts = currentURL.split("/"); // Split the URL by "/"
    const paymentIndex = urlParts.indexOf("reports"); // Find the index of "payment"
    if (paymentIndex !== -1 && paymentIndex < urlParts.length - 1) {
      // Check if "adminPanel" is in the URL and if there's a part after it
      const desiredPart = urlParts.slice(paymentIndex).join("/"); // Get the part after "payment"
      const formattedPart = desiredPart
        .split("/")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" / ");
      const spacedpart = formattedPart.replace(/([a-z])([A-Z])/g, "$1 $2");
      setExtractedPart(spacedpart); // Set the extracted part in the component's state
    } else {
      console.log("The URL does not contain 'adminPanel'");
    }
  }, []);
  const columnData = [
    { name: "date", label: "Date" },
    { name: "ledger", label: "Transaction Details", default: true },
    { name: "transactiontype", label: "Transaction Type" },
    { name: "referenceno", label: "Reference No" },
    { name: "Debit", label: "Debit" },
    { name: "Credit", label: "Credit" },
    { name: "Balance", label: "Balance" },
  ];
  const localStorageKey = "selectedColumns";

  const handleSelectedCategory = (e) => {
    setSearchValue(e.target.value);
  };

  // check box 1
  const [selectedColumns, setSelectedColumns] = useState(
    columnData.map((name) => ({ name, selected: true }))
  );
  const handleColumnToggle = (columnName) => {
    setSelectedColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.name === columnName
          ? { ...column, selected: !column.selected }
          : column
      )
    );
  };

  // useSatate

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [accTypeDropDown, setAccTypeDropDown] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios

      .post(`https://mis.saanviavs.com/api/v1/report/trialinnertwo`, {

        companyid: companyID,
        org,
        financialyear,
        accountname,
        ledger,
        fromDate,
        toDate,
      })
      .then((res) => {
      
        setData(res.data);
        setLoading(false);
        filterData1();
      })
      .catch((err) => console.log(err));
  }, [fromDate, toDate, loadData]);

  const handleClose = () => {
    setShow(false);
  };

  const ledgerName = data.length > 0 ? data[0].ledger : "";

  // check box for visible start

  const [columnVisibility, setColumnVisibility] = useState(() => {
    const initialVisibility = {};
    columnData.forEach((column) => {
      initialVisibility[column.name] = true;
    });
    return initialVisibility;
  });

  // Remove the useEffect block related to localStorage

  const handleCheckboxChange = (columnName) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [columnName]: !prevVisibility[columnName],
    }));
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    // localStorage.setItem("columnVisibility", JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  const handleSubmit = () => {
    // localStorage.setItem(localStorageKey, JSON.stringify(columnVisibility));
    handleClose();
  };

  // debu dropdown date range filter start

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };
  // const today = new Date();
  // const formatDate = (date) => {
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(date.getDate()).padStart(2, "0")}`;
  // };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;
   
    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end
  // financila year should be in that same year to choose from date range start
  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 32);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);

  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };
  // financila year should be in that same year to choose from date range end

  // inithially show monthly data
  useEffect(() => {
    handleThisMonth();
  }, []);

  // const filterData1 = () => {
  //   if (!data) {
  //     // Data is not available yet
  //     alert("data null");
  //     return;
  //   }

  //   // Check if either fromDate or toDate is not empty
  //   if (fromDate || toDate) {
  //     // for from date
  //     const d = new Date(fromDate);
  //     const day = String(d.getDate()).padStart(2, "0");
  //     const month = String(d.getMonth() + 1).padStart(2, "0");
  //     const year = d.getFullYear();
  //     const newFromDate = `${day}-${month}-${year}`;

  //     // for to date
  //     const d1 = new Date(toDate);
  //     const day1 = String(d1.getDate()).padStart(2, "0");
  //     const month1 = String(d1.getMonth() + 1).padStart(2, "0");
  //     const year1 = d1.getFullYear();
  //     const newToDate = `${day1}-${month1}-${year1}`;

  //     const filtered = data && data.filter(
  //       (item) =>
  //         parseIndianStyleDate(item.date) >=
  //         parseIndianStyleDate(newFromDate) &&
  //         parseIndianStyleDate(item.date) <= parseIndianStyleDate(newToDate) &&
  //         Object.values(item).some((value) =>
  //           value
  //             ?.toString()
  //             ?.toLowerCase()
  //             ?.includes(searchValue.toLowerCase().trim())
  //         )
  //     );

  //     setFilteredData(filtered);
  //   } else {
  //     // No date range selected, consider only the search value
  //     const filtered = data.filter((item) =>
  //       Object.values(item).some((value) =>
  //         value
  //           ?.toString()
  //           ?.toLowerCase()
  //           ?.includes(searchValue.toLowerCase().trim())
  //       )
  //     );

  //     setFilteredData(filtered);
  //   }
  // };

  const filterData1 = () => {
    if (!data) {
      // Data is not available yet
      alert("data null");
      return;
    }

    // Check if either fromDate or toDate is not empty
    // if (fromDate || toDate) {
    //   // for from date
    //   const newFromDate = fromDate ? new Date(fromDate).toISOString() : "";

    //   // for to date
    //   const newToDate = toDate ? new Date(toDate).toISOString() : "";

    //   const filtered =
    //     data &&
    //     data.filter(
    //       (item) =>
    //         item.date >= newFromDate &&
    //         item.date <= newToDate &&
    //         Object.values(item).some((value) =>
    //           value
    //             ?.toString()
    //             ?.toLowerCase()
    //             ?.includes(searchValue.toLowerCase().trim())
    //         )
    //     );

    //   setFilteredData(filtered);
    // } else {
    // No date range selected, consider only the search value
    const filtered =
      data.length > 0 &&
      data.filter((item) =>
        Object.values(item).some((value) =>
          value
            ?.toString()
            ?.toLowerCase()
            ?.includes(searchValue.toLowerCase().trim())
        )
      );

    setFilteredData(filtered);
    // }
  };

  const parseIndianStyleDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  useEffect(() => {
    filterData1();
    //   const fltr = filterData();
    // setFilteredData(fltr);
  }, [fromDate, toDate, data, searchValue]);

  // for date range end

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleLeftArrow = () => {
    navigate(`/reports/trialBalance/accountTransactions`, {
      state: { accountname },
    });
  };


    // downnload as a pdf

    const handleDownload = () => {
      if (pdfRef.current) {
        const element = pdfRef.current;
        const options = {
          margin: 10,
          filename: "Details-Transaction.pdf",
          image: { type: "jpeg", quality: 2.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        };
  
        html2pdf(element, options);
      }
    };
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{extractedPart}</span>
        </Col>
      </Row>
      <Row>
        <Col className="table-wrapper-container">
          <Row>
            <Col lg={8}>
              <p className="section-title form-section ptag">
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={handleLeftArrow}
                />
              </p>
            </Col>
            <Col lg={2}>
              <div className="inputFieldIcon">
                <FormControl
                  type="text"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="basic-addon2"
                  className="mt-2"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <span className="iconPosition">
                  <BiSearch />
                </span>
              </div>
            </Col>
            <Col md={1} className="d-flex gap-3">
              <span>
                {/* <ReactHTMLTableToExcel
                  id="exportButton"
                  className="commonBtn smallBtn cancelBtn mt-2"
                  table="table-to-export"
                  filename={`Details-Transaction-${formatDateToDDMMYYYY(
                    fromDate
                  )} - ${formatDateToDDMMYYYY(toDate)}`}
                  sheet="trial_report"
                  buttonText={
                    <>
                      <BiSolidFileExport /> Export
                    </>
                  }
                /> */}


<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

              </span>
              <span>
                <Button className="commonBtn smallBtn cancelBtn mt-2" onClick={handleDownload}>Save pdf</Button>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={4}>
          <Form.Label className="section-title form-section ptag">
            Detailed Account Transactions
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Label
            className="form-section ptag text-align-center"
            style={{ fontSize: "20px" }}
          >
            {ledger || ""}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={3}>
          <Form.Label className="form-section ptag text-align-center">
            {`From ${fromDate ? formatDateToDDMMYYYY(fromDate) : ""} to ${
              toDate ? formatDateToDDMMYYYY(toDate) : ""
            }`}
          </Form.Label>
        </Col>
      </Row>
      <Row className="justify-content-center table-wrapper-container">
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
            <Form.Label className="mt-3 text-color">Range</Form.Label>
            {/* <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
            >
              <option value="Today">Today</option>
              <option value="Yesterday">Yesterday</option>
              <option value="This Week">This Week</option>
              <option value="This Month">This Month</option>
              <option value="This Quarter">This Quarter</option>
              <option value="This Year">This Year</option>
              <option value="Date Range">Date Range</option>
            </Form.Select> */}
            <Form.Select
              className="mt-2 p-2"
              value={selectedRange}
              onChange={handleSelectedRange}
              // disabled={!isCurrentYear}
            >
              <option disabled={!isCurrentYear} value="Today">
                Today
              </option>
              <option disabled={!isCurrentYear} value="Yesterday">
                Yesterday
              </option>
              <option disabled={!isCurrentYear} value="This Week">
                This Week
              </option>
              <option disabled={!isCurrentYear} value="This Month">
                This Month
              </option>
              <option disabled={!isCurrentYear} value="This Quarter">
                This Quarter
              </option>
              <option disabled={!isCurrentYear} value="This Year">
                This Year
              </option>
              <option value="Date Range">Date Range</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">From</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="from"
              // value={data.requestdate}
              value={fromDate}
              onChange={handleFromDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={2}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">To</Form.Label>
            <Form.Control
              disabled={selectedRange != "Date Range"}
              className="mt-2 p-2"
              type="date"
              name="to"
              // value={data.requestdate}
              value={toDate}
              onChange={handleToDateChange}
              // isInvalid={data.requestdate === "" && showEmptyError}
            />
            <Form.Control.Feedback type="invalid">
              Select Date
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={3}>
          <Form.Group className="d-flex gap-2 mt-4" controlId="formBasicEmail">
            <Form.Label className="mt-3 text-color">Select Column</Form.Label>
            <span>
              <HiViewColumns
                onClick={handleShow}
                className="column-select-icon"
              />
            </span>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="table-main-container" ref={pdfRef}>
          <Table id="table-to-export" responsive>
            <thead className="tableContainer">
              <tr>
                {columnData.map(
                  (column) =>
                    columnVisibility[column.name] && (
                      <th key={column.index}>{column.label}</th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {loadData || loading ? (
                <tr>
                  <td colSpan="9" className="text-center">
                    <div
                      className="spinner-border spinner-border-md"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                  </td>
                </tr>
              ) : filteredData.length > 0 ? (
                filteredData.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    {columnData.map(
                      (column) =>
                        columnVisibility[column.name] && (
                          <React.Fragment key={column.index}>
                            {
                              <td key={column.index}>
                                {column.name === "date"
                                  ? formatDateToDDMMYYYY(
                                      item[column.name.toLowerCase()]
                                    )
                                  : item[column.name.toLowerCase()]}
                              </td>
                            }
                          </React.Fragment>
                        )
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="datanotfound-center-text">
                      No Record Found!! 🤔
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*  //---------------Modal-----------------------------// */}
      <Modal
        show={show}
        size="sm"
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton>
          <h4>Select Column</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              {columnData.map((column) => (
                <div key={column.index} className="mb-3">
                  {column.default ? (
                    <span>{column.label}</span>
                  ) : (
                    <Form.Check
                      type="checkbox"
                      id={column.name}
                      label={column.label}
                      checked={columnVisibility[column.name]}
                      onChange={() => handleCheckboxChange(column.name)}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center">
          <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer> */}
      </Modal>
    </Container>
  );
};

export default DetailedAccountTransactions;
