import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GlobalPagination from "../../../constants/GlobalPagination";
import SearchField from "../../../constants/SearchField";
import { MdModeEditOutline } from "react-icons/md";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import {
  deleteSuggestedItemData,
  suggestedItemData,
} from "../../../redux/actions/suggesteditems.actions";
import ViewSuggestedItem from "./ViewSuggestedItem";
import axios from "axios";

const ListTableSuggestedItem = () => {
  const navigate = useNavigate();
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedItemNo, setSelectedItemNo] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data);
  const data = useSelector(
    (state) => state.suggestedItemData.suggestedItemData
  );
  const loadData = useSelector((state) => state.loadReducer);

  const filteredData = !data.data ? [] : data.data;

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData[0].companyid;
  const userid = userData[0].userid;
  const branchid = userData[0].branchid;
  const org = userData[0].org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    keyword: searchKeyword || org,
    page: currentPage,
    pageSize: pageSize,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(suggestedItemData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  //search logic

  const handleShow = (item) => {
    const { id, groupid } = item;
    setShow(true);
    setSelectedItemId(id);
    setSelectedItemNo(groupid);
  };

  const handleEdit = (id) => {
    navigate(`/inventory/suggestedItem/editSuggestedItem/${id}`);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteSuggestedItemData(
        {
          id: selectedItemId,
          groupid: selectedItemNo,
          companyid: companyid,
          userid: userid,
          org: org,
        },
        handleClose
      )
    );
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

        userid,
        submodule: "Suggested Item",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);


  useEffect(() => {
    axios
      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {
        userid,
        submodule: "Suggested Item",
        companyid,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);

  // crud functionality to show and hide create, edit, delete icons end


  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Suggested Items</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <Button
                className="commonBtn mt-2"
                onClick={() =>
                  navigate("/inventory/suggestedItem/addSuggestedItem")
                }
              >
                Add Suggested Items
              </Button>
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <thead className="tableContainer">
            <tr>
              <th>Group ID</th>
              <th>Group Name</th>
              <th>Primary Item</th>
              <th>Associated Item</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => {
                const {
                  id,
                  groupid,
                  groupname,
                  primaryitem,
                  itemname,
                  itemarray,
                } = item;
                return (
                  <tr key={index}>
                    <td>{!groupid ? "--" : groupid}</td>
                    <td>{!groupname ? "--" : groupname}</td>
                    <td>{!primaryitem ? "--" : primaryitem}</td>
                    {/* <td>{!itemname ? "--" : itemname}</td> */}
                    <td>
                      {itemarray.length > 0
                        ? itemarray.map((item) => item.itemname).join(",")
                        : "--"}
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center gap-2">
                        <div>
                          <ViewSuggestedItem item={item} />
                        </div>
                        <div>
                          {" "}
                          {crud && crud.includes("u") && (
                            <MdModeEditOutline
                              className="editIcon mr-2"
                              onClick={() => handleEdit(item.id)}
                            />
                          )}
                        </div>
                        <div>
                          {" "}
                          {crud && crud.includes("d") && (
                            <DeleteConfirmation
                              show={show}
                              handleShow={() => handleShow(item)}
                              handleClose={handleClose}
                              handleDelete={handleDelete}
                              name={"Suggested Item"}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
      {/* pagination ui  */}
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTableSuggestedItem;
