import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import SearchField from "../../../constants/SearchField";
import { useNavigate } from "react-router-dom";
import {
  userDeleteUserPost,
  userUpdateStatus,
  userUserpaginationPost,
} from "../../../constants/api";
import axios from "axios";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "../../../constants/DeleteConfirmation";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";

import { MdModeEditOutline } from "react-icons/md";
import TableHeading from "../../../constants/TableHeading";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import AddPageButton from "../../../constants/AddPageButton";
import DataSpinner from "../../../constants/DataSpinner";
import {
  employeeData,
  switchEmployee,
} from "../../../redux/actions/employee.actions";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import GlobalPagination from "../../../constants/GlobalPagination";
import ViewEmployee from "./ViewEmployee";

const ListTable = () => {
  const navigate = useNavigate();
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;
  //state
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  //Redux part
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authManager.userData.data)[0];
  
  const data = useSelector((state) => state.employeeData.employeeData);
  const loadData = useSelector((state) => state.loadReducer);

  //pagination data
  const totalPagesFromAPI = {
    totalRecords: data.totalItems ? data.totalItems : 0,
    totalPages: data.totalPages ? data.totalPages : 0,
  };

  //Default data backend needed
  const companyid = userData.companyid;
  const userid = userData.userid;
  const branchid = userData.branchid;
  const org = userData.org;
  const backendData = {
    companyid: companyid,
    userid: userid,
    branchid: branchid,
    page: currentPage,
    pageSize: pageSize,
    keyword: searchKeyword || org,
  };

  useEffect(() => {
    setLoading(!loading);
    dispatch(employeeData(backendData, setLoading));
  }, [dispatch, pageSize, currentPage, loadData, searchKeyword]);

  const handleShow = (id) => {
    setShow(true);
    setSelectedItemId(id);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSwitch = (id, status) => {
    dispatch(switchEmployee({ id, status }, handleClose));
  };

  const handleEdit = (id) => {
    navigate(`/employeemanagement/employee/editEmployee/${id}`);
  };

   // crud functionality to show and hide create, edit, delete icons start
   const [crud, setCrud] = useState(["c", "d", "u"]);

   useEffect(() => {
     axios

       .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

         userid,
         submodule: "Registration",
         companyid,
       })
       .then((res) => {
         
         setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
       })
       .catch((err) => console.log(err));
   }, [loadData]);
   
   // crud functionality to show and hide create, edit, delete icons end

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Employees</span>
            </p>
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
        { crud && crud.includes("c") && <AddPageButton
              text="Employee"
              link={`/employeemanagement/employee/addEmployee`}
            />}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading
            data={[
              "Employee ID",
              "Employee Name",
              "City",
              "DOB",
              "Phone",
              "Email",
              "Action",
            ]}
          />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="7" />
            ) : data.data && data.data.length > 0 ? (
              data.data.map((item, index) => (
                <tr key={index}>
                  <td>{item.employeeid}</td>
                  <td>{item.fullname}</td>
                  <td>{item.currentcity}</td>
                  <td>{item.dob ? indianFormatDate(item.dob) : ""}</td>
                  <td>{item.phoneno}</td>
                  <td>{item.emailid}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <div>
                        <OverlayTrigger
                          placement="top"
                          overlay={renderTooltip("Active/Inactive")}
                        >
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            checked={item.status == "Active"}
                            className="custom-switch"
                            onChange={() =>
                              handleSwitch(
                                item.id,
                                item.status === "Active"
                                  ? "In-Active"
                                  : "Active"
                              )
                            }
                          />
                        </OverlayTrigger>
                      </div>
                      <div>
                        <ViewEmployee item={item} />
                      </div>
                      <div>
                     {crud && crud.includes("u") &&  <MdModeEditOutline
                        className="editIcon mr-2"
                        onClick={() => handleEdit(item.id)}
                      />}
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="7" name="Employees" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListTable;
