//----------------SignUP---------------//

export const userAdduserPost = "https://mis.saanviavs.com/api/v1/user/adduser";

//Add User (Signup): [POST]
//(req.body = userid, password, name, email, contactno, designation, branch, branchid, role, warehouse, isShowroom, company, status)

//----------------Login---------------//
export const loginUserPost = "https://mis.saanviavs.com/api/v1/user/loginuser";
// (req.body = userid, password)

export const logoutUserPost = "https://mis.saanviavs.com/api/v1/user/logout";

//----------------Comapany-------------//
export const companyPagiDataPost =
  "https://mis.saanviavs.com/api/v1/master/getcomapanypage";
// (req.body = companyid, userid, page, pageSize)

export const companyAddPost = "https://mis.saanviavs.com/api/v1/master/addcompany";
// (req.body =  companyname, companyid, description, userid)

export const companyUpdatePost = "https://mis.saanviavs.com/api/v1/master/updatecompany";
// (req.body = companyname, companyid, description, userid, id )

export const companyStatusChangePost =
  "https://mis.saanviavs.com/api/v1/master/companyupdatestatus";
// (req.body = id, status)

export const companyDeletePost = "https://mis.saanviavs.com/api/v1/master/deletecompany";
// (req.body = id)

//----------------Branch-------------//
export const branchStatusChangePost =

  "https://mis.saanviavs.com/api/v1/master/branchstatus";
// (req.body = id, status)
export const branchPagiDataPost = "https://mis.saanviavs.com/api/v1/master/getbranchpage";
// (req.body = userid, company, page, pageSize)

export const branchAddPost = "https://mis.saanviavs.com/api/v1/master/addbranch";
//(req.body = branchid, branchname, city, state, pin, userid, company, companyid, address, status, gstno);

export const branchUpdatePost = "https://mis.saanviavs.com/api/v1/master/updatebranch";
//(req.body - branchid, branchname, city, state, pin, userid, company, companyid, address, status, gstno, id)

export const branchDeletePost = "https://mis.saanviavs.com/api/v1/master/deletebranch";
// (req.body = id)

export const branchCountPost = "https://mis.saanviavs.com/api/v1/idgenerate/branchid";
// (req.body = userid)

//----------------------USER---------------------//
export const userAddUserPost = "https://mis.saanviavs.com/api/v1/user/adduser";
//Add User (Signup): [POST]
//(req.body = userid, password, name, email, contactno, designationname,
//            designationid, departmentname, departmentid, branch, branchid,
//            warehousename, warehouseid, showroomname, showroomid, isShowroom,
//            companyname, companyid, status )
//Response: {message: "User added successfully" }


export const userUserpaginationPost = "https://mis.saanviavs.com/api/v1/user/users";

//Get All Users: [POST]
//(req.body = userid)
//Response: {  "data": [{}, {}]}


export const idgenerateUseridPost = "https://mis.saanviavs.com/api/v1/idgenerate/userid";

//Count user: [POST]
//(req.body = userid)
//Response: {}


export const userDeleteUserPost = "https://mis.saanviavs.com/api/v1/user/deleteuser";

//Delete user: [POST]
//(req.body = id)
// Response: {
//     "message": "User deleted successfully"
// }


export const userUpdateStatus = "https://mis.saanviavs.com/api/v1/user/updatestatus";
//Update user status: [POST]
//(req.body = id, status)

export const userUpdateUserPost = "https://mis.saanviavs.com/api/v1/user/updateuser";

//Update user: [POST]
//(req.body = userid, password, name, email, contactno, designationname,
//           designationid, departmentname, departmentid, branch, branchid,
//           warehousename, warehouseid, showroomname, showroomid, isShowroom,
//           companyname, companyid, status, id)
// Response: {
//     "message": "User updated successfully"
// }

//---------------------Designation------------------------------
export const masterAddDesignationPost =

  "https://mis.saanviavs.com/api/v1/master/adddesignation";
//Req.body =  designationid,designationname,

export const masterEditDesignationPost =
  "https://mis.saanviavs.com/api/v1/master/updatedesignation";
//Req.body =  designationid,designationname,

export const masterGetDesignationPost =
  "https://mis.saanviavs.com/api/v1/master/getdesignation";
//Req.body =  userid,

export const masterDeleteDesignationPost =
  "https://mis.saanviavs.com/api/v1/master/deletedesignation";

//Req.body =  id,

//---------------------Department------------------------------
export const masterAddDepartmentPost =

  "https://mis.saanviavs.com/api/v1/master/adddepartment";
//Req.body =  departmentid,departmentname,

export const masterEditDepartmentPost =
  "https://mis.saanviavs.com/api/v1/master/updatedepartment";
//Req.body =  departmentid,departmentname,

export const masterGetDepartmentPost =
  "https://mis.saanviavs.com/api/v1/master/getdepartment";
//Req.body =  userid,

export const masterDeleteDepartmentPost =
  "https://mis.saanviavs.com/api/v1/master/deletedepartment";

//Req.body =  id,

//--------------------------WAREHOUSE------------------------

export const warehousePagiDataPost =

  "https://mis.saanviavs.com/api/v1/master/getwarehousepage";

export const addWarehousePost = "https://mis.saanviavs.com/api/v1/master/addwarehouse ";

export const warehouseStatusChange =
  "https://mis.saanviavs.com/api/v1/master/warehouseupdatestatus";

export const updateWarehousePost =
  " https://mis.saanviavs.com/api/v1/master/updatewarehouse ";

export const deleteWarehouse = "https://mis.saanviavs.com/api/v1/master/deletewarehouse";

export const warehouseGetById =
  "https://mis.saanviavs.com/api/v1/master/getwarehousebyid";

export const warehouseIdCount = "https://mis.saanviavs.com/api/v1/idgenerate/warehouseid";


//--------------------------WAREHOUSE------------------------

//--------------------------STOCK LOCATION------------------------
export const stockPagiDataPost =
  "https://mis.saanviavs.com/api/v1/master/getlocationpage";

export const addStockPost = "https://mis.saanviavs.com/api/v1/master/addlocation";

export const stockStatusChange =
  "https://mis.saanviavs.com/api/v1/master/locationupdatestatus";

export const updateStockPost = "https://mis.saanviavs.com/api/v1/master/updatelocation";

export const deleteStock = "https://mis.saanviavs.com/api/v1/master/deletelocation";

export const stockGetById = "https://mis.saanviavs.com/api/v1/master/getlocationbyid";

export const stockIdCount = "https://mis.saanviavs.com/api/v1/idgenerate/locationid";
//--------------------------STOCK LOCATION------------------------

//---------------------------Items----------------------------//
export const itemAddItemPost = "https://mis.saanviavs.com/api/v1/item/additem";

//Add -[post]
//(req.body = itemid, itemname, category, brand, size, unit, itemdescription,
//            taxability, gst, cessvaluationtype, openingqty, rateperunit, asondate,
//            reorderpoint, inventoryaccount, inventorydescription, purchaseaccount,
//            purchasedescription, saleaccount, saledescription, companyid, image, sku,
//            mpn, grade, thickness, userid, chartofaccount)


export const itemUpdateItemPost = "https://mis.saanviavs.com/api/v1/item/updateitem";

//Update -[post]
//(req.body =  itemid, itemname, category, brand, size, unit, itemdescription,
//             taxability, gst, cessvaluationtype, openingqty, rateperunit, asondate,
//             reorderpoint, inventoryaccount, inventorydescription, purchaseaccount,
//             purchasedescription, saleaccount, saledescription, companyid, image, suk,
//             mpn, grade, thickness, userid, chartofaccount id)


export const itemGetItemPost = "https://mis.saanviavs.com/api/v1/item/getitem";
//Get Item- [POST]
//(req.body = page, pageSize, companyid, branchid, userid)

export const itemDeleteItemPost = "https://mis.saanviavs.com/api/v1/item/deleteitem";
//Delete Item- [POST] (req.body = id)

export const itemgetcount = "https://mis.saanviavs.com/api/v1/item/getcount";
//Get Count - [POST]

//-----------------------Access Module-----------------------
export const addorEditAccess = "https://mis.saanviavs.com/api/v1/user/updateaccessmodule";
//Req.body= module: <Array>, submodule: <Array>,   id,

//-----------------------Category---------------------
export const itemCategoryPost = "https://mis.saanviavs.com/api/v1/item/category";
// Req.body = userid

export const itemAddCategoryPost = "https://mis.saanviavs.com/api/v1/item/addcategory";

//req.body = categoryid, category, userid, companyid,branchid,

//-----------------------Suggested Item Start--------------------
export const getSuggestedItemApi =

  "https://mis.saanviavs.com/api/v1/suggesteditem/getpage";

export const addSuggestedItemApi = "https://mis.saanviavs.com/api/v1/suggesteditem/add";

export const deleteSuggestedItemApi =
  "https://mis.saanviavs.com/api/v1/suggesteditem/delete";

export const updateSuggestedItemApi =
  "https://mis.saanviavs.com/api/v1/suggesteditem/update";

export const suggestedItemGetByIdApi =
  "https://mis.saanviavs.com/api/v1/suggesteditem/getbyid";

export const suggestedItemIdAutoCount =
  "https://mis.saanviavs.com/api/v1/idgenerate/suggestid";


//-----------------------Suggested Item End--------------------

//-------------Vendor--------------------------------


export const getVendorPagiApi = "https://mis.saanviavs.com/api/v1/vendor/getpage";

export const addVendorApi = "https://mis.saanviavs.com/api/v1/vendor/add";

export const deleteVendorApi = "https://mis.saanviavs.com/api/v1/vendor/delete";

export const editVendorApi = "https://mis.saanviavs.com/api/v1/vendor/update";

export const getVendorByIdApi = "https://mis.saanviavs.com/api/v1/vendor/getbyid";


//-------------Vendor--------------------------------------------------

//------Purchase Order--------------------------------

export const getPurchaseOrderPagiApi =

  "https://mis.saanviavs.com/api/v1/purchaseorder/getpage";

export const addPurchaseOrderApi = "https://mis.saanviavs.com/api/v1/purchaseorder/add";

export const deletePurchaseOrderApi =
  "https://mis.saanviavs.com/api/v1/purchaseorder/delete";

export const editPurchaseOrderApi =
  "https://mis.saanviavs.com/api/v1/purchaseorder/update";

export const poidAutoCount = "https://mis.saanviavs.com/api/v1/idgenerate/poid";

export const getPoByid = "https://mis.saanviavs.com/api/v1/purchaseorder/getbyid";


//------Purchase Order--------------------------------

//------ Purchase Bills--------------------------------


export const getPurchasebillApi = "https://mis.saanviavs.com/api/v1/purchasebill/getpage";

export const purchaseBillAutoIdCount =
  "https://mis.saanviavs.com/api/v1/idgenerate/billid";

export const addPurchasebillsApi = "https://mis.saanviavs.com/api/v1/purchasebill/add";

export const updatePurchaseBillsApi =
  "https://mis.saanviavs.com/api/v1/purchasebill/update";

export const getPurchaseBillById = "https://mis.saanviavs.com/api/v1/purchasebill/byid";


//-------------Purchase Bills--------------------------

//-------------Purchase Target------------------------

export const getPurchaseTargetApi =
  "https://mis.saanviavs.com/api/v1/purchasetarget/getpage";

export const addPurchaseTargetApi = "https://mis.saanviavs.com/api/v1/purchasetarget/add";

export const updatePurchaseTargetApi =
  "https://mis.saanviavs.com/api/v1/purchasetarget/update";

export const deletePurchaseTargetApi =
  "https://mis.saanviavs.com/api/v1/purchasetarget/delete";

export const purchaseTargetIdAutoCount =
  "https://mis.saanviavs.com/api/v1/idgenerate/purchasetargetid";

export const purchaseTargetGetById =
  "https://mis.saanviavs.com/api/v1/purchasetarget/getbyid";

  export const getPurchasePaymentApi =
  "https://mis.saanviavs.com/api/v1/paymentmade/getallpaymentmadepage";

export const addPurchasePaymentApi = "https://mis.saanviavs.com/api/v1/paymentmade/addpaymentmade";

export const updatePurchasePaymentApi =
  "https://mis.saanviavs.com/api/v1/paymentmade/updatepaymentmade";

export const deletePurchasePaymentApi =
  "https://mis.saanviavs.com/api/v1/paymentmade/deletepaymentmade";

export const PurchasePaymentIdCountApi =
  "https://mis.saanviavs.com/api/v1/paymentmade/paymentmadelength";

export const purchasePaymentGetByIdApi =
  "https://mis.saanviavs.com/api/v1/paymentmade/paymentmadebyid";

export const getPurchasetargetDashboardApi =
  "https://mis.saanviavs.com/api/v1/purchasebill/pachievedashboard";

export const getSalesTargetApi = "https://mis.saanviavs.com/api/v1/saletarget/getpage";

export const addSalesTargetApi = "https://mis.saanviavs.com/api/v1/saletarget/add";

export const deleteSalesTargetApi = "https://mis.saanviavs.com/api/v1/saletarget/delete";

export const updateSalesTargetApi = "https://mis.saanviavs.com/api/v1/saletarget/update";

export const salesTargetGetById = "https://mis.saanviavs.com/api/v1/saletarget/getbyid";

export const salesTargetIdAutoCount =

  "https://mis.saanviavs.com/api/v1/idgenerate/saletargetid" ;


//------Purchase Target--------------------------------

//---payment term------

export const addptApi = "https://mis.saanviavs.com/api/v1/pt/addpt";

export const getptApi = "https://mis.saanviavs.com/api/v1/pt/getpt";

export const deleteptApi = "https://mis.saanviavs.com/api/v1/pt/deletept";


//payment term

//-------------Chart of Account API------------------------------------
export const coaAddPost = "https://mis.saanviavs.com/api/v1/coa/addcoa";
// ADD coa= [POST]
//(Req.body = coaid, category, subcategory, gledger, siteid, userid)

export const coaUpdatePost = "https://mis.saanviavs.com/api/v1/coa/updatecoa";

// UPDATE coa = [POST]
//(req.body = coaid, category, subcategory, gledger, siteid, userid, id)

// export const coacoabysitepost = "http://localhost:8081/coa/coabysite";
// GET coa by Site= [POST]
//(Req.body = siteid,org)


export const coaPagiDataPost = "https://mis.saanviavs.com/api/v1/coa/getcoa";
// (Req.body = keyword, userid, companyid, branchid, page, pageSize)

export const coaCountPost = "https://mis.saanviavs.com/api/v1/idgenerate/coaid";
//(Req.body = userid)

export const coaParentAccountPost = "https://mis.saanviavs.com/api/v1/coa/accounttype";

//(Req.body = type, userid, companyid, branchid)

export const deleteCoaPost =  "https://mis.saanviavs.com/api/v1/coa/deletecoa"
/// Delete coa = [POST] (Req.body = id)

//-------------Chart of Account API------------------------------------

//-------------Manual Journal API------------------------------------
export const manualJournalAddPost =

  "https://mis.saanviavs.com/api/v1/manualjournal/addmanualjournal";
//(Req.body = date, journalno, note, journalarray, status, totaldebits, totalcredits, documents, refno, userid, branchid, companyid, month, financialyear)

export const manualJournalUpdatePost =
  "https://mis.saanviavs.com/api/v1/manualjournal/updatemanualjournal";
//(Req.body = date, journalno, note, journalarray, status, totaldebits, totalcredits, documents, refno, userid, branchid, companyid, month, financialyear, id)

export const manualJournalPagiDataPost =
  "https://mis.saanviavs.com/api/v1/manualjournal/allmanualjournal";
// (Req.body = keyword, userid, companyid, branchid)

export const manualJournalCountPost = "https://mis.saanviavs.com/api/v1/idgenerate/mjid";
//(Req.body = userid, companyid, branchid)

export const manualJournalDocumentUploadPost =
  "https://mis.saanviavs.com/api/v1/manualjournal/multipledocumentupload";
//(req.body = file)

export const manualJournalById =
  "https://mis.saanviavs.com/api/v1/manualjournal/journalno";

//(Req.body = type, userid, companyid, branchid)

//-------------Manual Journal API------------------------------------

//---------------------Ledger API---------------------------------

export const ledgerPagiDataPost = "https://mis.saanviavs.com/api/v1/ledger/allledger";
//(Req.body = keyword, userid, companyid, branchid, page, pageSize)

export const ledgerAddPost = "https://mis.saanviavs.com/api/v1/ledger/addledger";
// (Req.body = ledgerid, ledgername, ledgertype, istds,  isinventory, accountid, accountname, accounttype, openingbalance, debitcredit, asondate, companyid, userid, branchid)

export const ledgerUpdatePost = "https://mis.saanviavs.com/api/v1/ledger/updateledger";
//(Req.body = ledgerid, ledgername, ledgertype, istds,  isinventory, accountid, accountname, accounttype, openingbalance, debitcredit, asondate, companyid, userid, branchid, id)

export const ledgerCountPost = "https://mis.saanviavs.com/api/v1/ledger/ledgercount";
//(Req.body = userid, branchid, companyid)

export const ledgerByIdPost = "https://mis.saanviavs.com/api/v1/ledger/ledgerbyid";
//(req.body = id)

export const ledgerDeletePost = "https://mis.saanviavs.com/api/v1/ledger/deleteledger";

//(Req.body = id)

//---------------------Ledger API---------------------------------

//-------------------------Customer API------------------------------//

export const customerAddPost = "https://mis.saanviavs.com/api/v1/customer/addcustomer";
export const customerIDCreatePost =
  "https://mis.saanviavs.com/api/v1/idgenerate/customerid";
export const customerDeletePost =
  "https://mis.saanviavs.com/api/v1/customer/deletecustomer";
// (Req.body = = userid, companyid, branchid)
export const customerUpdatePost =
  "https://mis.saanviavs.com/api/v1/customer/updatecustomer";
export const customerGetPost =
  "https://mis.saanviavs.com/api/v1/customer/getallcustomerpage";

// project api's start

export const addProjectApi = "https://mis.saanviavs.com/api/v1/projects/add";

export const getProjectApi = "https://mis.saanviavs.com/api/v1/projects/getpage";

export const deleteProjectApi = "https://mis.saanviavs.com/api/v1/projects/delete";

export const updateProjectApi = "https://mis.saanviavs.com/api/v1/projects/update";

export const projectIdAutocount = "https://mis.saanviavs.com/api/v1/idgenerate/projectid";

export const getProjectById = "https://mis.saanviavs.com/api/v1/projects/projectbyid";

export const getProjectTeamsApi = "https://mis.saanviavs.com/api/v1/projectteam/getpage";

//project api's end

// taxes api's starts

export const tdsIdAutocountApi = "https://mis.saanviavs.com/api/v1/idgenerate/tdsid";

export const addTdsApi = "https://mis.saanviavs.com/api/v1/tds/add";

export const updateTdsApi = "https://mis.saanviavs.com/api/v1/tds/update";

export const getTdsApi = "https://mis.saanviavs.com/api/v1/tds/get";

export const deleteTdsApi = "https://mis.saanviavs.com/api/v1/tds/delete";

export const getTdsByIdApi = "https://mis.saanviavs.com/api/v1/tds/tdsbyid";

export const tcsIdAutocountApi = "https://mis.saanviavs.com/api/v1/idgenerate/tcsid";

export const addTcsApi = "https://mis.saanviavs.com/api/v1/tcs/add";

export const updateTcsApi = "https://mis.saanviavs.com/api/v1/tcs/update";

export const getTcsApi = "https://mis.saanviavs.com/api/v1/tcs/get";

export const deleteTcsApi = "https://mis.saanviavs.com/api/v1/tcs/delete";

export const getTcsByIdApi = "https://mis.saanviavs.com/api/v1/tcs/tcsbyid";


//taxes api's ends

//----------------Receive Good---------------------------------//

export const grnGenerate = "https://mis.saanviavs.com/api/v1/idgenerate/grnid";
export const addReceiveGood = "https://mis.saanviavs.com/api/v1/receivegoods/add";
export const getReceiveGood = "https://mis.saanviavs.com/api/v1/receivegoods/getpage";
//-------------Bank API Start------------------------------------
export const bankAddPost = "https://mis.saanviavs.com/api/v1/bank/add";
//(Req.body = bank, bankid, accountnumber, branchname, ifsccode, address, accounttype, account, org, userid, companyid, branchid)

export const bankUpdatePost = "https://mis.saanviavs.com/api/v1/bank/update";
//(Req.body = bank, bankid, accountnumber, branchname, ifsccode, address, accounttype, account, org, userid, companyid, branchid, id)

export const BankPagiDataPost = "https://mis.saanviavs.com/api/v1/bank/get";
// (Req.body =  keyword, userid, companyid, branchid, page, pageSize)

export const BankCountPost = "https://mis.saanviavs.com/api/v1/bank/count";
//(Req.body = userid, companyid, branchid)

export const BankDeletePost = "https://mis.saanviavs.com/api/v1/bank/delete";

//(Req.body =  id)

//----------------------Bank API End-----------------------------

//-------------Payment API Start------------------------------------

export const paymentAddPost = "https://mis.saanviavs.com/api/v1/transaction/addpayment";
//(Req.body =   paymentid, date, prefno, paymentmode,  paidthrough, paidto, amount, companyid, userid, org(₹ΘθϖΠ), month, financialyear)

export const paymentUpdatePost =
  "https://mis.saanviavs.com/api/v1/transaction/updatepayment";
//(Req.body =   paymentid, date, prefno, paymentmode,  paidthrough, paidto, amount, companyid, userid, org₹ΘθϖΠ), month, financialyear, id)

export const paymentPagiDataPost =
  "https://mis.saanviavs.com/api/v1/transaction/getallpaymentpage";
// (Req.body = keyword, userid, companyid, branchid, page, pageSize)

export const paymentCountPost =
  "https://mis.saanviavs.com/api/v1/transaction/paymentcount";
//(Req.body = userid, companyid, branchid)

export const paymentDeletePost =
  "https://mis.saanviavs.com/api/v1/transaction/deletepayment";

//(Req.body =  id)
//----------------------Payment API End-----------------------------

//-------------Receipt API Start------------------------------------

export const receiptAddPost = "https://mis.saanviavs.com/api/v1/transaction/addreceipt";
//(Req.body =  receiptid, date, prefno, receivefrom,  receivemode, receiveto, amount, companyid, userid, org(₹ΘθϖΠ), month, financialyear)

export const receiptUpdatePost =
  "https://mis.saanviavs.com/api/v1/transaction/updatereceipt";
//(Req.body =  receiptid, date, prefno, receivefrom,  receivemode, receiveto, amount, companyid, userid, org(₹ΘθϖΠ), month, financialyear, id)

export const receiptPagiDataPost =
  "https://mis.saanviavs.com/api/v1/transaction/getallreceiptpage";
// (Req.body = keyword, userid, companyid, branchid, page, pageSize)

export const receiptCountPost =
  "https://mis.saanviavs.com/api/v1/transaction/receiptcount";
//(Req.body = userid, companyid, branchid)

export const receiptDeletePost =
  "https://mis.saanviavs.com/api/v1/transaction/deletereceipt";

//(Req.body =  id)
//----------------------Receipt API End-----------------------------

//-------------ContraEntry API Start------------------------------------
export const ContraEntryAddPost =
  "https://mis.saanviavs.com/api/v1/transaction/addcontra";
//(Req.body =  contraid, contratype, prefno, amount, "from", "to", date, companyid, userid, org(₹ΘθϖΠ), remark, month, financialyear)

export const ContraEntryUpdatePost =
  "https://mis.saanviavs.com/api/v1/transaction/updatecontra";
//(Req.body =  contraid, contratype, prefno, amount, "from", "to", date, companyid, userid, org(₹ΘθϖΠ), remark, month, financialyear, id)

export const ContraEntryPagiDataPost =
  "https://mis.saanviavs.com/api/v1/transaction/getallcontrapage";
// (Req.body = keyword, userid, companyid, branchid, page, pageSize)

export const ContraEntryCountPost =
  "https://mis.saanviavs.com/api/v1/transaction/contracount";
//(Req.body = userid, companyid, branchid)

export const ContraEntryDeletePost =
  "https://mis.saanviavs.com/api/v1/transaction/deletecontra";

//(Req.body =  id)
//----------------------ContraEntry API End-----------------------------

//----------------------Quotation API-------------------------------//

export const quoteGetpagePost = "https://mis.saanviavs.com/api/v1/quote/getpage";
//Get all Quote: [POST]
//(req.body = keyword, userid, companyid, branchid)

export const quoteCancelPatch = "https://mis.saanviavs.com/api/v1/quote/canclequote";
//Cancle Quote: [PATCH]
//(req.body = id, status)

export const generateQuoteIDPost = "https://mis.saanviavs.com/api/v1/idgenerate/quoteid";
//Count of Quote: [POST]
//(req.body = userid, companyid, branchid)

export const quoteAddPost = "https://mis.saanviavs.com/api/v1/quote/add";

//Add Quote: [POST]
//(req.body = customerid, customername, saleperson, quoteno,
//            quotedate, project, projectid, createdby, expiredby,
//            itemarray, subtotal, discount, tax, adjustment,
//            finalamount, tandc, branchid, companyid, userid, org,
//            status)


export const quoteUpdatePost = "https://mis.saanviavs.com/api/v1/quote/update";

//Update Quote: [POST]
//(req.body = customerid, customername, saleperson, quoteno,
//            quotedate, project, projectid, createdby, expiredby,
//            itemarray, subtotal, discount, tax, adjustment,
//            finalamount, tandc, branchid, companyid, userid, org,
//            status, id)

//----------------------issue goods API-------------------------------//

export const issueGoodgetPagePost = "https://mis.saanviavs.com/api/v1/issuegood/getpage";

//Get all issuegoods: [POST]
//(req.body = keyword, userid, companyid, branchid, page, pageSize)

export const idgenerateIssueGood =

  "https://mis.saanviavs.com/api/v1/idgenerate/issuegoodid";
//Count of issuegoods: [POST]
//(req.body = userid, companyid, branchid)

export const issuegoodAdd = "https://mis.saanviavs.com/api/v1/issuegood/add";

//Add issuegoods: [POST]
//(req.body = issueto, issuetoname, issuetoid, psorder, gino, gidate,
// vehicletype, vehicleno, issuedby, checkedby, remarks, ewaybill, uploads,
// itemarray, subtotal, branchid, userid, companyid, month, financialyear, org,
// company, issuecompanyid, vendor, vendorid, franchise, franchiseid, subdealer,
// subdealerid)

//-------------Sales Order API Start------------------------------------

export const salesOrderAddPost = "https://mis.saanviavs.com/api/v1/saleorder/add";
//(Req.body = customername, customerid, project, projectid, quoteno, saleorderno, saleorderdate, exshippingdate, salesperson, createdby, uploads, itemarray, subtotal, discount, discountamount, adjustment, tax, customernote, finalamount, tandc, company, companyid, branch, branchid, userid, month, financialyear, deliverytype, org, orderstatus, invoicestatus)

export const salesOrderUpdatePost = "https://mis.saanviavs.com/api/v1/saleorder/update";
//(Req.body = customername, customerid, project, projectid, quoteno, saleorderno, saleorderdate, exshippingdate, salesperson, createdby, uploads, itemarray, subtotal, discount, discountamount, adjustment, tax, customernote, finalamount, tandc, company, companyid, branch, branchid, userid, month, financialyear, deliverytype, org, orderstatus, invoicestatus, id)

export const salesOrderPagiDataPost =
  "https://mis.saanviavs.com/api/v1/saleorder/getpage";
// (Req.body = keyword, userid, companyid, branchid, page , pageSize)

export const salesOrderMultipleDocumentUploadPost =
  "https://mis.saanviavs.com/api/v1/saleorder/fileupload";
// (file)

export const salesOrderCountPost = "https://mis.saanviavs.com/api/v1/idgenerate/soid";
//(Req.body = userid, companyid, branchid)

export const salesOrderDeletePost = "https://mis.saanviavs.com/api/v1/saleorder/delete";
//(Req.body =  id)

export const customerPagiDataPost ="https://mis.saanviavs.com/api/v1/customer/getallcustomerpage";
//(Req.body = org, page, pageSize, userid, branchid, companyid )

export const categoryPagiDataPost = "https://mis.saanviavs.com/api/v1/item/category";
//(Req.body = org, page, pageSize, userid, branchid, companyid )

export const itemPagiDataPost = "https://mis.saanviavs.com/api/v1/item/getitem";
//(Req.body = org, page, pageSize, userid, branchid, companyid )

export const termsAndConditionAllDataPost =
  "https://mis.saanviavs.com/api/v1/termcondition/get";

// (Req.body = companyid,)
//----------------------Sales Order API End-----------------------------

//----------------------------Employee API--------------------------------//

export const employeeGetpagePost = "https://mis.saanviavs.com/api/v1/employee/getpage"
//Get all employee: [POST]
//(req.body = keyword, userid, companyid, branchid, page, pageSize)
  
export const employeeAddPost = "https://mis.saanviavs.com/api/v1/employee/add"

//Add employee: [POST]
//(req.body = fullname, phoneno, aadharno, emailid, dob, age, gender, photo,
//            department, designation, joiningdate, idproof, aadhar, remarks,
//            currentcountry, currentstate, currentcity, currentaddress,
//            currentpincode, permanentcountry, permanentstate, permanentcity,
//            permanentaddress, permanentpincode, bankname, bankbranch,
//            bankaccno, bankifsccode, company, companyid, branch, branchid,
//            userid, employeeid, org, status)

export const employeeUpdatePost = "https://mis.saanviavs.com/api/v1/employee/update"

//Update employee: [POST]
//(req.body = fullname, phoneno, aadharno, emailid, dob, age, gender, photo,
//            department, designation, joiningdate, idproof, aadhar, remarks,
//            currentcountry, currentstate, currentcity, currentaddress,
//            currentpincode, permanentcountry, permanentstate, permanentcity,
//            permanentaddress, permanentpincode, bankname, bankbranch,
//            bankaccno, bankifsccode, company, companyid, branch, branchid,
//            userid, employeeid, org, status, id)


export const employeeUpdateStatus = "https://mis.saanviavs.com/api/v1/employee/updatestatus"
//Update employee status: [POST] 
//(req.body = status, id)
  
export const  idgenerateEmpID = "https://mis.saanviavs.com/api/v1/idgenerate/employeeid"
//Count of employee: [POST]
//(req.body = userid, companyid, branchid)

export const empGetByIDPost =   "https://mis.saanviavs.com/api/v1/employee/getbyid" 

//Get employee by id: [POST]
//(req.body = id)

//-------------Sales Invoice API Start------------------------------------

export const salesInvoiceAddPost = "https://mis.saanviavs.com/api/v1/salebill/add";
//(Req.body = invoicetype, commonname, commonid, gino, saleorderno, invoiceno, invoicedate, paymentterms, paymentduedate, transportertype, vehicleno, salesperson, ewaybillno, einvoiceno, createdby, remarks, itemarray, subtotal, discount, discountamount, taxableamount, cgst, sgst, igst, tcs, tds, tsamount, frightcharges, adjustmentamount, grandtotal, balancedue, tandc, istandc, status, userid, companyid, branchid, org, month, financialyear)

export const salesInvoiceUpdatePost = "https://mis.saanviavs.com/api/v1/salebill/update";
//(Req.body =  invoicetype, commonname, commonid, gino, saleorderno, invoiceno, invoicedate, paymentterms, paymentduedate, transportertype, vehicleno, salesperson, ewaybillno, einvoiceno, createdby, remarks, itemarray, subtotal, discount, discountamount, taxableamount, cgst, sgst, igst, tcs, tds, tsamount, frightcharges, adjustmentamount, grandtotal, balancedue, tandc, istandc, status, userid, companyid, branchid, org, month, financialyear, id)

export const salesInvoicePagiDataPost = "https://mis.saanviavs.com/api/v1/salebill/getpage";
//(Req.body = keyword, userid, companyid, branchid, status, page, pageSize )

export const salesInvoiceMultipleDocumentUploadPost = "https://mis.saanviavs.com/api/v1/salebill/fileupload";
// (file)

export const salesInvoiceCountPost = "https://mis.saanviavs.com/api/v1/idgenerate/saleid";
//(Req.body = userid, branchid, companyid)

export const salesInvoiceDeletePost = "https://mis.saanviavs.com/api/v1/salebill/delete";
//(Req.body =  id)

export const saleInvoiceSingleDataPost ="https://mis.saanviavs.com/api/v1/salebill/byid";
//(Req.body = id)

export const saleInvoiceItemData ="https://mis.saanviavs.com/api/v1/salebill/recievedqtyfromorder";
//(Req.body = saleorderno)
//----------------------Sales Invoice API End-----------------------------

//--------------------Sale Payment API --------------------------------

export const getAllSalePaymentPagi = "https://mis.saanviavs.com/api/v1/salepayment/getallsalepaymentpage"
// GET salepayment = [POST]
//(Req.body = userid, branchid, companyid, page, pageSize, keyword )

export const addSalePayment = "https://mis.saanviavs.com/api/v1/salepayment/addsalepayment"
// ADD salepayment = [POST]  

export const salePaymentID = "https://mis.saanviavs.com/api/v1/salepayment/salepaymentlength"
//salepayment COUNT: [POST]
// (Req.body = branchid, companyid, userid)

export const getSalePaymentByID = "https://mis.saanviavs.com/api/v1/salepayment/salepaymentbyid" 

// GET salepayment By ID = [POST]
//(Req.body = id)

export const fileUploadSalePayment = "https://mis.saanviavs.com/api/v1/salepayment/fileupload"

//-------------Credit Note API Start------------------------------------
export const creditNoteAddPost = "https://mis.saanviavs.com/api/v1/creditnote/add";

export const creditNoteUpdatePost = "https://mis.saanviavs.com/api/v1/creditnote/update";

export const creditNotePagiDataPost = "https://mis.saanviavs.com/api/v1/creditnote/get";

export const creditNoteMultipleDocumentUploadPost = "https://mis.saanviavs.com/api/v1/creditnote/fileupload";

export const creditNoteDeletePost = "https://mis.saanviavs.com/api/v1/creditnote/delete";

//----------------------Sales Invoice API End-----------------------------

