import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { vendorData } from "../../../../redux/actions/vendor.actions";
import axios from "axios";
import { notifyError } from "../../../../constants/toastAlert";
import { PurchasePaymentIdCountApi } from "../../../../constants/api";
import { purchaseBillsData } from "../../../../redux/actions/purchaseBills.actions";
import { bankPagiData } from "../../../../redux/actions/bank.actions";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { addPurchasePayment } from "../../../../redux/actions/purchasePayment.actions";
import { BiErrorCircle } from "react-icons/bi";

const VendorAdvanceForm = () => {
  const finalcialYear = localStorage.getItem("financialyear") || "";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(false);
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branch, branchid, name, org } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const vendorMaster =
    useSelector((state) => state.vendorData.vendorData.data) || [];

  const bankMaster =
    useSelector((state) => state.bankData.bankPagiData.data) || [];

    const purchasePaymentMaster =
    useSelector(
      (state) => state.purchasePaymentData.purchasePaymentData.data
    ) || [];

  const [data, setData] = useState({
    vendor: "",
    vendorid: "",
    vendordetails: {},
    paymentno: "",
    paymentdate: "",
    paymentmode: "",
    paidthrough: "",
    createdby: name,
    referenceno: "",
    paymentmade: 0.0,
    uploads: [],
    billarray: [],
    total: 0.0,
    paymentammount: "",
    paymenttype: "Vendor Advance",
    adjustamount: 0.0,
    excessamount: 0.0,
    billno: "",
    companyid: companyid,
    branchid: branchid,
    userid: userid,
    month: "",
    financialyear: finalcialYear,
    org: org,
    depositto: "Advance Taxes",
    remarks: "",
    appliedadvance: 0.0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));

    if (name == "paymentmode") {
      setData((prev) => ({ ...prev, paymentmode: value, paidthrough: "" }));
    }
    if (name == "vendor") {
      if (value) {
        const findVendor = vendorMaster.find((vendor) => vendor.id == value);
        setData((prev) => ({
          ...prev,
          vendorid: `VD-${findVendor.id}`,
          vendor: findVendor.fullname,
          vendordetails: findVendor,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          vendorid: "",
          vendor: "",
          vendordetails: {},
        }));
      }
    }
  };

  const handleSave = () => {
    if (
      !data.vendor ||
      !data.paymentno ||
      !data.paymentdate ||
      !data.paymentmode ||
      !data.paidthrough ||
      !data.paymentmade
    ) {
      setShowEmptyError(true);
      notifyError("You have empty fields");
      return;
    }

    dispatch(addPurchasePayment(data, navigate));
  };

  const generateCategoryID = () => {
    if (purchasePaymentMaster.length === 0) {
      // If no data, default to 1
      return "1";
    } else {
      // Get the last category ID
      const lastCategoryID = purchasePaymentMaster[0].paymentno.split("/")[2];
      // Increment the last category ID and return
      return String(parseInt(lastCategoryID) + 1);
    }
  };

  useEffect(() => {
    // Update the category ID in the row state
    let count = generateCategoryID();
    let paddedCount = count.toString().padStart(4, "0");
    setData((prevRow) => ({
      ...prevRow,
      paymentno: `PM/SAANVI/${paddedCount}`,
    }));
  }, []);


  

  useEffect(() => {
    setLoading(!loading);
    dispatch(
      purchaseBillsData(
        { companyid, userid, branchid, keyword: "" },
        setLoading
      )
    );
    dispatch(
      bankPagiData({ companyid, userid, branchid, keyword: "" }, setLoading)
    );
    dispatch(vendorData({ companyid, userid, keyword: "" }, setLoading));
  }, [dispatch, loadData]);

  return (
    <Container fluid>
      <div className="form-container">
        <Form className="form-lable-name">
          <div className="d-flex gap-4">
            <div className="w-100">
              <Container fluid>
                <Row lg={2} md={1} sm={1}>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Vendor <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="vendor"
                        // value={data.vendor}
                        onChange={handleChange}
                        isInvalid={!data.vendor && showEmptyError}
                      >
                        <option value="">Select</option>
                        {vendorMaster?.map((vendor) => {
                          return (
                            <option value={vendor.id} key={vendor.id}>
                              {vendor.fullname}-VD-{vendor.id}
                              {"\n"}
                              {vendor.companyname}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment No.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            name="paymentno"
                            value={data.paymentno}
                            // onChange={handleChange}
                            isInvalid={!data.paymentno && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Date.<span className="star"> *</span>
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="paymentdate"
                            value={data.paymentdate}
                            onChange={handleChange}
                            isInvalid={!data.paymentdate && showEmptyError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        Payment Made <span className="star"> * </span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="paymentmade"
                        value={data.paymentmade}
                        onChange={handleChange}
                        isInvalid={!data.paymentmade && showEmptyError}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Payment Mode <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="paymentmode"
                            value={data.paymentmode}
                            onChange={handleChange}
                            isInvalid={!data.paymentmode && showEmptyError}
                          >
                            <option value="">Select</option>
                            <option value="Cash">Cash</option>
                            <option value="Net Banking">Net Banking</option>
                            <option value="UPI">UPI</option>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="CC Account">CC Account</option>
                            <option value="Cheque">Cheque</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Paid Through <span className="star">*</span>
                          </Form.Label>
                          <Form.Select
                            name="paidthrough"
                            value={data.paidthrough}
                            onChange={handleChange}
                            isInvalid={!data.paidthrough && showEmptyError}
                          >
                            <option value="">Select</option>
                            {data.paymentmode === "Cash" ? (
                              <option value="Saanvi Cash Account">
                                Saanvi Cash Account
                              </option>
                            ) : null}

                            {data.paymentmode && data.paymentmode !== "Cash"
                              ? bankMaster.map((bank) => (
                                  <option value={bank.bank} key={bank.id}>
                                    {bank.bank}
                                  </option>
                                ))
                              : null}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Deposit To</Form.Label>
                      <Form.Select
                        name="depositto"
                        value={data.depositto}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        <option value="Advance Taxes">Advance Taxes</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        name="remarks"
                        value={data.remarks}
                        onChange={handleChange}
                        placeholder="Enter Remarks..."
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
              </Container>
            </div>
          </div>
        </Form>

        <Col sm={12} className="d-flex justify-content-start gap-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/purchase/purchasePayment")}
          >
            Cancel
          </Button>
        </Col>
      </div>
    </Container>
  );
};

export default VendorAdvanceForm;
