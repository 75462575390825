import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { reloadPage } from "../../../redux/actions/load.action";
import { useDispatch, useSelector } from "react-redux";
import { notifySuccess, notifyWarning } from "../../../constants/toastAlert";
import { userUserpaginationPost } from "../../../constants/api";

const EditCrud = ({ userid, companyid, id }) => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  const dispatch = useDispatch();
  // dispatch(reloadPage(false));
  const loadData = useSelector((state) => state.loadReducer);
  const [refreshData, setRefreshData] = useState(false);

  const [show, setShow] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [subModuleData, setSubModuleData] = useState([
    "Company Management",
    "Branch",
    "Godown/Showroom",
  ]);
  const [crudArrayData, setCrudArrayData] = useState({
    crud: ["c", "u", "d"],
    name: ["Create", "Update", "Delete"],
  });
  const [selectedModule, setSelectedModule] = useState([]);
  const [selectedSubModule, setSelectedSubModule] = useState([]);
  const [selectedCrud, setSelectedCrud] = useState([]);
  const [moduleOfSubModule, setModuleOfSubModule] = useState("");
  const [userData, setUserData] = useState([]);
  const [preCheckedData, setPreCheckedData] = useState(null);
  const [selectAllSubModule, setSelectAllSubModule] = useState(false); // State for "Select All" checkbox
  const [loading, setLoading] = useState(false);


  const handleShow = () => setShow(true);

  useEffect(() => {
    //get selected user
    // dispatch(reloadPage(true));
    axios

      .post(`https://mis.saanviavs.com/api/v1/user/getuserbyid`, {

        id,
      })
      .then((res) => {
        setUserData(res.data.length > 0 ? res.data : []);
        setSubModuleData(res.data[0].submodule);
        dispatch(reloadPage(false));
      })
      .catch((err) => console.log(err));

    axios

  .post(`https://mis.saanviavs.com/api/v1/crud/getbyuserid`, { userid, companyid })

  .then((res) => {
    if (res.data && res.data.length > 0) {
    
        // Find the first non-empty array in crudData
        const firstNonEmptyArray = res.data.find((elem) => elem.unsplitcrud.length > 0);
        
        if (firstNonEmptyArray) {
          const crudData = firstNonEmptyArray.unsplitcrud;
          setSelectedCrud(crudData);
  
          const submoduleData = firstNonEmptyArray.crud.map((elem) => elem.submodule);
          setSelectedSubModule(submoduleData);
        }

      const submoduleData = res.data
        .filter((elem) => elem.crud.length > 0)
        .map((elem) => elem.submodule);
      setSelectedSubModule(submoduleData);
    } else {
      console.log("No data received from the server.");
    }
  })
  .catch((err) => {
    console.log(err);
  });

  }, [companyid, userid, loadData, refreshData, id]);

  useEffect(() => {
    // setSelectedSubModule()
  }, []);
  //   for sub module

  // const handleRowSelectionSubModule = (row) => {
  //   const isSelected = selectedSubModule.includes(row);
  //   if (isSelected) {
  //     // If row is already selected, remove it
  //     setSelectedSubModule(
  //       selectedSubModule.filter((selected) => selected !== row)
  //     );
  //   } else {
  //     // If row is not selected, add it
  //     setSelectedSubModule([...selectedSubModule, row]);
  //   }
  // };

  const handleRowSelectionSubModule = (row) => {
    const isSelected = selectedSubModule&&selectedSubModule.includes(row);
    let updatedSelectedCrud = [...selectedCrud];
    if (isSelected) {
      // If row is already selected, remove it
      setSelectedSubModule(
        selectedSubModule.filter((selected) => selected !== row)
      );
      // Remove CRUD entries for this submodule
      updatedSelectedCrud = updatedSelectedCrud.filter(
        (crud) => !crud.startsWith(`${row}_`)
      );
    } else {
      // If row is not selected, add it
      setSelectedSubModule([...selectedSubModule, row]);
      // Add CRUD entries for this submodule
      updatedSelectedCrud.push(
        `${row}_c`,
        `${row}_u`,
        `${row}_d`
      );
    }
    setSelectedCrud(updatedSelectedCrud);
  };

  // for crud start
  const handleRowSelectionCrud = (row) => {
    const isSelected = selectedCrud.includes(row);
    if (isSelected) {
      // If row is already selected, remove it
      setSelectedCrud(selectedCrud.filter((selected) => selected !== row));
    } else {
      // If row is not selected, add it
      setSelectedCrud([...selectedCrud, row]);
    }
  };


  const handleSelectAllSubModule = () => {
    setSelectAllSubModule(!selectAllSubModule);
    if (!selectAllSubModule) {
      // Select all rows
      setSelectedSubModule([...subModuleData]);
      // Add CRUD entries for all selected submodules
      const updatedSelectedCrud = [...selectedCrud];
      subModuleData.forEach((submodule) => {
        updatedSelectedCrud.push(
          `${submodule}_c`,
          `${submodule}_u`,
          `${submodule}_d`
        );
      });
      setSelectedCrud(updatedSelectedCrud);
    } else {
      // Deselect all rows
      setSelectedSubModule([]);
      // Remove CRUD entries for all submodules
      const updatedSelectedCrud = selectedCrud.filter(
        (crud) => !crud.endsWith("_c") && !crud.endsWith("_u") && !crud.endsWith("_d")
      );
      setSelectedCrud(updatedSelectedCrud);
    }
  };

  const result = {};
  selectedSubModule&&selectedSubModule.forEach((key, index) => {
    result[key] = selectedCrud&&selectedCrud.map((value) => value);
  });

  // for crud end

  const handleSubmit = async () => {
    // dispatch(reloadPage(true));
    if (selectedSubModule.length === 0) {
      return notifyWarning("Please select checkbox");
    }
    setLoading(true)
    try {
      // dispatch(reloadPage(true));

      const res = await axios.post("https://mis.saanviavs.com/api/v1/crud/add", {

        name: userData[0].name,
        designation: userData[0].designationname,
        userid: userData[0].userid,
        submodule: selectedSubModule,
        crud: selectedCrud,
        company: userData[0].companyname,
        branch: userData[0].branch,
        companyid: userData[0].companyid,
        branchid: userData[0].branchid,
      });

      if (res.data.message == "Activity Successful") {
        notifySuccess(res.data.message);
        setRefreshData(prevState => !prevState);

        dispatch(reloadPage(true));
        setShow(false);
      } else {
        notifyWarning(res.data.message);
      }
      setLoading(false)
      dispatch(reloadPage(false));
     
    } catch (error) {
      console.error("Error:", error);
      dispatch(reloadPage(false));
    }
  };

  return (
    <>
      <MdEdit className="editIcon" onClick={handleShow} />
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <span className="form-title">CRUD</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row lg={4} md={2} sm={1}>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="userid"
                    value={userData.length > 0 ? userData[0].userid : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Designation</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="designationname"
                    value={
                      userData.length > 0 ? userData[0].designationname : ""
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="name"
                    value={userData.length > 0 ? userData[0].name : ""}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Comapny Name</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    name="companyname"
                    value={userData.length > 0 ? userData[0].companyname : ""}
                  />
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <div className="viewpage-table-container">
                  <span className="viewpage-module">Sub Module</span>
                  <Table responsive className="mb-0">

                  <thead>
              <tr>
                {/* <th>#</th>
                <th>Sub Module</th>
    
                <th>
                  <input
                    type="checkbox"
                    checked={selectAllSubModule}
                    onChange={handleSelectAllSubModule}
                  />{" "}
                  Select All
                </th>
                <th>Create</th>
                <th>Update</th>
                <th>Delete</th> */}
              </tr>
            </thead>
                    <tbody className="table-main-container">
                    <tr>
                    <td>#</td>
                <td><b>Select All</b></td>
    
                <td>
                  <input
                    type="checkbox"
                    checked={selectAllSubModule}
                    onChange={handleSelectAllSubModule}
                  />{" "}
                 
                </td>
                <td><b>Create</b></td>
                <td><b>Update</b></td>
                <td><b>Delete</b></td>
                    </tr>
                      {subModuleData !== null &&
                        subModuleData.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedSubModule&&selectedSubModule.includes(row)}
                                onChange={() =>
                                  handleRowSelectionSubModule(row)
                                }
                              />
                            </td>
                          
                            <td>
                              Create -
                              <input
                                type="checkbox"
                                checked={selectedCrud&&selectedCrud.includes(`${row}_c`)}
                                onChange={() =>
                                  handleRowSelectionCrud(`${row}_c`)
                                }
                              />
                            </td>
                            <td>
                              Update -
                              <input
                                type="checkbox"
                                checked={selectedCrud&&selectedCrud.includes(`${row}_u`)}
                                onChange={() =>
                                  handleRowSelectionCrud(`${row}_u`)
                                }
                              />
                            </td>
                            <td>
                              Delete -
                              <input
                                type="checkbox"
                                checked={selectedCrud&&selectedCrud.includes(`${row}_d`)}
                                onChange={() =>
                                  handleRowSelectionCrud(`${row}_d`)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              {/* <Col>
                <div className="viewpage-table-container">
                  <span className="viewpage-module">CRUD</span>
                  <Table responsive className="mb-0">
                    <tbody className="table-main-container">
                      {crudArrayData.crud.length > 0
                        ? crudArrayData.crud.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{crudArrayData.name[index]}</td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedCrud.includes(row)}
                                  onChange={() => handleRowSelectionCrud(row)}
                                />
                              </td>
                            </tr>
                          ))
                        : []}
                    </tbody>
                  </Table>
                </div>
              </Col> */}
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start gap-2">
          {/* <Button
            type="button"
            className="commonBtn smallBtn"
            onClick={handleSubmit}
          >
            Update
          </Button> */}
          {loading ? (
            <Spinner  animation="border" variant="primary" ></Spinner>
          ) : (
            <Button
              type="button"
              className="commonBtn smallBtn"
              onClick={handleSubmit}
              disabled={loading}
            >
              Update
            </Button>
          )}

          <Button
            type="button"
            className="commonBtn smallBtn cancelBtn"
            onClick={() => setShow(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCrud;
