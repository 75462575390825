import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import FilterField from "../../../constants/FilterField";
import AddPageButton from "../../../constants/AddPageButton";
import SearchField from "../../../constants/SearchField";
import TableHeading from "../../../constants/TableHeading";
import { useDispatch, useSelector } from "react-redux";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import GlobalPagination from "../../../constants/GlobalPagination";
import { quoteCancelPatch, quoteGetpagePost } from "../../../constants/api";
import { reloadPage } from "../../../redux/actions/load.action";
import axios from "axios";
import { initialData } from "../../../constants/resData";
import DataSpinner from "../../../constants/DataSpinner";
import { indianFormatDate } from "../../../constants/indianFormatDate";
import { MdModeEditOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CancelModal from "./CancelModal";
import ViewQuote from "./ViewQuote";
import { notifyError, notifySuccess } from "../../../constants/toastAlert";

const ListQuote = () => {
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
  let userID = localData.userid;
  let companyID = localData.companyid;
  let branchID = localData.branchid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadData = useSelector((state) => state.loadReducer);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [data, setData] = useState([]);
  const [itemId, setItemid] = useState("");
  const [show, setShow] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [description, setDescription] = useState("")

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPagesFromAPI, setTotalPagesFromAPI] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);

  const handleShow = (id) => {
    setShow(true);
    setItemid(id);
  };

  const handleClose = () => {
    dispatch(reloadPage(true));
    setShow(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.post(quoteGetpagePost, {
          page: currentPage,
          pageSize: pageSize,
          userid: userID,
          branchid: branchID,
          companyid: companyID,
          keyword: searchKeyword || "₹ΘθϖΠ" || selectedStatus,
        });

        setData(initialData(res));
        setTotalPagesFromAPI({
          totalPages: res.data.totalPages ? res.data.totalPages : 0,
          totalRecords: res.data.totalItems ? res.data.totalItems : 0,
        });
        dispatch(reloadPage(false));
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [loadData, pageSize, currentPage]);

  const filteredData = !data
    ? []
    : data
        .filter((item) =>
          searchKeyword
            ? Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchKeyword.toLowerCase().trim())
              )
            : true
        )
        .filter((item) => {
          if (selectedStatus == "") {
            return true;
          } else {
            return item.status === selectedStatus;
          }
        });
  const handleEdit = (id) => {
    navigate(`/sales/quotation/editQuotation/${id}`);
  };
  const handleView = (id) => {
    navigate(`/sales/quotation/viewQuotation/${id}`);
  };

  const handleCancel = () => {
    const canceldata = async () => {
      try {
        const res = await axios.patch(quoteCancelPatch, {
          id: itemId,
          status: "Cancelled",
          remarks: remarks,
          description: description
        });

        if (res.statusText == "OK") {
          notifySuccess(res.data.message);
          dispatch(reloadPage(true));
        } else {
          notifyError("Error Canceling quote");
        }
        handleClose();
      } catch (err) {
        console.log("Error", err);
      }
    };
    canceldata();
  };

  // crud functionality to show and hide create, edit, delete icons start
  const [crud, setCrud] = useState(["c", "d", "u"]);

  useEffect(() => {
    axios

      .post(`https://mis.saanviavs.com/api/v1/crud/getsubmodule`, {

        userid: userID,
        submodule: "Quotation",
        companyid: companyID,
      })
      .then((res) => {
        setCrud(res.data.length > 0 ? res.data[0].crud : ["c", "d", "u"]);
      })
      .catch((err) => console.log(err));
  }, [loadData]);
  // crud functionality to show and hide create, edit, delete icons end
  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={6}>
            <p className="section-title form-section ptag">
              List of All <span className="form-title">Quotation</span>
            </p>
          </Col>
          <Col lg={2}>
            <FilterField
              label=""
              islable={false}
              emptyValue="All"
              selectedFilterValue={selectedStatus}
              handleSelectedFilter={(e) => setSelectedStatus(e.target.value)}
              filterWords={[
                { value: "Created", text: "Created" },
                { value: "Drafted", text: "Drafted" },
                { value: "Converted", text: "Converted" },
                { value: "Cancelled", text: "Cancelled" },
                { value: "Expired", text: "Expired" },
              ]}
            />
          </Col>
          <Col lg={2}>
            <SearchField
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </Col>
          <Col lg={2}>
            {crud && crud.includes("c") && (
              <AddPageButton
                text="Quotation"
                link={`/sales/quotation/addQuotation`}
              />
            )}
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container">
        <Table responsive>
          <TableHeading
            data={[
              "Quote ID",
              "Reg. Date",
              "Customer ID",
              "Name",
              "Projects",
              "Status",
              "Action",
            ]}
          />
          <tbody>
            {loadData || loading ? (
              <DataSpinner count="7" />
            ) : filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.quoteno}</td>
                  <td>
                    {item.quotedate ? indianFormatDate(item.quotedate) : "--"}
                  </td>
                  <td>{item.customerid}</td>
                  <td>{item.customername}</td>
                  <td>{item.project}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className="d-flex justify-content-center gap-3">
                      <MdOutlineRemoveRedEye
                        className="editIcon"
                        onClick={() => handleView(item.id)}
                      />
                      <MdModeEditOutline
                        className="editIcon mr-2"
                        onClick={() => handleEdit(item.id)}
                      />
                      {item.status == "Drafted" || item.status == "Created" ? (
                        <CancelModal
                          show={show}
                          handleShow={() => handleShow(item.id)}
                          handleClose={() => setShow(false)}
                          handleCancel={handleCancel}
                          name="quotation"
                          remarks={remarks}
                          setRemarks={setRemarks} 
                          description={description}
                          setDescription={setDescription} 
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <NoRecordFoud count="7" name="Quotations" />
            )}
          </tbody>
        </Table>
      </Col>
      <GlobalPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPagesFromAPI={totalPagesFromAPI}
        setPageSize={setPageSize}
      />
    </Row>
  );
};

export default ListQuote;
