import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { BsFillExclamationTriangleFill, BsPlusCircle } from "react-icons/bs";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { extractAndFormatURLPart } from "../../../constants/breadCrumb";
import { notifyError, notifyWarning } from "../../../constants/toastAlert";
import NoRecordFoud from "../../../constants/NoRecordFoud";
import {
  addmanualJournal,
  manualJournalAutoCount,
  manualJournalDocmnentUpload,
  manualJournalPagiData,
} from "../../../redux/actions/manualjournal.actions";
import { coaPagiData } from "../../../redux/actions/chartofaccount.actions";
import { ledgerPagiData } from "../../../redux/actions/ledger.actions";
import axios from "axios";
import { manualJournalPagiDataPost } from "../../../constants/api";

const NewJournal = () => {
  const URL = extractAndFormatURLPart("accounting");
  const finalcialYear = localStorage.getItem("financialyear");
  const finalYear = finalcialYear.trim().split("-");

  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const { manualjournalCount, manualjournalDocument } =
    useSelector((state) => state.manualJournalData) || 0;
  const { data: coaAllData } =
    useSelector((state) => state.chartOfAccountData.coaPagiData) || [];
  const { data: ledgerAllData } =
    useSelector((state) => state.ledgerData.ledgerPagiData) || [];
  // const {data: manualJournalData} = useSelector((state) => state.manualJournalData.manualjournalPagiData) || 0;
  // const manualJournalAllData = !manualJournalData ? [] : manualJournalData;


  //sate part
  const [extractedPart, setExtractedPart] = useState("");
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [journalno, setjournalno] = useState("");
  const [loadState, setLoadState] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showbtn, setShowBtn] = useState(true);
  const [itemData, setItemData] = useState([]);
  const [totaldebit, setTotaldebit] = useState(0.0);
  const [totalcredit, setTotalcredit] = useState(0.0);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({
    date: "",
    month: "",
    journalno: "",
    refno: "",
    note: "",
    journalarray: "",
    status: "",
    rawdocuments: "",
    totaldebits: 0,
    totalcredits: 0,
    documents: [],
    refno: "",
    userid: userid,
    branchid: branchid,
    companyid: companyid,
    financialyear: finalcialYear,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //Default Data backend needed
  const backendData = {
    keyword: "₹ΘθϖΠ",
    userid: userid,
    companyid: companyid,
    branchid: branchid,
  };

  useEffect(() => {
    setLoading(!loading);
    // dispatch(manualJournalAutoCount(backendData, setLoading));
    // dispatch(manualJournalPagiData(backendData, setLoading));
    dispatch(coaPagiData(backendData, setLoading));
    dispatch(ledgerPagiData(backendData, setLoading));
    // let count = +manualjournalCount;
    // let count = autoGenerateID();
    // let paddedCount = count.toString().padStart(4, "0");
    // let result = `MJ/SAANVI/${paddedCount}`;

    axios.post(manualJournalPagiDataPost, backendData)
      .then((res) => {
        let currentCount = res.data && res.data.data && res.data.data.length > 0 ?
          String(parseInt(res.data.data[0].journalno.split("/")[2]) + 1)
          : "1";
        let paddedCount = currentCount.toString().padStart(4, "0");
        let result = `MJ/SAANVI/${paddedCount}`;
        setData({
          ...data,
          journalno: result,
          documents: manualjournalDocument,
        });
      })
      .catch(e => console.log(e));

  }, [dispatch, manualjournalCount, manualjournalDocument]);

  useEffect(() => {
    handleAddNewRow();
    handleAddNewRow();
  }, []);

  //Auto generate Id start
  // function autoGenerateID() {
  //   if (manualJournalAllData.length === 0) {
  //     return "1";
  //   } else {
  //     const currentId = manualJournalAllData[0].journalno.split("/")[2];
  //     return String(parseInt(currentId) + 1);
  //   }
  // };

  //Auto generate Id end

  function handleAddNewRow() {
    setShowForm(true);
    setTableData((prevTableData) => [...prevTableData, {}]);
  }

  const handleTableRowChange = (e, rowIndex) => {
    const { name, value } = e.target;

    const selectedItem = coaAllData.find((item) => item.accountname === value);

    if (selectedItem) {
      setTableData((prevTableData) => {
        const updatedTableData = [...prevTableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
        };
        return updatedTableData;
      });
    }

    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData[rowIndex] = {
        ...updatedTableData[rowIndex],
        [name]: value,
      };

      setData((prevData) => ({
        ...prevData,
        journalarray: updatedTableData, // Set the updated tableData as the itemarray
      }));

      const newtotalDebit = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.debits) || 0);
      }, 0);
      setTotaldebit(newtotalDebit);

      const newTotalCredit = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.credits) || 0);
      }, 0);
      setTotalcredit(newTotalCredit);

      return updatedTableData;
    });
  };

  const handleTableRowDelete = (rowIndex) => {
    setTableData((prevTableData) => {
      const updatedTableData = [...prevTableData];
      updatedTableData.splice(rowIndex, 1);
      setData((prevData) => ({
        ...prevData,
        journalarray: updatedTableData, // Update the journalarray with the updated table data
      }));

      const newtotalDebit = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.debits) || 0);
      }, 0);
      setTotaldebit(newtotalDebit);

      const newTotalCredit = updatedTableData.reduce((total, row) => {
        return total + (parseInt(row.credits) || 0);
      }, 0);
      setTotalcredit(newTotalCredit);

      return updatedTableData;
    });
  };

  const handlePublish = async () => {
    if (data.date == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    if (totaldebit - totalcredit != 0) {
      setShowEmptyError(true);
      return notifyError("Please ensure that Debits and Credits are equal");
    }
    if (totaldebit == 0 && totalcredit == 0) {
      setShowEmptyError(true);
      return notifyError("Please ensure that Debits and Credits are equal");
    }

    const formData = {
      ...data,
      status: "Publish",
      totaldebits: totaldebit,
      totalcredits: totalcredit,
    };

    // await dispatch(manualJournalDocmnentUpload(data.documents))
    dispatch(addmanualJournal(formData, navigate));

    // const formData = {
    //   ...data,
    //   journalno: `MJ-${+journalno + 1}`,
    //   status: "Publish",
    //   totaldebits: totaldebit,
    //   totalcredits: totalcredit,
    // };
    // let multipledocumentResponse;
    // try {
    //   multipledocumentResponse = await handleUploadFile(data.rawdocuments);
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    //   // Handle the error, possibly notify the user
    //   return;
    // }
    // const updatedFormData = {
    //   ...formData,
    //   documents: multipledocumentResponse ? multipledocumentResponse : [],
    // };
    // const res = await axios.post(manualjournaladdmanualjournalpost, {
    //   ...updatedFormData,
    //   financialyear: sessionDataString,
    // });

    // if (res.data.message == "ManualJournal added successfully") {
    //   setData({
    //     date: "",
    //     journalno: "",
    //     note: "",
    //     refno: "",
    //     journalarray: itemData,
    //     status: "",
    //     totaldebits: "",
    //     totalcredits: "",
    //     userid: userId,
    //     siteid: siteId,
    //   });
    //   notifySuccess("journal saved");
    //   navigate("/accounting/manualJournal");
    //   setLoadState(!loadState);
    // } else {
    //   notifyWarning(res.data.message);
    // }
  };

  const handleDraft = async () => {
    if (data.date == "") {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }
    if (totaldebit - totalcredit != 0) {
      setShowEmptyError(true);
      return notifyError("Please ensure that Debits and Credits are equal");
    }
    if (totaldebit == 0 && totalcredit == 0) {
      setShowEmptyError(true);
      return notifyError("Please ensure that Debits and Credits are equal");
    }

    const formData = {
      ...data,
      status: "Draft",
      totaldebits: totaldebit,
      totalcredits: totalcredit,
    };

    let multipledocumentResponse;
    try {
      multipledocumentResponse = await handleUploadFile(data.rawdocuments);
    } catch (error) {
      console.error("Error:", error);
    }
    const updatedFormData = {
      ...formData,
      documents: multipledocumentResponse ? multipledocumentResponse : [],
    };

    dispatch(addmanualJournal(formData, navigate));
  };

  const handleCancel = () => {
    setShow(true);
  };

  const handleStay = () => {
    setShow(false);
  };

  const handleDiscard = () => {
    navigate("/accounting/manualJournal");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "date") {
      const dateObj = new Date(value);
      const month = dateObj.toLocaleString("default", { month: "long" });
      setData((prevData) => ({
        ...prevData,
        month: month,
      }));
    }

    setShowEmptyError(false);
  };

  const handleUploadFile = async (files) => {
    // try {
    //   const formData = new FormData();
    //   for (let i = 0; i < files.length; i++) {
    //     formData.append("file", files[i]);
    //   }
    //   const response = await axios.post(
    //     "http://localhost:8081/manualjournal/multipledocumentupload",
    //     { ...formData, financialyear: sessionDataString },
    //     { headers: { "Content-Type": "multipart/form-data" } }
    //   );
    //   return response.data.files; // Return the entire response object
    // } catch (error) {
    //   console.error("Error uploading file:", error.message);
    //   throw error;
    // }
  };

  return (
    <Container fluid>
      <Row>
        <Col className="colStyle">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-container main">
            <Form>
              <h1>
                <FaArrowLeftLong
                  className="left-arrow"
                  onClick={() => navigate("/accounting/manualJournal")}
                />
                <span className="form-title">New Journal</span>
              </h1>
              <hr />
              <Row md={12}>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>
                      Date<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Date"
                      name="date"
                      value={data.date}
                      min={`${parseInt(
                        finalYear[0].replace('"', ""),
                        10
                      )}-04-01`}
                      max={`${parseInt(
                        finalYear[1].replace('"', ""),
                        10
                      )}-03-31`}
                      onChange={handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                      isInvalid={data.date === "" && showEmptyError}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Enter Date
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="mb-3" controlId="formBasicJournalNo">
                    <Form.Label>
                      Journal No.<span className="star"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Journal No."
                      name="journalno"
                      value={data.journalno}
                      onChange={handleChange}
                      //isInvalid={data.grnno === "" && showEmptyError}
                      disabled
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Reference No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reference No."
                      name="refno"
                      value={data.refno}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row md={6}>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formBasicDescription">
                    <Form.Label>Note</Form.Label>
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder="Type here..."
                      name="note"
                      value={data.note}
                      onChange={handleChange}
                      // isInvalid={data.description === "" && showEmptyError}
                      required
                    ></textarea>
                    <Form.Control.Feedback type="invalid">
                      Enter a description
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <hr />
            <Row>
              <Col className="table-main-container">
                <Table responsive>
                  <thead className="tableContainer">
                    <tr>
                      <th>Account</th>
                      <th>Ledger</th>
                      <th>Description</th>
                      <th>Debits</th>
                      <th>Credits</th>
                    </tr>
                  </thead>
                  {showForm && tableData.length > 0 ? (
                    <tbody>
                      {tableData.map((row, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="account"
                              className="text-center"
                              onChange={(e) => handleTableRowChange(e, index)}
                              value={row.account}
                              isInvalid={row.account === "" && showEmptyError}
                            >
                              <option value="">--Select--</option>
                              {coaAllData && coaAllData.length > 0 ? (
                                Object.entries(
                                  coaAllData.reduce((groups, item) => {
                                    if (!groups[item.accounttype]) {
                                      groups[item.accounttype] = [];
                                    }
                                    groups[item.accounttype].push(
                                      item.accountname
                                    );
                                    return groups;
                                  }, {})
                                ).map(([accounttype, accountname], index) => (
                                  <optgroup key={index} label={accounttype}>
                                    {accountname.map((name, nameIndex) => (
                                      <option key={nameIndex} value={name}>
                                        {name}
                                      </option>
                                    ))}
                                  </optgroup>
                                ))
                              ) : (
                                <option value="">No Account available</option>
                              )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Choose Account
                            </Form.Control.Feedback>
                          </td>

                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="ledger"
                              className="text-center"
                              onChange={(e) => handleTableRowChange(e, index)}
                              value={row.ledger}
                              isInvalid={row.ledger === "" && showEmptyError}
                            >
                              <option value="">--Select--</option>
                              {ledgerAllData && ledgerAllData.length > 0 ? (
                                ledgerAllData.map((item, index) => {
                                  const { ledgername } = item;
                                  return (
                                    <option key={index} value={ledgername}>
                                      {ledgername}
                                    </option>
                                  );
                                })
                              ) : (
                                <option value="">No Ledger available</option>
                              )}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              Choose ledger
                            </Form.Control.Feedback>
                          </td>

                          <td>
                            <Form.Control
                              className="text-center"
                              name="description"
                              placeholder="Description"
                              onChange={(e) => handleTableRowChange(e, index)}
                              value={row.description}
                            //isInvalid={section === "" && showEmptyError}
                            />
                            <Form.Control.Feedback type="invalid">
                              Description
                            </Form.Control.Feedback>
                          </td>

                          <td>
                            <Form.Control
                              disabled={row.credits}
                              className="text-right"
                              type="number"
                              name="debits"
                              placeholder="0.00"
                              onChange={(e) => handleTableRowChange(e, index)}
                              value={row.debits}

                            //isInvalid={section === "" && showEmptyError}
                            />

                            <Form.Control.Feedback type="invalid">
                              Debit
                            </Form.Control.Feedback>
                          </td>

                          <td>
                            <div className="d-flex gap-3">
                              <Form.Control
                                disabled={row.debits}
                                className="text-right"
                                type="number"
                                placeholder="0.00"
                                name="credits"
                                onChange={(e) => handleTableRowChange(e, index)}
                                value={row.credits}
                              //isInvalid={tillDate === "" && showEmptyError}
                              />
                              {showbtn && (
                                <MdDelete
                                  className="deleteIcon table-delete"
                                  onClick={() => handleTableRowDelete(index)}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <NoRecordFoud count="5" name="data" />
                    </tbody>
                  )}
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className="form-title-sibling" onClick={handleAddNewRow}>
                  <BsPlusCircle /> Add Row
                </span>
              </Col>
            </Row>
            {/* <Row >
              <Col sm={6}></Col>
              <Col >Total</Col>
              <Col className="text-center">
                <h1>hello</h1>
              </Col>
              <Col className="text-center">
                <h1>hello2</h1>
              </Col>
            </Row> */}
            <Row>
              <Col sm={6}></Col>
              <Col
                style={{
                  background: "var(--text, #F5F5F5)",
                  borderRadius: "16px",
                  padding: "30px",
                  fontSize: "20px",
                }}
              >
                <Row>
                  <Col>
                    <Form.Label>Total</Form.Label>
                  </Col>
                  <Col>
                    <p>{totaldebit}</p>
                  </Col>
                  <Col>
                    <p>{totalcredit}</p>
                  </Col>
                </Row>
                <Row className="star">
                  <Col>
                    <Form.Label>Difference</Form.Label>
                  </Col>
                  <Col>
                    <p>
                      {totaldebit < totalcredit
                        ? totalcredit - totaldebit
                        : "0.00"}
                    </p>
                  </Col>
                  <Col>
                    <p>
                      {totaldebit > totalcredit
                        ? totaldebit - totalcredit
                        : "0.00"}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row md={6}>
              <Col md={2} className="w-25">
                <Form.Label>Attach Document</Form.Label>

                <FormControl
                  type="file"
                  name="rawdocuments"
                  onChange={(e) => {
                    const file = e.target.files; // Assuming you only want to upload one file

                    if (file.length > 5) {
                      notifyWarning("Please select a maximum of 5 documents.");
                      e.target.value = null;
                      return;
                    }
                    setData((prevData) => ({
                      ...prevData,
                      documents: file,
                    }));
                  }}
                  multiple
                />
              </Col>
              <Col></Col>
            </Row>
            <hr />
            <Row md={6}>
              <Col>
                <Button className="commonBtn" onClick={handlePublish}>
                  {loadData ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save as Publish"
                  )}
                </Button>
              </Col>
              <Col>
                <Button
                  className="commonBtn cancelBtn mx-3"
                  style={{ boxShadow: "none" }}
                  onClick={handleDraft}
                >
                  {loadData ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save as Draft"
                  )}
                </Button>
              </Col>
              <Col>
                <Button
                  className="commonBtn smallBtn cancelBtn mx-3"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <Modal show={show} size="md" backdrop="static">
              <Modal.Body>
                <div style={{ margin: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      fontSize: "26px",
                    }}
                  >
                    <span style={{ color: "#FB6514" }}>
                      <BsFillExclamationTriangleFill />
                    </span>
                    <span className="mt-2"> Leave this page ?</span>
                  </div>
                  <div
                    className="mt-4"
                    style={{ textAlign: "center", fontSize: "18px" }}
                  >
                    If you leave, your unsaved changes will be discarded.
                  </div>
                  <div className="d-flex justify-content-center gap-3 mt-4">
                    <Button className="commonBtn smallBtn" onClick={handleStay}>
                      Stay Here
                    </Button>
                    <Button
                      className="commonBtn cancelBtn"
                      onClick={handleDiscard}
                    >
                      Leave and Discard Changes
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewJournal;
