import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Country, State, City } from "country-state-city";

import { useDispatch, useSelector } from "react-redux";
import { extractAndFormatURLPart } from "../../../../constants/breadCrumb";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { notifyError } from "../../../../constants/toastAlert";
import {
  validateEmail,
  validateGSTNumber,
  validatePhoneNumber,
} from "../../../../constants/validations";
import { companyData } from "../../../../redux/actions/company.actions";
import {
  addBranch,
  branchAutoCount,
  branchPagiData,
} from "../../../../redux/actions/branch.action";
import axios from "axios";
import { branchPagiDataPost } from "../../../../constants/api";
const states = State.getStatesOfCountry("IN");

function AddBranch() {
  const URL = extractAndFormatURLPart("admin");
  //Redux part
  const dispatch = useDispatch();
  const loadData = useSelector((state) => state.loadReducer);
  const { companyid, userid, companyname, branchid } =
    useSelector((state) => state.authManager.userData.data[0]) || {};
  const allCompanyName =
    useSelector((state) => state.companyData.companyData.data) || [];
  const companyName =
    allCompanyName && allCompanyName.filter((item) => item.status == "active");
  const { branchCount } = useSelector((state) => state.branchData) || 0;
  
  //state
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [data, setData] = useState({
    branchid: "",
    branchname: "",
    company: "",
    gstno: "",
    address: "",
    status: "active",
    state: "",
    city: "",
    pin: "",
    userid: userid,
    companyid: companyid,
  });
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  //default data backend needed
  const companybackendData = {
    keyword: "₹ΘθϖΠ",
    companyid: companyid,
    branchid: branchid,
    userid: userid,
    companyname,
  };

  useEffect(() => {
    setLoading(!loading);
    // dispatch(branchAutoCount({ userid }, setLoading));
    dispatch(companyData(companybackendData, setLoading));

    if (selectedState) {
      const citiesOfSelectedState = City.getCitiesOfState("IN", selectedState);
      const cityNames = citiesOfSelectedState.map((city) => city.name);
      setCities(cityNames);
    } else {
      setCities([]);
    }

    axios.post(branchPagiDataPost, companybackendData)
      .then((res) => {
        let branchData = res.data && res.data.data && res.data.data.length > 0 ?
          String(parseInt(res.data.data[0].branchid.split("/")[2]) + 1)
          : "1";
        let paddedCount = branchData.toString().padStart(4, "0");
        let result = `BRANCH/SAANVI/${paddedCount}`;
        setData({
          ...data,
          branchid: result,
        });
      })
      .catch(e => console.log(e));
  }, [dispatch, loadData, data.state, branchCount]);
  //Navigate part
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "state") {
      if (value) {
        const selectedState = states.find((state) => state.name === value);

        setSelectedState(selectedState.isoCode);
        setData((prev) => ({
          ...prev,
          [name]: selectedState.name,
          city: "",
        }));
      } else {
        setSelectedState("");
        setData((prev) => ({
          ...prev,
          state: "",
          city: "",
        }));
      }
    }

    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    let validateErrCount = 0;
    if (
      data.branchid == "" ||
      data.branchname == "" ||
      data.company == "" ||
      data.gstno == "" ||
      data.address == "" ||
      data.status == "" ||
      data.state == "" ||
      data.city == "" ||
      data.pin == ""
    ) {
      setShowEmptyError(true);
      return notifyError("You have empty field");
    }

    //---for validation start---
    const validateAndNotify = (field, validator) => {
      if (data[field]) {
        const validationMsg = validator(data[field]);
        if (validationMsg !== "Done") {
          validateErrCount++;
          return notifyError(validationMsg);
        }
      }
    };

    validateAndNotify("gstno", validateGSTNumber);

    if (validateErrCount > 0) {
      return;
    }

    //---for validation end---
    dispatch(addBranch(data, navigate));
  };
  return (
    <Container fluid>
      <Row>
        <Col className="colStyle p-0">
          <span>{URL}</span>
        </Col>
      </Row>
      <Row className="form-container">
        <Col sm={12}>
          <h1>
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/admin/branch")}
            />
            Add <span className="form-title"> Branch</span>
          </h1>
        </Col>
        <hr />
        <Col sm={12}>
          <Form className="form-lable-name">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchid">
                  <Form.Label>
                    Branch ID<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter ID"
                    name="branchid"
                    value={data.branchid}
                    onChange={handleChange}
                    isInvalid={data.branchid === "" && showEmptyError}
                    disabled
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Branch ID
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="branchname">
                  <Form.Label>
                    Branch Name<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Branch Name"
                    name="branchname"
                    value={data.branchname}
                    onChange={handleChange}
                    isInvalid={data.branchname == "" && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Branch Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>
                    Company Name<span className="star">*</span>
                  </Form.Label>
                  <Form.Select
                    aria-label="company"
                    name="company"
                    value={data.company}
                    onChange={handleChange}
                    isInvalid={data.company === "" && showEmptyError}
                    required
                  >
                    <option value="">Select</option>
                    {companyName && companyName.length > 0 ? (
                      companyName.map((item, index) => {
                        const { companyname } = item;
                        return (
                          <option key={index} value={companyname}>
                            {companyname}
                          </option>
                        );
                      })
                    ) : (
                      <option value="">No Company available</option>
                    )}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Select Company Name
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="gstno">
                  <Form.Label>
                    GSTIN<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your GSTIN"
                    name="gstno"
                    value={data.gstno}
                    onChange={handleChange}
                    isInvalid={data.gstno == "" && showEmptyError}
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter GST No.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>
                    Address<span className="star"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Address"
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                    isInvalid={data.address == "" && showEmptyError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter Your Address
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Label>
                        State <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="state"
                        value={data.state}
                        onChange={handleChange}
                        isInvalid={data.state == "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {states.map((state) => {
                          return (
                            <option value={state.name} key={state.isoCode}>
                              {state.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="city">
                      <Form.Label>
                        City <span className="star">*</span>
                      </Form.Label>
                      <Form.Select
                        name="city"
                        value={data.city}
                        onChange={handleChange}
                        isInvalid={data.city == "" && showEmptyError}
                      >
                        <option value="">Select</option>
                        {cities.map((city, ind) => {
                          return (
                            <option value={city} key={ind}>
                              {city}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="pin">
                      <Form.Label>
                        Pincode <span className="star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="pin"
                        value={data.pin}
                        onChange={handleChange}
                        isInvalid={data.pin == "" && showEmptyError}
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
        <hr />
        <Col sm={12} className="d-flex justify-content-start gap-2">
          <Button className="commonBtn smallBtn" onClick={handleSave}>
            {loadData ? (
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </Button>
          <Button
            className="commonBtn smallBtn cancelBtn"
            onClick={() => navigate("/admin/branch")}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default AddBranch;
