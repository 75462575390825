import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, FormControl, Row, Table } from "react-bootstrap";
import { BiSearch, BiSolidFileExport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../constants/toastAlert";

import { MdModeEditOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FaArrowLeftLong, FaFileExport } from "react-icons/fa6";
import { AiFillEye } from "react-icons/ai";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import html2pdf from "html2pdf.js";
import { DownloadTableExcel } from 'react-export-table-to-excel';

function ListTable() {
  const pdfRef = useRef(null);


  const loadData = useSelector((state) => state.loadReducer);
  const dispatch = useDispatch();
  const localData = JSON.parse(localStorage.getItem("userData")).data[0];
    let userID = localData.userid;
    let branchID = localData.branchid;
    let companyID = localData.companyid;
    let org = localData.org;
  
    let financialyear= localStorage.financialyear;
    // const financialyear = JSON.parse(financialyearString);

  // for by default date as a month this is connected to bellow date wise filtering functions
  const today = new Date();
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };
  const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const formattedStartOfMonth = formatDate(monthStart);
  const formattedEndOfMonth = formatDate(monthEnd);

  const navigate = useNavigate();
  // const loadData = useSelector((state) => state.loadReducer);

  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [show, setShow] = useState(false);
  const [referesh, setReferesh] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [fromDate, setFromDate] = useState(formattedStartOfMonth);
  const [toDate, setToDate] = useState(formattedEndOfMonth);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios

      .post(`https://mis.saanviavs.com/api/v1/report/ledgerouter`, {

        companyid:companyID,
        org,
        financialyear,
        fromDate,
        toDate,
      })
      .then((res) => {
        setData(res.data);

        setLoading(false);
        filterData1()
      })
      .catch((err) => console.log(err));
  }, [fromDate, toDate, selectedType]);

  const handleLeftArrow = () => {
    navigate("/reports");
  };

  // debu dropdown date range filter start

  const handleSelectedType = (event) => {
    const typeValue = event.target.value;
    setSelectedType(typeValue);
  };

  const handleSelectedRange = (event) => {
    const selectedValue = event.target.value;

    setSelectedRange(selectedValue);
    switch (selectedValue) {
      case "Today":
        handleToday();
        break;
      case "Yesterday":
        handleYesterday();
        break;
      case "This Week":
        handleThisWeek();
        break;
      case "This Month":
        handleThisMonth();
        break;
      case "This Quarter":
        handleThisQuarter();
        break;
      case "This Year":
        handleThisYear();
        break;
      case "Date Range":
        handleDaterange();
        break;
      default:
        handleThisMonth();
    }
  };

  // const today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
  var yyyy = today.getFullYear();
  var formattedTodayDate = dd + "-" + mm + "-" + yyyy;

  // const formatDate = (date) => {
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
  //     2,
  //     "0"
  //   )}-${String(date.getDate()).padStart(2, "0")}`;
  // };

  const handleToday = () => {
    const formattedToday = formatDate(today);

    setFromDate(formattedToday);
    setToDate(formattedToday);
  };

  const handleYesterday = () => {
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formattedYesterday = formatDate(yesterday);

    setFromDate(formattedYesterday);
    setToDate(formattedYesterday);
  };

  const handleThisWeek = () => {
    const weekStart = new Date(today);
    weekStart.setDate(today.getDate() - today.getDay());

    const weekEnd = new Date(today);
    weekEnd.setDate(today.getDate() + (6 - today.getDay()));

    const formattedweekStart = formatDate(weekStart);
    const formattedweekEnd = formatDate(weekEnd);

    setFromDate(formattedweekStart);
    setToDate(formattedweekEnd);
  };
  const handleThisMonth = () => {
    const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);

    const monthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const formattedStartOfMonth = formatDate(monthStart);
    const formattedEndOfMonth = formatDate(monthEnd);

    setFromDate(formattedStartOfMonth);
    setToDate(formattedEndOfMonth);
  };
  const handleThisQuarter = () => {
    const quarter = Math.floor((today.getMonth() + 3) / 3);

    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    let startOfQuarter;
    let endOfQuarter;
    let quarterNumber;

    if (currentMonth >= 0 && currentMonth <= 2) {
      // Quarter 1 (Jan - Mar)
      startOfQuarter = new Date(currentYear, 0, 1);
      endOfQuarter = new Date(currentYear, 2, 31);
      quarterNumber = 1;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      // Quarter 2 (Apr - Jun)
      startOfQuarter = new Date(currentYear, 3, 1);
      endOfQuarter = new Date(currentYear, 5, 30);
      quarterNumber = 2;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      // Quarter 3 (Jul - Sep)
      startOfQuarter = new Date(currentYear, 6, 1);
      endOfQuarter = new Date(currentYear, 8, 30);
      quarterNumber = 3;
    } else {
      // Quarter 4 (Oct - Dec)
      startOfQuarter = new Date(currentYear, 9, 1);
      endOfQuarter = new Date(currentYear, 11, 31);
      quarterNumber = 4;
    }
    const formattedStartOfQuarter = formatDate(startOfQuarter);
    const formattedEndOfQuarter = formatDate(endOfQuarter);

    setFromDate(formattedStartOfQuarter);
    setToDate(formattedEndOfQuarter);
  };
  const handleThisYear = () => {
    const currentYear = today.getFullYear();

    let financialYearStart;
    let financialYearEnd;

    if (today.getMonth() >= 3) {
      financialYearStart = new Date(currentYear, 3, 1);
    } else {
      financialYearStart = new Date(currentYear - 1, 3, 1);
    }
    if (today.getMonth() >= 3) {
      financialYearEnd = new Date(currentYear + 1, 2, 31);
    } else {
      financialYearEnd = new Date(currentYear, 2, 31);
    }

    const formattedStartOfYear = formatDate(financialYearStart);
    const formattedEndOfYear = formatDate(financialYearEnd);

    setFromDate(formattedStartOfYear);
    setToDate(formattedEndOfYear);
  };

  const handleDaterange = () => {
    setFromDate("");
    setToDate("");
  };

  // debu dropdown date range filter end

  // financila year should be in that same year to choose from date range start
  const [startYear, endYear] = financialyear.split("-").map(Number);

  // Create the financialYearStart and financialYearEnd dates
  const financialYearStart = new Date(startYear, 3, 1); // April 1, of the start year
  const financialYearEnd = new Date(endYear, 2, 32);

  const currentYear = new Date().getFullYear();

  const isCurrentYear = currentYear >= startYear && currentYear <= endYear;

  const handleFromDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    const selectedDate = new Date(e.target.value);

    // Check if the selected date is within the financial year
    if (selectedDate < financialYearStart || selectedDate > financialYearEnd) {
      // Show an alert or provide feedback that the selected date is not within the financial year
      alert("Please select a date within the financial year.");
      return;
    }

    setToDate(e.target.value);
  };
  // financila year should be in that same year to choose from date range end
  // const filterData1 = () => {
  //   if (!data) {
  //     // Data is not available yet
  //     return;
  //   }

  //   // Check if either fromDate or toDate is not empty
  //   if (fromDate || toDate) {
  //     // for from date
  //     const d = new Date(fromDate);
  //     const day = String(d.getDate()).padStart(2, "0");
  //     const month = String(d.getMonth() + 1).padStart(2, "0");
  //     const year = d.getFullYear();
  //     const newFromDate = `${day}-${month}-${year}`;

  //     // for to date
  //     const d1 = new Date(toDate);
  //     const day1 = String(d1.getDate()).padStart(2, "0");
  //     const month1 = String(d1.getMonth() + 1).padStart(2, "0");
  //     const year1 = d1.getFullYear();
  //     const newToDate = `${day1}-${month1}-${year1}`;

  //     const filtered = data.filter(
  //       (item) =>
  //         parseIndianStyleDate(item.date) >=
  //         parseIndianStyleDate(newFromDate) &&
  //         parseIndianStyleDate(item.date) <= parseIndianStyleDate(newToDate) &&
  //         Object.values(item).some((value) =>
  //           value
  //             ?.toString()
  //             ?.toLowerCase()
  //             ?.includes(searchValue.toLowerCase().trim())
  //         )
  //     );

  //     setFilteredData(filtered);
  //   } else {
  //     // No date range selected, consider only the search value
  //     const filtered = data.filter((item) =>
  //       Object.values(item).some((value) =>
  //         value
  //           ?.toString()
  //           ?.toLowerCase()
  //           ?.includes(searchValue.toLowerCase().trim())
  //       )
  //     );

  //     setFilteredData(filtered);
  //   }
  // };

  const filterData1 = () => {
    if (!data) {
      // Data is not available yet
      alert("data null");
      return;
    }

    // Check if either fromDate or toDate is not empty
    // if (fromDate || toDate) {
    //   // for from date
    //   const newFromDate = fromDate ? new Date(fromDate).toISOString() : '';

    //   // for to date
    //   const newToDate = toDate ? new Date(toDate).toISOString() : '';

    //   const filtered = data.length>0 ? data.filter(
    //     (item) =>
    //       item.date >= newFromDate &&
    //       item.date <= newToDate &&
    //       Object.values(item).some((value) =>
    //         value
    //           ?.toString()
    //           ?.toLowerCase()
    //           ?.includes(searchValue.toLowerCase().trim())
    //       )
    //   ) : []

    //   setFilteredData(filtered);
    // } else {
    // No date range selected, consider only the search value
    const filtered =
      data.length > 0
        ? data
            .filter((item) =>
              Object.values(item).some((value) =>
                value
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchValue.toLowerCase().trim())
              )
            )
            .filter((item) => {
              if (selectedType == "") {
                return true; // No status selected, don't filter by status
              } else if (selectedType == "Vendor") {
                return item.ledgerid.includes("VD");
              } else if (selectedType == "Customer") {
                return item.ledgerid.includes("CT");
              } else if (selectedType == "Bank") {
                return item.ledgerid.includes("BK");
              } else if (selectedType == "Other") {
                return (
                  (!item.ledgerid.includes("VD") &&
                    !item.ledgerid.includes("CT")) &&
                  !item.ledgerid.includes("BK")
                );
              }
            })
        : [];

    setFilteredData(filtered);
    // }
  };

  const parseIndianStyleDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
  };
  // inithially show monthly data
  useEffect(() => {
    handleThisMonth();
  }, []);

  useEffect(() => {
    filterData1();
  }, [fromDate, toDate, data, searchValue, selectedType]);

  // for date range end

  const handleView = (ledgerid) => {
    navigate(`/reports/ledgerReport/detailedAccountTransactions`, {
      state: { ledgerid },
    });
  };

  const handleExport = () => {
    // Create a new array to hold the data for export
    const exportData = filteredData.map((item) => ({
      "Ledger ID": item.ledgerid,
      Ledger: item.ledger,
      "Ledger Type": item.ledgertype,
      "Account ID": item.accountid,
      "Account Name": item.accountname,
      Debit: item.total_debit,
      Credit: item.total_credit,
      Balance: item.total_balance,
    }));

    // const csvData = Papa.unparse(exportData, {
    //   header: true, // Include headers based on object keys
    // });
    // const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    // saveAs(blob, `Ledger Report,${formattedTodayDate}.xls`); // FileSaver function to trigger download
    const headerStyle = {
      font: { bold: true },
      fill: { bgColor: { indexed: 22 } },
    };

    const ws = XLSX.utils.json_to_sheet(exportData, {
      header: Object.keys(exportData[0]),
      styles: {
        0: headerStyle, // Apply styles to the first (header) row
      },
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Ledger Report");
    XLSX.writeFile(wb, `Ledger Report_${formattedTodayDate}.xls`);
  };

  function formatDateToDDMMYYYY(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  }

    // downnload as a pdf

    // const handleDownload = () => {
    //   if (pdfRef.current) {
    //     const element = pdfRef.current;
    //     const options = {
    //       margin: 10,
    //       filename: "Legder.pdf",
    //       image: { type: "jpeg", quality: 2.98 },
    //       html2canvas: { scale: 2 },
    //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //     };
  
    //     html2pdf(element, options);
    //   }
    // };

    
    const handleDownload = () => {
      const element = pdfRef.current;
      const options = {
          margin: 10,
          filename: "Ledger Report.pdf",
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const content = `
      <div style="font-family: Arial, sans-serif;">
      <p style="font-size: 20px; font-weight: bold; margin-bottom: 20px;">
        List of All <span style="color: #ffa000;">Ledger Report</span>
      </p>
      <table style="width: 100%; border-collapse: collapse;">
        <thead>
          <tr style="background-color: #f0f0f0;">
          <th style="border: 1px solid black; padding: 6px;">Ledger ID</th>
          <th style="border: 1px solid black; padding: 6px;">Ledger</th>
          <th style="border: 1px solid black; padding: 6px;">Ledger Type</th>
          <th style="border: 1px solid black; padding: 6px;">Debit</th>
          <th style="border: 1px solid black; padding: 6px;">Credit</th>
          <th style="border: 1px solid black; padding: 6px;">Balance</th>

          
          </tr>
        </thead>
        <tbody>
        ${filteredData&&filteredData
          .map(
            (item, index) => `
          <tr key=${index}>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.ledgerid
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.ledger
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.ledgertype
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.total_debit
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.total_credit
            }</td>
            <td style="border: 1px solid #ddd; padding: 6px;">${
              item.total_balance
            }</td>
          </tr>
        `
          )
          .join("")}
      </tbody>
      </table>
    </div>
    `;
      //html2pdf(element, options);
      html2pdf().from(content).set(options).save();
  };

  return (
    <Row>
      <Col className="table-wrapper-container">
        <Row>
          <Col lg={8}>
            <p className="section-title form-section ptag">
              <FaArrowLeftLong
                className="left-arrow"
                onClick={handleLeftArrow}
              />
              List of All <span className="form-title">Ledgers</span>
            </p>
          </Col>
          <Col lg={2}>
            <div className="inputFieldIcon">
              <FormControl
                type="text"
                placeholder="Search..."
                aria-label="Search..."
                aria-describedby="basic-addon2"
                className="mt-2"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <span className="iconPosition">
                <BiSearch />
              </span>
            </div>
          </Col>
          <Col md={1} className="d-flex gap-3">
              <span>
                {/* <ReactHTMLTableToExcel
                  id="exportButton"
                  className="commonBtn smallBtn cancelBtn mt-2"
                  table="table-to-export"
                  filename={`ledger-${formatDateToDDMMYYYY(
                    fromDate
                  )} - ${formatDateToDDMMYYYY(toDate)}`}
                  sheet="trial_report"
                  buttonText={
                    <>
                      <BiSolidFileExport /> Export
                    </>
                  }
                /> */}

<DownloadTableExcel
                    filename="Sell table"
                    sheet="Sales"
                    currentTableRef={pdfRef.current}
                >

                   <Button className="commonBtn smallBtn cancelBtn mt-2"> Excel </Button>

                </DownloadTableExcel>

              </span>
              <span>
                <Button className="commonBtn smallBtn cancelBtn mt-2" onClick={handleDownload}>Save pdf</Button>
              </span>
            </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={2}>
            <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Type</Form.Label>
              <Form.Select
                className="mt-2 p-2"
                value={selectedType}
                onChange={handleSelectedType}
              >
                <option value="">All</option>
                <option value="Vendor">Vendor</option>
                <option value="Customer">Customer</option>
                <option value="Other">Other</option>
                <option value="Bank">Bank</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="d-flex gap-2 mt-4" controlId="formMonth">
              <Form.Label className="mt-3 text-color">Range</Form.Label>

              <Form.Select
                className="mt-2 p-2"
                value={selectedRange}
                onChange={handleSelectedRange}
                // disabled={!isCurrentYear}
              >
                <option disabled={!isCurrentYear} value="Today">
                  Today
                </option>
                <option disabled={!isCurrentYear} value="Yesterday">
                  Yesterday
                </option>
                <option disabled={!isCurrentYear} value="This Week">
                  This Week
                </option>
                <option disabled={!isCurrentYear} value="This Month">
                  This Month
                </option>
                <option disabled={!isCurrentYear} value="This Quarter">
                  This Quarter
                </option>
                <option disabled={!isCurrentYear} value="This Year">
                  This Year
                </option>
                <option value="Date Range">Date Range</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group
              className="d-flex gap-2 mt-4"
              controlId="formBasicEmail"
            >
              <Form.Label className="mt-3 text-color">From</Form.Label>
              <Form.Control
                disabled={selectedRange != "Date Range"}
                className="mt-2 p-2"
                type="date"
                name="from"
                value={fromDate}
                onChange={handleFromDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              <Form.Control.Feedback type="invalid">
                Select Date
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group
              className="d-flex gap-2 mt-4"
              controlId="formBasicEmail"
            >
              <Form.Label className="mt-3 text-color">To</Form.Label>
              <Form.Control
                disabled={selectedRange != "Date Range"}
                className="mt-2 p-2"
                type="date"
                name="to"
                value={toDate}
                onChange={handleToDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
              <Form.Control.Feedback type="invalid">
                Select Date
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col md={12} className="table-main-container" ref={pdfRef}>
        <Table id="table-to-export" responsive>
          <thead className="tableContainer">
            <tr>
              <th>Ledger ID</th>
              <th>Ledger</th>
              <th>Ledger Type</th>
              {/* <th>Account ID</th> */}
              {/* <th>Account Name</th> */}
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loadData || loading ? (
              <tr>
                <td colSpan="9" className="text-center">
                  <div
                    className="spinner-border spinner-border-md"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                </td>
              </tr>
            ) :filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.ledgerid}</td>
                  <td>{item.ledger}</td>
                  <td>{item.ledgertype}</td>
                  {/* <td>{item.accountid}</td> */}
                  {/* <td>{item.accountname}</td> */}
                  <td>{item.total_debit}</td>
                  <td>{item.total_credit}</td>
                  <td>{item.total_balance}</td>
                  <td onClick={() => handleView(item.ledgerid)}>
                    {" "}
                    <AiFillEye className="editIcon" />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9">
                  <div className="datanotfound-center-text">
                    No Record Found!! 🤔
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
}

export default ListTable;
